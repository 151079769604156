<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div v-if="!(loading === false)">Loading</div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Einheit konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <d-row v-else no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span
          class="text-uppercase page-subtitle"
          @click="$router.push({ name: 'units' })"
          style="cursor: pointer"
          >Inhalte</span
        >
        <h3 class="page-title">
          <template v-if="edit_title == false">
            {{ data.name
            }}<br class="d-sm-none"><small style="cursor:pointer;" @click="edit_title = true" v-if="auth_modify"><span class="material-icons text-secondary ml-2" >edit</span><span class="d-sm-none">bearbeiten</span></small>
          </template>
          <div v-else class="d-flex mr-5">
            <b-form-input v-model="edit_name" :placeholder="data.name"/>
            <b-button class="mx-2" @click="handleEditTitle">Ändern</b-button>
            <span
              class="material-icons text-secondary ml-2 my-auto"
              style="cursor: pointer"
              @click="edit_title = false"
              >close</span
            >
          </div>
        </h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3">
        <b-form-group
          label="Kategorie"
          label-size="sm"
          label-align="center"
          label-align-sm="left"
          label-class="pb-1"
          class="text-center text-sm-left"
        >
          <CategorySelector :selectable="false" />
        </b-form-group>
        <div class="text-center text-danger d-sm-none" v-if="!auth_modify">Keine Berechtigung zum Editieren</div>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <!-- Component Header -->
          <d-card-header class="border-bottom d-none d-sm-flex justify-content-between">
            <h6 class="m-0">Details</h6>
            <div class="text-danger" v-if="!auth_modify">Keine Berechtigung zum Editieren</div>
          </d-card-header>

          <d-card-body class="d-flex flex-column px-0 px-lg-3">
            <Unit
              ref="course_card"
              :id="id"
              v-on:finish="finish"
            />
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import { mapGetters } from "vuex";
import Unit from "@/components/education/unit/Unit";
export default {
  name: "UnitShow",
  components: {
    CategorySelector,
    Unit
  },
  data() {
    return {
      notFound: false,
      loading: null,
      auth_modify: false,
      id: parseInt(this.$route.params.id),
      data: null,
      edit_title: false,
      edit_name: null,
    };
  },
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
    }),
  },
  methods: {
    async init() {
      this.loading = true;
      // get data
      this.data = null;
      this.data = await this.$store
        .dispatch("getUnit", this.id)
        .catch((err) => {
          if (!err.message.includes("NOT_FOUND")) throw err;
          // notFound
          this.notFound = true;
          this.loading = false;
        });
      if (!this.data) return;
      this.$store.dispatch("updateSelectedCategory", this.data.category);
      this.loading = false;
    },
    async handleEditTitle() {
      await this.$store.dispatch("updateUnit", {
        id: this.data.id,
        name: this.edit_name,
      });
      this.init();
      this.edit_title = false;
    },
    finish(result) {
      this.$router.push({name: "units"});
    }
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "unit_modify"
    );
    await this.init();
  },
};
</script>
