<template>
  <b-list-group>
    <b-list-group-item>
      <div class="d-flex justify-content-between">
        <h5>Barzahlung</h5>
        <div
          :class="
            settings_obj.payment_bar.value.current
              ? 'text-success'
              : 'text-secondary'
          "
        >
          {{
            settings_obj.payment_bar.value.current ? "Aktiv" : "Ausgeschlossen"
          }}
        </div>
      </div>
      <PaymentBar />
    </b-list-group-item>
    <b-list-group-item>
      <div
        class="d-flex justify-content-between"
        v-b-toggle.payment_transfer-collapse
      >
        <h5>Überweisung</h5>
        <div
          :class="
            settings_obj.payment_transfer.value.current
              ? 'text-success'
              : 'text-secondary'
          "
        >
          {{
            settings_obj.payment_transfer.value.current
              ? "Aktiv"
              : "Ausgeschlossen"
          }}
        </div>
      </div>
      <PaymentTransfer />
    </b-list-group-item>
    <b-list-group-item>
      <div class="d-flex justify-content-between">
        <h5>Kartenzahlung</h5>
        <div
          :class="
            settings_obj.payment_card.value.current
              ? 'text-success'
              : 'text-secondary'
          "
        >
          {{
            settings_obj.payment_card.value.current ? "Aktiv" : "Ausgeschlossen"
          }}
        </div>
      </div>
      <PaymentCard />
    </b-list-group-item>
    <b-list-group-item>
      <div class="d-flex justify-content-between">
        <h5>Finanzierung</h5>
        <div
          :class="
            settings_obj.payment_credit.value.current
              ? 'text-success'
              : 'text-secondary'
          "
        >
          {{
            settings_obj.payment_credit.value.current
              ? "Aktiv"
              : "Ausgeschlossen"
          }}
        </div>
      </div>
      <PaymentCredit />
    </b-list-group-item>
  </b-list-group>
</template>

<script>
import { mapGetters } from "vuex";
import PaymentBar from "./PaymentBar.vue";
import PaymentCard from "./PaymentCard.vue";
import PaymentCredit from "./PaymentCredit.vue";
import PaymentTransfer from "./PaymentTransfer.vue";
export default {
  components: {
    PaymentBar,
    PaymentTransfer,
    PaymentCard,
    PaymentCredit,
  },
  computed: {
    ...mapGetters(["settings_obj"]),
  },
};
</script>

<style>
</style>
