<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle"
          >Firma</span
        >
        <h3 class="page-title">Filialen</h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3" class="text-center text-md-right">
        <b-dropdown
          v-if="branches.length > 0"
          right
          text="Weitere Aktionen"
          variant="outline-light"
          size="sm"
        >
          <b-dropdown-item-button
            :disabled="!auth_modify"
            @click="$router.push({ name: 'branch_new' })"
            >Neue Filiale</b-dropdown-item-button
          >
          <b-dropdown-item-button
            disabled
            @click="handleViewManageBranchesOccupation"
            >Dienstplanung</b-dropdown-item-button
          >
          <b-dropdown-item-button disabled @click="handleViewClosedDates"
            >Geschlossene Tage</b-dropdown-item-button
          >
        </b-dropdown>
      </b-col>
    </d-row>
    <b-row class="mb-5 justify-content-center">
      <b-col v-if="branches.length == 0" cols="12" class="text-center">
        <div class="my-5 d-none d-md-block">
          Sie haben noch keine Filialen erstellt.
        </div>
        <b-button
          variant="outline-secondary"
          :disabled="!auth_modify"
          @click="$router.push({ name: 'branch_new' })"
          >Filiale hinzufügen</b-button
        >
      </b-col>
      <b-col v-else v-for="branch in branches" :key="branch.id" cols="12" md="6" lg="4" class="mb-2">
        <b-card
          style="cursor: pointer"
          :img-src="branch.thumbnail_file_id ? $api.defaults.baseURL+`company/branch/${branch.id}/file/${branch.thumbnail_file_id}` : null"
          img-alt="Image"
          img-top
          body-class="p-0"
          @click="$router.push({ name: 'branch', params: { id: branch.id } })"
        >
          <b-card-body class="px-2 px-lg-4">
            <b-card-title>{{ branch.name }}</b-card-title>
            <b-card-text>
              <div class="font-weight-bold mt-2">Öffnungszeiten</div>
              <OpenHours v-bind:data="branch.openHours" class="ml-1" />
              <div class="font-weight-bold mt-2">Addresse</div>
              <span class="text-danger" v-if="!branch.address">
                <span class="font-weight-bold">Achtung: </span>noch keine
                Adresse hinterlegt.
              </span>
              <template v-else>
                <div>
                  {{ branch.address.street }} {{ branch.address.streetnumber }}
                </div>
                <div>{{ branch.address.zip }} {{ branch.address.city }}</div>
              </template>
              <template v-if="branch.phone_numbers.length > 0">
                <div class="font-weight-bold">Telefon/Fax:</div>
                <PhoneNumbers v-bind:data="branch.phone_numbers" class="ml-1" />
              </template>
              <template v-if="branch.emails.length > 0">
                <div class="font-weight-bold">E-Mails:</div>
                <Emails v-bind:data="branch.emails" class="ml-1" />
              </template>
            </b-card-text>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import Emails from "@/components/communication/Emails.vue";
import PhoneNumbers from "@/components/communication/PhoneNumbers.vue";
import OpenHours from "@/components/company/branch/OpenHours.vue";
export default {
  name: "Branches",
  components: {
    Emails,
    PhoneNumbers,
    OpenHours,
  },
  data() {
    return {
      auth_modify: false,
    };
  },
  computed: {
    ...mapGetters(["branches"]),
  },
  methods: {
    handleViewManageBranchesOccupation() {},
    handleViewClosedDates() {},
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "branch_modify"
    );
  },
};
</script>
