<template>
  <b-form-group>
    <div
      class="d-flex"
      v-b-popover.bottom.hover="settings_obj.payment_card.description"
    >
      Kartenzahlung erlaubt:
      <b-form-checkbox
        class="ml-2"
        switch
        :checked="settings_obj.payment_card.value.current"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'payment_card',
              value: !!val,
            })
        "
      />
    </div>
    <b-collapse
      v-model="settings_obj.payment_card.value.current"
      class="ml-2 small"
    >
      <div
        class="d-flex font-weight-bold mt-2"
        v-b-popover.bottom.hover="settings_obj.payment_card_giro.description"
      >
        <b-form-checkbox
          :checked="!!settings_obj.payment_card_giro.value.current"
          switch
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_card_giro',
                value: !!val,
              })
          "
        />
        Giro / EC-Karte
      </div>
      <div class="d-flex font-weight-bold mt-2">
        <b-form-checkbox
          switch
          :checked="
            settings_obj.payment_card_amex.value.current ||
            settings_obj.payment_card_mastercard.value.current ||
            settings_obj.payment_card_visa.value.current
          "
          disabled
        />
        Kreditkarten
      </div>
      <b-input-group
        class="ml-5"
        v-b-popover.bottom.hover="settings_obj.payment_card_amex.description"
      >
        <b-form-checkbox
          :checked="!!settings_obj.payment_card_amex.value.current"
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_card_amex',
                value: !!val,
              })
          "
        />
        American Express
      </b-input-group>
      <b-input-group
        class="ml-5"
        v-b-popover.bottom.hover="
          settings_obj.payment_card_mastercard.description
        "
      >
        <b-form-checkbox
          :checked="!!settings_obj.payment_card_mastercard.value.current"
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_card_mastercard',
                value: !!val,
              })
          "
        />
        MasterCard
      </b-input-group>
      <b-input-group
        class="ml-5"
        v-b-popover.bottom.hover="settings_obj.payment_card_visa.description"
      >
        <b-form-checkbox
          :checked="!!settings_obj.payment_card_visa.value.current"
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_card_visa',
                value: !!val,
              })
          "
        />
        Visa
      </b-input-group>
      <div
        class="d-flex font-weight-bold mt-2"
        v-b-popover.bottom.hover="
          settings_obj.payment_card_contactless.description
        "
      >
        <b-form-checkbox
          switch
          :checked="settings_obj.payment_card_contactless.value.current"
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_card_contactless',
                value: !!val,
              })
          "
        />
        Kontaktloses Zahlen
      </div>
      <b-collapse
        v-model="settings_obj.payment_card_contactless.value.current"
        class="ml-5"
      >
        <b-input-group
          v-b-popover.bottom.hover="
            settings_obj.payment_card_google.description
          "
        >
          <b-form-checkbox
            :checked="!!settings_obj.payment_card_google.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_card_google',
                  value: !!val,
                })
            "
          />
          Google Pay
        </b-input-group>
        <b-input-group
          v-b-popover.bottom.hover="settings_obj.payment_card_apple.description"
        >
          <b-form-checkbox
            :checked="!!settings_obj.payment_card_apple.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_card_apple',
                  value: !!val,
                })
            "
          />
          Apple Pay
        </b-input-group>
      </b-collapse>
      <b-form-group
        class="mt-2"
        label="Mindestumsatz"
        v-b-popover.bottom.hover="settings_obj.payment_card_min.description"
      >
        <div class="d-flex">
          <b-form-checkbox
            :checked="!!settings_obj.payment_card_min.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_card_min',
                  value: val ? '50 €' : null,
                })
            "
          />
          <b-input-group append="€">
            <b-form-input
              size="sm"
              :value="settings_obj.payment_card_min.value.current"
              :disabled="settings_obj.payment_card_min.value.current === null"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'payment_card_min',
                    value:
                      (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                  })
              "
              debounce="2000"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-collapse>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "payment_card",
      "payment_card_min",
      "payment_card_amex",
      "payment_card_apple",
      "payment_card_contactless",
      "payment_card_giro",
      "payment_card_google",
      "payment_card_mastercard",
      "payment_card_visa",
    ]);
  },
};
</script>

<style>
</style>
