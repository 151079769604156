<template>
  <d-container fluid class="main-content-container px-lg-4">
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">Unternehmen</span>
        <h3 class="page-title">Bewertungen</h3>
      </b-col>
      <b-col>
        <div class="d-flex justify-content-between">
          Ansicht:
          <div>
            <i
              @click="setView(1)"
              class="material-icons"
              :class="view == 1 ? 'text-primary' : ''"
              style="cursor: pointer"
              >view_module</i
            >
            |
            <i
              @click="setView(2)"
              class="material-icons"
              :class="view == 2 ? 'text-primary' : ''"
              style="cursor: pointer"
              >list</i
            >
          </div>
        </div>
        <div class="d-flex justify-content-between my-1">
          Status:
          <b-form-select
            size="sm"
            class="ml-3"
            :options="stati"
            v-model="status"
          ></b-form-select>
        </div>
        <div class="d-flex justify-content-between">
          Filter:
          <b-form-select
            size="sm"
            class="ml-3"
            :options="filters"
            v-model="filter"
          ></b-form-select>
        </div>
      </b-col>
    </d-row>
    <!-- View: Loading (NULL) -->
    <b-row v-if="!loaded">
      <b-col cols="12" class="text-center">
        <b-row class="justify-content-center">
          <b-col cols="6" md="5" lg="4">
            <b-skeleton width="100%" height="4rem"> </b-skeleton>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="mt-2" v-if="view == 1">
        <b-row v-for="num in [1, 2]" :key="num">
          <b-col cols="12" md="3" class="mb-3"
            ><b-skeleton
              animation="wave"
              width="100%"
              height="15rem"
            ></b-skeleton
          ></b-col>
          <b-col cols="12" md="3" class="mb-3"
            ><b-skeleton
              animation="wave"
              width="100%"
              height="15rem"
            ></b-skeleton
          ></b-col>
          <b-col cols="12" md="3" class="mb-3"
            ><b-skeleton
              animation="wave"
              width="100%"
              height="15rem"
            ></b-skeleton
          ></b-col>
          <b-col cols="12" md="3" class="mb-3"
            ><b-skeleton
              animation="wave"
              width="100%"
              height="15rem"
            ></b-skeleton
          ></b-col>
        </b-row>
      </b-col>
      <b-col class="mt-2" v-if="view == 2">
        <b-skeleton
          v-for="num in [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
          class="mb-2"
          :key="num"
          animation="wave"
          width="100%"
          height="3rem"
        ></b-skeleton>
      </b-col>
    </b-row>
    <template v-else>
      <b-row v-if="reviews.length == 0" class="justify-content-center mb-3">
        <b-col cols="auto" class="text-center">
          <div class="font-weight-bold">
            Es sind noch keine Bewertungen vorhanden.
          </div>
          <div class="font-italic">
            Hinweis: Verknüpfen Sie Fremdsysteme um Bewertungen von anderen
            Plattformen importieren und verwalten zu können.<br />Diese finden
            Sie unter:
            <span class="font-weight-bold"
              >System -> Einstellungen -> Schnittstellen</span
            >
          </div>
        </b-col>
      </b-row>
      <b-row v-else class="justify-content-center mb-3">
        <b-col cols="auto" class="text-center">
          Anzahl: {{ filteredReviews.length }} <br />
          Durchschnitt:
          {{
            parseFloat(
              (Math.round((average_rating / 20) * 100) / 100).toFixed(2)
            )
          }}
          <i class="material-icons">star</i>
        </b-col>
      </b-row>
      <!-- View: Dashboard (1) -->
      <template v-if="view === 1">
        <div
          v-if="reviews.length != 0 && filteredReviews.length == 0"
          class="text-center font-weight-bold"
        >
          Es können leider keine Bewertungen zu den von Ihnen angegebenen
          Einschränkungen angezeigt werden.
        </div>
        <b-row style="position: relative;">
          <b-col cols="12" class="text-right" style="position: absolute; top: -2.6rem; right: 0;">
            <span style="cursor: pointer;" class="p-2 pr-1" :class="sort_rating==0 ? 'text-muted' : ''" @click="sort_rating = (sort_rating==-1) ? 1 : -1; sort_date = 0;"
              ><i class="material-icons">star</i
              ><i class="material-icons" v-if="sort_rating==-1 || sort_rating == 0">south</i
              ><i class="material-icons" v-if="sort_rating==1">north</i></span
            >
            <span style="cursor: pointer;" class="p-2 pl-1" :class="sort_date==0 ? 'text-muted' : ''" @click="sort_date = (sort_date==-1) ? 1 : -1; sort_rating = 0"
              ><i class="material-icons">calendar_today</i
              ><i class="material-icons" v-if="sort_date==0 || sort_date==-1">south</i
              ><i class="material-icons" v-if="sort_date==1">north</i></span
            >
          </b-col>
        </b-row>
        <b-row
          v-for="(row, idx) in dashboardMappedReviews"
          :key="idx"
          align-v="stretch"
        >
          <b-col
            cols="12"
            md="6"
            lg="3"
            v-for="(review, index) in row"
            :key="index"
            class="mb-4"
          >
            <b-card
              class="h-100"
              style="cursor: pointer"
              body-class="p-0 pt-1"
              footer-bg-variant="light"
              footer-class="py-2"
              @click="showReview(review.id)"
            >
              <b-card-title>
                <div class="d-flex justify-content-between h6 mr-2">
                  <div class="mt-2 ml-2">
                    <b-badge
                      v-if="review.status == 'created'"
                      class="mr-2 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.5rem;
                        padding: 0.3rem;
                      "
                      variant="primary"
                      >NEU</b-badge
                    >
                    <b-badge
                      v-if="review.status == 'published'"
                      class="mr-2 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.5rem;
                        padding: 0.3rem;
                      "
                      variant="success"
                      >Veröffentlicht</b-badge
                    >
                    <b-badge
                      v-if="review.status == 'unpublished'"
                      class="mr-2 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.5rem;
                        padding: 0.3rem;
                      "
                      variant="secondary"
                      >Privat</b-badge
                    >
                    <b-badge
                      v-if="review.rating < 50 && review.text && !review.answer"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.5rem;
                        padding: 0.3rem;
                      "
                      class="mr-2"
                      variant="warning"
                      v-b-popover.hover="'Sollte Beantwortet werden'"
                      ><i class="material-icons text-white"
                        >priority_high</i
                      ></b-badge
                    >
                    <b-badge
                      v-if="review.answer"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.5rem;
                        padding: 0.3rem;
                      "
                      class="mr-2"
                      variant="success"
                      v-b-popover.hover="{
                        html: true,
                        content:
                          'Beantwortet: <br>' +
                          review.answer
                            .split('\r\n')
                            .join('<br>')
                            .split('\n')
                            .join('<br>'),
                      }"
                      ><i class="material-icons">mark_email_read</i></b-badge
                    >
                  </div>
                  <div>{{ review.create_date | moment("D. MMM YYYY") }}</div>
                </div>
                <div class="font-weight-bold ml-3 mb-2 small">
                  {{ review.author_name }}
                </div>
                <div
                  class="font-weight-bold ml-3 mb-2"
                  v-if="!review.author_name"
                >
                  {{ review.author_email }}
                </div>
                <b-form-rating
                  v-if="review.rating"
                  readonly
                  no-border
                  show-value
                  :value="review.rating / 20"
                  stars="5"
                ></b-form-rating>
                <div v-else class="text-center">keine Bewertung angegeben</div>
              </b-card-title>
              <b-card-body
                v-if="review.text && review.text.trim().length > 0"
                class="pt-0 pb-2 px-2"
              >
                <div class="small ml-2">
                  {{
                    review.text.split(" ").slice(0, -1).join(" ").length > 120
                      ? review.text
                          .substr(0, 120)
                          .split(" ")
                          .slice(0, -1)
                          .join(" ") + "..."
                      : review.text
                  }}
                </div>
              </b-card-body>

              <template #footer>
                <div class="d-flex justify-content-between">
                  <div
                    class="small"
                    v-if="
                      review.reference && review.reference.includes('branch')
                    "
                  >
                    Filiale:
                    <b-link
                      :to="{
                        name: 'branch',
                        params: {
                          id: branches.find(
                            (branch) =>
                              branch.id == review.reference.split('_')[1]
                          ).id,
                        },
                      }"
                      >{{
                        branches.find(
                          (branch) =>
                            branch.id == review.reference.split("_")[1]
                        ).name
                      }}</b-link
                    >
                  </div>
                  <div
                    v-if="review.source.includes('google')"
                    v-b-popover.hover="{
                      html: true,
                      content: `Ursprung: <span class='font-weight-bold'>Google My Business API</span>`,
                    }"
                  >
                    <i class="fab fa-google" />
                  </div>
                  <div
                    v-if="review.source.includes('system')"
                    v-b-popover.hover="{
                      html: true,
                      content: `Ursprung: <span class='font-weight-bold'>Webseite</span>`,
                    }"
                  >
                    <i class="fa fa-home" />
                  </div>
                </div>
              </template>
            </b-card>
          </b-col>
        </b-row>
      </template>
      <!-- View: List (2) -->
      <d-row v-else-if="view === 2">
        <d-col class="p-0 p-md-3 mb-4">
          <d-card class="card-small" v-if="filteredReviews.length">
            <d-card-body class="d-flex flex-column p-0">
              <b-table
                ref="review_table"
                :items="filteredReviews"
                :fields="fields"
                sort-by="create_date"
                :sort-desc="true"
                hover
                selectable
                select-mode="single"
                @row-selected="onRowSelected"
                class="border-top-0"
              >
                <template #cell(author_name)="data">
                  <div>
                    <b-badge
                      v-if="data.item.status == 'created'"
                      class="mr-1 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.1rem;
                        padding: 0.3rem;
                      "
                      variant="primary"
                      >NEU</b-badge
                    >
                    <b-badge
                      v-if="data.item.status == 'published'"
                      class="mr-1 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.1rem;
                        padding: 0.3rem;
                      "
                      variant="success"
                      >Veröffentlicht</b-badge
                    >
                    <b-badge
                      v-if="data.item.status == 'unpublished'"
                      class="mr-1 text-white"
                      style="
                        font-size: 60%;
                        position: relative;
                        top: -0.1rem;
                        padding: 0.3rem;
                      "
                      variant="secondary"
                      >Privat</b-badge
                    >
                    <template v-if="data.item.user_id">
                      {{ getUser(data.item.user_id).name }}
                    </template>
                    <template v-else-if="data.item.author_name">
                      {{ data.item.author_name }}
                    </template>
                    <template v-else-if="data.item.author_email">
                      {{ data.item.author_email }}
                    </template>
                  </div>
                  <div
                    class="small font-italic ml-2"
                    v-if="data.item.text && data.item.text.trim().length > 0"
                  >
                    {{
                      data.item.text.length > 80
                        ? data.item.text
                            .substr(0, 80)
                            .split(" ")
                            .slice(0, -1)
                            .join(" ") + "..."
                        : data.item.text
                    }}
                  </div>
                </template>
                <template #cell(rating)="data">
                  <b-form-rating
                    :value="data.item.rating / 20"
                    stars="5"
                    size="sm"
                    inline
                    show-value
                    no-border
                    readonly
                    precision="2"
                    class="mr-2"
                  ></b-form-rating>
                  <div class="d-inline-block">
                    <template v-if="data.item.source.includes('google')">
                      <i
                        class="pr-2 fab fa-google"
                        style="cursor: pointer"
                        v-b-popover.hover="{
                          html: true,
                          content: `Ursprung: <span class='font-weight-bold'>Google My Business</span>`,
                        }"
                      ></i>
                    </template>
                    <b-badge
                      v-if="
                        data.item.reference &&
                        data.item.reference.includes('branch_')
                      "
                      :to="{
                        name: 'branch',
                        params: { id: data.item.reference.split('_')[1] },
                      }"
                      class="mr-2"
                      ><i class="material-icons">home</i>
                      {{
                        branches.find(
                          (branch) =>
                            branch.id == data.item.reference.split("_")[1]
                        ).name
                      }}</b-badge
                    >
                  </div>
                  <b-badge
                    v-if="
                      data.item.rating < 50 &&
                      data.item.text &&
                      !data.item.answer
                    "
                    class="mr-2"
                    variant="warning"
                    v-b-popover.hover="'Sollte Beantwortet werden'"
                    ><i class="material-icons text-white"
                      >priority_high</i
                    ></b-badge
                  >
                  <b-badge
                    v-if="data.item.answer"
                    class="mr-2"
                    variant="success"
                    v-b-popover.hover="{
                      html: true,
                      content:
                        'Beantwortet: <br>' +
                        data.item.answer
                          .split('\r\n')
                          .join('<br>')
                          .split('\n')
                          .join('<br>'),
                    }"
                    ><i class="material-icons">mark_email_read</i></b-badge
                  >
                </template>
                <template #cell(create_date)="data">
                  <div class="d-none d-lg-inline-block">
                    {{ data.item.create_date | moment("D. MMM YY") }}
                  </div>
                  <div class="d-inline-block d-lg-none">
                    {{ data.item.create_date | moment("DD.MM.YYYY") }}
                  </div>
                </template>
              </b-table>
            </d-card-body>
          </d-card>
        </d-col>
      </d-row>
    </template>
    <b-modal
      size="lg"
      centered
      ref="review_details"
      hide-footer
      body-class="pt-2"
    >
      <template #modal-header="{ close }">
        <b-container fluid>
          <b-row class="justify-content-center">
            <b-col class="text-center">
              <span>{{ review_show.author_name }}</span>
              <span v-if="!review_show.author_name">{{
                review_show.author_email
              }}</span>
              <br />
              <b-form-rating
                :value="review_show.rating / 20"
                stars="5"
                size="sm"
                inline
                no-border
                readonly
              ></b-form-rating>
              <div class="ml-3 m-2">
                <b-button
                  v-if="review_show.status !== 'published'"
                  size="sm"
                  class="mr-1"
                  variant="outline-dark"
                  @click="publish(review_show.id)"
                  >Veröffentlichen</b-button
                >
                <b-button
                  v-if="review_show.status !== 'unpublished'"
                  size="sm"
                  variant="outline-dark"
                  @click="unpublish(review_show.id)"
                  >Verstecken</b-button
                >
              </div>
            </b-col>
          </b-row>
        </b-container>
        <div style="position: absolute; top: 0; left: 0" class="ml-3 m-2">
          <span v-if="review_show.status == 'created'" class="text-primary"
            >Neu</span
          >
          <span v-if="review_show.status == 'published'" class="text-success"
            >Veröffentlicht</span
          >
          <span
            v-if="review_show.status == 'unpublished'"
            class="text-secondary"
            >Privat</span
          >
        </div>
        <b-button
          class="text-secondary m-2"
          style="position: absolute; top: 0; right: 0; text-decoration: none"
          variant="link"
          @click="close"
          >X</b-button
        >
      </template>
      <template #default="">
        <b-container fluid>
          <b-row>
            <!-- Text -->
            <b-col v-if="review_show.text" class="p-0">
              <div class="small font-weight-bold">Bewertungstext</div>
              <div class="pl-2">
                {{ review_show.text }}
              </div>
            </b-col>
            <!-- Herkunft -->
            <b-col class="p-0" v-if="review_show.source != 'system'">
              <div class="small font-weight-bold">Herkunft</div>
              <div class="pl-2">
                <template v-if="review_show.source.includes('google')">
                  <i class="fab fa-google mr-1" /><b-link
                    href="https://www.google.com/intl/de_de/business/"
                    target="_blank"
                    class="font-weight-bold"
                    >Google My Business</b-link
                  ><br />
                  Dieses Review gehört zur Filiale:
                  <b-link
                    target="_blank"
                    :to="{
                      name: 'branch',
                      params: {
                        id: branches.find(
                          (el) => el.id == review_show.reference.split('_')[1]
                        ).id,
                      },
                    }"
                    >{{
                      branches.find(
                        (el) => el.id == review_show.reference.split("_")[1]
                      ).name
                    }}</b-link
                  >
                </template>
              </div>
            </b-col>
          </b-row>
          <!-- Answer -->
          <b-row>
            <b-col cols="12" class="p-0">
              <div class="small font-weight-bold">Antwort</div>
              <div class="ml-2">
                <template v-if="review_show_edit_answer">
                  <b-form-textarea
                    v-model="review_show_newanswer"
                  ></b-form-textarea>
                  <b-button class="mt-1" @click="review_show_updateAnswer"
                    >Aktualisieren</b-button
                  >
                  <div v-if="answerHint.find(hint => review_show.source.includes(hint.source))" class="text-muted small" v-html="answerHint.find(hint => review_show.source.includes(hint.source)).html"></div>
                  <div v-if="answerHint.find(hint => hint.source == 'default')" class="text-muted small" v-html="answerHint.find(hint => hint.source == 'default').html"></div>
                </template>
                <template v-else-if="review_show.answer">
                  <div
                    v-html="
                      review_show.answer
                        .split('\r\n')
                        .join('<br>')
                        .split('\n')
                        .join('<br>')
                    "
                  ></div>
                  <br />
                  <b-button @click="review_show_edit_answer = true"
                    >Antwort bearbeiten</b-button
                  >
                </template>
                <template v-else>
                  noch keine Antwort vorhanden<br />
                  <b-button @click="review_show_edit_answer = true"
                    >Antwort hinzufügen</b-button
                  >
                </template>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "Reviews",
  data() {
    return {
      loaded: false,
      view: null,
      stati: [
        {
          text: "Alle",
          value: null,
        },
        {
          text: "Neu",
          value: "created",
        },
        {
          text: "Öffentlich",
          value: "published",
        },
        {
          text: "Privat",
          value: "unpublished",
        },
      ],
      status: null,
      filter: null,
      branch_locations: null,
      average_rating: null,
      // Show
      review_show: null,
      review_show_newanswer: null,
      review_show_edit_answer: false,
      // Dashboard
      sort_date: -1, // -1 desc, 0 - none, 1 asc
      sort_rating: 0, // -1 descr, 0 - none, 1 asc
      // List
      fields: [
        {
          key: "author_name",
          label: "Bewertung",
        },
        {
          key: "rating",
          label: "Bewertung",
          sortable: true,
        },
        {
          key: "create_date",
          label: "Datum",
          sortable: true,
        },
      ],
      answerHint: [
        {
          source: "default",
          html: "Bitte beachten Sie die weiteren <a href='https://fahrschule-api.de/docs/Hinweise_zur_Nutzung_des_Bewertungssystems.docx' target='_blank'>Hinweise zur Nutzung des Bewertungssystems</a>."
        },
        {
          source: "system",
          html: "",
        },
        {
          source: "google_mybusiness",
          html: "Beachten Sie, dass Ihre Antwort öffentlich auf Google angezeigt wird und den Inhaltsrichtlinien für lokale Brancheneinträge bei Google entsprechen muss.<br><a href='https://support.google.com/contributionpolicy/answer/7400114?hl=de&_ga=2.89521823.561634942.1610379469-117520532.1588330113' target='_blank'>Nutzungsbedingungen</a><br>Die Antwort wird auch dann öffentlich angezeigt, wenn die Bewertung intern verborgen wurde."
        }
      ]
    };
  },
  computed: {
    ...mapGetters(["reviews", "branches"]),
    filteredReviews() {
      return JSON.parse(JSON.stringify(this.reviews))
        .filter(
          (review) => this.status === null || review.status == this.status
        )
        .map((review) => {
          if (review.source.includes("google")) {
            // get Branch from reference
            let branch_id = this.getBranchIdFromLocationId(
              review.reference.split("/")[1]
            );
            review.reference = branch_id ? `branch_${branch_id}` : null;
            review.text =
              review.text && review.text.includes("(Translated by Google)")
                ? review.text.split("(Translated by Google)")[0]
                : review.text;
          }
          return review;
        })
        .filter(
          (review) =>
            this.filter === null ||
            review.reference == this.filter ||
            (this.filter === "none" && review.reference == null)
        )
        .sort((a, b) =>
          moment(a.create_date, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(
            moment(b.create_date, "YYYY-MM-DD HH:mm:ss")
          )
            ? 1
            : -1
        );
    },
    filters() {
      let output = [{ text: "Alle", value: null }];
      this.reviews.forEach((review) => {
        if (review.source.includes("google")) {
          // get Branch from reference
          let branch_id = this.getBranchIdFromLocationId(
            review.reference.split("/")[1]
          );
          if (
            branch_id &&
            !output.find((el) => el.value == "branch_" + branch_id)
          )
            output.push({
              text:
                "Filiale: " +
                this.branches.find((branch) => branch.id == branch_id).name,
              value: "branch_" + branch_id,
            });
        }
      });
      output.push({ text: "Ohne", value: "none" });
      return output;
    },
    dashboardMappedReviews() {
      let output = [];
      let helper = [];
      this.filteredReviews
        // Sort Date
        .sort((a, b) => {
          if (this.sort_date == 0) return 0;
          if (
            this.sort_date == -1 &&
            moment(a.create_date, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(
              moment(b.create_date, "YYYY-MM-DD HH:mm:ss")
            )
          )
            return -1;
          if (
            this.sort_date == 1 &&
            moment(a.create_date, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(
              moment(b.create_date, "YYYY-MM-DD HH:mm:ss")
            )
          )
            return -1;
          return 0;
        })
        // Sort Rating
        .sort((a, b) => {
          if (this.sort_rating == 0) return 0;
          if (
            this.sort_rating == -1 &&
            (a.rating == null || a.rating > b.rating)
          )
            return -1;
          if (
            this.sort_rating == 1 &&
            (a.rating == null || a.rating < b.rating)
          )
            return -1;
          return 0;
        })
        .forEach((review) => {
          if (helper.length == 4) {
            output.push(helper);
            helper = [];
          }
          helper.push(review);
        });
      if (helper.length) output.push(helper);
      return output;
    },
  },
  methods: {
    setView(input) {
      input = parseInt(input);
      switch (input) {
        case 1:
        case 2:
          this.view = input;
          localStorage.setItem("review_view", input);
          break;
        default:
          console.warn("wrong input for view: " + input);
          this.view = 1;
      }
    },
    getBranchIdFromLocationId(input) {
      for (let branch_id in this.branch_locations)
        if (
          this.branch_locations[branch_id] && (
          this.branch_locations[branch_id].id == input ||
          this.branch_locations[branch_id].name == input)
        )
          return branch_id;
      return null;
    },
    onRowSelected(val) {
      if (val.length == 0) return;
      this.showReview(val[0].id);
      this.$refs.review_table.clearSelected();
    },
    showReview(review_id) {
      this.review_show = this.filteredReviews.find(
        (review) => review.id == review_id
      );
      this.review_show_newanswer = JSON.parse(
        JSON.stringify(this.review_show.answer)
      );
      this.review_show_edit_answer = false;
      this.$refs.review_details.show();
    },
    async review_show_updateAnswer() {
      let result = await this.$store.dispatch("updateReviewAnswer", {
        id: this.review_show.id,
        answer: this.review_show_newanswer,
      });
      this.review_show.answer = this.review_show_newanswer;
      this.review_show_newanswer = JSON.parse(
        JSON.stringify(this.review_show.answer)
      );
      this.review_show_edit_answer = false;
    },
    async publish(id) {
      await this.$store.dispatch("publishReview", { id });
      this.review_show.status = "published";
    },
    async unpublish(id) {
      await this.$store.dispatch("unpublishReview", { id });
      this.review_show.status = "unpublished";
    },
    async getUser(user_id) {
      return { name: "test" };
    },
  },
  watch: {
    status(val) {
      sessionStorage.setItem("review_status", JSON.stringify(val));
    },
    filteredReviews(val) {
      let average = 0;
      let count = 0;
      for (let i in val) {
        if (val[i].rating !== null && val[i].rating !== undefined) {
          count = count + 1;
          average = average + val[i].rating;
        }
      }
      this.average_rating = count == 0 ? null : average / count;
    },
  },
  async created() {
    // Load last View
    let local_view = localStorage.getItem("review_view");
    this.setView(local_view ? local_view : 2);
    // Load Filter is it was modified in last session
    let cache_status = sessionStorage.getItem("review_status");
    try {
      cache_status = JSON.parse(cache_status);
    } catch (err) {
      console.log(err);
    }
    if (cache_status)
      switch (cache_status) {
        case null:
        case "created":
        case "published":
        case "unpublished":
          this.status = cache_status;
          break;
        default:
          console.warn("wrong input for status: " + cache_status);
          this.status = null;
      }
    await Promise.all([
      this.$store.dispatch("initReviewModule"),
      (async () => {
        try {
          this.branch_locations = await this.$store.dispatch(
            "gmb_getBranchLocations"
          );
        } catch (err) {
          console.log(err);
          this.branch_locations = [];
        }
      })(),
    ]);
    this.loaded = true;
    if (this.$route.params.id) this.showReview(this.$route.params.id);
  },
};
</script>
