<template>
  <div>
    <b-form-group
      label="In welchen Sprachen erfolgt die Beratung?"
      v-b-popover.bottom.hover="
        settings_obj.company_consultance_language.description
      "
    >
      <b-form-checkbox-group
        :checked="
          settings_obj.company_consultance_language.value.current
            ? settings_obj.company_consultance_language.value.current.filter((el) =>
                ['de', 'en', 'ru', 'it', 'fr', 'tk', 'zp'].includes(el)
              )
            : []
        "
        :options="languages"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'company_consultance_language',
              value: val,
            })
        "
        :disabled="settings_obj.company_consultance_language.value.current == null"
      />
      <b-form-checkbox
        :checked="settings_obj.company_consultance_language.value.current == null"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'company_consultance_language',
              value: val ? null : ['de'],
            })
        "
        >keine Angabe</b-form-checkbox
      >
    </b-form-group>
    <b-form-group
      label="In welchen Sprachen kann die Ausbildung erfolgen?"
      v-b-popover.bottom.hover="
        settings_obj.company_education_language.description
      "
    >
      <b-form-checkbox-group
        :checked="
          settings_obj.company_education_language.value.current
            ? settings_obj.company_education_language.value.current.filter((el) =>
                ['de', 'en', 'ru', 'it', 'fr', 'tk', 'zp'].includes(el)
              )
            : []
        "
        :options="languages"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'company_education_language',
              value: val,
            })
        "
        :disabled="settings_obj.company_education_language.value.current == null"
      />
      <b-form-checkbox
        :checked="settings_obj.company_education_language.value.current == null"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'company_education_language',
              value: val ? null : ['de'],
            })
        "
        >keine Angabe</b-form-checkbox
      >
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      languages: [
        { text: "Deutsch", value: "de" },
        { text: "Englisch", value: "en" },
        { text: "Russisch", value: "ru" },
        { text: "Italienisch", value: "it" },
        { text: "Französisch", value: "fr" },
        { text: "Türkisch", value: "tk" },
        { text: "Zeichensprache", value: "zp" },
      ],
    };
  },
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "company_consultance_language",
      "company_education_language",
    ]);
  },
};
</script>

<style>
</style>
