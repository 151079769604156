var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.auth_modify && !_vm.id)?_c('div',[_vm._v("Sie besitze nicht die erforderlichen Rechte")]):_c('b-container',{attrs:{"fluid":""}},[(_vm.category == null)?_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{staticClass:"text-center mb-2",attrs:{"cols":"12"}},[_vm._v("Bitte Kategorie auswählen")]),_c('b-col',{staticClass:"d-none d-sm-flex text-center mb-2",attrs:{"cols":"12","sm":"8","md":"6","lg":"4","xl":"3"}},[_c('CategorySelector')],1)],1):(_vm.notfound)?_c('div',{staticClass:"text-center"},[_vm._v(" Theorieeinheit nicht gefunden ")]):(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('i',{staticClass:"fa fa-spinner fa-spin"})]):_c('div',{attrs:{"id":"panel-unit-form"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('b-form-group',[_c('label',{staticClass:"mb-1"},[_vm._v("Kennzeichen "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
                html: true,
                content:
                  'Das Kennzeichen dient der eindeutigen Identifizierung einer Theorieeinheit. <br>Meist wie folgt verwendet: <br>\'Thema <strong>Kennzeichenname</strong>\'<br><br>z.B. Thema 5<br><small><strong>max. Länge:</strong> 4 Zeichen</small>',
              }),expression:"{\n                html: true,\n                content:\n                  'Das Kennzeichen dient der eindeutigen Identifizierung einer Theorieeinheit. <br>Meist wie folgt verwendet: <br>\\'Thema <strong>Kennzeichenname</strong>\\'<br><br>z.B. Thema 5<br><small><strong>max. Länge:</strong> 4 Zeichen</small>',\n              }",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons"},[_vm._v("help_outline")])]),_c('b-form-input',{attrs:{"disabled":!_vm.auth_modify,"state":_vm.key == null || _vm.key.length <= 4 ? null : false},model:{value:(_vm.key),callback:function ($$v) {_vm.key=$$v},expression:"key"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"9"}},[_c('b-form-group',{attrs:{"label":"Name"}},[_c('b-form-input',{attrs:{"disabled":!_vm.auth_modify},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticClass:"mb-1"},[_vm._v("Kurzbeschreibung "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                'Die Kurzbeschreibung dient dazu in 1-3 Sätzen dem Kunden zu verdeutlichen, welche Inhalte diese Theorieeinheit vermittelt.'
              ),expression:"\n                'Die Kurzbeschreibung dient dazu in 1-3 Sätzen dem Kunden zu verdeutlichen, welche Inhalte diese Theorieeinheit vermittelt.'\n              ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons"},[_vm._v("help_outline")])]),_c('b-form-textarea',{attrs:{"disabled":!_vm.auth_modify,"rows":"3","max-rows":"6"},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticClass:"mb-1"},[_vm._v("Standarddauer "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:({
                html: true,
                content:
                  'Die Standarddauer gibt an, welcher Zeitraum für die Absolvierung dieser Einheit standardmäßig benötigt wird.<br><br><small>Z.B. wird in der Anlage einer <strong>Theoriestunde</strong> aus diesem Wert die Dauer der Theoriestunde vorgeschlagen.</small>',
              }),expression:"{\n                html: true,\n                content:\n                  'Die Standarddauer gibt an, welcher Zeitraum für die Absolvierung dieser Einheit standardmäßig benötigt wird.<br><br><small>Z.B. wird in der Anlage einer <strong>Theoriestunde</strong> aus diesem Wert die Dauer der Theoriestunde vorgeschlagen.</small>',\n              }",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons"},[_vm._v("help_outline")])]),(_vm.duration === null)?_c('div',[_c('b-button',{attrs:{"disabled":!_vm.auth_modify,"variant":"light"},on:{"click":function($event){_vm.duration = (_vm.default_duration) ? _vm.default_duration : '01:30:00'}}},[_vm._v("Hinzufügen")])],1):_c('b-row',[_c('b-col',[_c('b-form-timepicker',_vm._b({attrs:{"disabled":!_vm.auth_modify},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}},'b-form-timepicker',_vm.TimePickerConfig,false))],1),_c('b-col',{staticClass:"pl-0",attrs:{"cols":"auto"}},[_c('b-button',{staticClass:"mt-1",attrs:{"variant":"light","disabled":!_vm.auth_modify},on:{"click":function($event){_vm.duration = null}}},[_vm._v("Entfernen")])],1)],1)],1)],1)],1),_c('b-row',[_c('b-col',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-contents",modifiers:{"accordion-contents":true}}],staticStyle:{"cursor":"pointer"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mx-1 mb-0 pb-0"},[_c('div',{staticClass:"pb-0"},[_vm._v(" Inhalte "),_c('small',{},[_vm._v(" (klicken zum Öffnen)")])]),_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
              'In diesem Abschnitt können Sie ausführlicher definieren, welche Inhalte in der Unterrichtseinheit behandelt werden'
            ),expression:"\n              'In diesem Abschnitt können Sie ausführlicher definieren, welche Inhalte in der Unterrichtseinheit behandelt werden'\n            ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons"},[_vm._v("help_outline")])]),_c('hr',{staticClass:"mt-1"})]),_c('b-collapse',{staticClass:"col",attrs:{"id":"accordion-contents"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Diese Funktionalität ist noch in Arbeit")]),_c('small',[_vm._v("Editor zur strukturierung der Daten")])])],1)],1)],1),_c('b-row',[_c('b-col',{directives:[{name:"b-toggle",rawName:"v-b-toggle.accordion-more",modifiers:{"accordion-more":true}}],staticStyle:{"cursor":"pointer"},attrs:{"cols":"12"}},[_c('div',{staticClass:"d-flex justify-content-between mx-1 mb-0 pb-0"},[_c('div',{staticClass:"pb-0"},[_vm._v(" Informationsmaterial "),_c('small',{},[_vm._v(" (klicken zum Öffnen)")])]),_c('span',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
              'In diesem Abschnitt können Sie Informationsmaterial zur Theorieeinheit hinterlegen, welche dem Kunden im Rahmen eines Kurses bzw. einer Unterrichtsstunde zur Verfügung gestellt werden kann.'
            ),expression:"\n              'In diesem Abschnitt können Sie Informationsmaterial zur Theorieeinheit hinterlegen, welche dem Kunden im Rahmen eines Kurses bzw. einer Unterrichtsstunde zur Verfügung gestellt werden kann.'\n            ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons"},[_vm._v("help_outline")])]),_c('hr',{staticClass:"mt-1"})]),_c('b-collapse',{staticClass:"col",attrs:{"id":"accordion-more"}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('h3',[_vm._v("Diese Funktionalität ist noch in Arbeit")]),_c('small',[_vm._v("anhängen von Dateien, welche in anderen Objekten verwendet werden können. z.B. Kurse, Stunden")])])],1)],1)],1),_c('b-row',{staticClass:"mx-1"},[_c('b-button',{attrs:{"variant":"success","disabled":!_vm.auth_modify},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.id ? "Aktualisieren" : "Anlegen"))]),(_vm.id)?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"outline-danger","disabled":!_vm.auth_modify},on:{"click":function($event){return _vm.handleRemove()}}},[_vm._v("Löschen")]):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }