<template>
  <d-container fluid class="main-content-container px-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Dashboard</span>
        <h3 class="page-title">{{ company.name }}</h3>
      </d-col>
    </d-row>

    <!-- Small Stats Blocks -->
    <d-row>
      <d-col lg v-for="(stats, idx) in smallStats" :key="idx" class="mb-4">
        <small-stats
          :id="`small-stats-${idx}`"
          variation="1"
          :chart-data="stats.datasets"
          :label="stats.label"
          :value="stats.value"
          :percentage="stats.percentage"
          :increase="stats.increase"
          :decrease="stats.decrease"
        />
      </d-col>
    </d-row>

    <d-row>
      <!-- Index Announcements -->
      <d-col lg="4" md="6" sm="12" class="mb-4">
        <bo-announcement-card type_name="index" />
      </d-col>

      <!-- Reviews -->
      <d-col lg="5" md="12" sm="12" class="mb-4">
        <bo-reviews />
      </d-col>

      <!-- Questions -->
      <d-col lg="3" md="12" sm="12" class="mb-4">
        <bo-site-articles />
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import SmallStats from "@/components/common/SmallStats.vue";
import DashboardArticles from "@/components/articles/DashboardCard.vue";
import AnnouncementCard from "@/components/announcement/AnnouncementCard.vue";
import Reviews from "@/components/review/DashboardCard.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    SmallStats,
    boAnnouncementCard: AnnouncementCard,
    boReviews: Reviews,
    boSiteArticles: DashboardArticles,
  },
  created() {
    this.setlessonchart();
    this.setuserchart();
  },
  data() {
    return {
      dateRange: {
        from: null,
        to: null,
      },
      day: 60 * 60 * 24,
      smallStats: [],
    };
  },
  methods: {
    async setuserchart() {
      var time = Math.floor(Date.now() / 1000);
      var obj = {
        label: "User",
        value: "Lädt...",
        percentage: null,
        increase: false,
        decrease: false,
        labels: [],
        datasets: [
          {
            label: "heute",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [],
          },
        ],
      };
      let response = await this.$api.get("user/count?time=" + time);
      obj.value = response.data.result;
      obj.datasets[0].data[5] = response.data.result;
      var promisses = [];
      for (let i = 0; i < 5; i++) {
        promisses.push(
          this.$api
            .get("user/count?time=" + (time - (5 - i) * 6 * this.day))
            .then((response) => {
              obj.datasets[0].data[i] = response.data.result;
            })
        );
      }
      await Promise.all(promisses);
      var percentage = obj.value / obj.datasets[0].data[obj.datasets[0].data.length-2];
      if (percentage >= 1) {
        obj.increase = true;
      } else {
        obj.decrease = true;
      }
      obj.percentage =
        "" + (obj.value - obj.datasets[0].data[0]) + " Nutzer (letzte 30 Tage)";
      this.smallStats.push(obj);
    },
    async setlessonchart() {
      var time = Math.floor(Date.now() / 1000);
      var obj = {
        label: "Theoriestunden",
        value: "Lädt...",
        percentage: null,
        increase: false,
        decrease: false,
        labels: [],
        datasets: [
          {
            label: "heute",
            fill: "start",
            borderWidth: 1.5,
            backgroundColor: "rgb(0,123,255,0.1)",
            borderColor: "rgb(0,123,255)",
            data: [],
          },
        ],
      };
      let response = await this.$api.get("lesson/count?time=" + time);
      obj.value = response.data.result;
      obj.datasets[0].data[0] = response.data.result;
      var promisses = [];
      for (let i = 0; i < 6; i++) {
        promisses.push(
          this.$api
            .get("lesson/count?time=" + (time - (5 - i) * 30 * this.day))
            .then((response) => {
              obj.datasets[0].data[i] = response.data.result;
            })
        );
      }
      await Promise.all(promisses);
      var percentage =
        obj.value / obj.datasets[0].data[obj.datasets[0].data.length - 2];
      if (percentage >= 1) {
        obj.increase = true;
      } else {
        obj.decrease = true;
      }
      if (obj.decrease)
        obj.percentage =
          "" +
          (percentage == Infinity
            ? "100"
            : Math.round((percentage - 1) * -1 * 100)) +
          "% weniger Theoriestunden geplant, als letzten Monat";
      if (obj.increase)
        obj.percentage =
          "" +
          (percentage == Infinity
            ? "100"
            : Math.round((percentage - 1) * 100)) +
          "% mehr Theoriestunden geplant, als letzten Monat";
      if (percentage == 1)
        obj.percentage = "gleiche Anzahl an Theoriestunden wie letzten Monat";
      console.log(obj.datasets[0].data)
      this.smallStats.push(obj);
    },
  },
  computed: {
    ...mapGetters(["company"]),
  },
};
</script>

