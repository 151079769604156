<template>
  <b-container fluid>
    <b-row v-if="auth_modify">
      <b-col class="text-center">
        <b-button-group >
          <b-button
            v-if="type"
            @click="$router.push({ name: type=='price' ? 'unit_price_group_new' : 'unit_group_group_new' })"
            class="pr-2"
          >
            <i class="material-icons">add</i> Hinzufügen
            <i class="material-icons ml-3">double_arrow</i>
          </b-button>
          <b-dropdown v-else right>
            <template #button-content>
              <i class="material-icons">add</i> Hinzufügen
            </template>
            <b-dropdown-item
              @click="$router.push({ name: 'unit_price_group_new' })"
            >Preiskategorie</b-dropdown-item>
            <b-dropdown-item
              @click="$router.push({ name: 'unit_group_group_new' })"
            >Sortiergruppe</b-dropdown-item>
          </b-dropdown>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Suche..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      hover
      ref="unit_group_table"
      show-empty
      small
      :items="items"
      :fields="fields"
      :filter="filter"
      selectable
      @row-selected="onRowSelected"
      select-mode="single"
    >
      <template #cell(type)="data">
        {{ (() => {switch(data.value) {
          case "group":
            return "Sortiergruppe";
          case "price":
            return "Preiskategorie";
          default:
            return "Unbekannt";
        }
        })() }}
      </template>
      <template #cell(category)="data">
        {{ categories.find(el => el.name == data.value).title }}
      </template>
      <!-- Error's -->
      <template v-slot:empty>
        <div
          class="text-center mt-2 small"
          @click="
            selectedCategory == null
              ? null
              : $store.dispatch('updateSelectedCategory', null)
          "
        >
          <template v-if="selectedCategory == null">
            Es sind keine Gruppen vorhanden...
          </template>
          <template v-else>
            Es wurden leider keine Gruppen für die Kategorie
            <strong>{{
              categories.find((elem) => elem.name == selectedCategory).title
            }}</strong>
            gefunden...
            <br />
            <br />
            <small>Hier klicken für <strong>Alle Kategorien</strong></small>
            <br />
          </template>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:emptyfiltered>
        <div class="text-center mt-2 small">
          Keine Theorieeinheitsgruppen für diese Filtereinstellung gefunden...
        </div>
      </template>
    </b-table>
    <small>Es werden max. 100 Einträge angezeigt</small>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "UnitGroupOverview",
  props: {
    /**
     * TYPE for creating/filter unit_groups
     */
    type: {
      type: String,
      default: null,
      validator(input) {
        if (input === null) return true;
        if (typeof input != "string") return false;
        return ["group", "price"].includes(input);
      },
    },
  },
  data() {
    return {
      auth_modify: null,
      selected: [],
      filter: null,
    };
  },
  computed: {
    ...mapGetters([
      "branches",
      "units",
      "selectedCategory",
      "categories",
      "unit_groups",
    ]),
    fields: function () {
      let out = [
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
      ];
      if (!this.type)
        out.push({
          key: "type",
          label: "Typ",
          sortable: true,
        });
      if (!this.selectedCategory)
        out.push({
          key: "category",
          label: "Kategorie",
          sortable: true,
        });
      return out;
    },
    items() {
      return this.unit_groups.filter(
        (el) =>
          (!this.selectedCategory || el.category == this.selectedCategory) &&
          (!this.type || el.type == this.type)
      );
    },
    totalRows() {
      return this.items.length;
    },
  },
  methods: {
    onRowSelected(items) {
      if (items[items.length - 1]) this.view(items[items.length - 1].id);
    },
    view(unit_group_id) {
      let unit_group = this.unit_groups.find((el) => el.id == unit_group_id);
      if (!unit_group || !["group", "price"].includes(unit_group.type))
        throw new Error("Invalid type of unit group or invalid id");
      this.$router.push({
        name:
          unit_group.type == "price" ? "unit_price_group" : "unit_group_group",
        params: { id: unit_group_id },
      });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "unit_group_modify"
    );
  },
};
</script>
