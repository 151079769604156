<template>
  <aside
    :class="['main-sidebar', 'col-12', 'col-md-3', 'col-lg-2', 'px-0', sidebarVisible ? 'open' : '']"
  >
    <div class="main-navbar">
      <nav
        class="navbar align-items-stretch navbar-light bg-white flex-md-nowrap border-bottom p-0"
      >
        <b-link class="navbar-brand w-100 mr-0" style="line-height: 25px;" @click="$router.push({name: 'dashboard'}); sidebarVisible=false;">
          <div class="d-table m-auto">
            <img
              id="main-logo"
              class="d-inline-block align-top mr-1"
              style="max-width: 25px;"
              src="@/assets/images/shards-dashboards-logo.svg"
              alt="Shards Dashboard"
            />
            <span v-if="!hideLogoText" class="d-none d-md-inline ml-1">{{ company.name }}</span>
          </div>
        </b-link>
        <b-link class="toggle-sidebar d-sm-inline d-md-none d-lg-none" @click="handleToggleSidebar()">
          <i class="material-icons">&#xE5C4;</i>
        </b-link>
      </nav>
    </div>

    <form action="#" class="main-sidebar__search w-100 border-right d-sm-flex d-md-none d-lg-none">
      <div class="input-group input-group-seamless ml-3">
        <div class="input-group-prepend">
          <div class="input-group-text">
            <i class="fas fa-search"></i>
          </div>
        </div>
        <b-form-input
        class="navbar-search"
        placeholder="Suchbegriff eingeben..."
        v-model="search"
        @focus="show=true"
        @blur="hideResults()"
        @keydown.enter.native="resolveAction"
        autocomplete="aus"
      />
            </div>

        <b-collapse
          id="searchresults"
          v-if="results!=null"
          :visible="show"
          class="dropdown-menu rounded-top-0"
        >
          <b-dropdown-item v-if="showError" link-class="text-danger" disabled>Dieses Shortcut existiert nicht!</b-dropdown-item>
          <b-dropdown-item v-else-if="results.length==0" disabled>
            Keine Ergebnisse zur Suche gefunden.
          </b-dropdown-item>
          <b-dropdown-item
            v-else
            v-for="element in results"
            :key="element.name"
            :to="{name: element.name}"
            active-class
          >
            <i class="material-icons">{{element.meta.material_icon}}</i> {{element.meta.title}}
          </b-dropdown-item>
        </b-collapse>
    </form>

    <div class="nav-wrapper">
      <b-nav class="flex-column">
        <li v-for="(item, navItemIdx) in items.filter(el => !el.hidden)" :key="navItemIdx" class="nav-item dropdown">
          <b-link
            :class="['nav-link', item.items && item.items.length ? 'dropdown-toggle' : '']"
            :to="item.to"
            v-b-toggle="`snc-${navItemIdx}`"
          >
            <div class="item-icon-wrapper" v-if="item.htmlBefore" v-html="item.htmlBefore" />
            <span v-if="item.title">{{ item.title }}</span>
            <div class="item-icon-wrapper" v-if="item.htmlAfter" v-html="item.htmlAfter" />
          </b-link>
          <b-collapse
            v-if="item.items && item.items.length"
            :id="`snc-${navItemIdx}`"
            class="dropdown-menu dropdown-menu-small"
            accordion="sidebar-items-accordion"
            :visible="!!(item.id == $route.meta.nav_id)"
            appear
          >
            <d-dropdown-item
              style="cursor: pointer;"
              v-for="(subItem, subItemIdx) in item.items.filter(el => !el.hidden)"
              :key="subItemIdx"
              :href="subItem.href"
              :to="subItem.to"
            >{{ subItem.title }}</d-dropdown-item>
          </b-collapse>
        </li>
      </b-nav>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "main-sidebar",
  props: {
    /**
     * Whether to hide the logo text, or not.
     */
    hideLogoText: {
      type: Boolean,
      default: false,
    },
    /**
     * The menu items.
     */
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      sidebarVisible: false,
      search: "",
      show: false,
      showError: false,
    };
  },
  created() {
    this.$eventHub.$on("toggle-sidebar", this.handleToggleSidebar);
  },
  beforeDestroy() {
    this.$eventHub.$off("toggle-sidebar");
  },
  methods: {
    handleToggleSidebar() {
      this.sidebarVisible = !this.sidebarVisible;
    },
    hideResults() {
      setTimeout(() => (this.show = false), 0);
    },
    resolveAction(e) {
      e.preventDefault();
      try {
        this.$router.dispatchShortcut(this.search);
        this.search = "";
        this.show = false;
        this.sidebarVisible = false;
      } catch (err) {
        if (err.message.includes("NOT_FOUND")) {
          if (this.results.length >= 1) {
            if (this.$router.history.current.name!=this.results[0].name) this.$router.push({ name: this.results[0].name });
            this.search = "";
            this.show = false;
            this.sidebarVisible = false;
          } else {
            this.showError=true;
          }
        } else throw err;
      }
    },
  },
  watch: {
    search(val) {
      this.show = true;
      this.showError = false;
      setTimeout(() => {
        if (this.show == true) return;
        if (val === this.search) this.search = "";
      }, 15000);
    },
    show(val) {
      if (val == false) {
        setTimeout(() => {
          if (this.show == true) return;
          this.search = "";
        }, 15000);
      }
    },
    $route() {
      this.sidebarVisible=false;
    }
  },
  computed: {
    ...mapGetters(["company"]),
    results() {
      return this.$router.dictionary(this.search);
    },
  },
};
</script>

<style lang="scss">
.main-sidebar {
  .item-icon-wrapper {
    display: inline-block;
  }
  .dropdown-menu {
    display: block;
  }
}
</style>
