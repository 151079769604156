<template>

</template>

<script>
import { mapGetters } from 'vuex';
// New Client Onboarding process
export default {
  data() {
    return {
      view: 0,
      setting_names: [
        // Firmendaten
        // 'company_name',
        // 'company_description',
        // 'company_iban',
        // 'company_bic',
        // Zahlungsmethoden von Kunden
        'payment_bar'
      ],
    };
  },
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    console.log(await this.$store.dispatch('getSetting', this.setting_names));
  }
}
</script>

<style>

</style>
