<template>
  <b-container fluid class="h-100">
    <b-row class="text-center justify-content-center h-100 align-items-center">
      <b-col cols="auto" class="mb-5 text-center">
        <template v-if="loadingStatus===null">
          <i class="fa fa-spinner fa-3x fa-spin m-2"></i>
          <div style="font-size: 5vh">App startet...</div>
        </template>
        <template v-else-if="apiStatus!==true">
          <template v-if="apiStatus!=null">
            <i class="fa fa-times-circle fa-4x m-2"></i>
            <div style="font-size: 5vh">Connection Error</div>
          </template>
          <template v-else>
            <i class="fa fa-spinner fa-3x fa-spin m-2"></i>
            <div style="font-size: 5vh">Connecting to API</div>
          </template>
        </template>
        <template v-else>
          <i class="fa fa-spinner fa-3x fa-spin m-2"></i>
          <div style="font-size: 5vh">{{ loadingStatus }}</div>
        </template>
      </b-col>
    </b-row>
    <b-row id="footer" class="w-100 text-center justify-content-center">
      <a class="text-muted px-1" href="https://wer-ner.de">WER-NER.de</a>
      ©{{new Date().getFullYear()}} All Rights Reserved.
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      loadingStatus: "loadingStatus",
      apiStatus: "apiStatus"
    })
  },
  methods: {
    reporterror() {
      // MISSING
    }
  }
};
</script>
<style scoped>
#footer {
  position: absolute;
  bottom: 3vh;
}
</style>
