<template>
  <b-container>
    <b-row>
      <b-col>
        Die folgenden Einstellungen konnten keiner Kategorie zugeordnet werden
      </b-col>
    </b-row>
    <b-row v-for="setting in settings" :key="setting.name">
      <b-col>
        <b-form-group :disabled="!setting.editable">
          <h3>{{ setting.name }}</h3>
          <div>{{ setting.description }}</div>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { used_settings } from "../used_settings";
export default {
  data() {
    return {
      data: {}
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings.filter(setting => !used_settings.find(setting_name => setting.name==setting_name))
    }
  },
  methods: {
    reset() {
      this.settings.forEach(setting => this.data[setting.name] = setting.value.current);
    }
  },
  created() {
    this.reset();
  }
}
</script>
