<template>
  <div v-if="loading" class="text-center">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
  <div v-else-if="!auth_modify">Sie besitzen nicht die benötigten Rechte</div>
  <div v-else-if="notfound" class="text-center">
    Theoriestunde nicht gefunden
  </div>
  <b-container v-else fluid>
    <b-row v-if="data.category === null" class="justify-content-center">
      <b-col cols="12" class="text-center mb-2"
        >Bitte Kategorie auswählen</b-col
      >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="3"
        class="d-none d-sm-flex text-center mb-2"
        ><CategorySelector
      /></b-col>
    </b-row>

    <template v-else id="panel-lesson-form">
      <b-row>
        <b-col cols="12" md="9" class="pr-1" style="margin-bottom: 0.5em">
          <b-form-group>
            <label class="mb-1"
              >Name
              <i
                class="material-icons"
                v-b-popover.hover.right="{
                  html: true,
                  content:
                    'Wird der Parameter <strong>Name</strong> befüllt, so werden alle generierten Namen aus den Theoriebestandteilen überschrieben.',
                }"
                >help_outline</i
              ></label
            >
            <b-form-input
              v-model="data.name"
              :state="state_name"
              type="text"
              id="lesson-name"
              placeholder="wenn leer, wird Name aus Theorieeinheiten gebildet"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="pl-0">
          <b-form-group label="Ort:">
            <b-form-select v-model="data.branch_id">
              <option
                v-for="branch in state_branches"
                :key="branch.id"
                :value="branch.id"
              >
                {{ branch.name }}
              </option>
              <option :value="null">Addresse wählen</option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="data.branch_id === null" class="mb-2">
        <!-- Address -->
        <b-col cols="12">Addresse:</b-col>
        <b-col cols="12" sm="9" class="mb-1">
          <b-form-input v-model="data.address.street" placeholder="Straße" />
        </b-col>
        <b-col cols="12" sm="3" class="mb-1">
          <b-form-input
            v-model="data.address.streetnumber"
            min="0"
            placeholder="Nr."
          />
        </b-col>
        <b-col cols="12" sm="4" class="mb-1">
          <b-form-input
            v-model="data.address.zip"
            min="0"
            max="99999"
            type="number"
            placeholder="PLZ"
          />
        </b-col>
        <b-col cols="12" sm="8" class="mb-1">
          <b-form-input v-model="data.address.city" placeholder="Stadt" />
        </b-col>
        <hr class="col mx-3" />
      </b-row>
      <b-row>
        <b-col cols="12" md="9" class="pr-1">
          <b-form-group label="Anfang">
            <b-row>
              <b-col cols="8" class="pr-1">
                <b-form-datepicker
                  v-model="start_date"
                  :state="state_date"
                  :min="min_date"
                  :max="max_date"
                  v-bind="DatePickerConfig"
                ></b-form-datepicker>
              </b-col>
              <b-col cols="4" class="pl-0">
                <b-form-timepicker
                  v-model="start_time"
                  :state="state_date"
                  v-bind="TimePickerConfig"
                ></b-form-timepicker>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="3" class="pl-0">
          <b-form-group>
            <label class="mb-1"
              >Dauer
              <i
                class="material-icons"
                v-b-popover.hover.right="{
                  html: true,
                  content:
                    'Der Parameter <strong>Dauer</strong> gibt an, wie lange die Theoriestunde dauert.<br>Standardmäßig wird hier die Zeit für die Gesamtdauer aller hinzugefügten Inhalte hinterlegt.<br><br><small><strong>Hinweis: </strong>Sobald Sie diesen Parameter das Erste Mal selbständig ändern wird dieser nicht mehr von alleine angepasst.<br>Entnehmen Sie die Standarddauer aus dem Popup der einzelnen Theoriebestandteile oder Laden Sie die Seite neu.',
                }"
                >help_outline</i
              ></label
            >
            <b-form-timepicker
              v-model="duration"
              :state="state_date"
              @hidden="genDuration = false"
              v-bind="TimePickerConfig"
            ></b-form-timepicker>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <template slot="label">
              <div class="d-flex justify-content-between">
                <span>Inhalte</span>
                <span
                  class="material-icons"
                  v-b-popover.hover.top="
                    'Inhalte definieren die Bestandteile, welche unterrichtet werden.'
                  "
                  >help_outline</span
                >
              </div>
            </template>
            <ul
              v-if="selectedUnits.length > 0"
              class="list-inline d-inline-block mb-2"
            >
              <li
                v-for="lesson in selectedUnits"
                :key="lesson.id"
                class="list-inline-item"
              >
                <b-form-tag
                  @remove="remUnit(lesson.id)"
                  variant="secondary"
                  class="mb-1"
                >
                  <div :id="`unit_${lesson.id}`" class="text-white">
                    {{ lesson.key }} -
                    {{ lesson.name }}
                  </div>
                </b-form-tag>
                <b-popover
                  :target="`unit_${lesson.id}`"
                  triggers="hover"
                  v-if="false"
                >
                  <div>
                    <strong>Standarddauer: </strong
                    >{{ [lesson.duration] | duration("humanize") }}
                  </div>
                  <div>
                    <strong>Beschreibung: </strong
                    >{{
                      lesson.description_short !== null &&
                      lesson.description_short.length > 0
                        ? lesson.description_short
                        : "keine"
                    }}
                  </div>
                  <router-link :to="{ name: 'unit', params: { id: lesson.id } }"
                    >Zur Detailseite</router-link
                  >
                </b-popover>
              </li>
            </ul>
            <b-dropdown
              size="sm"
              variant="outline-secondary"
              block
              menu-class="w-100"
            >
              <template v-slot:button-content no-outer-focus
                >Theorieeinheit auswählen</template
              >
              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  label-for="unit-search-input"
                  label="Suche:"
                  label-cols-md="auto"
                  class="mb-0"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="search"
                    id="unit-search-input"
                    type="search"
                    size="sm"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </b-dropdown-form>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group style="overflow: auto; max-height: 30vh">
                <b-dropdown-item
                  v-for="unit in filteredUnits"
                  :key="unit.id"
                  @click="addUnit(unit.id)"
                  >{{ unit.key }} - {{ unit.name }}</b-dropdown-item
                >
              </b-dropdown-group>
              <b-dropdown-text v-if="allowedUnits && allowedUnits.length === 0"
                >Keine Inhalte auswählbar</b-dropdown-text
              >
              <b-dropdown-text v-else-if="state_units.length === 0"
                >Keine Inhalte definiert</b-dropdown-text
              >
              <b-dropdown-text v-else-if="filteredUnits.length === 0"
                >Keine Inhalte gefunden</b-dropdown-text
              >
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              <b-form-checkbox
                :checked="!!data.participation"
                @input="
                  (val) =>
                    val
                      ? setParticipationDefault()
                      : (data.participation = false)
                "
                switch
                right
                >Teilnahme</b-form-checkbox
              >
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="{
                content:
                  'In diesem Abschnitt legen Sie fest, wie die <strong>Teilnahme</strong> an der Unterrichtsstunde geregelt ist.<br><br>Sie können zum Beispiel entscheiden, ob Ihre Kunden sich selbstständig, nur durch eine Anmeldung bei einem Mitarbeiter oder garnicht anmelden können.<br>Auch eine max. Teilnehmeranzahl ist auswählbar.',
                html: true,
              }"
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse :visible="!!data.participation" class="col pb-4">
          <template v-if="data.participation">
            <b-form-group label="Anmeldung..." label-size="sm">
              <b-form-select
                :value="
                  data.participation.register_modes.includes('direct')
                    ? 'direct'
                    : data.participation.register_modes.includes('request')
                    ? 'request'
                    : null
                "
                :options="[
                  { text: 'deaktiviert - durch Mitarbeiter', value: null },
                  { text: 'auf Anfrage', value: 'request' },
                  { text: 'direkt ohne Bestätigung', value: 'direct' },
                ]"
                @input="
                  (val) =>
                    val == 'request'
                      ? (data.participation.register_modes = ['request'])
                      : val === 'direct'
                      ? (data.participation.register_modes = [
                          'direct',
                          'request',
                        ])
                      : (data.participation.register_modes = [])
                "
                size="sm"
              />
            </b-form-group>
            <b-form-checkbox
              switch
              :checked="!!data.participation.max_participators"
              @input="
                (val) =>
                  val
                    ? (data.participation.max_participators = 5)
                    : (data.participation.max_participators = null)
              "
              class="mt-1"
              >max. Teilnehmeranzahl</b-form-checkbox
            >
            <b-collapse
              v-if="data.participation.max_participators"
              :visible="data.participation.max_participators !== null"
            >
              <b-form-input
                v-model="data.participation.max_participators"
                type="number"
                min="1"
                size="sm"
                class="mt-1"
              />
            </b-collapse>
          </template>
        </b-collapse>
      </b-row>
      <b-row>
        <b-col v-b-toggle.accordion-more cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              weitere Möglichkeiten
              <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="
                'In diesem Abschnitt finden Sie weitere Einstellungen.'
              "
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-more" class="col">
          <b-row>
            <b-col>
              <b-form-group label="Beschreibung">
                <b-form-textarea
                  v-model="data.description"
                  placeholder="keine Beschreibung hinterlegt"
                ></b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
      <b-row>
        <b-col v-b-toggle.accordion-actions cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Aktionen <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="{
                content:
                  'In diesem Abschnitt legen Sie Aktionen fest, welche bei bestimmten Bedingungen automatisch ausgeführt werden sollen. <br>Das kann zum Beispiel eine Begrüßung eines neuen Teilnehmers oder das Versenden von interessanten Informationen oder Angeboten nach Unterrichtsende sein.',
                html: true,
              }"
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-actions" class="col pb-4">
          <h3>Diese Funktionalität ist noch in Arbeit</h3>
          <small
            >z.B. Versenden von Begrüßungsnachichten o. Infomaterial an
            Angemeldete</small
          >
        </b-collapse>
      </b-row>
      <b-row class="mx-1">
        <b-button variant="success" @click="submit()" v-if="!hideSubmit"
          >Anlegen</b-button
        >
      </b-row>
    </template>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
import apimethod from "@/plugins/apimethod";
// TODO: blur component, if the use has not the right "lesson_modify"
// HINT: If you want to migrate to a better dat-handeling take a look to store->education_module->postpone->swal-component
export default {
  name: "Lesson",
  components: {
    CategorySelector,
  },
  props: {
    id: {
      type: Number,
      default: null,
    },
    /**
     * generates the duration from the default duration of the selected units
     */
    generateDuration: {
      type: Boolean,
      default: true,
    },
    allowedUnits: {
      type: Array,
      default: null,
    },
    preventSubmit: {
      type: Boolean,
      default: false,
    },
    hideSubmit: {
      type: Boolean,
      default: false,
    },
    min_date: {
      type: String,
      default: null,
      validator(val) {
        if (!moment(val, "YYYY-MM-DD HH:mm:ss").isValid()) return false;
        return true;
      },
    },
    max_date: {
      type: String,
      default: null,
      validator(val) {
        if (!moment(val, "YYYY-MM-DD HH:mm:ss").isValid()) return false;
        return true;
      },
    },
    // V-Bind Input
    name: {
      type: String,
      default: null,
    },
    category: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default: null,
    },
    branch_id: {
      type: Number,
      default: null,
    },
    address: {
      type: Object,
      default: null,
    },
    date: {
      type: Object,
    },
    units: {
      type: Array,
    },
    participation: {},
  },
  data() {
    let start_date = moment().set({
      hour: 18,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    let end_date = moment().set({
      hour: 18,
      minute: 0,
      second: 0,
      millisecond: 0,
    });
    // TODO: Check min_date/max_date period and modify the start_date/end_date if needed
    if (this.date && this.date.start) {
      let new_start_date = moment(this.date.start, "YYYY-MM-DD HH:mm:ss");
      // TODO: Check Valid
      // if (this.min_date && moment(this.min_date, "YYYY-MM-DD HH:mm:ss").isAfter(new_end_date)) throw new Error("INVALID")
      start_date = new_start_date.set({
        years: new_start_date._pf.unusedTokens.find((el) => el == "YYYY")
          ? start_date.year()
          : new_start_date.year(),
        months: new_start_date._pf.unusedTokens.find((el) => el == "MM")
          ? start_date.month()
          : new_start_date.month(),
        days: new_start_date._pf.unusedTokens.find((el) => el == "DD")
          ? start_date.day()
          : new_start_date.day(),
        hours: new_start_date._pf.unusedTokens.find((el) => el == "HH")
          ? start_date.hour()
          : new_start_date.hour(),
        minutes: new_start_date._pf.unusedTokens.find((el) => el == "mm")
          ? start_date.minute()
          : new_start_date.minute(),
        seconds: new_start_date._pf.unusedTokens.find((el) => el == "ss")
          ? start_date.second()
          : new_start_date.second(),
      });
      start_date = new_start_date;
    }
    if (this.date && this.date.end) {
      let new_end_date = moment(this.date.end, "YYYY-MM-DD HH:mm:ss");
      // TODO: Check valid
      // if (this.min_date && moment(this.min_date, "YYYY-MM-DD HH:mm:ss").isAfter(new_end_date)) throw new Error("INVALID")
      end_date = new_end_date.set({
        years: new_end_date._pf.unusedTokens.find((el) => el == "YYYY")
          ? end_date.year()
          : new_end_date.year(),
        months: new_end_date._pf.unusedTokens.find((el) => el == "MM")
          ? end_date.month()
          : new_end_date.month(),
        days: new_end_date._pf.unusedTokens.find((el) => el == "DD")
          ? end_date.day()
          : new_end_date.day(),
        hours: new_end_date._pf.unusedTokens.find((el) => el == "HH")
          ? end_date.hour()
          : new_end_date.hour(),
        minutes: new_end_date._pf.unusedTokens.find((el) => el == "mm")
          ? end_date.minute()
          : new_end_date.minute(),
        seconds: new_end_date._pf.unusedTokens.find((el) => el == "ss")
          ? end_date.second()
          : new_end_date.second(),
      });
      end_date = new_end_date;
    }
    return {
      loading: null,
      notfound: false,
      auth_modify: null,
      genDuration: this.generateDuration, // No mutation of a prop
      request_running: false,
      data: {
        id: null,
        name: this.name ? this.name : null,
        category: this.category ? this.category : null,
        description: this.description ? this.description : null,
        units: this.units ? this.units : [],
        branch_id: this.branch_id ? this.branch_id : null,
        address: {
          street:
            this.address && this.address.street ? this.address.street : null,
          streetnumber:
            this.address && this.address.streetnumber
              ? this.address.streetnumber
              : null,
          zip: this.address && this.address.zip ? this.address.zip : null,
          city: this.address && this.address.city ? this.address.city : null,
        },
        date: {
          start: start_date,
          end: end_date,
        },
        postponed: false,
        canceled: false,
        actions: [],
        participation:
          this.participation !== undefined ? this.participation : false,
      },
      // Actions
      action_events: [],
      action_types: [],
      action_name: null,
      // More
      search: "",
      DatePickerConfig,
      TimePickerConfig,
      // Validation
      validated: false,
    };
  },
  computed: {
    ...mapGetters({
      state_units: "units",
      state_branches: "branches",
      state_selectedCategory: "selectedCategory",
      settings_obj: "settings_obj",
    }),
    filteredUnits() {
      const criteria = this.search.trim().toLowerCase();
      // Filter out already selected options
      const options = this.state_units.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.state_selectedCategory && // match category
          !this.data.units.find((unit_id) => unit_id == option.id) && // not existing in selection
          (this.allowedUnits === null ||
            this.allowedUnits.find((allowed_id) => allowed_id == option.id)) // is allowed
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    selectedUnits: function () {
      return this.state_units.filter((unit) =>
        this.data.units.find((unit_id) => unit_id == unit.id)
      );
    },
    start_date: {
      get() {
        return moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD"
        );
      },
      set(value) {
        let duration = this.duration;
        this.data.date.start = moment(
          value + " " + this.start_time,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.data.date.end = moment(
          value + " " + this.start_time,
          "YYYY-MM-DD HH:mm:ss"
        )
          .add({
            hours: duration.substr(0, 2),
            minutes: duration.substr(3, 2),
            seconds: duration.substr(6, 2),
          })
          .format("YYYY-MM-DD HH:mm:ss");
      },
      deep: true,
    },
    start_time: {
      get() {
        return moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss").format(
          "HH:mm:ss"
        );
      },
      set(value) {
        let duration = this.duration;
        this.data.date.start = moment(
          this.start_date + " " + value,
          "YYYY-MM-DD HH:mm:ss"
        ).format("YYYY-MM-DD HH:mm:ss");
        this.data.date.end = moment(
          this.start_date + " " + value,
          "YYYY-MM-DD HH:mm:ss"
        )
          .add({
            hours: duration.substr(0, 2),
            minutes: duration.substr(3, 2),
            seconds: duration.substr(6, 2),
          })
          .format("YYYY-MM-DD HH:mm:ss");
      },
      deep: true,
    },
    duration: {
      get() {
        let duration = moment.duration(
          moment(this.data.date.end, "YYYY-MM-DD HH:mm:ss").diff(
            moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss")
          )
        );
        return (
          ("00" + duration.get("h")).slice(-2) +
          ":" +
          ("00" + duration.get("m")).slice(-2) +
          ":" +
          ("00" + duration.get("s")).slice(-2)
        );
      },
      set(value) {
        this.data.date.end = moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss")
          .add({
            hours: value.substr(0, 2),
            minutes: value.substr(3, 2),
            seconds: value.substr(6, 2),
          })
          .format("YYYY-MM-DD HH:mm:ss");
        this.genDuration = false;
      },
      deep: true,
    },
    // Validations
    state_date: function () {
      if (!this.validated) return null;
      if (
        (!this.min_date ||
          (moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(
            moment(this.min_date, "YYYY-MM-DD HH:mm:ss")
          ) &&
            moment(this.data.date.end, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(
              moment(this.min_date, "YYYY-MM-DD HH:mm:ss")
            ))) &&
        (!this.max_date ||
          (moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(
            moment(this.max_date, "YYYY-MM-DD HH:mm:ss")
          ) &&
            moment(this.data.date.end, "YYYY-MM-DD HH:mm:ss").isSameOrBefore(
              moment(this.max_date, "YYYY-MM-DD HH:mm:ss")
            )))
      )
        return true;
      return false;
    },
    state_name: function () {
      if (!this.validated) return null;
      if (
        this.data.units.length == 0 &&
        (!this.data.name || this.data.name.trim() == "")
      )
        return false;
      return true;
    },
  },
  watch: {
    state_selectedCategory: async function () {
      await this.init();
    },
    "data.category": function (val, oldval) {
      if (val != this.state_selectedCategory)
        this.$store.dispatch("updateSelectedCategory", val);
      if (val == oldval) return;
      this.data.units = [];
    },
  },
  methods: {
    async init() {
      this.loading = true;
      this.validated = false;
      if (this.id) {
        let lesson = await this.$store.dispatch("getLesson", this.id);
        this.data.id = lesson.id;
        this.data.name = lesson.name;
        this.data.category = lesson.category;
        this.data.description = lesson.category;
        this.data.date.start = lesson.date.start;
        this.data.date.end = lesson.date.end;
        this.data.units = lesson.category;
        this.data.branch_id = lesson.category;
        this.data.address.street =
          lesson.address && lesson.address.street
            ? lesson.address.street
            : null;
        this.data.address.streetnumber =
          lesson.address && lesson.address.streetnumber
            ? lesson.address.streetnumber
            : null;
        this.data.address.zip =
          lesson.address && lesson.address.zip ? lesson.address.zip : null;
        this.data.address.city =
          lesson.address && lesson.address.city ? lesson.address.city : null;
        this.data.postponed = lesson.postponed;
        this.data.canceled = lesson.canceled;

        this.loading = false;
        return;
      }
      this.data.id = null;
      this.data.name = null;
      this.data.branch_id = [];
      this.data.units = [];

      this.data.category =
        this.data.category !== null
          ? this.data.category
          : this.state_selectedCategory;

      // Get Default start-Time, participation
      let lesson_default_time_start = await this.$store.dispatch(
        "getSetting",
        "lesson_default_time_start"
      );
      this.start_time = lesson_default_time_start
        ? lesson_default_time_start
        : this.start_time;
      await this.setParticipationDefault();

      // Set first Branch found in state
      if (this.state_branches && this.state_branches[0])
        this.data.branch_id = this.state_branches[0].id;
      this.loading = false;
    },
    validate() {
      this.validated = true;
      return this.state_date && this.state_name;
    },
    async submit() {
      // Validate
      if (!this.validate()) return;

      // Prevent Submit
      if (this.preventSubmit)
        return this.$emit("finish", {
          name: this.data.name,
          category: this.data.category,
          type: "open",
          description: this.data.description,
          units: this.data.units,
          date: this.data.date,
          branch_id: this.data.branch_id,
          address: !this.data.branch_id ? this.data.address : null,
          participation: this.data.participation,
        });

      // Prevent double requests
      if (this.request_running) return;
      this.request_running = true;

      // Create/update a new Lesson
      await this.$store
        .dispatch(this.data.id !== null ? "updateLesson" : "createLesson", {
          id: this.id ? this.data.id : undefined,
          name: this.data.name,
          category: this.data.category,
          type: "open",
          description: this.data.description,
          units: this.data.units,
          date: this.data.date,
          branch_id: this.data.branch_id,
          address: !this.data.branch_id ? this.data.address : null,
          participation: this.data.participation,
        })
        .catch(apimethod.defaulthandler)
        .then((result) => {
          this.$emit("finish", result);
        })
        .finally(() => {
          this.request_running = false;
        });
    },
    addUnit(unit_id) {
      var unit = this.state_units.find((elem) => elem.id == unit_id);
      if (!unit) return;
      this.data.units.push(parseInt(unit_id));
      // Generate Duration if duration was not touched
      if (this.genDuration) {
        const newduration = moment
          .duration(this.duration, "HH:mm:ss")
          .add(moment.duration(unit.duration, "HH:mm:ss"));
        this.duration =
          ("00" + newduration.get("h")).slice(-2) +
          ":" +
          ("00" + newduration.get("m")).slice(-2) +
          ":" +
          ("00" + newduration.get("s")).slice(-2);
        this.genDuration = true;
      }
    },
    remUnit(unit_id) {
      var unit = this.state_units.find((elem) => elem.id == unit_id);
      if (!unit) return;
      this.data.units = this.data.units.filter((unit) => unit.id == unit_id);
      // Generate Duration if duration was not touched
      if (this.genDuration) {
        const newduration = moment
          .duration(this.duration)
          .subtract(unit.duration);
        this.duration =
          ("00" + newduration.get("h")).slice(-2) +
          ":" +
          ("00" + newduration.get("m")).slice(-2) +
          ":" +
          ("00" + newduration.get("s")).slice(-2);
        this.genDuration = true;
      }
    },
    async setParticipationDefault() {
      let {
        lesson_default_allow_participation,
        lesson_default_max_participators,
        lesson_default_register_mode_direct,
        lesson_default_register_mode_request,
      } = await this.$store.dispatch("getSetting", [
        "lesson_default_allow_participation",
        "lesson_default_max_participators",
        "lesson_default_register_mode_direct",
        "lesson_default_register_mode_request",
      ]);
      this.data.participation = lesson_default_allow_participation
        ? {
            max_participators: lesson_default_max_participators,
            register_modes: [
              lesson_default_register_mode_direct ? "direct" : null,
              lesson_default_register_mode_request ? "request" : null,
            ].filter((el) => el),
            start_date: null,
            end_date: null,
          }
        : false;
    },
  },
  async created() {
    this.loading = true;
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "lesson_modify"
    );
    await this.init();
  },
};
</script>
