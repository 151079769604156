<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div v-if="loading"></div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Sortiergruppe konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else>
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span
            class="text-uppercase page-subtitle"
            @click="$router.push({ name: 'unit_group_groups' })"
            style="cursor: pointer"
            >Gruppen</span
          >
          <h3 class="page-title">
            <template v-if="edit_title == false">
              {{ data.name }}<br class="d-sm-none" /><small
                style="cursor: pointer"
                @click="edit_title = true"
                v-if="auth_modify"
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span></small
              >
            </template>
            <div v-else class="d-flex mr-5">
              <b-form-input v-model="edit_name" :placeholder="data.name" />
              <b-button class="mx-2" @click="handleEditTitle">Ändern</b-button>
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_title = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3">
          <b-form-group
            label="Kategorie"
            label-size="sm"
            label-align="center"
            label-align-sm="left"
            label-class="pb-1"
            class="text-center text-sm-left"
          >
            <CategorySelector :selectable="false" />
          </b-form-group>
          <div class="text-center text-danger d-sm-none" v-if="!auth_modify">
            Keine Berechtigung zum Editieren
          </div>
        </b-col>
      </d-row>
      <d-row>
        <d-col class="p-0 p-md-3 mb-4">
          <d-card class="card-small">
            <!-- Component Header -->
            <d-card-header
              class="border-bottom d-none d-sm-flex justify-content-between"
            >
              <h6 class="m-0">Details</h6>
              <div class="text-danger" v-if="!auth_modify">
                Keine Berechtigung zum Editieren
              </div>
            </d-card-header>

            <d-card-body class="d-flex flex-column px-0 px-lg-3">
              <UnitGroup
                ref="unit_group_card"
                :id="id"
                v-on:finish="finish"
                type="group"
              />
            </d-card-body>
          </d-card>
        </d-col>
      </d-row>
    </template>
  </d-container>
</template>

<script>
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import { mapGetters } from "vuex";
import UnitGroup from "@/components/education/unit_group/UnitGroup";
export default {
  name: "UnitGroupShow",
  components: {
    CategorySelector,
    UnitGroup,
  },
  data() {
    return {
      notFound: false,
      loading: true,
      auth_modify: false,
      id: parseInt(this.$route.params.id),
      data: null,
      edit_title: false,
      edit_name: null,
    };
  },
  computed: {
    ...mapGetters(["selectedCategory", "unit_groups"]),
  },
  watch: {
    $route(to, from) {
      this.id = parseInt(this.$route.params.id);
      this.init();
    },
  },
  methods: {
    init() {
      this.loading = true;
      this.data = this.unit_groups.find(
        (el) => el.id == this.id && el.type == "group"
      );
      if (!this.data) return (this.notFound = true);
      else this.notFound = false;
      this.$store.dispatch("updateSelectedCategory", this.data.category);
      this.loading = false;
    },
    async handleEditTitle() {
      await this.$store.dispatch("updateUnitGroup", {
        id: this.data.id,
        name: this.edit_name,
      });
      this.init();
      this.edit_title = false;
    },
    finish(result) {
      this.$router.push({ name: "unit_group_groups" });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "unit_group_modify"
    );
    this.init();
  },
};
</script>
