<template>
  <b-container fluid class="main-content-container px-4">
    <b-row no-gutters class="page-header py-4">
      <b-col col sm="12" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weiteres</span>
        <h3 class="page-title">zusätzliche Funktionen</h3>
      </b-col>
    </b-row>
    <d-row>
      <b-col class="p-0 p-md-3 mb-4">
        <b-card class="card-small">
          <b-list-group flush>
            <b-list-group-item :to="{ name: 'integration_generator' }">
              <h5>Webseitenintegration-Generator</h5>
              <div class="ml-4">
                Mit Hilfe dieses Generators können Sie Programmcode für Ihre Webseite erstellen um verschiedene Bestandteile einzufügen.
                Mit individuellen Filtern können Sie die Anwendungen Ihren Wünschen anpassen.
              </div>
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'calendar_generator' }">
              <h5>Kalender-Generator</h5>
              <div class="ml-4">
                Mit Hilfe dieses Generators können Sie einen Link erstellen, um verschiedene Inhalte der Unterrichtssysteme (z.B. Unterrichtsstunden/Kurse) in externe Kalender (z.B. Outlook, Google Calendar) einbinden zu können.
                Mit individuellen Filtern können Sie den Kalender Ihren Wünschen anpassen.
              </div>
            </b-list-group-item>
            <b-list-group-item :to="{ name: 'report' }">
              <h5>Feedback / Fehler melden</h5>
              <div class="ml-4">
                Funktioniert etwas nicht oder wünschen Sie sich etwas für die Zukunft? Bitte teilen Sie uns Ihre Meinung und Gedanken mit! Nur so können wir uns und das Programm stetig verbessern oder aus unseren Fehlern lernen.<br>
                Vielen Dank
              </div>
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </d-row>
  </b-container>
</template>

<script>
export default {
  name: "more_list",
  data() {
    return {};
  }
};
</script>
