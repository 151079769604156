<template>
  <b-container fluid v-if="edit !== undefined">
    <b-row v-for="(elem, index) in phone_numbers" :key="elem.role">
      <b-col class="font-italic" cols="4">
        <b-form-select v-model="elem.role" :options="[{value: null, text: 'Bitte wählen'}, PhoneNumberRoles.find(role=>role.value==elem.role), ...selectablePhoneNumberRoles.filter(el => PhoneNumberRoles.find(role=>role.value==elem.role) && el.value!=PhoneNumberRoles.find(role=>role.value==elem.role).value)].filter(el => el!==undefined)"/>
        <b-button :disabled="!elem.role" v-if="!extended.find(el => el.toString()==index.toString())" variant="link" @click="extended.push(index.toString())">Name oder Beschreibung hinzufügen</b-button>
        <template v-else>
          <b-form-input v-model="elem.name" placeholder="Name" class="mt-1"/>
          <b-button variant="link" @click="extended = extended.filter(el => el.toString()!=index.toString()); elem.name=null; elem.text=null;">Name & Beschreibung entfernen</b-button>
        </template>
      </b-col>
      <b-col
        cols="6"
        class="pl-3"
      >
        <b-form-input :disabled="!elem.role" v-model="elem.value" placeholder="Telefonnummer"/>
        <b-form-textarea v-if="extended.find(el => el.toString()==index.toString())" v-model="phone_numbers[index].text" class="mt-1" placeholder="Beschreibung"/>
      </b-col>
      <b-col cols="2">
        <b-button variant="link" @click="rem(index)">Löschen</b-button>
      </b-col>
    </b-row>
    <b-row class="border-top">
      <b-button variant="link" @click="add">Weitere Telefonnummer hinzufügen</b-button>
    </b-row>
  </b-container>
  <div v-else>
    <div v-for="elem in phone_numbers" :key="elem.value" class="d-flex">
      <div class="font-italic">
        {{ getRoleName(elem.role) }}
      </div>
      <div
        class="pl-3"
        :style="link ? 'cursor: pointer;' : ''"
        @click.stop="handleClick(elem)"
        v-b-popover.hover="{ html: true, content: `${(elem.name) ? `<strong>${elem.name}</strong> ${elem.text ? ' - ' : ''}` : ''}${elem.text ? elem.text : ''}`}"
      >
        {{ elem.value }}
      </div>
    </div>
  </div>
</template>
<script>
import { PhoneNumberRoles } from "@/data/communication-roles";
export default {
  props: {
    data: {
      type: Array,
      required: true,
      validator(data) {
        for (let i in data) {
          if (
            typeof data[i].role != "string" ||
            typeof data[i].value != "string"
          )
            return false;
        }
        return true;
      },
    },
    link: {
      type: Boolean,
      default: false,
      required: false,
    },
    edit: {
      required: false,
    },
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  data() {
    return {
      phone_numbers: null,
      PhoneNumberRoles,
      extended: []
    };
  },
  methods: {
    handleClick(elem) {
      if (!this.link) return;
      window = window.open(`tel:${elem.value}`, "telWindow");
      if (window && window.open && !window.closed) window.close();
    },
    reset() {
      this.phone_numbers = this.data;
      this.extended = [];
      for (let i in this.phone_numbers) if (this.phone_numbers[i].name!=null || this.phone_numbers[i].text!=null) this.extended.push(i);
    },
    add() {
      if (this.selectablePhoneNumberRoles.length==0) return;
      if (this.phone_numbers.find(el => el.role==null || el.value==null)) return;
      this.phone_numbers.push({
        name: null,
        text: null,
        role: (this.phone_numbers.length==0) ? PhoneNumberRoles[0].value : PhoneNumberRoles[1].value,
        value: null
      });
    },
    rem(index) {
      this.phone_numbers.splice(index,1);
      this.extended = this.extended.filter(el => el.toString()!=index.toString());
      if (this.phone_numbers.length==1) this.phone_numbers[0].role=PhoneNumberRoles[0].value;
    },
    getRoleName(role) {
      return PhoneNumberRoles.find((el) => el.value == role).text;
    },
  },
  computed: {
    selectablePhoneNumberRoles() {
      return PhoneNumberRoles.filter(role => !this.phone_numbers.find(elem => elem.role==role.value && role.value=='primary'));
    }
  },
  watch: {
    phone_numbers: {
      handler(val) {
        this.$emit('input', val.filter(el => el.role!==null && el.value!==null));
      },
      deep: true,
    }
  },
  created() {
    this.reset();
  }
};
</script>
