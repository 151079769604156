<template>
  <b-container fluid>
    <b-row
      align-v="center"
      :class="!fb_pages_activated || !api_modify ? 'text-muted' : ''"
    >
      <div
        v-if="!fb_pages_activated"
        class="mr-3 text-danger"
        style="position: absolute; top: 0; right: 0"
      >
        <i class="material-icons">warning</i> derzeit deaktiviert
      </div>
      <div
        v-else-if="!api_modify"
        class="mr-3 text-danger"
        style="position: absolute; top: 0; right: 0"
      >
        <i class="material-icons">warning</i> keine Berechtigung
      </div>
      <b-col cols="4" class="d-none d-md-flex justify-content-center">
        <img
          style="max-width: 100%"
          :style="
            !fb_pages_activated || !api_modify ? 'filter: grayscale(0.75);' : ''
          "
          width="50%"
          src="@/assets/images/Facebook.png"
        />
      </b-col>
      <b-col>
        <h3>
          Facebook Unternehmensseite
          <a
            :href="INFO_LINK"
            target="_blank"
            class="material-icons"
            style="cursor: pointer"
            >link</a
          >
        </h3>
        <div>
          <span class="font-weight-bold">Status: </span>
          <template v-if="fb_pages_connected">Verbunden <template v-if="fb_pages_self">mit <b-link :href="fb_pages_self.link" target="_blank" class="text-muted font-italic">{{ fb_pages_self.name }}</b-link></template></template
          ><template v-else>Nicht Verbunden</template>
        </div>
        <div class="font-weight-bold">Funktionen:</div>
        <ul class="small mb-0">
          <li>Syncronisierung von Firmendaten mit Ihrer Facebookseite</li>
          <li>Zugriff auf Bewertungen & Nachrichten</li>
          <li>Veröffentlichen von Ankündigungen als Beiträge</li>
        </ul>
        <b-link
          class="ml-4 small text-muted text-secondary"
          v-b-modal.modal-fbp-details
          >weitere Informationen</b-link
        >
        <div class="d-flex justify-content-between">
          <b-button
            v-if="fb_pages_connected"
            variant="link"
            v-b-modal.modal-facebook_pages
            size="lg"
            :disabled="!fb_pages_activated || !api_modify"
            >Konfigurieren</b-button
          >
          <b-button
            v-if="fb_pages_connected"
            variant="link"
            class="text-danger"
            @click="
              $store.dispatch('removeAPIConnections', {
                api_name: 'facebook_pages',
              })
            "
            size="lg"
            :disabled="!fb_pages_activated || !api_modify"
            >Trennen</b-button
          >
          <b-button
            v-else
            variant="link"
            class="text-success"
            @click="handleConnect"
            size="lg"
            :disabled="!fb_pages_activated || !api_modify"
            >Jetzt verbinden</b-button
          >
        </div>
        <b-modal
          ref="choose_page_modal"
          title="Seite auswählen"
          hide-footer
          body-class="p-0"
          >
          <b-container fluid>
            <b-row>
              <b-col cols="12">Bitte wählen Sie eine Seite aus den von Ihnen freigegebenen Seiten Ihres Facebook Account.</b-col>
            </b-row>
            <b-row v-if="!fb_graph_pages || fb_graph_pages.length==0">
              <b-col cols="12">Es wurde keine freigegebene Seite gefunden, welche syncronisiert werden kann.</b-col>
              <b-col cols="6">
                <b-link variant="success" @click="handleConnect(true)">Freigabeeinstellungen bei Facebook anpassen.</b-link>
              </b-col>
              <b-col cols="6" class="text-right">
                <b-link variant="primary" :href="CREATE_PAGE_LINK">Neue Seite in Facebook erstellen.</b-link>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12">
                <b-list-group>
                  <b-list-group-item v-for="page in fb_graph_pages" @click="handleConnectPage(page.id)" :key="page.id" style="cursor: pointer;">
                    {{ page.name }}
                  </b-list-group-item>
                </b-list-group>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
        <b-modal
          id="modal-facebook_pages"
          title="Facebook Unternehmensseite"
          hide-footer
          body-class="p-0"
          @show="modal_view=1;"
        >
          <b-container fluid>
            <b-row class="py-2">
              <b-col cols="12">
                <div
                  class="border-bottom font-weight-bold"
                >
                  Firmendaten
                </div>
                <b-form-group>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self"
                    switch
                  >
                    Aktiviert
                  </b-form-checkbox>
                  <div>Welche Eigenschaften sollen syncronisisert werden?</div>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_name"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    Firmennname
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_about"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    Kurzbeschreibung
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_location"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    primäre Anschrift
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_phone"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    primäre Telefonnummer
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_emails"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    E-Mails
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-if="false"
                    v-model="api_facebook_pages_sync_self_locations"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    Filialen
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_website"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    Webseite
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_self_impressum"
                    :disabled="!api_facebook_pages_sync_self"
                  >
                    Impressumsangabe
                  </b-form-checkbox>
                </b-form-group>
                <div
                  class="border-bottom font-weight-bold"
                >
                  Ankündigungen
                  <i
                      @click.stop="$router.push({ name: 'gmb_announcements' })"
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken für Übersicht aller Ankündigungen für Google My Business.'
                      "
                      >link</i
                    >
                </div>
                <b-form-group>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation aktiv
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_facebook_pages_sync_announcement_type_global
                    "
                    :disabled="!api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation von Globalen Ankündigung
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'global' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_facebook_pages_sync_announcement_type_branches
                    "
                    :disabled="!api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation von Filialankündigungen
                    <i
                      @click.stop="
                        $router.push({ name: 'branch_announcements' })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungsgruppe zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_facebook_pages_sync_announcement_type_index"
                    :disabled="!api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation von Ankündigungen auf der Startseite
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'index' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_facebook_pages_sync_announcement_type_search_job
                    "
                    :disabled="!api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation von Jobangeboten
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'search_job' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_facebook_pages_sync_announcement_type_covid_19
                    "
                    :disabled="!api_facebook_pages_sync_announcements"
                    switch
                  >
                    Syncronisation von Hinweisen zu Covid-19
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'covid_19' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                </b-form-group>
                <div
                  class="border-bottom font-weight-bold"
                >
                  Bewertungen
                </div>
                <b-form-checkbox
                    v-model="api_facebook_pages_sync_reviews"
                    switch
                  >
                    Aktiviert
                  </b-form-checkbox>
                  <b-form-checkbox v-model="api_facebook_pages_sync_review_restore_old" :disabled="!api_facebook_pages_sync_reviews" switch>
                    Wiederherstellung des Status alter Bewertungen <i class="material-icons" v-b-popover.hover="'Wenn aktiviert wird beim Laden einer neuen Bewertung überprüft, ob diese zu einem früheren Zeitpunkt schoneinmal existiert hat und der Veröffentlichungsstatus wiederhergestellt.'">info</i>
                  </b-form-checkbox>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>

        <!-- Details -->
        <b-modal
          id="modal-fbp-details"
          hide-footer
          hide-header
          body-class="p-0"
        >
          <b-container fluid>
            <b-row>
              <b-col>
                <div class="font-weight-bold">Was ist das?</div>
                <div>
                  Ihre Facebookseite bietet Ihnen die Möglichkeit sich auf der Plattform Facebook als Unternehmen zu repräsentieren. Ihre Kunden
                  erhalten somit auf diesem Wege aktuelle Informationen zu Ihrer
                  Firma, Ihren Öffnungszeiten und können über Ankündigungen
                  informiert werden. Gleichzeitig haben Sie Zugriff auf
                  Bewertungen und Nachrichten ihrer Kunden, sowie auf alle
                  veröffentlichte Beiträge Ihrer Firma.
                </div>
                <div class="font-weight-bold">Warum wichtig?</div>
                <div>
                  Mit der Nutzung der Facebook Unternehmensseite in der Fahrschule API
                  werden Ihnen die wichtigsten Informationen zusammen mit den
                  Daten aus anderen Platformen an einem Ort übersichtlich
                  zusammengetragen. Somit können Sie über viele Kanäle
                  einheitlich mit Ihren Kunden komunizieren.
                </div>
                <br />
                <b-link href="https://www.facebook.com/policies_center" target="_blank"
                  >Nutzungsbedingungen und Richtlinien</b-link
                ><br />
                <b-link
                  href="https://www.facebook.com/communitystandards/"
                  target="_blank"
                  >Hinweise zur Veröffentlichung von Inhalten auf Facebook</b-link
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { addSetting } from "../used_settings";
addSetting([
  "api_facebook_pages_active",
  "api_facebook_pages_sync_self",
  "api_facebook_pages_sync_self_name",
  "api_facebook_pages_sync_self_about",
  "api_facebook_pages_sync_self_location",
  "api_facebook_pages_sync_self_phone",
  "api_facebook_pages_sync_self_emails",
  "api_facebook_pages_sync_self_locations",
  "api_facebook_pages_sync_self_website",
  "api_facebook_pages_sync_self_impressum",
  "api_facebook_pages_sync_announcements",
  "api_facebook_pages_sync_announcement_type_branches",
  "api_facebook_pages_sync_announcement_type_covid_19",
  "api_facebook_pages_sync_announcement_type_global",
  "api_facebook_pages_sync_announcement_type_index",
  "api_facebook_pages_sync_announcement_type_search_job",
  "api_facebook_pages_sync_reviews",
  "api_facebook_pages_sync_review_restore_old",
]);
export default {
  data() {
    return {
      loaded: false,
      api_modify: null,
      INFO_LINK: "https://developers.facebook.com/docs/pages/?translation",
      CREATE_PAGE_LINK: "https://www.facebook.com/pages/creation/",
      // SELF
      api_facebook_pages_sync_self: null,
      api_facebook_pages_sync_self_name: null,
      api_facebook_pages_sync_self_about: null,
      api_facebook_pages_sync_self_location: null,
      api_facebook_pages_sync_self_phone: null,
      api_facebook_pages_sync_self_emails: null,
      api_facebook_pages_sync_self_locations: null,
      api_facebook_pages_sync_self_website: null,
      api_facebook_pages_sync_self_impressum: null,
      // ANNOUNCEMENTS
      api_facebook_pages_sync_announcements: null,
      api_facebook_pages_sync_announcement_type_branches: null,
      api_facebook_pages_sync_announcement_type_covid_19: null,
      api_facebook_pages_sync_announcement_type_global: null,
      api_facebook_pages_sync_announcement_type_index: null,
      api_facebook_pages_sync_announcement_type_search_job: null,
      // REVIEWS
      api_facebook_pages_sync_reviews: null,
      api_facebook_pages_sync_review_restore_old: null,
      // Modal
    };
  },
  methods: {
    async handleConnect(force = false) {
      await this.$store.dispatch('connectAPI', {
        api_name: 'facebook_pages',
        oAuth_redirect: window.location.href+'?connectFBP',
        rerequest: force,
      });
      this.openModal();
    },
    async handleConnectPage(page) {
      await this.$store.dispatch('connectAPI', {
        api_name: 'facebook_pages',
        oAuth_redirect: window.location.href+'?connectFBP',
        api_key: page,
      });
      this.openModal();
    },
    async refresh() {
      await Promise.all([
        // SELF
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self").then( val => this.api_facebook_pages_sync_self=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_name").then( val => this.api_facebook_pages_sync_self_name=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_about").then( val => this.api_facebook_pages_sync_self_about=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_location").then( val => this.api_facebook_pages_sync_self_location=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_phone").then( val => this.api_facebook_pages_sync_self_phone=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_emails").then( val => this.api_facebook_pages_sync_self_emails=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_locations").then( val => this.api_facebook_pages_sync_self_locations=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_website").then( val => this.api_facebook_pages_sync_self_website=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_self_impressum").then( val => this.api_facebook_pages_sync_self_impressum=val.value.current),
        // ANNOUNCEMENTS
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcements").then( val => this.api_facebook_pages_sync_announcements=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcement_type_branches").then( val => this.api_facebook_pages_sync_announcement_type_branches=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcement_type_covid_19").then( val => this.api_facebook_pages_sync_announcement_type_covid_19=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcement_type_global").then( val => this.api_facebook_pages_sync_announcement_type_global=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcement_type_index").then( val => this.api_facebook_pages_sync_announcement_type_index=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_announcement_type_search_job").then( val => this.api_facebook_pages_sync_announcement_type_search_job=val.value.current),
        // REVIEWS
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_reviews").then( val => this.api_facebook_pages_sync_reviews=val.value.current),
        this.$store.dispatch('getSetting', "api_facebook_pages_sync_review_restore_old").then( val => this.api_facebook_pages_sync_review_restore_old=val.value.current),
      ]);
    },
    openModal() {
      this.$refs['choose_page_modal'].show();
    }
  },
  computed: {
    ...mapGetters([
      "fb_pages_activated",
      "fb_pages_connected",
      "fb_pages_self",
      "fb_graph_pages",
    ]),
  },
  watch: {
    // SELF
    async api_facebook_pages_sync_self(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_name(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_name", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_about(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_about", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_location(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_location", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_phone(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_phone", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_emails(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_emails", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_locations(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_locations", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_website(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_website", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_self_impressum(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_self_impressum", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    // ANNOUNCEMENTS
    async api_facebook_pages_sync_announcements(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcements", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_announcement_type_branches(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcement_type_branches", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_announcement_type_covid_19(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcement_type_covid_19", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_announcement_type_global(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcement_type_global", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_announcement_type_index(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcement_type_index", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_announcement_type_search_job(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_announcement_type_search_job", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    // REVIEWS
    async api_facebook_pages_sync_reviews(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_reviews", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_facebook_pages_sync_review_restore_old(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_facebook_pages_sync_review_restore_old", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
  },
  async created() {
    this.loaded = false;
    await Promise.all([
      (async () => {
        this.api_modify = await this.$store.dispatch(
          "haveUserRight",
          "api_modify"
        );
      })(),
      this.refresh(),
    ]);
    this.loaded = true;
    // connection with oAuth was established and a window to choose a page will be opened directly
    if (this.$route.query.connectFBP!==undefined) this.openModal();
  },
};
</script>
