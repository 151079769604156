import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_user = {
  state: {
    employees: [],
    instructors: [],
    office_workers: [],
  },
  mutations: {
    setEmployees(state, input) {
      state.employees = input;
    },
    setInstructors(state, input) {
      state.instructors = input;
    },
    setOfficeWorkers(state, input) {
      state.office_workers = input;
    },
    resetUserModule(state) {
      state.employees = [];
      state.instructors = [];
      state.office_workers = [];
    }
  },
  actions: {
    async setupUserModule(context) {
      try {
        await Promise.all([
          (async () => {context.commit("setEmployees", await context.dispatch('getEmployees'));})(),
          (async () => {context.commit("setOfficeWorkers", await context.dispatch('getOfficeWorkers'));})(),
          (async () => {context.commit("setInstructors", await context.dispatch('getInstructors'));})(),
        ]);
      } catch (err) {
        return setTimeout(async () => await context.dispatch('setupUserModule'), 3000);
      }
    },
    async getUser(context, {id, extended } = {}) {
      let response = await store.$api.get(`user/${id}${extended ? '/data' : ''}`);
      return response.data.result;
    },
    async getUsers(context, { id, extended }) {
      let id_query="";
      if (!Array.isArray(id)) id = [id];
      id.forEach(id => {
        id_query=`${(id_query != "") ? "&" : "?"}id[]=${id}`;
      })
      if (id.length == 0) throw new Error();
      let response = await store.$api.get(`user${extended ? '/data' : ''}${id_query}`);
      return response.data.result;
    },
    async getEmployees() {
      let response = await store.$api.get(`user?isEmployee`);
      return response.data.result;
    },
    async getInstructors() {
      let response = await store.$api.get(`user?isInstructor`);
      return response.data.result;
    },
    async getOfficeWorkers() {
      let response = await store.$api.get(`user?isOfficeWorker`);
      return response.data.result;
    },
    async updateUser(context, { id, first_name, middle_name, last_name, address}) {
      let response = await store.$api.put(`user/data/${id}`, {
        first_name,
        middle_name,
        last_name,
        address
      });
      return response.data.result;
    },
    async getUserParticipations(context, user_id) {
      let response = await store.$api.get(`user/${user_id}/participation`);
      return response.data.result;
    }
  },
  getters: {
    employees(state) {
      return state.employees;
    },
    instructors(state) {
      return state.instructors
    },
    office_workers(state) {
      return state.office_workers;
    },
  }
}
export default module_user;
