var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.edit!==undefined)?_c('div',_vm._l((_vm.weekdays),function(day){return _c('b-row',{key:day.id},[_c('b-col',[_vm._v(_vm._s(day.name))]),_c('b-col',{staticClass:"text-right"},[(
          _vm.openHours.filter(function (elem) { return elem.open_day == day.value; })
            .length == 0
        )?_c('div',{staticClass:"font-italic"},[_vm._v(" nicht geöffnet "),(_vm.edit!==undefined)?_c('i',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.add(day)}}},[_vm._v("add")]):_vm._e()]):[(_vm.edit!==undefined)?_c('div',{staticClass:"text-right"},[_c('span',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.add(day)}}},[_vm._v("add")])]):_vm._e(),_vm._l((_vm.openHours.filter(
            function (elem) { return elem.open_day == day.value; }
          )),function(elem,index){return _c('div',{key:index,staticClass:"d-flex text-right"},[_c('b-form-input',{staticClass:"mr-2",attrs:{"id":"open_time","type":"time","state":_vm.validate_time(elem),"size":"sm"},model:{value:(elem.open_time),callback:function ($$v) {_vm.$set(elem, "open_time", $$v)},expression:"elem.open_time"}}),_vm._v(" - "),_c('b-form-input',{staticClass:"ml-2",attrs:{"type":"time","state":_vm.validate_time(elem),"size":"sm"},model:{value:(elem.close_time),callback:function ($$v) {_vm.$set(elem, "close_time", $$v)},expression:"elem.close_time"}}),(_vm.edit!==undefined)?_c('span',{staticClass:"material-icons ml-2 my-auto",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.remove(elem)}}},[_vm._v("delete")]):_vm._e(),(elem.open_day != elem.close_day)?_c('div',{staticClass:"small font-italic"},[_vm._v(" (schließt am "+_vm._s(_vm.weekdays.find(function (el) { return el.value == elem.close_day; }).name)+") ")]):_vm._e()],1)})]],2)],1)}),1):_c('div',_vm._l((_vm.weekdays),function(day){return _c('div',{key:day.id,staticClass:"d-flex justify-content-between"},[_c('div',[_vm._v(_vm._s(day.name))]),_c('div',[(
          _vm.openHours.filter(function (elem) { return elem.open_day == day.value; })
            .length == 0
        )?_c('div',{staticClass:"font-italic"},[_vm._v(" nicht geöffnet ")]):_vm._e(),_vm._l((_vm.openHours.filter(
            function (elem) { return elem.open_day == day.value; }
          )),function(elem){return _c('div',{key:elem.open_day,staticClass:"text-right"},[_vm._v(" "+_vm._s(_vm._f("moment")([elem.open_time, ["HH:mm:ss", "HH:mm"]],"H:mm"))+" - "+_vm._s(_vm._f("moment")([elem.close_time, ["HH:mm:ss", "HH:mm"]],"H:mm"))+" "),(elem.open_day != elem.close_day)?_c('div',{staticClass:"small font-italic"},[_vm._v(" (schließt am "+_vm._s(_vm.weekdays.find(function (el) { return el.value == elem.close_day; }).name)+") ")]):_vm._e()])})],2)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }