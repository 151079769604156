<template>
  <b-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div v-if="!(loading === false)"></div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Theoriestunde konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else>
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0 pr-1 pr-sm-5"
        >
          <span
            class="text-uppercase page-subtitle"
            @click="$router.push({ name: 'lessons' })"
            style="cursor: pointer"
            >Theoriestunden</span
          >
          <h3 class="page-title">
            <template v-if="edit_title == false">
              {{
                data.name
                  ? data.name
                  : data.processed_unit_name
                  ? data.processed_unit_name
                  : "Achtung: kein Name!!"
              }}<br class="d-sm-none" /><small
                style="cursor: pointer"
                @click="edit_title = true"
                v-if="auth_modify"
                v-b-popover.hover.right="
                  'Standardmäßig wird der Name aus den vorhandenen Inhalten einer Theoriestunde ermittelt. Sie können manuell auch einen anderen Namen festlegen.'
                "
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span></small
              >
            </template>
            <div v-else class="d-md-flex mr-5">
              <b-form-input
                v-model="edit.name"
                :placeholder="
                  data.processed_unit_name
                    ? data.processed_unit_name
                    : 'Achtung: kein Name!!'
                "
              />
              <b-button
                variant="light"
                class="mx-2"
                @click="edit.name = null"
                :disabled="
                  !this.data.processed_unit_name &&
                  (this.edit.name == null || this.edit.name.trim() == '')
                "
                >Standard</b-button
              >
              <b-button class="mr-2" @click="handleEditTitle">Ändern</b-button>
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_title = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3">
          <b-form-group
            label="Kategorie"
            label-size="sm"
            label-align="center"
            label-align-sm="left"
            label-class="pb-1"
            class="text-center text-sm-left"
          >
            <CategorySelector :selectable="false" />
          </b-form-group>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col cols="12" md="8" class="pr-md-0">
          <d-card class="card-small pb-2">
            <d-card-body class="d-flex flex-column px-0 px-lg-3">
              <b-form-group>
                <b-row class="mr-1">
                  <b-col cols="12" :md="!edit_date ? 6 : 12">
                    <b-form-group label="Termin">
                      <template v-if="!edit_date">
                        <span
                          v-if="auth_modify"
                          style="
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                          "
                          @click="edit_date = true"
                          ><i class="material-icons">edit</i>Anpassen</span
                        >
                        {{ [data.date.start] | moment("DD. MMM YYYY HH:mm") }}
                        ({{ [data.date.duration] | duration("humanize") }})
                      </template>
                      <template v-else>
                        <span
                          style="
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                          "
                          @click="
                            edit_date = false;
                            edit.date = data.date;
                          "
                        >
                          <i class="material-icons">close</i>Schließen
                        </span>
                        <b-form-group label-size="sm" label="Anfang">
                          <b-row>
                            <b-col cols="12" md="9" class="pr-md-1">
                              <b-form-datepicker
                                v-model="edit_start_date"
                                v-bind="DatePickerConfig"
                              ></b-form-datepicker>
                            </b-col>
                            <b-col cols="12" md="3" class="pl-md-1">
                              <b-form-timepicker
                                v-model="edit_start_time"
                                v-bind="TimePickerConfig"
                              ></b-form-timepicker>
                            </b-col>
                          </b-row>
                        </b-form-group>
                        <b-form-group label-size="sm" label="Dauer">
                          <b-form-timepicker
                            v-model="edit_duration"
                            v-bind="{
                              ...TimePickerConfig,
                              labelSelected: 'Ausgewählte Dauer',
                              labelNoTimeSelected: 'Keine Dauer ausgewählt',
                            }"
                          ></b-form-timepicker>
                        </b-form-group>
                      </template>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" :md="!edit_date ? 6 : 12">
                    <b-form-group label="Ort" style="">
                      <template v-if="!edit_location">
                        <span
                          v-if="auth_modify"
                          style="
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                          "
                          @click="edit_location = true"
                          ><i class="material-icons">edit</i>Anpassen</span
                        >
                        <span
                          v-if="data.branch_id"
                          @click="
                            $router.push({
                              name: 'branch',
                              params: { id: branch.id },
                            })
                          "
                          style="cursor: pointer"
                        >
                          Filiale: {{ branch.name }}
                        </span>
                        <template v-else-if="data.address">
                          <div>
                            {{
                              data.address.street +
                              " " +
                              data.address.streetnumber
                            }}
                          </div>
                          <div>
                            {{ data.address.zip + " " + data.address.city }}
                          </div>
                        </template>
                        <template v-else>
                          <small>keine Adresse angegeben</small>
                        </template>
                      </template>
                      <template v-else>
                        <span
                          style="
                            position: absolute;
                            top: 0;
                            right: 0;
                            cursor: pointer;
                          "
                          @click="
                            edit_location = false;
                            edit.branch_id = data.branch_id;
                            edit.address = data.address;
                          "
                          ><i class="material-icons">close</i>Schließen</span
                        >
                        <b-form-select v-model="edit.branch_id">
                          <option
                            v-for="branch in branches"
                            :key="branch.id"
                            :value="branch.id"
                          >
                            {{ branch.name }}
                          </option>
                          <option :value="null">Addresse wählen</option>
                        </b-form-select>
                      </template>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="12">
                    <b-collapse
                      :visible="edit_location && edit.branch_id === null"
                    >
                      <b-row v-if="edit_location && edit.branch_id === null">
                        <b-col cols="12">Addresse:</b-col>
                        <b-col cols="12" sm="9" class="mb-1">
                          <b-form-input
                            v-model="edit.address.street"
                            placeholder="Straße"
                          />
                        </b-col>
                        <b-col cols="12" sm="3" class="mb-1">
                          <b-form-input
                            v-model="edit.address.streetnumber"
                            min="0"
                            oninput="validity.valid||(value='');"
                            placeholder="Nr."
                          />
                        </b-col>
                        <b-col cols="12" sm="4" class="mb-1">
                          <b-form-input
                            v-model="edit.address.zip"
                            min="0"
                            max="99999"
                            oninput="validity.valid||(value='');"
                            placeholder="PLZ"
                          />
                        </b-col>
                        <b-col cols="12" sm="8" class="mb-1">
                          <b-form-input
                            v-model="edit.address.city"
                            placeholder="Stadt"
                          />
                        </b-col>
                        <hr class="col mx-3 mt-2 mb-0" />
                      </b-row>
                    </b-collapse>
                  </b-col>
                </b-row>
              </b-form-group>
              <b-form-group style="position: relative">
                <template slot="label">
                  Inhalte
                  <i
                    class="material-icons"
                    v-b-popover.hover.right="
                      'Inhalte sind Unterrichtseinheiten, welche einzelne Inhalte der gesamten Theoriestunde wiederspiegeln'
                    "
                    >help_outline</i
                  >
                </template>
                <template v-if="!edit_units">
                  <div
                    v-if="auth_modify"
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      cursor: pointer;
                    "
                    @click="edit_units = true"
                  >
                    <i class="material-icons">edit</i>Anpassen
                  </div>
                  <b-list-group flush>
                    <b-list-group-item
                      href="#"
                      class="pl-2 py-1"
                      v-for="unit in data.units.map((unit_id) =>
                        units.find((unit) => unit.id == unit_id)
                      )"
                      :key="unit.id"
                      @click="
                        $router.push({ name: 'unit', params: { id: unit.id } })
                      "
                    >
                      <div>{{ unit.name }}</div>
                    </b-list-group-item>
                  </b-list-group>
                </template>
                <template v-else>
                  <div
                    class="text-right mb-1"
                    style="
                      position: absolute;
                      top: 0;
                      right: 0;
                      cursor: pointer;
                    "
                    @click="
                      edit_units = false;
                      edit.units = data.units;
                      search = '';
                    "
                  >
                    <i class="material-icons">close</i>Schließen
                  </div>
                  <b-form-tags
                    v-model="edit.units"
                    no-outer-focus
                    class="mb-2 border-0"
                  >
                    <template v-slot="{ tags, disabled, addTag, removeTag }">
                      <ul
                        v-if="tags.length > 0"
                        class="list-inline d-inline-block mb-2"
                      >
                        <li
                          v-for="tag in tags"
                          :key="tag"
                          class="list-inline-item"
                        >
                          <b-form-tag
                            @remove="remUnitTag({ unit_id: tag, removeTag })"
                            :disabled="disabled"
                            variant="secondary"
                            class="mb-1"
                          >
                            <a :id="`unit_${tag}`" href="#" class="text-white"
                              >{{ units.find((unit) => unit.id == tag).key }} -
                              {{ units.find((unit) => unit.id == tag).name }}</a
                            >
                          </b-form-tag>
                          <b-popover :target="`unit_${tag}`" triggers="focus">
                            <div>
                              <strong>Standarddauer: </strong
                              >{{
                                [units.find((unit) => tag == unit.id).duration]
                                  | duration("humanize")
                              }}
                            </div>
                            <div>
                              <strong>Beschreibung: </strong
                              >{{
                                units.find((unit) => tag == unit.id)
                                  .description_short !== null &&
                                units.find((unit) => tag == unit.id)
                                  .description_short.length > 0
                                  ? units.find((unit) => tag == unit.id)
                                      .description_short
                                  : "keine"
                              }}
                            </div>
                            <router-link
                              :to="{ name: 'unit', params: { unitId: tag } }"
                              >Zur Detailseite</router-link
                            >
                          </b-popover>
                        </li>
                      </ul>
                      <b-dropdown
                        size="sm"
                        variant="outline-secondary"
                        block
                        menu-class="w-100"
                      >
                        <template v-slot:button-content no-outer-focus
                          >Theorieeinheit auswählen</template
                        >
                        <b-dropdown-form @submit.stop.prevent="() => {}">
                          <b-form-group
                            label-for="unit-search-input"
                            label="Suche:"
                            label-cols-md="auto"
                            class="mb-0"
                            label-size="sm"
                            :disabled="disabled"
                          >
                            <b-form-input
                              v-model="search"
                              id="unit-search-input"
                              type="search"
                              size="sm"
                              autocomplete="off"
                            ></b-form-input>
                          </b-form-group>
                        </b-dropdown-form>
                        <b-dropdown-divider></b-dropdown-divider>
                        <b-dropdown-group
                          style="overflow: auto; max-height: 30vh"
                        >
                          <b-dropdown-item
                            v-for="unit in filteredUnits"
                            :key="unit.id"
                            @click="addUnitTag({ unit_id: unit.id, addTag })"
                            >{{ unit.key }} - {{ unit.name }}</b-dropdown-item
                          >
                        </b-dropdown-group>
                        <b-dropdown-text v-if="units.length === 0"
                          >Keine Theorieeinheiten vorhanden</b-dropdown-text
                        >
                        <b-dropdown-text v-else-if="filteredUnits.length === 0"
                          >Keine Theorieeinheit gefunden</b-dropdown-text
                        >
                      </b-dropdown>
                    </template>
                  </b-form-tags>
                </template>
              </b-form-group>
              <b-form-group style="position: relative;">
                <label v-if="data.description || edit_description">Beschreibung</label>
                <template v-if="!edit_description">
                  <div v-if="data.description" @click="edit_description = true" v-b-tooltip="'Bearbeiten'" style="cursor: pointer;" class="ml-2 font-italic text-light">
                    {{ data.description }}
                  </div>
                  <b-button v-else variant="link" class="pl-0 pb-0" @click="edit_description = true">zusätzliche Beschreibung hinterlegen</b-button>
                </template>
                <template v-else >
                  <div
                    style="cursor: pointer; position: absolute; top: 0; right: 0;"
                    @click="
                      edit_description = false;
                      edit.description = data.description;
                    "
                  >
                    <i class="material-icons">close</i>Schließen
                  </div>
                  <b-form-textarea v-model="edit.description"></b-form-textarea>
                </template>
              </b-form-group>
              <b-form-group style="position: relative;">
                      <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
                        <div class="pb-0">
                          <b-form-checkbox
                            :checked="!!edit.participation"
                            @input="
                              (val) =>
                                val
                                  ? setParticipationDefault()
                                  : (edit.participation = false)
                            "
                            switch
                            right
                            :disabled="!edit_participation"
                            >Teilnahme</b-form-checkbox
                          >
                        </div>
                        <span
                          class="material-icons"
                          v-b-popover.hover.top="{
                            content:
                              'In diesem Abschnitt legen Sie fest, wie die <strong>Teilnahme</strong> an der Unterrichtsstunde geregelt ist.<br><br>Sie können zum Beispiel entscheiden, ob Ihre Kunden sich selbstständig, nur durch eine Anmeldung bei einem Mitarbeiter oder garnicht anmelden können.<br>Auch eine max. Teilnehmeranzahl ist auswählbar.',
                            html: true,
                          }"
                          >help_outline</span
                        >
                      </div>
                      <hr class="mt-1" />
                      <template v-if="!edit_participation">
                        <span
                          v-if="auth_modify"
                          style="
                            position: absolute;
                            top: 0;
                            right: 2rem;
                            cursor: pointer;
                          "
                          @click="
                           edit_participation = true;
                           edit.participation = JSON.parse(JSON.stringify(data.participation));
                          "
                          ><i class="material-icons">edit</i>Anpassen</span
                        >
                        <div v-if="edit.participation" class="small">
                            <strong>Teilnahmemöglichkeiten:</strong>
                            <ul>
                              <li v-if="edit.participation && edit.participation.register_modes.length == 0">Nur durch Einladung</li>
                              <li v-if="edit.participation && edit.participation.register_modes.find(m => m == 'direct')">Direktanmeldung</li>
                              <li v-if="edit.participation && edit.participation.register_modes.find(m => m == 'request')">Auf Anfrage</li>
                            </ul>
                            <strong v-if="edit.participation.max_participators">max. {{ edit.participation.max_participators }} Teilnehmer</strong>
                        </div>
                      </template>
                      <template v-else>
                        <span
                          style="
                            position: absolute;
                            top: 0;
                            right: 2rem;
                            cursor: pointer;
                          "
                          @click="
                            edit_participation = false;
                            edit.participation = JSON.parse(JSON.stringify(data.participation));
                          "
                        >
                          <i class="material-icons">close</i>Schließen
                        </span>
                        <b-collapse :visible="!!edit.participation" class="col pb-4">
                          <template v-if="edit.participation">
                            <b-form-group label="Anmeldung..." label-size="sm">
                              <b-form-select
                                :value="
                                  edit.participation.register_modes.includes('direct')
                                    ? 'direct'
                                    : edit.participation.register_modes.includes('request')
                                    ? 'request'
                                    : null
                                "
                                :options="[
                                  { text: 'deaktiviert - durch Mitarbeiter', value: null },
                                  { text: 'auf Anfrage', value: 'request' },
                                  { text: 'direkt ohne Bestätigung', value: 'direct' },
                                ]"
                                @input="
                                  (val) =>
                                    val == 'request'
                                      ? (edit.participation.register_modes = ['request'])
                                      : val === 'direct'
                                      ? (edit.participation.register_modes = [
                                          'direct',
                                          'request',
                                        ])
                                      : (edit.participation.register_modes = [])
                                "
                                size="sm"
                              />
                            </b-form-group>
                            <b-form-checkbox
                              switch
                              :checked="!!edit.participation.max_participators"
                              @input="
                                (val) =>
                                  val
                                    ? (edit.participation.max_participators = 5)
                                    : (edit.participation.max_participators = null)
                              "
                              class="mt-1"
                              >max. Teilnehmeranzahl</b-form-checkbox
                            >
                            <b-collapse
                              v-if="edit.participation.max_participators"
                              :visible="edit.participation.max_participators !== null"
                            >
                              <b-form-input
                                v-model="edit.participation.max_participators"
                                type="number"
                                min="1"
                                size="sm"
                                class="mt-1"
                              />
                            </b-collapse>
                          </template>
                        </b-collapse>
                      </template>
                    </b-form-group>

                    <!-- <b-form-group style="position: relative;">
                <label v-if="data.files || edit_files">Dateien</label>
                <template v-if="!edit_files">
                  <div v-if="data.files" @click="edit_files = true" v-b-tooltip="'Bearbeiten'" style="cursor: pointer;" class="ml-2 font-italic text-light">
                    {{ data.files }}
                  </div>
                  <b-button v-else variant="link" class="pl-0 pb-0" @click="edit_description = true">Informationsmaterialien / Dateien hinterlegen</b-button>
                </template>
                <template v-else >
                  <div
                    style="cursor: pointer; position: absolute; top: 0; right: 0;"
                    @click="
                      edit_files = false;
                      edit.files = data.files;
                    "
                  >
                    <i class="material-icons">close</i>Schließen
                  </div>
                  <b-form-textarea v-model="edit.files"></b-form-textarea>
                </template>
              </b-form-group> -->
              <b-collapse :visible="changes">
                <b-button variant="light" @click="handleSave"
                  >Änderungen übernehmen</b-button
                >
              </b-collapse>
            </d-card-body>
          </d-card>
          <div class="d-flex flex-column px-3 d-md-none mt-3">
            <div class="text-center">
              <div class="d-flex justify-content-between">
                <div class="text-left pr-1">Verschoben:<br />Abgesagt:</div>
                <div class="text-right text-primary">
                  {{ data.postponed ? "Ja" : "Nein" }}<br />{{
                    data.canceled ? "Ja" : "Nein"
                  }}
                </div>
              </div>
              <hr class="my-2" />
            </div>
            <div class="row justify-content-between">
              <b-button
                variant="outline-secondary"
                class="my-1"
                v-if="data.canceled == false"
                @click="handleCancel"
                >Absagen</b-button
              >
              <b-button
                variant="outline-secondary"
                class="my-1"
                v-else
                @click="handleReactivate"
                >Reaktivieren</b-button
              >
              <b-dropdown
                variant="outline-secondary"
                size="sm"
                dropup
                right
                split
                text="Verschieben"
                class="my-1"
                @click="handlePostpone"
                menu-class="p-0"
              >
                <b-dropdown-item
                  v-if="data.postponed == false"
                  @click="handleMarkPostpone"
                  ><small>Als Verschoben kennzeichnen</small></b-dropdown-item
                >
                <b-dropdown-item v-else @click="handleNotMarkPostpone"
                  ><small
                    >Nicht mehr als Verschoben kennzeichnen</small
                  ></b-dropdown-item
                >
                <b-dropdown-item v-b-modal.modal-help-postpone title="Title"
                  ><small
                    >Hilfe <i class="material-icons">help_outline</i></small
                  ></b-dropdown-item
                >
                <b-modal
                  id="modal-help-postpone"
                  title="Erklärung: Verschieben einer Theoriestunde"
                  hide-footer
                >
                  Mit Hilfe dieser Funktion können Sie Theoriestunden
                  verschieben und Ihre Kunden über die Änderung informieren.
                  <br />
                  <br />
                  <small
                    >Hinweis: Falls Sie nur ein falsches Datum in der Anlage
                    gewählt haben und der Durchführungstermin noch weit in der
                    Zukunft liegt ist es empfehlenswert nur das Datum des
                    Termins zu verändern.</small
                  >
                </b-modal>
              </b-dropdown>
            </div>
            <b-button
              variant="outline-danger"
              class="row my-1"
              @click="handleRemove"
              >Löschen</b-button
            >
          </div>
        </b-col>
        <b-col cols="12" md="4" class="pl-0">
          <b-col class="text-center mt-0 mb-3 pr-0">
            <b-row class="justify-content-center">
              <b-col cols="8">
                <div class="d-none d-md-flex flex-column mx-auto">
                  <div class="text-center">
                    <div class="d-flex justify-content-between">
                      <div class="text-left pr-1">
                        Verschoben:<br />Abgesagt:
                      </div>
                      <div class="text-right text-primary">
                        {{ data.postponed ? "Ja" : "Nein" }}
                        <span
                          class="text-secondary small"
                          v-if="
                            dependencies.courses.find((course) => {
                              if (!course) return false;
                              if (course.type == 'closed' && course.canceled)
                                return true;
                            })
                          "
                        >
                          durch Kurs
                        </span>
                        <br />{{ data.canceled ? "Ja" : "Nein" }}
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                  <div class="row justify-content-between">
                    <b-button
                      variant="outline-secondary"
                      class="my-1 mx-auto mx-xl-0"
                      v-if="data.canceled == false"
                      :disabled="
                        !auth_modify ||
                        dependencies.courses.find((course) => {
                          if (!course) return false;
                          if (course.type == 'closed' && course.canceled)
                            return true;
                        })
                      "
                      @click="handleCancel"
                      >Absagen</b-button
                    >
                    <b-button
                      variant="outline-secondary"
                      class="my-1 mx-auto mx-xl-0"
                      :disabled="!auth_modify"
                      v-else
                      @click="handleReactivate"
                      >Reaktivieren</b-button
                    >
                    <b-dropdown
                      variant="outline-secondary"
                      size="sm"
                      dropup
                      right
                      split
                      text="Verschieben"
                      class="my-1 mx-auto mx-xl-0"
                      :disabled="!auth_modify"
                      @click="handlePostpone"
                      menu-class="p-0"
                    >
                      <b-dropdown-item
                        v-if="data.postponed == false"
                        :disabled="!auth_modify"
                        @click="handleMarkPostpone"
                        ><small
                          >Als Verschoben kennzeichnen</small
                        ></b-dropdown-item
                      >
                      <b-dropdown-item
                        v-else
                        :disabled="!auth_modify"
                        @click="handleNotMarkPostpone"
                        ><small
                          >Nicht mehr als Verschoben kennzeichnen</small
                        ></b-dropdown-item
                      >
                      <b-dropdown-item
                        v-b-modal.modal-help-postpone
                        title="Title"
                        ><small
                          >Hilfe
                          <i class="material-icons">help_outline</i></small
                        ></b-dropdown-item
                      >
                      <b-modal
                        id="modal-help-postpone"
                        title="Erklärung: Verschieben einer Theoriestunde"
                        hide-footer
                      >
                        Mit Hilfe dieser Funktion können Sie Theoriestunden
                        verschieben und Ihre Kunden über die Änderung
                        informieren.
                        <br />
                        <br />
                        <small
                          >Hinweis: Falls Sie nur ein falsches Datum in der
                          Anlage gewählt haben und der Durchführungstermin noch
                          weit in der Zukunft liegt ist es empfehlenswert nur
                          das Datum des Termins zu verändern.</small
                        >
                      </b-modal>
                    </b-dropdown>
                  </div>
                  <b-button
                    variant="outline-danger"
                    class="row my-1"
                    :disabled="!auth_modify"
                    @click="handleRemove"
                    >Löschen</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col
            v-if="false"
            class="pr-5 ml-3"
            :style="organisators.length == 0 ? 'opacity: 0.5;' : ''"
          >
            <div
              v-if="organisators.length == 0"
              class="d-flex justify-content-between"
            >
              <b-button variant="link" class="text-secondary small"
                >Organisator zuweisen</b-button
              >
              <i
                class="material-icons"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Mit der Hinterlegung eines Organisators können Sie die Planung und Organisation Ihres Unterrichts besser verteilen.<br>Die Organisatoren erhalten daher zum Beispiel Benachrichtigungen über wichtige Ereignisse.',
                }"
                >help_outline</i
              >
            </div>
            <template v-else>
              <h6 class="m-0">
                Organisatoren
                <i
                  class="material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover.top="{
                    html: true,
                    content:
                      'Mit der Hinterlegung eines Organisators können Sie die Planung und Organisation Ihres Unterrichts besser verteilen.<br>Die Organisatoren erhalten daher zum Beispiel Benachrichtigungen über wichtige Ereignisse.',
                  }"
                  >help_outline</i
                >
              </h6>
              <participation-manager
                class="ml-3"
                :participation="data.participation"
                :participators="participators"
                role="organisator"
                noEvents
                hideRole
                hideLabel
                showRequestCount
              />
            </template>
          </b-col>

          <b-col
            v-if="false"
            class="pr-5 ml-3"
            :style="instructors.length == 0 ? 'opacity: 0.5;' : ''"
          >
            <div
              v-if="instructors.length == 0"
              class="d-flex justify-content-between"
            >
              <b-button variant="link" class="text-secondary small"
                >Lehrer hinzufügen</b-button
              >
              <i
                class="material-icons small"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Lehrer sind Mitarbeiter, welche Ihren Kunden als Ansprechpartner für Fragen werden. Des Weiteren erhalten diese einen Blocker für Ihren Kalender.',
                }"
                >help_outline</i
              >
            </div>
            <template v-else>
              <h6 class="m-0">
                Lehrer
                <i
                  class="material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover.top="{
                    html: true,
                    content:
                      'Lehrer sind Mitarbeiter, welche Ihren Kunden als Ansprechpartner für Fragen werden. Des Weiteren erhalten diese einen Blocker für Ihren Kalender.',
                  }"
                  >help_outline</i
                >
              </h6>
              <participation-manager
                class="ml-3"
                :participation="data.participation"
                :participators="participators"
                role="instructor"
                noEvents
                hideRole
                hideLabel
                showRequestCount
              />
            </template>
          </b-col>

          <b-col
            class="pr-5 ml-3"
            :style="data.participation === false ? 'opacity: 0.5;' : ''"
          >
            <h6 class="m-0">
              Teilnehmer
              <b-button
                v-if="false"
                variant="link"
                class="px-1"
                :disabled="data.participation === false"
              >
                <span
                  class="material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover.top="{
                    html: true,
                    content: 'Teilnehmer hinzufügen',
                  }"
                  >person_add</span
                >
              </b-button>
              <b-button
                v-if="false"
                variant="link"
                class="px-1"
                :disabled="data.participation === false"
              >
                <span
                  class="material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover.top="{
                    html: true,
                    content: 'Teilnahmeeinstellungen bearbeiten',
                  }"
                  >settings</span
                >
              </b-button>
            </h6>
            <participation-manager
              class="ml-3"
              :participation="data.participation"
              :participators="participators"
              role="student"
              noEvents
              hideRole
              hideLabel
              hideInvalid
              showRequestCount
            />
          </b-col>

          <b-col class="pr-0 ml-3" v-if="false">
            <!-- Aktionen -->
            <!-- Component Header -->
            <h6 class="m-0">
              Aktionen
              <i
                class="material-icons"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Aktionen werden ausgeführt, wenn eine bestimmte Situation eintritt. Das kann zum Beispiel Beginn oder Ende einer Theoriestunde sein.<br>Sie haben hierdurch die Möglichkeit zum Beispiel automatisiert Informationsmaterial zu versenden.',
                }"
                >help_outline</i
              >
            </h6>
            <small class="ml-3">folgt in Kürze</small>
          </b-col>

          <b-col
            class="pr-0 ml-3 mt-4"
            :style="dependencies.courses.length == 0 ? 'opacity: 0.5;' : ''"
          >
            <!-- Abhängigkeiten -->
            <h6 class="m-0">
              Abhängigkeiten
              <i
                class="material-icons"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Eine Theoriestunde besitzt eine Abhängigkeit, wenn sie zum Beispiel Bestandteil eines <strong>Kurses</strong> ist.<br>Abhängig von der dortigen Konfiguration kann dies bedeuten, dass die Bearbeitung der vorliegenden Theoriestunde eingeschränkt ist.',
                }"
                >help_outline</i
              >
            </h6>

            <small v-if="dependencies.courses.length == 0" class="mt-3 ml-3"
              >keine Abhängigkeiten vorhanden</small
            >
            <template v-else>
              <b-list-group flush>
                <b-list-group-item
                  href="#"
                  v-for="course in dependencies.courses"
                  :key="course.id"
                  @click="
                    $router.push({
                      name: 'course',
                      params: { id: course.id },
                    })
                  "
                  >Kurs: {{ course.name }}</b-list-group-item
                >
              </b-list-group>
            </template>
          </b-col>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import EmployeeSingleSelect from "@/components/user/EmployeeSingleSelect.vue";
import ParticipationManager from "@/components/education/other_elements/ParticipationManager.vue";
import { mapGetters } from "vuex";
import moment from "moment";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
export default {
  name: "Lesson",
  components: {
    CategorySelector,
    EmployeeSingleSelect,
    ParticipationManager,
  },
  data() {
    return {
      notFound: false,
      loading: null,
      auth_modify: false,
      id: this.$route.params.id,
      data: null,
      dependencies: null,
      participators: null,
      // TITLE
      edit_title: false,
      // MAIN
      edit: null, // Copy of data, which can altered by user
      edit_description: false,
      edit_date: false,
      edit_location: false,
      edit_units: false,
      edit_user: false,
      edit_participation: false,
      // MAIN - DATE
      allow_date_change_on_dependencies: false,
      edit_start_date: null,
      edit_start_time: null,
      edit_duration: null,
      // MAIN - UNITS
      search: "",
      // CONFIGS
      DatePickerConfig,
      TimePickerConfig,
    };
  },
  computed: {
    ...mapGetters({
      units: "units",
      branches: "branches",
      selectedCategory: "selectedCategory",
    }),
    changes: function () {
      return (
        this.edit_description ||
        this.edit_date ||
        this.edit_location ||
        this.edit_units ||
        this.edit_user ||
        this.edit_participation
      );
    },
    branch: function () {
      return this.branches.find((el) => el.id == this.data.branch_id);
    },
    edit_date_start: function () {
      return this.edit_start_date + " " + this.edit_start_time;
    },
    edit_date_end: function () {
      return moment(this.edit_date_start, "YYYY-MM-DD HH:mm:ss")
        .add(moment.duration(this.edit_duration, "HH:mm:ss"))
        .format("YYYY-MM-DD HH:mm:ss");
    },
    filteredUnits() {
      const criteria = this.search.trim().toLowerCase();
      // Filter out already selected options
      const options = this.units.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.edit.units.find((unit_id) => unit_id == option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    // Participation
    organisators() {
      return this.participators
        ? this.participators.filter((el) => el.role == "organisator")
        : null;
    },
    instructors() {
      return this.participators
        ? this.participators.filter((el) => el.role == "instructor")
        : null;
    },
    students() {
      return this.participators
        ? this.participators.filter((el) => el.role == "student")
        : null;
    },
  },
  watch: {
    "edit.branch_id": function () {
      if (!this.edit.branch_id)
        this.edit.address = {
          street: null,
          streetnumber: null,
          zip: null,
          city: null,
        };
      else this.edit.address = null;
    },
    edit_date_end: async function (val, oldval) {
      if (!(await this.checkEditDateDependencies()))
        this.edit_start_date = oldval;
    },
  },
  methods: {
    async init() {
      this.edit_title = false;
      this.edit_description = false;
      this.edit_date = false;
      this.edit_location = false;
      this.edit_units = false;
      this.edit_users = false;
      this.allow_date_change_on_dependencies = false;

      this.loading = true;
      // get data
      let data = await this.$store
        .dispatch("getLesson", this.id)
        .catch((err) => {
          if (!err.message.includes("NOT_FOUND")) throw err;
          // notFound
          this.data = null;
          this.notFound = true;
          this.loading = false;
        });
      await Promise.all([
        (async () => {
          this.dependencies = await this.$store.dispatch(
            "getLessonDependencies",
            this.id
          );
        })(),
        (async () => {
          this.participators = await this.$store.dispatch(
            "getParticipatorsLesson",
            {
              id: this.id,
            }
          );
        })(),
      ]);
      // Update Component
      this.data = data;
      this.edit = { ...this.data };
      this.resetEditDate();
      if (!this.data) return;
      this.$store.dispatch("updateSelectedCategory", this.data.category);
      this.loading = false;
    },
    async setParticipationDefault() {
      let {
        lesson_default_allow_participation,
        lesson_default_max_participators,
        lesson_default_register_mode_direct,
        lesson_default_register_mode_request,
      } = await this.$store.dispatch("getSetting", [
        "lesson_default_allow_participation",
        "lesson_default_max_participators",
        "lesson_default_register_mode_direct",
        "lesson_default_register_mode_request",
      ]);
      this.edit.participation = lesson_default_allow_participation
        ? {
            max_participators: lesson_default_max_participators,
            register_modes: [
              lesson_default_register_mode_direct ? "direct" : null,
              lesson_default_register_mode_request ? "request" : null,
            ].filter((el) => el),
            start_date: null,
            end_date: null,
          }
        : false;
    },
    async handleEditTitle() {
      if (
        !this.data.processed_unit_name &&
        (this.edit.name == null || this.edit.name.trim() == "")
      )
        return;
      await this.$store.dispatch("updateLesson", {
        id: this.data.id,
        name: this.edit.name,
      });
      await this.init();
    },
    async handleSave() {
      // Check Dependencies
      // Kurs-Anfang is nach Theoriestunden-Anfang
      for (let i in this.dependencies.courses) {
        let course = this.dependencies.courses[i];
        if (
          moment(course.date.start, "YYYY-MM-DD").isAfter(
            moment(this.edit_date_start, "YYYY-MM-DD HH:mm:ss")
          )
        ) {
          if (!this.allow_date_change_on_dependencies)
            throw new Error("Must be set");
          await this.$store.dispatch("updateCourse", {
            id: course.id,
            date: {
              start: course.date.start,
              end: course.date.end,
            },
          });
        }

        // Kurs-Ende ist vor Theoriestunden-Ende
        if (
          moment(course.date.end, "YYYY-MM-DD").isBefore(
            moment(this.edit_date_end, "YYYY-MM-DD HH:mm:ss")
          )
        ) {
          if (!this.allow_date_change_on_dependencies)
            throw new Error("Must be set");
          await this.$store.dispatch("updateCourse", {
            id: course.id,
            date: {
              start: course.date.start,
              end: course.date.end,
            },
          });
        }
      }
      // Update Lesson
      await this.$store
        .dispatch("updateLesson", {
          id: this.id,
          description: this.edit.description,
          date: {
            start: this.edit_date_start,
            end: this.edit_date_end,
          },
          branch_id: this.edit.branch_id,
          address: this.edit.address,
          units: this.edit.units.map((el) => parseInt(el)),
          participation: this.edit.participation,
        })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.description = this.edit.description;
      this.data.date = {
        start: this.edit_date_start,
        end: this.edit_date_end,
      };
      this.data.branch_id = this.edit.branch_id;
      this.data.address = this.edit.address;
      this.data.units = this.edit.units.map((el) => parseInt(el));
      this.data.participation = this.edit.participation;
      this.edit_title = false;
      this.edit_description = false;
      this.edit_date = false;
      this.edit_location = false;
      this.edit_units = false;
      this.edit_users = false;
      this.edit_participation = false;
      this.allow_date_change_on_dependencies = false;
    },
    resetEditDate() {
      let start = moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss");
      this.edit_start_date = start.format("YYYY-MM-DD");
      this.edit_start_time = start.format("HH:mm:ss");
      this.edit_duration = this.data.date.duration;
    },
    async checkEditDateDependencies() {
      if (!this.dependencies) return true;
      let require_date_change_on_dependencies = false;
      this.dependencies.courses.forEach((course) => {
        // Kurs-Anfang is nach Theoriestunden-Anfang
        if (
          moment(course.date.start, "YYYY-MM-DD").isAfter(
            moment(this.edit_date_start, "YYYY-MM-DD HH:mm:ss")
          )
        )
          require_date_change_on_dependencies = true;
        // Kurs-Ende ist vor Theoriestunden-Ende
        if (
          moment(course.date.end, "YYYY-MM-DD").isBefore(
            moment(this.edit_date_end, "YYYY-MM-DD HH:mm:ss")
          )
        )
          require_date_change_on_dependencies = true;
      });
      if (!require_date_change_on_dependencies) return true;
      let result = await this.$swal.fire({
        title: "Diese Änderung ist nicht zulässig",
        text: "Auf Grund einer Abhängigkeit zu einem Kurs, kann diese Änderung nicht durchgeführt werden.",
        showCancelButton: true,
        confirmButtonText: `Kurs-Zeitraum automatisch anpassen`,
        cancelButtonText: `Abbrechen`,
      });
      if (result.isConfirmed) {
        this.allow_date_change_on_dependencies = true;
        return true;
      }
      return false;
    },
    addUnitTag({ unit_id, addTag }) {
      addTag(unit_id.toString());
    },
    remUnitTag({ unit_id, removeTag }) {
      removeTag(unit_id.toString());
    },
    async handleCancel() {
      let result = await this.$store
        .dispatch("cancelLesson", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.canceled = true;
      this.edit.canceled = true;
    },
    async handleReactivate() {
      let result = await this.$store
        .dispatch("reactivateLesson", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.canceled = false;
      this.edit.canceled = false;
    },
    async handlePostpone() {
      let result = await this.$store
        .dispatch("postponeLesson", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.postponed = true;
      this.edit.postponed = true;
    },
    async handleMarkPostpone() {
      await this.$store
        .dispatch("updateLesson", { id: this.data.id, postponed: true })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.postponed = true;
      this.edit.postponed = true;
    },
    async handleNotMarkPostpone() {
      await this.$store
        .dispatch("updateLesson", { id: this.data.id, postponed: false })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.postponed = false;
      this.edit.postponed = false;
    },
    async handleRemove() {
      let result = await this.$store
        .dispatch("removeLesson", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.$router.push({ name: "lessons" });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "lesson_modify"
    );
    await this.init();
  },
};
</script>
