<template>
  <d-container fluid class="main-content-container px-4 pb-4">
    <!-- Error -->
    <div class="error">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Seite konnte nicht gefunden werden.</p>
        <d-button pill @click="back()">&larr; Zurück</d-button>
      </div>
    </div>
  </d-container>
</template>
<script>
export default {
    methods: {
        back() {
            this.$router.go(-1);
        }
    }
}
</script>
