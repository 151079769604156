<template>
  <b-form-group>
    <div class="d-flex" v-b-popover.bottom.hover="settings_obj.payment_credit.description">
      Finanzierung / Kredit möglich
      <b-form-checkbox
        class="ml-2"
        switch
        :checked="settings_obj.payment_credit.value.current"
        @change="
          (val) =>
            $store.dispatch('setSetting', { name: 'payment_credit', value: !!val })
        "
      />
    </div>
    <b-collapse
      v-model="settings_obj.payment_credit.value.current"
      class="ml-2 small"
    >
        <b-form-group label="Angebotstext" v-b-popover.bottom.hover="settings_obj.payment_credit_advertisement_text.description">
          <b-form-textarea
            size="sm"
            :value="settings_obj.payment_credit_advertisement_text.value.current ||''"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_credit_advertisement_text',
                  value: val.trim() == '' ? null : val.trim(),
                })
            "
          />
        </b-form-group>
        <b-form-group label="Name der Bank (optional)" v-b-popover.bottom.hover="settings_obj.payment_credit_bank.description">
          <b-form-input
            size="sm"
            :value="settings_obj.payment_credit_bank.value.current || ''"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_credit_bank',
                  value: val.trim() == '' ? null : val.trim(),
                })
            "
          />
        </b-form-group>
        <b-form-group label="Link zur Bank / zum Angebot (optional)" v-b-popover.bottom.hover="settings_obj.payment_credit_bank_link.description">
          <b-form-input
            size="sm"
            :value="settings_obj.payment_credit_bank_link.value.current || ''"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_credit_bank_link',
                  value: val.trim() == '' ? null : val.trim(),
                })
            "
          />
          </b-form-group>
    </b-collapse>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "payment_credit",
      "payment_credit_advertisement_text",
      "payment_credit_bank",
      "payment_credit_bank_link",
    ]);
  },
};
</script>

<style>
</style>
