import Vue from 'vue'
import API from '@/plugins/api.js'
import Swal from "sweetalert2";
import moment from "moment";
import { DatePickerConfig, TimePickerConfig } from "../data/bootstrap-form-config";
import GlobalMethod from '../plugins/globals';
import APIMethod from '../plugins/apimethod';
Vue.use(GlobalMethod);
Vue.use(APIMethod);
Vue.use(API);
let store = new Vue() // use the store var for request api
const module_education = {
  state: {
    categories: [],
    units: [],
    unit_groups: [],
    selectedCategory: null,
  },
  mutations: {
    setCategories(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.categories = input.map(elem => { elem.name.trim().toLowerCase(); return elem; });
    },
    setUnits(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.units = input;
    },
    setUnitGroups(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.unit_groups = input;
    },
    setSelectedCategory(state, input) {
      state.selectedCategory = (input == null) ? null : input.trim().toLowerCase();
      sessionStorage.setItem("selectedTheoryCategory", state.selectedCategory);
    },
    resetTheory(state) {
      state.categories = [];
      state.units = [];
      state.unit_groups = [];
      state.selectedCategory = null;
    }
  },
  actions: {
    async setupTheory(context) {
      let category_promise = store.$api.get("category")
        .then(response => context.commit("setCategories", response.data.result));
      if (sessionStorage.getItem('selectedTheoryCategory')) {
        await category_promise;
        // Check category from sessionStorage and set if valid
        if ((context.state.categories.find(category => category.name == sessionStorage.getItem('selectedTheoryCategory').trim().toLowerCase()))) context.dispatch('updateSelectedCategory', sessionStorage.getItem('selectedTheoryCategory'));
      }
      try {
        context.commit("setUnits", await context.dispatch('getUnits'));
        await Promise.all([
          context.commit("setUnitGroups", await context.dispatch('getUnitGroups')),
          category_promise
        ]);
      } catch (err) {
        setTimeout(() => context.dispatch('setupTheory'), 3000);
        throw err;
      }
    },
    async updateCategory(context, input) {
      if (!input || !input.name) throw new Error(JSON.stringify(input));
      let response = await store.$api.put(`category/${input.name}`, input);
      await store.$api.get("category").then(response => context.commit("setCategories", response.data.result));
      store.$globalmethod.toast_success("Kategorie aktualisiert.");
      return response.data.result;
    },
    async updateSelectedCategory(context, input) {
      if (input != null && !(context.state.categories.find(category => category.name == input.trim().toLowerCase()))) throw new Error("NOT_FOUND")
      context.commit('setSelectedCategory', input);
    },
    // ACTIONS
    async getLessons(context, { id, category, branch, min_start_date, min_end_date, max_start_date, max_end_date, allow_closed_course_dependencies, allow_dependencies } = {}) {
      let response = await store.$api.get(`lesson`, { params: { id, branch, min_start_date, min_end_date, max_start_date, max_end_date, category, allow_closed_course_dependencies, allow_dependencies } });
      return response.data.result.map(lesson => {
        lesson.processed_unit_name = lesson.units
          .map((unit_id) => {
            let unit = context.getters.units.find(
              (elem) => (elem.id == unit_id)
            );
            if (!unit)
              return console.log(`Unit: ${unit_id} not found. ${context.getters.units}`);
            return unit.key + " - " + unit.name;
          })
          .join(", ");
        lesson.processed_unit_key_name = "";
        lesson.units.forEach((unit_id) => {
          let unit = context.getters.units.find((elem) => elem.id == unit_id);
          if (!unit)
            return console.log(`Unit: ${unit_id} not found. ${this.units}`);
          lesson.processed_unit_key_name += "Thema " + unit.key + ", ";
        });
        lesson.processed_unit_key_name = (lesson.processed_unit_key_name.length > 3) ? lesson.processed_unit_key_name.substr(
          0,
          lesson.processed_unit_key_name.length - 2
        ) : lesson.processed_unit_key_name;
        return lesson;
      });
    },
    async getLesson(context, id) {
      let lesson = await store.$api.get(`lesson/${id}`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND")
        else throw err;
      }).then(response => response.data.result);
      lesson.processed_unit_name = lesson.units
        .map((unit_id) => {
          let unit = context.getters.units.find(
            (elem) => (elem.id == unit_id)
          );
          if (!unit)
            return console.log(`Unit: ${unit_id} not found. ${context.getters.units}`);
          return unit.key + " - " + unit.name;
        })
        .join(", ");
      lesson.processed_unit_key_name = "";
      lesson.units.forEach((unit_id) => {
        let unit = context.getters.units.find((elem) => elem.id == unit_id);
        if (!unit)
          return console.log(`Unit: ${unit_id} not found. ${this.units}`);
        lesson.processed_unit_key_name += "Thema " + unit.key + ", ";
      });
      lesson.processed_unit_key_name = (lesson.processed_unit_key_name.length > 3) ? lesson.processed_unit_key_name.substr(
        0,
        lesson.processed_unit_key_name.length - 2
      ) : lesson.processed_unit_key_name;
      return lesson;
    },
    async getLessonDependencies(context, id) {
      let dependencies = await store.$api.get(`lesson/${id}/dependencies`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND")
        else throw err;
      }).then(response => response.data.result);
      // MAP COURSES
      let promisses = [];
      if (dependencies.courses) for (let i in dependencies.courses) promisses.push((async () => {
        dependencies.courses[i] = await context.dispatch('getCourse', dependencies.courses[i]);
      })());
      await Promise.all(promisses);
      return dependencies;
    },
    async createLesson(context, input) {
      if (!input.category && context.state.selectedCategory == null) throw new Error("MISSING_PARAMETER(category)");
      if (!input.category) input.category = context.state.selectedCategory;
      if (!(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.post(`lesson`, input);
      store.$globalmethod.toast_success("Theoriestunde angelegt.");
      return response.data.result;
    },
    async updateLesson(context, input) {
      let id = input.id;
      delete input.id;
      if (input.category !== undefined && !(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      // request
      let response = await store.$api.put(`lesson/${id}`, input).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Theoriestunde aktualisiert.");
      return response.data.result;
    },
    async postponeLesson(context, { id, start_date, end_date, email, direct } = {}) {
      if (start_date === undefined || end_date === undefined || email === undefined) {
        let lesson = await context.dispatch('getLesson', id);
        let swal_elem = Swal.fire({
          title: "Theoriestunde absagen",
          html: `
            <b-form-group label="Anfang" v-if="show_date">
              <b-row>
                <b-col cols="12" md="7" class="pr-md-1">
                  <b-form-datepicker
                    v-model="date"
                    v-bind="DatePickerConfig"
                  ></b-form-datepicker>
                </b-col>
                <b-col cols="12" md="5" class="pl-md-1">
                  <b-form-timepicker
                    v-model="time"
                    v-bind="TimePickerConfig"
                  ></b-form-timepicker>
                </b-col>
              </b-row>
            </b-form-group>
            <b-form-group label="Dauer">
              <b-form-timepicker
                v-model="duration"
                v-bind="{
                  ...TimePickerConfig,
                  labelSelected: 'Ausgewählte Dauer',
                  labelNoTimeSelected: 'Keine Dauer ausgewählt',
                }"
              ></b-form-timepicker>
            </b-form-group>
            <b-form-group v-if="show_email">
              <b-row class="justify-content-center">
                <b-col cols="10" md="8">
                <b-form-checkbox
                v-model="email"
              >
              Teilnehmer benachichtigen
              </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-group>`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        let component = new Vue({
          el: Swal.getContent(), data() {
            return {
              show_date: null,
              show_email: (email === undefined) ? true : false,
              start_date: (start_date === undefined) ? moment(lesson.date.start) : moment(start_date),
              end_date: (end_date === undefined) ? moment(lesson.date.end) : moment(end_date),
              email,
              DatePickerConfig,
              TimePickerConfig
            }
          },
          computed: {
            date: {
              get() {
                return this.start_date.format("YYYY-MM-DD");
              },
              set(value) {
                let duration = this.duration;
                this.start_date = moment(value + " " + this.time, "YYYY-MM-DD HH:mm:ss");
                this.end_date = moment(value + " " + this.time, "YYYY-MM-DD HH:mm:ss").add({
                  hours: duration.substr(0, 2),
                  minutes: duration.substr(3, 2),
                  seconds: duration.substr(6, 2)
                });
              },
              deep: true
            },
            time: {
              get() {
                return this.start_date.format("HH:mm:ss");
              },
              set(value) {
                let duration = this.duration;
                this.start_date = moment(this.date + " " + value, "YYYY-MM-DD HH:mm:ss");
                this.end_date = moment(this.date + " " + value, "YYYY-MM-DD HH:mm:ss").add({
                  hours: duration.substr(0, 2),
                  minutes: duration.substr(3, 2),
                  seconds: duration.substr(6, 2)
                });
              },
              deep: true
            },
            duration: {
              get() {
                let duration = moment.duration(this.end_date.diff(this.start_date));
                return ("00" + duration.get("h")).slice(-2) +
                  ":" +
                  ("00" + duration.get("m")).slice(-2) +
                  ":" +
                  ("00" + duration.get("s")).slice(-2);
              },
              set(value) {
                this.end_date = moment(this.start_date).add({
                  hours: value.substr(0, 2),
                  minutes: value.substr(3, 2),
                  seconds: value.substr(6, 2)
                });
              },
              deep: true
            }
          },
          created() {
            if (!(this.start_date.isValid() && this.end_date.isValid())) throw new Error("INVALID_TIME PROVIDED");
            this.show_date = (start_date === undefined || end_date === undefined) ? true : false;
          }
        });
        let result = await swal_elem;
        if (!result.value) return false;
        start_date = component.$data.start_date.format("YYYY-MM-DD HH:mm:ss");
        end_date = component.$data.end_date.format("YYYY-MM-DD HH:mm:ss");
        email = component.$data.email;
      }
      await store.$api.post(`lesson/${id}/postpone`, { start_date, end_date, email, direct }).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Theoriestunde verschoben.");
      return true;
    },
    async cancelLesson(context, { id, send_email, direct, hideAlert } = {}) {
      // If hideAlert not set, ask the user if he is sure
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Theoriestunde wirklich absagen?",
          html: `
          <form id="lesson_cancel_form_64apoa4daw87d8aw4">
            <div class="form-check" style="display: none;">
              <input type="checkbox" class="form-check-input" id="send_email">
              <label class="form-check-label" for="send_email"> Teilnehmer benachichtigen</label>
            </div>
          </form>`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
        var form = document.getElementById(
          "lesson_cancel_form_64apoa4daw87d8aw4"
        );
        send_email = form.send_email.value;
      }
      await store.$api.post(`lesson/${id}/cancel`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Theoriestunde abgesagt.");
      return true;
    },
    async reactivateLesson(context, { id, send_email, direct, hideAlert } = {}) {
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Reaktivieren einer abgesagten Theoriestunde",
          html: `
          <form id="lesson_reactivate_form_64apoa4daw87d8aw4">
            <div class="form-check" style="display: none;">
              <input type="checkbox" class="form-check-input" id="send_email">
              <label class="form-check-label" for="send_email"> Teilnehmer benachichtigen</label>
            </div>
          </form>`,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
        var form = document.getElementById(
          "lesson_reactivate_form_64apoa4daw87d8aw4"
        );
        send_email = form.send_email.value;
      }
      await store.$api.post(`lesson/${id}/reactivate`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Theoriestunde reaktiviert.");
      return true;
    },
    async getParticipatorsLesson(context, { id } = {}) {
      let response = await store.$api.get(`lesson/${id}/participation?all`);
      return response.data.result;
    },
    async requestParticipatorLesson(context, { id, email, user_id, role, mode } = {}) {
      let response = await store.$api.post(`lesson/${id}/participation`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
        mode,
      });
      return response.data.result;
    },
    async confirmParticipationLesson(context, { id, user_id, email, role } = {}) {
      let response = await store.$api.post(`lesson/${id}/participation/companyAccept`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
      });
      return response.data.result;
    },
    async denieParticipationLesson(context, { id, user_id, email, role } = {}) {
      let response = await store.$api.post(`lesson/${id}/participation/companyDenie`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
      });
      return response.data.result;
    },
    async removeLesson(context, { id, noConfirm } = {}) {
      if (!noConfirm) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Theoriestunde wirklich löschen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      // TODO: Ask for parameters: preventRemoveLessons, removeLessons
      await store.$api.delete(`lesson/${id}`);
      store.$globalmethod.toast_success("Erfolgreich gelöscht.");
      return true;
    },
    // COURSE
    async getCourses(context, { id, category, min_start_date, max_start_date, min_end_date, max_end_date } = {}) {
      let response = await store.$api.get(`course`, { params: { id, category, min_start_date, max_start_date, min_end_date, max_end_date } });
      return response.data.result.map(course => {
        course.processed_unit_name = course.units
          .map((unit_id) => {
            let unit = context.getters.units.find(
              (elem) => (elem.id == unit_id)
            );
            return unit.key + " - " + unit.name;
          })
          .join(", ");
        course.processed_unit_key_name = "";
        course.units.forEach((unit_id) => {
          let unit = context.getters.units.find((elem) => elem.id == unit_id);
          if (!unit)
            return console.log(`Unit: ${unit_id} not found. ${this.units}`);
          course.processed_unit_key_name += "Thema " + unit.key + ", ";
        });
        course.processed_unit_key_name = (course.processed_unit_key_name.length > 3) ? course.processed_unit_key_name.substr(
          0,
          course.processed_unit_key_name.length - 2
        ) : course.processed_unit_key_name;
        return course;
      });
    },
    async getCourse(context, id) {
      if (!(id)) throw new Error("MISSING_PARAMETER(id)");
      let course = await store.$api.get(`course/${id}`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND");
        else throw err;
      }).then(response => response.data.result);
      course.processed_unit_name = course.units
        .map((unit_id) => {
          let unit = context.getters.units.find(
            (elem) => (elem.id == unit_id)
          );
          return unit.key + " - " + unit.name;
        })
        .join(", ");
      course.processed_unit_key_name = "";
      course.units.forEach((unit_id) => {
        let unit = context.getters.units.find((elem) => elem.id == unit_id);
        if (!unit)
          return console.log(`Unit: ${unit_id} not found. ${this.units}`);
        course.processed_unit_key_name += "Thema " + unit.key + ", ";
      });
      course.processed_unit_key_name = (course.processed_unit_key_name.length > 3) ? course.processed_unit_key_name.substr(
        0,
        course.processed_unit_key_name.length - 2
      ) : course.processed_unit_key_name;
      return course;
    },
    async createCourse(context, input) {
      if (input.category == undefined && context.state.selectedCategory == null) throw new Error("MISSING_PARAMETER(category)");
      if (input.category == undefined) input.category = context.state.selectedCategory;
      if (!(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.post(`course`, input);
      store.$globalmethod.toast_success("Kurs angelegt.");
      return response.data.result;
    },
    async updateCourse(context, input) {
      let course_id = input.id;
      delete input.id;
      if (input.category !== undefined && !(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.put(`course/${course_id}`, input);
      store.$globalmethod.toast_success("Kurs aktualisiert.");
      return response.data.result;
    },
    async postponeCourse(context, { id, start_date, end_date, hideAlert } = {}) {
      let response = await store.$api.post(`course/${id}/postpone`, { start_date, end_date });
      if (!hideAlert) store.$globalmethod.toast_success("Kurs verschoben.");
      return response.data.result;
    },
    async cancelCourse(context, { id, hideAlert } = {}) {
      let response = await store.$api.post(`course/${id}/cancel`);
      if (!hideAlert) store.$globalmethod.toast_success("Kurs abgesagt.");
      return response.data.result;
    },
    async reactivateCourse(context, { id, hideAlert } = {}) {
      let response = await store.$api.post(`course/${id}/reactivate`);
      if (!hideAlert) store.$globalmethod.toast_success("Kurs reaktiviert.");
      return response.data.result;
    },
    async getParticipatorsCourse(context, { id } = {}) {
      let response = await store.$api.get(`course/${id}/participation`);
      return response.data.result;
    },
    async requestParticipatorCourse(context, { id, email, user_id, role, mode } = {}) {
      let response = await store.$api.post(`course/${id}/participation`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
        mode,
      });
      return response.data.result;
    },
    async confirmParticipationCourse(context, { id, user_id, email, role } = {}) {
      let response = await store.$api.post(`course/${id}/participation/companyAccept`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
      });
      return response.data.result;
    },
    async denieParticipationCourse(context, { id, user_id, email, role } = {}) {
      let response = await store.$api.post(`course/${id}/participation/companyDenie`, {
        type: user_id ? 'user' : 'email',
        id: user_id ? user_id : email,
        role,
      });
      return response.data.result;
    },
    async removeCourse(context, { id, noConfirm } = {}) {
      if (!noConfirm) {
        let result = await Swal.fire({
          title: "Wollen Sie diesen Kurs wirklich löschen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`course/${id}`);
      store.$globalmethod.toast_success("Kurs gelöscht.");
      return true;
    },
    // TEMPLATE-COURSE
    async getTemplateCourses(context, { id, category } = {}) {
      let response = await store.$api.get(`course/template`, { params: { id, category } })
      return response.data.result.map(course => {
        course.processed_unit_name = course.units
          .map((unit_id) => {
            let unit = context.getters.units.find(
              (elem) => (elem.id == unit_id)
            );
            return unit.key + " - " + unit.name;
          })
          .join(", ");
        course.processed_unit_key_name = "";
        course.units.forEach((unit_id) => {
          let unit = context.getters.units.find((elem) => elem.id == unit_id);
          if (!unit)
            return console.log(`Unit: ${unit_id} not found. ${this.units}`);
          course.processed_unit_key_name += "Thema " + unit.key + ", ";
        });
        course.processed_unit_key_name = (course.processed_unit_key_name.length > 3) ? course.processed_unit_key_name.substr(
          0,
          course.processed_unit_key_name.length - 2
        ) : course.processed_unit_key_name;
        return course;
      });
    },
    async getTemplateCourse(context, id) {
      let course = await store.$api.get(`course/template/${id}`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND");
        else throw err;
      }).then(response => response.data.result);
      course.processed_unit_name = course.units
        .map((unit_id) => {
          let unit = context.getters.units.find(
            (elem) => (elem.id == unit_id)
          );
          if (!unit) return console.log("Unit: "+unit_id+" not found.")
          return unit.key + " - " + unit.name;
        })
        .join(", ");
      course.processed_unit_key_name = "";
      course.units.forEach((unit_id) => {
        let unit = context.getters.units.find((elem) => elem.id == unit_id);
        if (!unit)
          return console.log(`Unit: ${unit_id} not found. ${this.units}`);
        course.processed_unit_key_name += "Thema " + unit.key + ", ";
      });
      course.processed_unit_key_name = (course.processed_unit_key_name.length > 3) ? course.processed_unit_key_name.substr(
        0,
        course.processed_unit_key_name.length - 2
      ) : course.processed_unit_key_name;
      return course;
    },
    async createTemplateCourse(context, input) {
      if (input.category == undefined && context.state.selectedCategory == null) throw new Error("MISSING_PARAMETER(category)");
      if (input.category == undefined) input.category = context.state.selectedCategory;
      if (!(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.post(`course/template`, input);
      return response.data.result;
    },
    async updateTemplateCourse(context, input) {
      let template_course_id = input.id;
      delete input.id;
      if (input.category !== undefined && !(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      await store.$api.put(`course/template/${template_course_id}`, input);
    },
    async removeTemplateCourse(context, { id, noConfirm } = {}) {
      if (!noConfirm) {
        let result = await Swal.fire({
          title: "Wollen Sie diesen Vorlagen-Kurs wirklich löschen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`course/template/${id}`);
      store.$globalmethod.toast_success("Kursvorlage gelöscht.");
      return true;
    },
    // UNIT
    async getUnits(context, { id, category } = {}) {
      let response = await store.$api.get(`unit`, { params: { id, category } })
      return response.data.result;
    },
    async getUnit(context, id) {
      return await store.$api.get(`unit/${id}`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND");
        else throw err;
      }).then(response => response.data.result);
    },
    async createUnit(context, input) {
      if (input.category == undefined && context.state.selectedCategory == null) throw new Error("MISSING_PARAMETER(category)");
      if (input.category == undefined) input.category = context.state.selectedCategory;
      if (!(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.post(`unit`, {
        ...input,
        type: "theory",   // TODO: other components have to implement the type, at the point we descide to devide between theory and practise
      });
      (async () => context.commit("setUnits", await context.dispatch('getUnits')))();
      // if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit angelegt.");
      return response.data.result;
    },
    async updateUnit(context, input) {
      let unit_id = input.id;
      delete input.id;
      if (input.category !== undefined && !(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.put(`unit/${unit_id}`, input);
      (async () => context.commit("setUnits", await context.dispatch('getUnits')))();
      // if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit aktualisiert.");
      return response.data.result;
    },
    async removeUnit(context, { id, noConfirm, hideAlert } = {}) {
      if (!noConfirm) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Unterrichtseinheit wirklich löschen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`unit/${id}`);
      if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit gelöscht.");
      (async () => context.commit("setUnits", await context.dispatch('getUnits')))();
      return true;
    },
    // UNIT-GROUP
    async getUnitGroups(context, { id, category } = {}) {
      let unit_groups = await store.$api.get(`unit/group`, { params: { id, category } })
        .then(response => response.data.result);
      return unit_groups.map(unit_group => {
        unit_group.processed_unit_name = unit_group.units.all
          .map((unit_id) => {
            let unit = context.getters.units.find(
              (elem) => (elem.id == unit_id)
            );
            return unit.key + " - " + unit.name;
          })
          .join(", ");
        unit_group.processed_unit_key_name = "";
        unit_group.units.all.forEach((unit_id) => {
          let unit = context.getters.units.find((elem) => elem.id == unit_id);
          if (!unit)
            return console.log(`Unit: ${unit_id} not found. ${this.units}`);
          unit_group.processed_unit_key_name += "Thema " + unit.key + ", ";
        });
        unit_group.processed_unit_key_name = (unit_group.processed_unit_key_name.length > 3) ? unit_group.processed_unit_key_name.substr(
          0,
          unit_group.processed_unit_key_name.length - 2
        ) : unit_group.processed_unit_key_name;
        return unit_group;
      });
    },
    async getUnitGroup(context, id) {
      let unit_group = await store.$api.get(`unit/group/${id}`).catch(err => {
        if (err.response.status == 404) throw new Error("NOT_FOUND");
        else throw err;
      }).then(response => response.data.result);
      unit_group.processed_unit_name = unit_group.units.all
        .map((unit_id) => {
          let unit = context.getters.units.find(
            (elem) => (elem.id == unit_id)
          );
          return unit.key + " - " + unit.name;
        })
        .join(", ");
      unit_group.processed_unit_key_name = "";
      unit_group.units.all.forEach((unit_id) => {
        let unit = context.getters.units.find((elem) => elem.id == unit_id);
        if (!unit)
          return console.log(`Unit: ${unit_id} not found. ${this.units}`);
        unit_group.processed_unit_key_name += "Thema " + unit.key + ", ";
      });
      unit_group.processed_unit_key_name = (unit_group.processed_unit_key_name.length > 3) ? unit_group.processed_unit_key_name.substr(
        0,
        unit_group.processed_unit_key_name.length - 2
      ) : unit_group.processed_unit_key_name;
      return unit_group;
    },
    async createUnitGroup(context, input) {
      if (input.category == undefined && context.state.selectedCategory == null) throw new Error("MISSING_PARAMETER(category)");
      if (input.category == undefined) input.category = context.state.selectedCategory;
      if (!(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.post(`unit/group`, input);
      (async () => context.commit("setUnitGroups", await context.dispatch('getUnitGroups')))();
      // if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit-Gruppe angelegt.");
      return response.data.result;
    },
    async updateUnitGroup(context, input) {
      let unit_group_id = input.id;
      delete input.id;
      if (input.category !== undefined && !(context.getters.categories.find(elem => elem.name == input.category))) throw new Error("INVALID_PARAMETER(category)");
      let response = await store.$api.put(`unit/group/${unit_group_id}`, input);
      (async () => context.commit("setUnitGroups", await context.dispatch('getUnitGroups')))();
      // if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit-Gruppe aktualisiert.");
      return response.data.result;
    },
    async removeUnitGroup(context, { id, noConfirm, hideAlert } = {}) {
      if (!noConfirm) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Unterichtseinheits-Gruppe wirklich löschen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`unit/group/${id}`);
      (async () => context.commit("setUnitGroups", await context.dispatch('getUnitGroups')))();
      if (!hideAlert) store.$globalmethod.toast_success("Unterrichtseinheit-Gruppe gelöscht.");
      return true;
    },
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    units(state) {
      return state.units;
    },
    unit_groups(state) {
      return state.unit_groups;
    },
    selectedCategory(state) {
      return state.selectedCategory;
    },
  },
};
export default module_education;
