import Announcements from '@/views/crm/announcement/Overview.vue';
import Announcement from '@/views/crm/announcement/Details.vue';

export default [
  {
    path: '/announcement/new',
    name: 'new_announcement',
    component: Announcement,
    meta: {
      title: 'Ankündigung erstellen',
      description: 'Erstellen einer neuen Ankündigung',
      material_icon: 'work',
      tags: [
        'announcements',
        'new',
        'neu',
        'create',
        'company',
        'crm',
        'kundenkontakt',
        'post',
        'alert',
      ]
    }
  },
  {
    path: '/announcement',
    name: 'announcements',
    component: Announcements,
    meta: {
      title: 'Ankündigungen',
      description: 'Erstellen und Verwalten Sie verschiedene Arten von Ankündigungen',
      material_icon: 'work',
      tags: [
        'announcements',
        'crm',
        'kundenkontakt',
        'post',
        'alert',
      ]
    }
  },
  {
    path: '/announcement/branch',
    name: 'branches_announcements',
    component: Announcements,
    props: {
      filter: "branches"
    },
    meta: {
      title: 'Ankündigungen zu Filialen',
      description: 'Erstellen und Verwalten Sie Ankündigungen, welche zu eine Filialen zugeordnet werden können',
      material_icon: 'account_balance',
      tags: [
        'announcements',
        'crm',
        'kundenkontakt',
        'post',
        'alert',
      ]
    }
  },
  {
    path: '/announcement/branch/:id',
    name: 'branch_announcements',
    component: Announcements,
    props: {
      filter: "branch",
    },
  },
  {
    path: '/announcement/classes',
    name: 'classes_announcements',
    component: Announcements,
    props: {
      filter: "classes"
    },
    meta: {
      title: 'Ankündigungen zum Unterrichtssystem',
      description: 'Erstellen und Verwalten Sie Ankündigungen für das Unterrichtssystem',
      material_icon: 'work',
      tags: [
        'announcements',
        'crm',
        'kundenkontakt',
        'post',
        'alert',
      ]
    }
  },
  {
    path: '/announcement/google_mybusiness',
    name: 'gmb_announcements',
    component: Announcements,
    props: {
      filter: "google_mybusiness"
    },
    meta: {
      title: 'Ankündigungen in Google My Business',
      description: 'Erstellen und Verwalten Sie Ankündigungen für Google My Business. Diese sind auf Google Maps sichtbar.',
      material_icon: 'work',
      tags: [
        'announcements',
        'crm',
        'kundenkontakt',
        'post',
        'alert',
      ]
    }
  },
  {
    path: '/announcement/:id',
    name: 'announcement',
    component: Announcement,
    meta: {
      title: 'Ankündigung Detailseite',
    }
  },
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "crm";
  return el;
});
