<template>
  <b-container fluid class="main-content-container px-4 pb-4">
    <template v-if="!loaded">
      <d-container fluid class="main-content-container px-4 pb-4">
        <!-- Error -->
        <div class="error">
          <div class="error__content">
            <h2>Lädt...</h2>
            <h3>Bitte warten</h3>
            <p>Es wird nach der richtigen Ankündigung gesucht.</p>
          </div>
        </div>
      </d-container>
    </template>
    <template v-else-if="+$route.params.id && !data">
      <d-container fluid class="main-content-container px-4 pb-4">
        <!-- Error -->
        <div class="error">
          <div class="error__content">
            <h2>404</h2>
            <h3>Nicht gefunden!</h3>
            <p>Diese Ankündigung konnte nicht gefunden werden.</p>
            <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
          </div>
        </div>
      </d-container>
    </template>
    <template v-else>
      <!-- Page Header -->
      <b-row no-gutters class="page-header py-4">
        <!-- Page Title -->
        <b-col sm="8" cols="12" class="text-center text-sm-left mb-4 mb-sm-0">
          <span
            class="text-uppercase page-subtitle"
            style="cursor: pointer"
            @click="$router.push({ name: 'announcements' })"
            >Ankündigungen</span
          >
          <h3 class="page-title">
            {{
              $route.params.id && data
                ? "Ankündigung bearbeiten"
                : "Neue Ankündigung erstellen"
            }}
          </h3>
        </b-col>
        <b-col v-if="data" cols="12" sm="4" class="text-right">
          <b-dropdown variant="light" right text="Weitere Aktionen">
            <b-dropdown-item @click="createNewByCurrent">in neue Ankündigung kopieren</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </b-row>

      <b-row v-if="types.length > 0">
        <!-- Editor -->
        <b-col lg="9" md="12">
          <b-card
            class="card-small mb-3"
            header-class="border-bottom"
            body-class="p-2"
          >
            <template #default>
              <b-form-group
                v-if="currentTypes.find((type) => type.use_title != false)"
                label="Titel"
              >
                <b-form-input v-model="title"></b-form-input>
              </b-form-group>
              <b-form-group label="Inhalt">
                <Editor v-model="content" />
              </b-form-group>
            </template>
          </b-card>
        </b-col>

        <!-- Sidebar Widgets -->
        <b-col lg="3" md="12">
          <b-card
            class="card-small mb-3"
            header-class="border-bottom"
            body-class="p-0 pb-2"
          >
            <template #header>
              <h6 class="m-0">Status</h6>
            </template>

            <template #default>
              <b-list-group flush>
                <b-list-group-item class="px-3">
                  <span class="d-flex mb-2"
                    ><i
                      class="material-icons mr-1"
                      v-b-popover.hover.top="'Sichtbarkeit'"
                      >visibility</i
                    >
                    <strong v-if="status == 'public'" class="text-success"
                      >Öffentlich</strong
                    >
                    <strong v-else class="text-danger">Privat</strong></span
                  >
                  <span class="d-flex"
                    ><i
                      class="material-icons mr-1"
                      v-b-popover.hover.top="'Anzeigezeitraum'"
                      >calendar_today</i
                    >
                    <span v-if="end_date == '9999-12-31 23:59:59'"
                      >Unbegrenzt</span
                    >
                    <span v-else>Benutzerdefiniert</span>
                    <b-button
                      v-b-toggle.collapse_announcement_dates
                      variant="link"
                      class="p-0 ml-2"
                      ><i class="material-icons" style="font-size: 1rem"
                        >edit</i
                      ></b-button
                    >
                  </span>
                  <b-collapse id="collapse_announcement_dates">
                    <b-form-group label="Von">
                      <b-form-datepicker
                        v-model="start_date"
                        v-bind="DatePickerConfig"
                      ></b-form-datepicker>
                    </b-form-group>
                    <b-form-group label="Bis">
                      <b-form-datepicker
                        v-model="end_date"
                        v-bind="DatePickerConfig"
                      ></b-form-datepicker>
                    </b-form-group>
                  </b-collapse>
                </b-list-group-item>

                <b-list-group-item class="d-flex px-3">
                  <b-button
                    size="sm"
                    class="btn-outline-accent"
                    @click="save"
                    :disabled="save_loading || types.length == 0"
                  >
                    <i v-if="save_loading" class="fas fa-spin fa-spinner"></i
                    ><i v-else class="material-icons">save</i> Speichern
                  </b-button>

                  <div
                    v-b-popover.hover.top="
                      types.length == 0
                        ? 'Es muss mindestens ein Anzeigeort hinterlegt werden.'
                        : (status == 'private' &&
                          currentTypes.find(
                            (type) => type.max && announcements.filter(
                                (row) =>
                                  row.types.find((el) => el == type.name) &&
                                  row.status == 'public'
                              ).length >= type.max
                          ))
                        ? 'Ein gewählter Anzeigeort besitzt bereits die maximale Anzahl an Ankündigungen, welche gleichzeitig aktiv sein dürfen.'
                        : undefined
                    "
                  >
                    <b-button
                      size="sm"
                      class="btn-accent ml-1"
                      @click="handleUnPublishClick"
                      :disabled="
                        !!(
                          publish_loading ||
                          types.length == 0 ||
                          (status == 'private' &&
                            currentTypes.find(
                              (type) => type.max && announcements.filter(
                                  (row) =>
                                    row.types.find((el) => el == type.name) &&
                                    row.status == 'public'
                                ).length >= type.max
                            ))
                        )
                      "
                    >
                      <template v-if="status == 'private'">
                        <i
                          v-if="publish_loading"
                          class="fas fa-spin fa-spinner"
                        ></i
                        ><i v-else class="material-icons">file_copy</i>
                        {{
                          $route.params.id
                            ? "Veröffentlichen"
                            : "Speichern & Veröffentlichen"
                        }}
                      </template>
                      <template v-else>
                        <i
                          v-if="publish_loading"
                          class="fas fa-spin fa-spinner"
                        ></i
                        ><i v-else class="material-icons">file_copy</i>
                        Verstecken
                      </template>
                    </b-button>
                  </div>
                </b-list-group-item>
                <b-list-group-item class="px-3 pt-1">
                  <b-button
                    v-if="$route.params.id"
                    block
                    variant="outline-danger"
                    @click="remove"
                    >Löschen</b-button
                  >
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-card>
          <b-card
            class="card-small mb-3"
            header-class="border-bottom"
            body-class="p-0 pb-2"
          >
            <!-- Card Header -->
            <template #header>
              <h6 class="m-0">Anzeigeorte</h6>
            </template>

            <template #default>
              <b-list-group flush>
                <!-- Types -->
                <b-list-group-item class="px-0 pb-2" v-if="types.length">
                  <b-list-group flush>
                    <b-list-group-item
                      v-for="type in announcement_types.filter((type) =>
                        types.find((type_name) => type_name == type.name)
                      )"
                      :key="type.name"
                      class="d-flex justify-content-between"
                    >
                      <div>
                        {{ type.label }}
                      </div>
                      <div
                        class="text-right"
                        style="cursor: pointer"
                        @click="
                          types = types.filter(
                            (type_name) => type_name != type.name
                          )
                        "
                      >
                        <i class="material-icons">delete</i>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-list-group-item>
                <b-list-group-item v-else class="text-danger px-3 small">
                  Bitte mindestens einen Anzeigeort auswählen.
                </b-list-group-item>

                <b-list-group-item>
                  <b-dropdown size="sm" variant="outline-secondary" block right>
                    <template v-slot:button-content no-outer-focus
                      >Anzeigeort auswählen</template
                    >
                    <b-dropdown-form @submit.stop.prevent="() => {}">
                      <b-form-group
                        label-for="type-search-input"
                        label="Suche:"
                        label-cols-md="auto"
                        class="mb-0"
                        label-size="sm"
                      >
                        <b-form-input
                          v-model="filterAnnouncementTypes"
                          id="type-search-input"
                          type="search"
                          size="sm"
                          autocomplete="off"
                        ></b-form-input>
                      </b-form-group>
                    </b-dropdown-form>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-group style="overflow: auto; max-height: 30vh">
                      <b-dropdown-item
                        v-for="type in filteredAnnouncementTypes"
                        :key="type.name"
                        @click="types = [...types, type.name]"
                        :disabled="
                          type.max && status == 'public'
                            ? announcements.filter(
                                (row) =>
                                  row.types.find((el) => el == type.name) &&
                                  row.status == 'public'
                              ).length >= type.max
                            : false
                        "
                        v-b-popover.hover.top="
                          type.max &&
                          status == 'public' &&
                          announcements.filter(
                            (row) =>
                              row.types.find((el) => el == type.name) &&
                              row.status == 'public'
                          ).length >= type.max
                            ? 'Max. Anzahl erreicht'
                            : undefined
                        "
                        >{{ type.label }}</b-dropdown-item
                      >
                    </b-dropdown-group>
                    <b-dropdown-text v-if="announcement_types.length === 0"
                      >Keine Anzeigeorte vorhanden</b-dropdown-text
                    >
                    <b-dropdown-text
                      v-else-if="filteredAnnouncementTypes.length === 0"
                      >Keine Anzeigeorte gefunden</b-dropdown-text
                    >
                  </b-dropdown>
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-card>
        </b-col>
      </b-row>
      <b-row v-else>
        <b-col cols="12">
          <b-card
            class="card-small mb-3"
            header-class="border-bottom"
            body-class="p-2"
          >
            <template #default>
              <b-container fluid>
                <b-row>
                  <b-col cols="12" class="w-100 text-center mt-2 mb-4">
                    <h4>Wo soll die Ankündigung angezeigt werden?</h4>
                    <small
                      >Sie können später noch weitere Anzeigeorte
                      bestimmen.</small
                    >
                  </b-col>
                </b-row>
                <b-row class="justify-content-center">
                  <b-col cols="12" md="6" class="text-left mb-3">
                    <b-form-group
                      label-for="type-search-input"
                      label="Suche:"
                      label-cols-md="auto"
                      class="mb-1"
                      label-size="sm"
                    >
                      <b-form-input
                        v-model="filterAnnouncementTypes"
                        id="type-search-input"
                        type="search"
                        size="sm"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                    <b-list-group
                      flush
                      style="overflow: auto; max-height: 40vh"
                    >
                      <b-list-group-item
                        v-for="type in filteredAnnouncementTypes"
                        :key="type.name"
                        @click="types = [...types, type.name]"
                        :disabled="
                          type.max && status == 'public'
                            ? announcements.filter(
                                (row) =>
                                  row.types.find((el) => el == type.name) &&
                                  row.status == 'public'
                              ).length >= type.max
                            : false
                        "
                        v-b-popover.hover.top="
                          type.max &&
                          status == 'public' &&
                          announcements.filter(
                            (row) =>
                              row.types.find((el) => el == type.name) &&
                              row.status == 'public'
                          ).length >= type.max
                            ? 'Max. Anzahl erreicht'
                            : undefined
                        "
                        style="cursor: pointer"
                        >{{ type.label }}</b-list-group-item
                      >
                    </b-list-group>
                    <div v-if="announcement_types.length === 0">
                      Keine Anzeigeorte vorhanden
                    </div>
                    <div v-else-if="filteredAnnouncementTypes.length === 0">
                      Keine Anzeigeorte gefunden
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </template>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import Editor from "@/components/editor/Editor.vue";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
// TODO: Umgang mit abgelaufenden Ankündigungen
// TODO: Zeitliche Einschränkung
export default {
  name: "AnnouncementShow",
  data() {
    return {
      auth_modify: null,
      loaded: false,
      save_loading: false,
      publish_loading: false,
      filterAnnouncementTypes: null,
      title: null,
      content: null,
      types: null,
      status: "private",
      start_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      end_date: "9999-12-31 23:59:59",
      // CONFIGS
      DatePickerConfig,
      TimePickerConfig,
    };
  },
  components: {
    Editor,
  },
  computed: {
    ...mapGetters(["announcement_types", "announcements"]),
    data() {
      return this.announcements.find(
        (announcement) => announcement.id == this.$route.params.id
      );
    },
    currentTypes() {
      return this.announcement_types && this.types
        ? this.announcement_types.filter((type) =>
            this.types.find((type_name) => type_name == type.name)
          )
        : [];
    },
    filteredAnnouncementTypes() {
      return this.announcement_types.filter((type) => {
        if (this.currentTypes.find((currType) => currType.name == type.name))
          return false;
        if (
          this.filterAnnouncementTypes &&
          !type.name.includes(this.filterAnnouncementTypes) &&
          !type.label.includes(this.filterAnnouncementTypes)
        )
          return false;
        return true;
      });
    },
  },
  watch: {
    "$route.params.id": function () {
      this.reset();
    },
    data: function () {
      this.reset();
    },
  },
  methods: {
    reset() {
      this.title = this.data ? this.data.title : this.$route.query.title ? this.$route.query.title : "";
      this.content = this.data ? this.data.content.html : this.$route.query.content ? this.$route.query.content : "";
      this.types = this.data
        ? this.data.types
        : this.$route.query.types &&
          Array.isArray(this.$route.query.types) &&
          !this.$route.query.types.find((el) => typeof el != "string")
        ? this.$route.query.types.filter((type_name) =>
            this.announcement_types.find((type) => type.name == type_name)
          )
        : [];
      this.status = this.data ? this.data.status : "private";
      this.start_date = this.data
        ? this.data.start_date
        : (this.$route.query.start_date && moment(this.$route.query.start_date).isValid()) ? moment(this.$route.query.start_date).format("YYYY-MM-DD HH:mm:ss") : moment().format("YYYY-MM-DD HH:mm:ss");
      this.end_date = this.data
        ? this.data.end_date
        : (this.$route.query.end_date && moment(this.$route.query.end_date).isValid()) ? moment(this.$route.query.end_date).format("YYYY-MM-DD HH:mm:ss") : "9999-12-31 23:59:59";
    },
    createNewByCurrent() {
      if (!this.data) return;
      this.$router.push({
        name: "new_announcement",
        query: {
          title: this.title,
          content: this.content,
          types: this.types,
          start_date: this.start_date,
          end_date: this.end_date,
        }
      })
    },
    async handleUnPublishClick() {
      this.publish_loading = true;
      try {
        if (!this.data) {
          // CREATE
          let announcement = await this.$store
            .dispatch("createAnnouncement", {
              title: this.title,
              content: this.content,
              types: this.types,
              status: "public",
              start_date: this.start_date,
              end_date: this.end_date,
            })
            .catch((err) => {
              if (err.response.status == 400)
                this.$globalmethod.toast_error(
                  "Konnte nicht angelegt werden.",
                  "Bitte Inhalt füllen."
                );
              else if (err.response.status == 409)
                this.$globalmethod.toast_error(
                  "Konnte nicht angelegt werden.",
                  "max. Anzahl für Anzeigeort bereits vorhanden."
                );
              else this.$apimethod.defaulthandler(err);
              throw err;
            });
          this.$globalmethod.toast_success("Ankündigung erstellt.");
          this.$router.push({
            name: "announcement",
            params: { id: announcement.id },
          });
        } else {
          // UPDATE
          await this.$store
            .dispatch("updateAnnouncement", {
              id: this.data.id,
              status: this.status == "public" ? "private" : "public",
            })
            .catch((err) => {
              if (err.response.status == 409)
                this.$globalmethod.toast_error(
                  "Konnte nicht aktualisiert werden.",
                  "Bereits max. Anzahl für bestimmten Ankündigungsort definiert."
                );
              else this.$apimethod.defaulthandler(err);
              throw err;
            });
          this.$globalmethod.toast_success(`Ankündigung aktualisiert.`);
        }
        this.publish_loading = false;
      } catch (err) {
        this.publish_loading = false;
        throw err;
      }
    },
    async save() {
      this.save_loading = true;
      try {
        if (!this.data) {
          // CREATE
          let announcement = await this.$store
            .dispatch("createAnnouncement", {
              title: this.title,
              content: this.content,
              types: this.types,
              status: this.status,
              start_date: this.start_date,
              end_date: this.end_date,
            })
            .catch((err) => {
              if (err.response.status == 400)
                this.$globalmethod.toast_error(
                  "Konnte nicht angelegt werden.",
                  "Bitte Inhalt füllen."
                );
              else if (err.response.status == 409)
                this.$globalmethod.toast_error(
                  "Konnte nicht angelegt werden.",
                  "max. Anzahl für Anzeigeort bereits vorhanden."
                );
              else this.$apimethod.defaulthandler(err);
              throw err;
            });
          this.$router.push({
            name: "announcement",
            params: { id: announcement.id },
          });
          this.$globalmethod.toast_success("Ankundigung angelegt.");
        } else {
          // UPDATE
          await this.$store
            .dispatch("updateAnnouncement", {
              id: this.data.id,
              title: this.title,
              content: this.content,
              types: this.types,
              status: this.status,
              start_date: this.start_date,
              end_date: this.end_date,
            })
            .catch((err) => {
              if (err.response.status == 400)
                this.$globalmethod.toast_error(
                  "Konnte nicht aktualisiert werden.",
                  "Bitte Inhalt füllen."
                );
              else this.$apimethod.defaulthandler(err);
              throw err;
            });
          this.$globalmethod.toast_success("Ankundigung aktualisiert.");
        }
        this.save_loading = false;
      } catch (err) {
        this.save_loading = false;
        throw err;
      }
    },
    async remove() {
      await this.$store
        .dispatch("deleteAnnouncement", { id: this.data.id })
        .catch(this.$apimethod.defaulthandler);
      this.$globalmethod.toast_success("Ankundigung gelöscht.");
      this.$router.push({ name: "announcements" });
    },
  },
  async created() {
    await Promise.all([
      (async () => {
        this.auth_modify = await this.$store.dispatch(
          "haveUserRight",
          "announcements_modify"
        );
      })(),
      this.$store.dispatch("initAnnouncementModule"),
    ]);
    this.loaded = true;
    this.reset();
  },
};
</script>
