import Vue from 'vue';
import Swal from 'sweetalert2'

const app = new Vue();
const GlobalMethod = {
  install(Vue) {
    Vue.prototype.$globalmethod = {
      haveRight() {
        // return true or false (sync)
        // TODO
      },
      haveRole() {
        // return true or false (sync)
        // execute a call to api(apimethod) if completly not found
        // TODO
      },
      toast_missing_right: function (input) {
        if (input) {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: 'warning',
            title: 'Berechtigung: ' + input + ' fehlt.',
            showConfirmButton: false,
            timer: 3000
          })
        }
      },
      toast_missing_role: function (input) {
        if (input) {
          Swal.fire({
            toast: true,
            position: "top-end",
            icon: 'warning',
            title: 'Rolle: ' + input + ' fehlt.',
            showConfirmButton: false,
            timer: 3000
          })
        }
      },
      toast_internal_server_error: function () {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: 'error',
          title: 'Anfrage nicht erfolgreich. Fehler auf Serverseite. Bitte erneut versuchen.',
          showConfirmButton: false,
          timer: 3000
        })
      },
      toast_unknown_error: function () {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: 'question',
          title: 'Es ist ein unbekannter Fehler aufgetreten.',
          showConfirmButton: false,
          timer: 3000
        })
      },
      toast_success: function (title) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: 'success',
          title,
          showConfirmButton: false,
          timer: 3000
        })
      },
      toast_error: function (title, text) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: 'error',
          title,
          text,
          showConfirmButton: false,
          timer: 3000
        })
      },
      toast_info: function (title) {
        Swal.fire({
          toast: true,
          position: "top-end",
          icon: 'info',
          title,
          showConfirmButton: false,
          timer: 3000
        })
      }
    };
  }
};

export default GlobalMethod;
