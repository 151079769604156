<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">Firma</span>
        <h3 class="page-title">Vermögenswerte</h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3" class="text-center text-md-right">
        <b-dropdown
          v-if="assets.length > 0"
          right
          text="Weitere Aktionen"
          variant="outline-light"
          size="sm"
        >
          <b-dropdown-item-button
            :disabled="!auth_modify"
            @click="$router.push({ name: 'asset_new' })"
            >Neuen Vermögenswert anlegen</b-dropdown-item-button
          >
          <b-dropdown-item-button
            disabled
            @click="handleViewManageAssetParticipation"
            >Verfügbarkeitsübersicht</b-dropdown-item-button
          >
        </b-dropdown>
      </b-col>
    </d-row>
    <b-row v-if="assets.length == 0" class="mb-5 justify-content-center">
      <b-col cols="12" class="text-center">
        <div class="my-5 d-none d-md-block">
          Sie haben noch keine Vermögenswerte hinterlegt.
        </div>
        <b-button
          variant="outline-secondary"
          :disabled="!auth_modify"
          @click="$router.push({ name: 'asset_new' })"
          >Vermögenswert hinzufügen</b-button
        >
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col cols="12">
        <!-- Head-band with filter -->
        <b-card>
          <div
            v-for="filter_type in filter_types.filter(
              (ft) =>
                ft.types.find((name) =>
                  available_types.find((at) => at == name)
                ) ||
                (ft.types.includes(null) &&
                  !filter_types.find((filter_type) =>
                    available_types.find((at) => filter_type.types.includes(at))
                  ))
            )"
            :key="filter_type.title"
          >
            <h4><i class="material-icons mr-2">{{ filter_type.material_icon ? filter_type.material_icon : 'help_outline'}}</i>{{ filter_type.title }}</h4>
            <b-table
            :fields="fields"
            :items="assets.filter(asset => filter_type.types.includes(asset.type) || (filter_type.types.includes(null) && !filter_types.find(ft => ft.types.includes(asset.type))))"
            selectable
            @row-clicked="(asset) => $router.push({ name: 'asset', params:{ id: asset.id }})">
            <template #cell(name)="row">
              <b-avatar icon=" " class="bg-white" size="sm" :src="row.item.thumbnail_file_id ? $api.defaults.baseURL+`company/asset/${row.item.id}/file/${row.item.thumbnail_file_id}` : null"></b-avatar>
              {{ row.item.name }}
            </template>
            <template #cell(actions)="row">
              <b-button size="sm" @click="handleRemove(row.item.id)" variant="link"><span class="material-icons">delete</span></b-button>
            </template>
            </b-table>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Assets",
  data() {
    return {
      auth_modify: false,
      fields: [
        {
          key: "name",
        },{
          key: "actions",
          label: "Aktionen"
        },
      ],
      filter_types: [
        {
          types: ["pkw"],
          title: "PKWs",
          material_icon: "directions_car",
        },
        {
          types: ["moped", "mini-bike", "bike"],
          title: "Zwei-Rad Fahrzeuge",
          material_icon: "two_wheeler",
        },
        {
          types: ["lkw"],
          title: "LKWs",
          material_icon: "local_shipping",
        },
        {
          types: ["pc","laptop", "beamer", "simulator"],
          title: "Geräte",
          material_icon: "devices",
        },
        {
          types: ["other", null], // null references all types, which are not listed in the admintools
          title: "Anderes",
          material_icon: "help_outline",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["assets"]),
    available_types() {
      return [...new Set(this.assets.map((el) => el.type))];
    },
  },
  methods: {
    handleViewManageAssetParticipation() {},
    async handleRemove(id) {
      await this.$store.dispatch('removeAsset', { id });
    }
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "asset_modify"
    );
  },
};
</script>
