var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_bar.description),expression:"settings_obj.payment_bar.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_vm._v(" Barzahlung erlaubt: "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":"","checked":_vm.settings_obj.payment_bar.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', { name: 'payment_bar', value: !!val }); }}})],1),_c('b-collapse',{staticClass:"ml-2 small",model:{value:(_vm.settings_obj.payment_bar.value.current),callback:function ($$v) {_vm.$set(_vm.settings_obj.payment_bar.value, "current", $$v)},expression:"settings_obj.payment_bar.value.current"}},[_c('b-form-group',{attrs:{"label":"Maximalbetrag"}},[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_bar_max.description),expression:"settings_obj.payment_bar_max.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_bar_max.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_bar_max',
                value: val ? '1000 €' : null,
              }); }}}),_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_bar_max.value.current,"disabled":_vm.settings_obj.payment_bar_max.value.current === null,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'payment_bar_max',
                  value:
                    (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                }); }}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }