import Vue from 'vue'
import API from '@/plugins/api.js'
import APIMethod from '@/plugins/apimethod'
Vue.use(API)
Vue.use(APIMethod)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: {
    data: [],
  },
  mutations: {
    setSettings(state, input) {
      state.data = input;
    },
    updateSetting(state, input) {
      state.data = state.data.map(el => {
        if (el.name == input.name) return input;
        return el;
      });
      state.data = [...state.data];
    },
    resetSettings(state) {
      state.data = [];
    }
  },
  actions: {
    async initAllSettings(context) {
      let response = await store.$api.get("system/setting")
      context.commit("setSettings", response.data.result);
    },
    async setSetting(context, { name, value }) {
      let response = await store.$api.put(`system/setting/${name}`, { value })
        .catch(store.$apimethod.defaulthandler);
      context.commit("updateSetting", response.data.result);
    },
    async getSetting(context, input) {
      let data = [input].flat();
      data = data.map(name => {
        return {
          name,
          value: context.getters.settings_obj[name] ? context.getters.settings_obj[name].value.current : undefined,
        };
      });
      if (data.length == 0) throw new Error('INVALID_INPUT');
      let missing_settings = data.filter(el => el.value===undefined).map(el => el.name);
      if (missing_settings.length == 0) return typeof input == "string" ? data[0].value : data.map(el => el.value);
      let response = await store.$api.get(`system/setting?${missing_settings.map(el => `name[]=${el}`).join('&')}`);
      data = data.map(row => {
        if (row.value) return row;
        if (!response.data.result.find(el => el.name == row.name)) throw new Error(`NOT_FOUND(${row.name}) - ${JSON.stringify(row)}`);
        row.value = response.data.result.find(el => el.name == row.name).value.current;
        context.commit("updateSetting", row.value);
        return row;
      });
      return typeof input == "string" ? data[0].value : data.reduce((out, curr) => {
        out[curr.name] = curr.value;
        return out;
      }, {});
    },
  },
  getters: {
    settings(state) {
      return state.data;
    },
    settings_obj(state) {
      let helper = {};
      state.data.forEach(el => helper[el.name] = el);
      return helper;
    }
  }
}
export default module_branch
