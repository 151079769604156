import Vue from 'vue';
import Vuex from 'vuex';
import API from '@/plugins/api.js';
import GlobalAPI from '@/plugins/global_api.js';
import module_announcement from "./module_announcement";
import module_api from "./module_api";
import module_company from "./module_company";
import module_review from "./module_review";
import module_session from "./module_session";
import module_setting from "./module_setting";
import module_education from "./module_education";
import module_mail_list from "./module_mail_list";
import module_user from "./module_user";
Vue.use(Vuex);
Vue.use(API);
Vue.use(GlobalAPI);
const store = new Vue(); // use the store var for request api
// Actions, Mutations and and getters of submodules have to expose like: <modulename>_<othername>

export default new Vuex.Store({
  state: {
    loadingStatus: null,
    apiStatus: false,
    companys: JSON.parse(sessionStorage.getItem('companys')) || [],
    currentcompany: JSON.parse(localStorage.getItem('currentcompany')) || null,
  },
  getters: {
    loaded(state) {
      return (state.loadingStatus===null) ? null : state.loadingStatus===true && state.apiStatus===true;
    },
    loadingStatus(state) {
      return state.loadingStatus;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
    companys(state) {
      return state.companys;
    },
    currentCompany(state) {
      return state.currentcompany;
    },
  },
  mutations: {
    // Syncronys
    setLoadingStatus(state, status) {
      state.loadingStatus = status;
    },
    setLoadingStatusOK(state) {
      state.loadingStatus = true;
    },
    setAPILoading(state) {
      state.apiStatus = null;
    },
    setAPIOK(state) {
      state.apiStatus = true;
    },
    setAPIFailed(state) {
      state.apiStatus = false;
    },
    setcurrentCompany(state, company) {
      var companys = JSON.parse(sessionStorage.getItem("companys"));
      if (!(companys.find((elem) => (elem.id == company)))) throw new Error("COMPANY_INVALID(NOT_FOUND");
      store.$api.defaults.baseURL = `${process.env.VUE_APP_API_BASE_URL + company}/`;
      localStorage.setItem('currentcompany', JSON.stringify(company));
      state.currentcompany = company;
    },
    unsetcurrentCompany(state) {
      this.commit('destroySession');
      store.$api.defaults.baseURL = null;
      localStorage.removeItem('currentcompany');
      state.currentcompany = null;
    },
    setCompanys(state, companys) {
      sessionStorage.setItem('companys', JSON.stringify(companys));
      state.companys = companys;
    },
  },
  actions: {
    // asyncronys or syncronys
    async startup(context) {
      // Get Companys, if they are not set. It will be saved in sessionStorage ==> everytime, the browser will reopen this will be cleaned
      console.log(`use API: ${JSON.stringify(process.env)}`);
      let offset = 2000;
      var apirequire = async () => new Promise((resolve) => {
        context.commit('setAPILoading');
        store.$globalapi.get('')
          .then((response) => {
            context.commit('setCompanys', response.data.clients);
            if (response.data.service_status.api.trim().toLowerCase()=="ok") context.commit('setAPIOK');
            else throw new Error("There was an Error on API-Side");
            resolve();
          })
          .catch((err) => {
            console.log(err);
            context.commit('setLoadingStatus', "Fehler beim kontaktieren der API");
            context.commit('setAPIFailed');
            setTimeout(() => {
              context.commit('setAPILoading');
              if (offset<10000) offset = offset + 1000;
            }, offset-2000);
            return setTimeout(() => apirequire().then(() => resolve()), offset);
          });
      });
      await apirequire(); // Wait until the api was connected
      await context.dispatch('refreshSession');
      context.commit('setLoadingStatusOK');
    },
    async updateAll(context) {
      await Promise.all([
        context.dispatch('setupAPIModule'),
        context.dispatch('updateCompanyData'),
        context.dispatch('setupTheory'),
        context.dispatch('setupUserModule'),
      ]);
      context.commit('setLoadingStatusOK');
    },
    async resetAll(context) {
      context.commit('setLoadingStatus', "Lösche Session Daten...");
      await Promise.all([
        context.commit('resetAnnouncementModule'),
        context.commit('resetCompanyData'),
        context.commit('resetTheory'),
        context.commit('resetSettings'),
        context.commit('resetUserModule'),
        context.commit('resetAPIModule'),
      ]);
      context.commit('setLoadingStatusOK');
    },
    async reportError(context, { title, text, reference } = {}) {
      await store.$globalapi.post("/error", {
        title,
        text,
        client: context.getters.currentCompany,
        reference
      });
      store.$globalmethod.toast_success("Fehlermeldung abgesendet.");
    },
  },
  modules: {
    announcement: module_announcement,
    api: module_api,
    company: module_company,
    review: module_review,
    settings: module_setting,
    session: module_session,
    education: module_education,
    mail_list: module_mail_list,
    user: module_user
  },
});

