export default function (notifications, this_obj) {
  if (!this_obj || !this_obj.company || !this_obj.$router || !this_obj.$route) {
    console.log(this_obj);
    throw new Error("Invalid THIS");}
  return notifications.map(
    (el) => {
      el.onClick = false;
      el.material_icon = "info_outline";
      switch (el.name) {
        case "course_participation_new_requests":
        case "course_status":
        case "course_customer_action":
          el.onClick = () => {
            if (this_obj.$route.name != "courses")
              this_obj.$router.push({ name: "courses" });
            this_obj.$router.push({
              name: "course",
              params: { id: el.reference },
            });
          };
          el.material_icon = "account_balance";
          break;
        case "no_current_courses":
          el.onClick = () => {
            if (this_obj.$route.name != "courses")
              this_obj.$router.push({ name: "courses" });
          };
          el.material_icon = "account_balance";
          break;
        case "lesson_participation_new_requests":
        case "lesson_status":
        case "lesson_customer_action":
          el.onClick = () => {
            if (this_obj.$route.name != "lessons")
              this_obj.$router.push({ name: "lessons" });
            this_obj.$router.push({
              name: "lesson",
              params: { id: el.reference },
            });
          };
          el.material_icon = "account_balance";
          break;
        case "no_current_lessons":
          el.onClick = () => {
            if (this_obj.$route.name != "lessons")
              this_obj.$router.push({ name: "lessons" });
          };
          el.material_icon = "account_balance";
          break;
        case "custom_email_apis_missing_info":
        case "custom_email_apis_registration_error":
          el.text = el.text.split("<firmenname>").join(this_obj.company.id); // ersetze, für bessere Semantik
          el.onClick = () => {
            if (this_obj.$route.name != "settings")
              this_obj.$router.push({ name: "settings", query: { name: "general" } });
          };
          el.material_icon = "extension";
          break;
        case "new_reviews":
          el.onClick = () => {
            if (this_obj.$route.name != "reviews")
              this_obj.$router.push({ name: "reviews" });
          };
          el.material_icon = "star";
          break;
        case "company_data":
          el.onClick = () => {
            if (this_obj.$route.name != "company")
              this_obj.$router.push({ name: "company" });
          };
          el.material_icon = "work";
          break;
      }
      return el;
    }
  );
}
