<template>
  <b-container fluid class="h-100">
    <b-row class="text-center justify-content-center h-100 align-items-center">
      <b-col id="login" cols="auto" class="mb-5">
        <form @submit.prevent="login">
          <h1>Adminoberfläche</h1>
          <hr />
          <div v-show="!loading && !currentUser">
            <div v-if="!fixedCompany" class="mb-2">
              <b-form-select
                v-model="selectedcompany"
                :options="companysList"
                :state="selectcompany_state"
                @change="
                  user = null;
                  password = null;
                "
              ></b-form-select>
            </div>
            <div v-else class="mb-2 text-left ml-2 font-weight-bold">
              {{
                companysList.find((elem) => selectedcompany == elem.value).text
              }}
            </div>
            <div>
              <input
                v-if="!password_expired"
                type="text"
                v-model="user"
                class="form-control"
                placeholder="E-Mail/Nutzername"
                required
                autofocus
                autocomplete="username"
                invalid-feedback="Benutzername eingeben"
                :disabled="selectedcompany === null"
              />
              <div class="text-left" v-else>
                <div class="">Nutzername/E-Mail</div>
                <div class="d-flex justify-content-between">
                  <div class="pl-2 small">{{user}}</div>
                  <b-button variant="light" size="sm" @click="password_expired=false; user=''; password=''; new_password=''">Ausloggen</b-button>
                </div>
              </div>
            </div>
            <div>
              <input
                v-if="!password_expired"
                type="password"
                v-model="password"
                class="form-control"
                placeholder="Passwort"
                required
                autocomplete="current-password"
                invalid-feedback="Bitte Passwort eingeben"
                :disabled="selectedcompany === null"
              />
              <div class="text-left" v-else>
                <div class="">Altes Passwort</div>
                <div class="pl-2 small">{{password.replace(/\S/gi, '*')}}</div>
              </div>
            </div>
            <div v-if="password_expired">
              <input
                type="password"
                v-model="new_password"
                class="form-control"
                placeholder="Neues Passwort"
                required
                autocomplete="current-password"
                invalid-feedback="Bitte Passwort eingeben"
                :disabled="selectedcompany === null"
              />
            </div>
          </div>
          <div v-show="loading" class="m-5">
            <i class="fa fa-spinner fa-spin"></i>
          </div>
          <div v-if="wrongpwd" class="text-danger m-3">
            Falsche Eingabe. Erneut versuchen!
          </div>
          <div v-if="brute" class="text-danger m-3">
            Zu viele Loginversuche.<br>Bitte versuchen Sie es später erneut!
          </div>
          <hr />
          <div>
            <input
              type="submit"
              id="loginbutton"
              class="btn btn-default submit"
              value="Log in"
            />
          </div>
          <GoogleLogin :params="googleLoginParams.params" :renderParams="googleLoginParams.renderParams" :onSuccess="onGoogleLoginSuccess"/>
        </form>
      </b-col>
    </b-row>
    <b-row id="footer" class="w-100 text-center justify-content-center">
      <b-col cols="" md=""></b-col>
      <b-col cols="12" md="">
        <b-link class="text-muted px-1" href="https://wer-ner.de"
          >WER-NER.de</b-link
        >
        ©{{ new Date().getFullYear() }} All Rights Reserved.
      </b-col>
      <b-col cols="12" md="" class="text-md-right">
        <b-link
          v-if="fixedCompany"
          class="text-muted"
          href="https://admin.fahrschule-api.de/"
          >Allgemeine Adminoberfläche</b-link
        >
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import GoogleLogin from 'vue-google-login';
import { mapGetters } from "vuex";
export default {
  components: {
    GoogleLogin
  },
  data() {
    return {
      loading: false,
      wrongpwd: false,
      brute: false,
      selectcompany_state: null,
      selectedcompany:
        JSON.parse(localStorage.getItem("currentcompany")) &&
        JSON.parse(localStorage.getItem("currentcompany")) != null
          ? JSON.parse(localStorage.getItem("currentcompany"))
          : null,
      user: "",
      password: "",
      password_expired: false,
      new_password: "",
      companysList: [],
      fixedCompany: null,
      googleLoginParams: {
        // client_id is the only required property but you can add several more params, full list down bellow on the Auth api section
        params: {
          client_id: process.env.VUE_APP_GOOGLE_OAUTH_CLIENT_ID,
        },
        // only needed if you want to render the button with the google ui
        renderParams: {
          width: 250,
          height: 50,
          longtitle: true,
        },
      },
    };
  },
  computed: {
    ...mapGetters(["currentUser","companys"]),
  },
  watch: {
    companys: function () {
      this.updatecompanyslist();
      this.setFixedCompany();
    },
  },
  methods: {
    login() {
      if (this.selectedcompany == null)
        return (this.selectcompany_state = false);
      this.missingCompany = null;
      this.loading = true;
      this.$store
        .dispatch("login", {
          company: this.selectedcompany,
          user: this.user,
          password: this.password,
          new_password: this.password_expired ? this.new_password : undefined,
        })
        .then(() => {
          this.wrongpwd = false;
          this.password_expired = false;
          this.brute = false;
          this.loading = false;
          this.$router.checkRouteRights();
        })
        .catch((error) => {
          console.log(error);
          if (error.response && error.response.status == 403) {
            this.wrongpwd = true;
            this.password_expired = false;
            this.brute = false;
          }
          if (error.response && error.response.status == 409) {
            this.wrongpwd = false;
            this.password_expired = true;
            this.brute = false;
          }
          if (error.response && error.response.status == 429) {
            this.wrongpwd = false;
            this.password_expired = false;
            this.brute = true;
          }
          this.loading = false;
        });
    },
    async onGoogleLoginSuccess(googleuser) {
      console.log(googleuser);
      console.log(googleUser.getBasicProfile());
      this.wrongpwd = false;
      this.loading = true;
      await this.$store
        .dispatch("login", {
          company: this.selectedcompany,
          google_idToken: googleuser.qc.id_token
        })
        .then(() => {
          this.$router.checkRouteRights();
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });

    },
    updatecompanyslist() {
      var array = [
        {
          value: null,
          text: "Firma auswählen",
        },
      ];
      this.companys.forEach((company) =>
        array.push({
          value: company.id,
          text: company.name,
        })
      );
      this.companysList = array;
    },
    /**
     * this function checks the current domain and set the company to a not changeable value (in login-screen) example: admin."fahrschule-werner.de"
     */
    setFixedCompany() {
      let company;
      if (
        (company = this.companys.find((company) =>
          document.location.hostname
            .trim()
            .toLowerCase()
            .includes(company.hostname.trim().toLowerCase())
        ))
      ) {
        this.fixedCompany = true;
        this.selectedcompany = company.id;
      } else this.fixedCompany = false;
    },
  },
  created() {
    this.updatecompanyslist();
    this.setFixedCompany();
  },
};
</script>
<style scoped>
#login {
  z-index: 1000;
  background-color: #f5f6f8;
}
#footer {
  position: absolute;
  bottom: 3vh;
  z-index: 0;
}
</style>
