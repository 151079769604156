<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div v-if="!(loading === false)">Loading</div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Dieser Kurs konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else>
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span
            class="text-uppercase page-subtitle"
            @click="$router.push({ name: 'courses' })"
            style="cursor: pointer"
            >Kurse</span
          >
          <h3 class="page-title">
            <template v-if="edit_title == false">
              {{ data.name }}<br class="d-sm-none" /><small
                style="cursor: pointer"
                @click="edit_title = true"
                v-if="auth_modify"
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span></small
              >
            </template>
            <div v-else class="d-flex mr-5">
              <b-form-input v-model="edit.name" />
              <b-button variant="light" class="mx-2" @click="edit_name = null"
                >Standard</b-button
              >
              <b-button
                class="mr-2"
                @click="handleEditTitle"
                :disabled="
                  (!this.data.processed_unit_name && this.edit_name == null) ||
                  this.edit_name.trim() == ''
                "
                >Ändern</b-button
              >
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_title = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3">
          <b-form-group
            label="Kategorie"
            label-size="sm"
            label-align="center"
            label-align-sm="left"
            label-class="pb-1"
            class="text-center text-sm-left"
          >
            <CategorySelector :selectable="false" />
          </b-form-group>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col cols="12" md="8" class="pr-md-0">
          <d-card class="card-small pb-2">
            <!-- Component Header -->
            <d-card-header class="border-bottom d-none d-md-block">
              <h6 class="m-0 d-flex justify-content-between">Allgemeines</h6>
            </d-card-header>
            <d-card-body class="d-flex flex-column px-0 px-lg-3">
              <b-form-group>
                <b-form-group label="Zeitraum" style="position: relative">
                  <template v-if="!edit_date">
                    <span
                      v-if="auth_modify"
                      style="
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                      "
                      @click="edit_date = true"
                      ><i class="material-icons">edit</i>Anpassen</span
                    >
                    {{ [data.date.start] | moment("DD. MMM YYYY") }} -
                    {{ [data.date.end] | moment("DD. MMM YYYY") }}
                  </template>
                  <template v-else>
                    <span
                      style="
                        position: absolute;
                        top: 0;
                        right: 0;
                        cursor: pointer;
                      "
                      @click="
                        edit_date = false;
                        edit.date = data.date;
                      "
                    >
                      <i class="material-icons">close</i>Schließen
                    </span>
                    <b-form-group label-size="sm" label="Beginn">
                      <b-form-datepicker
                        v-model="edit.date.start"
                        v-bind="DatePickerConfig"
                      ></b-form-datepicker>
                    </b-form-group>
                    <b-form-group label-size="sm" label="Ende">
                      <b-form-datepicker
                        v-model="edit.date.end"
                        v-bind="DatePickerConfig"
                      ></b-form-datepicker>
                    </b-form-group>
                  </template>
                </b-form-group>
              </b-form-group>
              <b-form-group label="Beschreibung">
                <template v-if="!edit_description">
                  <template v-if="data.description">
                    {{ data.description }}
                  </template>
                  <small v-else> keine Beschreibung vorhanden </small>
                  <i
                    v-if="auth_modify"
                    @click="edit_description = true"
                    class="material-icons"
                    style="cursor: pointer"
                    >edit</i
                  >
                </template>
                <template v-else>
                  <div
                    class="text-right mb-1"
                    style="cursor: pointer"
                    @click="
                      edit_description = false;
                      edit.description = data.description;
                    "
                  >
                    <i class="material-icons">close</i>Schließen
                  </div>
                  <b-form-textarea v-model="edit.description"></b-form-textarea>
                </template>
              </b-form-group>
              <b-collapse :visible="changes">
                <b-button variant="light" @click="handleSave"
                  >Änderungen übernehmen</b-button
                >
              </b-collapse>
            </d-card-body>
          </d-card>
          <div class="d-flex flex-column px-3 d-md-none mt-3">
            <div class="text-center">
              <div class="d-flex justify-content-between">
                <div class="text-left pr-1">Typ:</div>
                <div
                  class="text-right text-primary"
                  v-b-popover.hover="
                    data.type == 'open'
                      ? {
                          html: true,
                          content:
                            '<strong>Dies ist ein offener Kurs.</strong><br>Hierdurch hat der Kurs folgende Eigenschaften:<br> <ul><li>Termine werden weiterhin eigenständig in der Übersicht angezeigt.</li><li>die Theorieeinheiten werden nach Kursdurchführung nicht dem Kunden als durchgeführt angezeigt. <small>(nur bei Teilnahme an einem Termin)</small></li><li>bei Löschung des Kurses bleiben alle Termine erhalten.</li></ul>',
                        }
                      : data.type == 'closed'
                      ? {
                          html: true,
                          content:
                            '<strong>Dies ist ein geschlossener Kurs.</strong><br>Hierdurch hat der Kurs folgende Eigenschaften:<br> <ul><li>zugeordnete Termine sind nur innerhalb des Kurses sichtbar.</li><li>die Theorieeinheiten werden nach Kursdurchführung dem Kunden als durchgeführt angezeigt. <small>(auch bei keine Durchführung/Teilnahme an einem Termin mit der jeweiligen Einheit)</small></li><li>bei Löschung des Kurses werden auch alle verknüpften Termine gelöscht.</li></ul>',
                        }
                      : undefined
                  "
                >
                  {{
                    data.type == "open"
                      ? "Offen"
                      : data.type == "closed"
                      ? "Geschlossen"
                      : "unbekannt"
                  }}
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="text-left pr-1">Verschoben:</div>
                <div class="text-right text-primary">
                  {{ data.postponed ? "Ja" : "Nein" }}
                </div>
              </div>
              <div
                v-if="data.type != 'open'"
                class="d-flex justify-content-between"
              >
                <div class="text-left pr-1">Abgesagt:</div>
                <div class="text-right text-primary">
                  {{ data.canceled ? "Ja" : "Nein" }}
                </div>
              </div>
              <hr class="my-2" />
            </div>
            <div class="row justify-content-between">
              <div>
                <b-button
                  variant="outline-secondary"
                  class="my-1"
                  v-if="data.canceled == false"
                  :disabled="data.type == 'open'"
                  @click="handleCancel"
                  >Absagen</b-button
                >
                <b-button
                  variant="outline-secondary"
                  class="my-1"
                  v-else
                  :disabled="data.type == 'open'"
                  @click="handleReactivate"
                  >Reaktivieren</b-button
                >
                <i
                  v-if="data.type == 'open'"
                  class="ml-2 material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover="
                    'Offene Kurse können nicht abgesagt werden.'
                  "
                  >help_outline</i
                >
              </div>
              <b-dropdown
                variant="outline-secondary"
                size="sm"
                dropup
                right
                split
                text="Verschieben"
                class="my-1"
                @click="handlePostpone"
                menu-class="p-0"
              >
                <b-dropdown-item
                  v-if="data.postponed == false"
                  @click="handleMarkPostpone"
                  ><small>Als Verschoben kennzeichnen</small></b-dropdown-item
                >
                <b-dropdown-item v-else @click="handleNotMarkPostpone"
                  ><small
                    >Nicht mehr als Verschoben kennzeichnen</small
                  ></b-dropdown-item
                >
                <b-dropdown-item v-b-modal.modal-help-postpone title="Title"
                  ><small
                    >Hilfe <i class="material-icons">help_outline</i></small
                  ></b-dropdown-item
                >
                <b-modal
                  id="modal-help-postpone"
                  title="Erklärung: Verschieben des Kurses"
                  hide-footer
                >
                  Mit Hilfe dieser Funktion können Sie Kurse verschieben und
                  Ihre Kunden über die Änderung informieren.
                  <br />
                  <br />
                  <small
                    >Hinweis: Falls Sie nur ein falsches Datum in der Anlage
                    gewählt haben und der Durchführungstermin noch weit in der
                    Zukunft liegt ist es empfehlenswert nur das Datum des
                    Termins zu verändern.</small
                  >
                </b-modal>
              </b-dropdown>
            </div>
            <b-button
              variant="outline-danger"
              class="row my-1"
              @click="handleRemove"
              >Löschen</b-button
            >
          </div>
          <!-- Termine -->
          <d-card class="card-small mt-2">
            <!-- Component Header -->
            <d-card-header class="border-bottom">
              <h6 class="m-0 d-flex justify-content-between">
                <span>
                  Termine
                  <i
                    class="material-icons"
                    v-b-popover.hover.right="
                      'Termine sind Unterrichtsstunden, welche mit diesem Kurs verknüpft sind.'
                    "
                    >help_outline
                  </i>
                </span>
                <div v-if="auth_modify">
                  <b-button
                    v-if="data.type == 'open'"
                    variant="outline-light"
                    class="p-0 pr-2 mr-3"
                    v-b-modal.modal-addlessonexisting
                    ><div class="d-flex justify-content-between">
                      <span class="material-icons">note_add</span>
                      <div class="my-auto">Hinzufügen</div>
                    </div>
                  </b-button>
                  <b-modal
                    id="modal-addlessonexisting"
                    title="Termin hinzufügen"
                    hide-footer
                    body-class="p-0 pb-2"
                  >
                    <div
                      v-if="filteredLessons.length == 0"
                      class="ml-3 mt-3 mb-4"
                    >
                      keine existierenden Theoriestunden im Kurszeitraum
                    </div>
                    <b-list-group fluid>
                      <b-list-group-item
                        v-for="lesson in filteredLessons"
                        :key="lesson.id"
                        href="#"
                        @click="addLesson(lesson.id)"
                        ><div class="d-flex justify-content-between">
                          <strong>{{
                            lesson.name
                              ? lesson.name
                              : lesson.processed_unit_name
                              ? lesson.processed_unit_name
                              : "Achtung: kein Name"
                          }}</strong>
                          <small
                            >{{
                              [lesson.date.start, "YYYY-MM-DD HH:mm:ss"]
                                | moment("ddd, DD. MMM YYYY")
                            }}
                          </small>
                        </div>
                      </b-list-group-item>
                    </b-list-group>
                  </b-modal>
                  <b-button
                    variant="outline-light"
                    class="p-0 pr-2 mr-3"
                    v-b-modal.modal-newLesson
                    ><div class="d-flex justify-content-between">
                      <span class="material-icons">add</span>
                      <div class="my-auto">Erstellen</div>
                    </div>
                  </b-button>
                  <b-modal
                    id="modal-newLesson"
                    ref="modal-newLesson"
                    @ok="
                      (ev) => {
                        ev.preventDefault();
                        $refs['component-newLesson'].submit();
                      }
                    "
                    title="Theoriestunde anlegen"
                    body-class="p-0"
                  >
                    <Lesson
                      ref="component-newLesson"
                      preventSubmit
                      hideSubmit
                      v-on:finish="handleFinishNewLesson"
                      :allowedUnits="data.units"
                      :min_date="data.date.start"
                      :max_date="data.date.end"
                      :date="{ start: data.date.start }"
                    />
                  </b-modal>
                </div>
              </h6>
            </d-card-header>

            <d-card-body class="d-flex flex-column px-0 pt-0">
              <div v-if="data.lessons.length == 0" class="small mt-2 ml-3">
                keine Termine verknüpft
              </div>
              <b-list-group flush>
                <b-list-group-item
                  href="#"
                  class="pl-2 py-1"
                  v-for="lesson in selectedLessons"
                  :key="lesson.id"
                  @click="
                    $router.push({ name: 'lesson', params: { id: lesson.id } })
                  "
                >
                  <div class="pl-2 d-flex justify-content-between">
                    <span
                      >{{
                        lesson.name
                          ? lesson.name
                          : lesson.processed_unit_name
                          ? lesson.processed_unit_name
                          : "Achtung: kein Name"
                      }}
                    </span>
                    <span>
                      {{
                        [lesson.date.start, "YYYY-MM-DD HH:mm:ss"]
                          | moment("D.MMM YYYY")
                      }}
                    </span>
                    <div v-if="auth_modify">
                      <b-button
                        variant="outline-light"
                        class="p-0 pr-2"
                        v-on:click.stop="remLesson(lesson.id)"
                      >
                        <div
                          class="d-flex justify-content-between"
                          v-if="data.type == 'open'"
                        >
                          <span class="material-icons">remove</span>
                          <div class="my-auto">Entfernen</div>
                        </div>
                        <div class="d-flex justify-content-between" v-else>
                          <span class="material-icons">delete</span>
                          <div class="my-auto">Löschen</div>
                        </div>
                      </b-button>
                    </div>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </d-card-body>
          </d-card>
        </b-col>
        <b-col cols="12" md="4" class="pl-0">
          <b-col class="text-center mt-0 mb-4 pr-0">
            <b-row class="justify-content-center">
              <b-col cols="8">
                <div class="d-none d-md-flex flex-column mx-auto">
                  <div class="text-center">
                    <div class="d-flex justify-content-between">
                      <div class="text-left pr-1">Typ:</div>
                      <div
                        class="text-right text-primary"
                        v-b-popover.hover="
                          data.type == 'open'
                            ? {
                                html: true,
                                content:
                                  '<strong>Dies ist ein offener Kurs.</strong><br>Hierdurch hat der Kurs folgende Eigenschaften:<br> <ul><li>Termine werden weiterhin eigenständig in der Übersicht angezeigt.</li><li>die Theorieeinheiten werden nach Kursdurchführung nicht dem Kunden als durchgeführt angezeigt. <small>(nur bei Teilnahme an einem Termin)</small></li><li>bei Löschung des Kurses bleiben alle Termine erhalten.</li></ul>',
                              }
                            : data.type == 'closed'
                            ? {
                                html: true,
                                content:
                                  '<strong>Dies ist ein geschlossener Kurs.</strong><br>Hierdurch hat der Kurs folgende Eigenschaften:<br> <ul><li>zugeordnete Termine sind nur innerhalb des Kurses sichtbar.</li><li>die Theorieeinheiten werden nach Kursdurchführung dem Kunden als durchgeführt angezeigt. <small>(auch bei keine Durchführung/Teilnahme an einem Termin mit der jeweiligen Einheit)</small></li><li>bei Löschung des Kurses werden auch alle verknüpften Termine gelöscht.</li></ul>',
                              }
                            : undefined
                        "
                      >
                        {{
                          data.type == "open"
                            ? "Offen"
                            : data.type == "closed"
                            ? "Geschlossen"
                            : "unbekannt"
                        }}
                      </div>
                    </div>
                    <div class="d-flex justify-content-between">
                      <div class="text-left pr-1">Verschoben:</div>
                      <div class="text-right text-primary">
                        {{ data.postponed ? "Ja" : "Nein" }}
                      </div>
                    </div>
                    <div
                      v-if="data.type != 'open'"
                      class="d-flex justify-content-between"
                    >
                      <div class="text-left pr-1">Abgesagt:</div>
                      <div class="text-right text-primary">
                        {{ data.canceled ? "Ja" : "Nein" }}
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                  <div class="row justify-content-between">
                    <div>
                      <b-button
                        variant="outline-secondary"
                        class="my-1 mx-auto mx-xl-0"
                        v-if="data.canceled == false"
                        :disabled="data.type == 'open'"
                        @click="handleCancel"
                        >Absagen</b-button
                      >
                      <b-button
                        variant="outline-secondary"
                        class="my-1 mx-auto mx-xl-0"
                        v-else
                        :disabled="data.type == 'open'"
                        @click="handleReactivate"
                        >Reaktivieren</b-button
                      >
                      <i
                        v-if="data.type == 'open'"
                        class="ml-2 material-icons"
                        style="cursor: pointer"
                        v-b-popover.hover="
                          'Offene Kurse können nicht abgesagt werden.'
                        "
                        >help_outline</i
                      >
                    </div>
                    <b-dropdown
                      variant="outline-secondary"
                      size="sm"
                      dropup
                      right
                      split
                      text="Verschieben"
                      class="my-1 mx-auto mx-xl-0"
                      @click="handlePostpone"
                      menu-class="p-0"
                    >
                      <b-dropdown-item
                        v-if="data.postponed == false"
                        @click="handleMarkPostpone"
                        ><small
                          >Als Verschoben kennzeichnen</small
                        ></b-dropdown-item
                      >
                      <b-dropdown-item v-else @click="handleNotMarkPostpone"
                        ><small
                          >Nicht mehr als Verschoben kennzeichnen</small
                        ></b-dropdown-item
                      >
                      <b-dropdown-item
                        v-b-modal.modal-help-postpone
                        title="Title"
                        ><small
                          >Hilfe
                          <i class="material-icons">help_outline</i></small
                        ></b-dropdown-item
                      >
                      <b-modal
                        id="modal-help-postpone"
                        title="Erklärung: Verschieben einer Theoriestunde"
                        hide-footer
                      >
                        Mit Hilfe dieser Funktion können Sie den Kurs
                        verschieben und Ihre Kunden über die Änderung
                        informieren.
                        <br />
                        <br />
                        <small
                          >Hinweis: Falls Sie nur ein falsches Datum in der
                          Anlage gewählt haben und der Durchführungstermin noch
                          weit in der Zukunft liegt ist es empfehlenswert nur
                          das Datum des Termins zu verändern.</small
                        >
                      </b-modal>
                    </b-dropdown>
                  </div>
                  <b-button
                    variant="outline-danger"
                    class="row my-1"
                    @click="handleRemove"
                    >Löschen</b-button
                  >
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col class="mt-2 pr-0 ml-3" :style="data.units.length == 0 ? 'opacity: 0.5;' : ''">
            <!-- Inhalte -->
            <h6 class="m-0 d-flex justify-content-between">
              <span
                >Inhalte
                <i
                  class="material-icons"
                  style="cursor: pointer"
                  v-b-popover.hover.top="{
                    html: true,
                    content:
                      'Inhalte welche im Kurs-Durchführungszeitraum unterrichtet werden sollen. Schauen Sie für weitere Informationen in den Abschnitt <strong>Inhalte</strong>',
                  }"
                  >help_outline</i
                >
              </span>
              <b-button
                v-if="auth_modify"
                variant="outline-light"
                class="p-0 mr-3"
                v-b-modal.modal-addunit
                ><span class="material-icons">add</span></b-button
              >
              <b-modal
                id="modal-addunit"
                hide-footer
                body-class="p-0 pb-2"
                title="Theorieeinheit hinzufügen"
              >
                <div class="ml-3 mt-3 mb-4" v-if="filteredUnits.length == 0">
                  keine Theorieeinheiten gefunden
                </div>
                <b-list-group flush>
                  <b-list-group-item
                    href="#"
                    v-for="unit in filteredUnits"
                    :key="unit.id"
                    @click="
                      addUnit(unit.id).catch((err) => {
                        $swal({
                          html: `<span class='ml-2'><strong>Fehler beim Hinzufügen der Einheit:</strong> Thema ${unit.key}</span>`,
                          icon: `error`,
                          toast: true,
                          showConfirmButton: false,
                          timer: 3000,
                          position: `top-end`,
                        });
                        throw err;
                      });
                      $bvModal.hide('modal-addunit');
                    "
                    ><strong>Thema {{ unit.key }}</strong>
                    <div class="small">
                      {{ unit.name }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </b-modal>
            </h6>

            <small v-if="data.units.length == 0" class="mt-3 ml-3">
              beinhaltet keine Inhalte
            </small>
            <template v-else>
              <b-list-group flush>
                <b-list-group-item
                  href="#"
                  v-for="unit in selectedUnits"
                  :key="unit.id"
                  class="d-flex justify-content-between"
                  style="background-color: rgba(0, 0, 0, 0)"
                >
                  <div
                    class="pr-2"
                    @click="
                      $router.push({
                        name: 'unit',
                        params: { id: unit.id },
                      })
                    "
                  >
                    <strong>Thema {{ unit.key }}</strong>
                    <div class="small">{{ unit.name }}</div>
                  </div>
                  <div
                    class="my-auto"
                    v-if="auth_modify"
                    :id="'component-remUnit-' + unit.id"
                  >
                    <b-button
                      variant="outline-light"
                      class="p-1"
                      @click="remUnit(unit.id)"
                      :disabled="
                        !!selectedLessons.find((lesson) =>
                          lesson.units.find((unit_id) => unit_id == unit.id)
                        )
                      "
                      ><span class="material-icons">delete</span>
                    </b-button>
                    <b-popover
                      v-if="
                        selectedLessons.find((lesson) =>
                          lesson.units.find((unit_id) => unit_id == unit.id)
                        )
                      "
                      :target="'component-remUnit-' + unit.id"
                      triggers="hover"
                    >
                      Sie können diese Einheit
                      <strong>nicht entfernen</strong>, da diese in einem Termin
                      <strong>bereits unterrichtet</strong> wird.
                    </b-popover>
                  </div>
                </b-list-group-item>
              </b-list-group>
            </template>
          </b-col>
          <b-col
            class="mt-1   pr-5 ml-3"
            :style="!students || students.length == 0 ? 'opacity: 0.5;' : ''"
          >
            <h6 class="m-0">
              Teilnehmer
              <i
                class="material-icons"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Ihre Kunden können an Kursen teilnehmen:<ul><li>mit Nutzeraccount</li><li>mit E-Mail(ohne Registrierung)</li></ul>',
                }"
                >help_outline</i
              >
            </h6>
            <participation-manager
              class="ml-3"
              :participation="data.participation"
              :participators="participators"
              role="student"
              noEvents
              hideRole
              hideLabel
              hideRequest
              hideInvalid
              showRequestCount
            />
          </b-col>
          <b-col class="mt-1 pr-0 ml-3" v-if="false">
            <!-- Aktionen -->
            <!-- Component Header -->
            <h6 class="m-0">
              Aktionen
              <i
                class="material-icons"
                style="cursor: pointer"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Aktionen werden ausgeführt, wenn eine bestimmte Situation eintritt. Das kann zum Beispiel Beginn oder Ende einer Theoriestunde sein.<br>Sie haben hierdurch die Möglichkeit zum Beispiel automatisiert Informationsmaterial zu versenden.',
                }"
                >help_outline</i
              >
            </h6>
            <small class="ml-3">folgt in Kürze</small>
          </b-col>
        </b-col>
      </b-row>
    </template>
  </d-container>
</template>

<script>
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import ParticipationManager from "@/components/education/other_elements/ParticipationManager.vue";
import Lesson from "@/components/education/lesson/Lesson";
import { mapGetters } from "vuex";
import moment from "moment";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
export default {
  name: "CourseShow",
  components: {
    CategorySelector,
    Lesson,
    ParticipationManager,
  },
  data() {
    return {
      notFound: false,
      loading: null,
      auth_modify: false,
      id: this.$route.params.id,
      data: null,
      // TITLE
      edit_title: false,
      // MAIN
      edit: null, // Copy of data, which can altered by user
      edit_description: false,
      edit_date: false,
      edit_user: false,
      // MAIN - DATE
      allow_date_change_on_dependencies: false,
      edit_start_date: null,
      edit_start_time: null,
      edit_duration: null,
      // MAIN - UNITS
      search: "",
      // MAIN - LESSONS
      lessons: [],
      // CONFIGS
      DatePickerConfig,
      TimePickerConfig,
      // Participation
      participators: null,
    };
  },
  computed: {
    ...mapGetters({
      units: "units",
      selectedCategory: "selectedCategory",
    }),
    changes: function () {
      return this.edit_description || this.edit_date || this.edit_user;
    },
    filteredUnits() {
      const criteria = this.search.trim().toLowerCase();
      // Filter out already selected options
      const options = this.units.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.data.units.find((unit_id) => unit_id == option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    filteredLessons() {
      const criteria = this.search.trim().toLowerCase();
      // Filter out already selected options
      const options = this.lessons.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.data.lessons.find((lesson_id) => lesson_id == option.id) &&
          !option.units.find(
            (unit_id) => !this.data.units.find((id) => id == unit_id)
          )
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    selectedLessons() {
      return this.data.lessons
        ? this.lessons.filter((lesson) =>
            this.data.lessons.find((lesson_id) => lesson_id == lesson.id)
          )
        : [];
    },
    selectedUnits() {
      return this.data.units
        ? this.units.filter((unit) =>
            this.data.units.find((unit_id) => unit_id == unit.id)
          )
        : [];
    },
    // Participation
    organisators() {
      return this.participators
        ? this.participators.filter((el) => el.role == "organisator")
        : null;
    },
    instructors() {
      return this.participators
        ? this.participators.filter((el) => el.role == "instructor")
        : null;
    },
    students() {
      return this.participators
        ? this.participators.filter((el) => el.role == "student")
        : null;
    },
  },
  methods: {
    async init() {
      this.edit_title = false;
      this.edit_description = false;
      this.edit_date = false;
      this.edit_users = false;

      this.loading = true;
      // get data
      this.data = null;
      this.data = await this.$store
        .dispatch("getCourse", this.id)
        .catch((err) => {
          if (!err.message.includes("NOT_FOUND")) throw err;
          // notFound
          this.notFound = true;
          this.loading = false;
        });
      this.edit = { ...this.data };
      this.resetEditDate();
      await Promise.all([
        (async () => {
          this.participators = await this.$store.dispatch(
            "getParticipatorsCourse",
            {
              id: this.id,
            }
          );
        })(),
        this.updateLessons(),
      ]);
      if (!this.data) return;
      this.$store.dispatch("updateSelectedCategory", this.data.category);
      this.loading = false;
    },
    async updateLessons() {
      this.lessons = await this.$store.dispatch("getLessons", {
        min_start_date: this.data.date.start,
        max_end_date: this.data.date.end,
        allow_closed_course_dependencies: true,
      });
      await Promise.all(
        this.data.lessons.map((lesson_id) => {
          if (this.lessons.find((lesson) => lesson.id == lesson_id)) return;
          console.warn(
            `Requested Lesson ${lesson_id} was not found in course-period`
          );
          return (async () => {
            this.lessons.push(
              await this.$store.dispatch("getLesson", lesson_id)
            );
          })();
        })
      );
    },
    async handleEditTitle() {
      if (
        !this.data.processed_unit_name &&
        (this.edit.name == null || this.edit.name.trim() == "")
      )
        return;
      await this.$store.dispatch("updateCourse", {
        id: this.data.id,
        name: this.edit.name,
      });
      await this.init();
    },
    async handleSave() {
      // Check Dependencies
      // Kurs ist außerhalb vorheriger Theoriestunden
      // Update Course
      let newCourse = await this.$store
        .dispatch("updateCourse", {
          id: this.id,
          description: this.edit.description,
          date: {
            start: this.edit.date.start,
            end: this.edit.date.end,
          },
        })
        .catch((err) => {
          this.init();
          throw err;
        });
      await this.updateLessons();
      this.data = newCourse;
      this.edit_title = false;
      this.edit_description = false;
      this.edit_date = false;
    },
    resetEditDate() {
      let start = moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss");
      this.edit_start_date = start.format("YYYY-MM-DD");
      this.edit_start_time = start.format("HH:mm:ss");
      this.edit_duration = this.data.date.duration;
    },
    async checkEditDateDependencies() {
      if (!this.dependencies) return true;
      let require_date_change_on_dependencies = false;
      this.dependencies.courses.forEach((course) => {
        // Kurs-Anfang is nach Theoriestunden-Anfang
        if (
          moment(course.date.start, "YYYY-MM-DD").isAfter(
            moment(this.edit_date_start, "YYYY-MM-DD HH:mm:ss")
          )
        )
          require_date_change_on_dependencies = true;
        // Kurs-Ende ist vor Theoriestunden-Ende
        if (
          moment(course.date.end, "YYYY-MM-DD").isBefore(
            moment(this.edit_date_end, "YYYY-MM-DD HH:mm:ss")
          )
        )
          require_date_change_on_dependencies = true;
      });
      if (!require_date_change_on_dependencies) return true;
      let result = await this.$swal.fire({
        title: "Diese Änderung ist nicht zulässig",
        text:
          "Auf Grund einer verknüpften Theoriestunde, kann diese Änderung nicht durchgeführt werden.",
        showCancelButton: true,
        confirmButtonText: `Theoriestunden löschen`,
        cancelButtonText: `Abbrechen`,
      });
      if (result.isConfirmed) {
        this.allow_date_change_on_dependencies = true;
        return true;
      }
      return false;
    },
    async addUnit(unit_id) {
      let newUnits = [...this.data.units, unit_id];
      let newCourse = await this.$store.dispatch("updateCourse", {
        id: this.id,
        units: newUnits,
      });
      if (!newCourse) return false;
      this.data.units = newCourse.units;
      return true;
    },
    async remUnit(unit_id) {
      let unit = this.units.find((unit) => unit.id == unit_id);
      if (!unit) return;
      let newCourse = await this.$store.dispatch("updateCourse", {
        id: this.id,
        units: this.data.units.filter((id) => id != unit_id),
      });
      if (!newCourse) return;
      this.data.units = newCourse.units;
    },
    async handleFinishNewLesson(data) {
      delete data.id;
      let lessons = [...this.data.lessons, data];
      let newCourse = await this.$store.dispatch("updateCourse", {
        id: this.id,
        lessons,
      });
      if (!newCourse) return;
      this.data.lessons = newCourse.lessons;
      this.$refs["modal-newLesson"].hide();
      this.$refs["component-newLesson"].init();
      this.updateLessons();
    },
    async addLesson(lesson_id) {
      let lessons = [...this.data.lessons, lesson_id];
      let newCourse = await this.$store.dispatch("updateCourse", {
        id: this.id,
        lessons,
      });
      if (!newCourse) return;
      this.data.lessons = newCourse.lessons;
    },
    async remLesson(lesson_id) {
      let lesson = this.lessons.find((lesson) => lesson.id == lesson_id);
      if (!lesson) return;
      let result = await this.$swal({
        title: "Wollen Sie diese Stunde wirklich vom Kurs entfernen?",
        html: `<small>${
          lesson.name
            ? lesson.name
            : lesson.processed_unit_name
            ? lesson.processed_unit_name
            : "Achtung: kein Name"
        }</small><br><br>${
          this.data.type == "closed"
            ? "<strong>Achtung: </strong>Hierdurch werden alle Daten zu dieser Theoriestunde gelöscht"
            : "<strong>Achtung: </strong>Hierdurch wird die Theoriestunde lediglich vom Kurs entfernt, jedoch nicht gelöscht."
        }`,
        showConfirmButton: true,
        confirmButtonText: "Ja",
        showCancelButton: true,
        cancelButtonText: "Abbrechen",
      });
      if (!result.isConfirmed) return;
      let newCourse = await this.$store.dispatch("updateCourse", {
        id: this.id,
        lessons: this.data.lessons.filter((id) => id != lesson_id),
      });
      if (!newCourse) return;
      this.data.lessons = newCourse.lessons;
    },
    async handleCancel() {
      let result = await this.$store
        .dispatch("cancelCourse", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.canceled = true;
      this.edit.canceled = true;
    },
    async handleReactivate() {
      let result = await this.$store
        .dispatch("reactivateCourse", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.canceled = false;
      this.edit.canceled = false;
    },
    async handlePostpone() {
      let result = await this.$store
        .dispatch("postponeCourse", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.postponed = true;
      this.edit.postponed = true;
    },
    async handleMarkPostpone() {
      await this.$store
        .dispatch("updateCourse", { id: this.data.id, postponed: true })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.postponed = true;
      this.edit.postponed = true;
    },
    async handleNotMarkPostpone() {
      await this.$store
        .dispatch("updateCourse", { id: this.data.id, postponed: false })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.data.postponed = false;
      this.edit.postponed = false;
    },
    async handleRemove() {
      let result = await this.$store
        .dispatch("removeCourse", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.$router.push({ name: "courses" });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "course_modify"
    );
    await this.init();
  },
};
</script>
