import Error404 from '@/views/Error404.vue';
import Report from '@/views/Report.vue';
export default [
  {
    path: '/support',
    name: 'support',
    component: Error404,
    meta: {
      title: 'Hilfe und Support',
      description: 'Einstellungen und Voreinstellungen',
      material_icon: 'work',
      tags: [
        'support',
        'more',
        'mehr',
        'weiteres',
        'help',
        'anleitungen',
        'Fehler melden',
        'Dokumentation',
        'wie',
      ],
      shortcuts: [
      ]
    }
  },

  {
    path: '/support/report',
    name: 'report',
    component: Report,
    meta: {
      title: 'Feedback / Error melden',
      description: 'Hier können Sie einen Fehler melden und so zur Verbesserung der Software beitragen',
      material_icon: 'home',
      tags: [
        'error',
        'fehler',
        'hilfe',
        'melden',
        'kaputt',
        'falsch',
      ],
      shortcuts: [
        'err',
        'error'
      ]
    }
  }
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "more";
  return el;
});
