<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-form-checkbox
            :checked="!!participation_available"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'participation_available',
                  value: !!val,
                })
            "
            disabled
          >Teilnahmesystem aktiviert</b-form-checkbox>


          <h4>Stunden</h4>
          <b-form-checkbox
              :checked="!!lesson_default_allow_participation"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'lesson_default_allow_participation',
                    value: !!val,
                  }).then(() => lesson_default_allow_participation = !!val)
              "
            >Standardmäßig aktiv</b-form-checkbox>
          <b-form-checkbox
              :checked="!!lesson_default_register_mode_direct"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'lesson_default_register_mode_direct',
                    value: !!val,
                  })
              "
              :disabled="!lesson_default_allow_participation"
              class="ml-1"
            >Kunden können standardmäßig direkt teilnehmen</b-form-checkbox>
          <b-form-checkbox
              :checked="!!lesson_default_register_mode_request"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'lesson_default_register_mode_request',
                    value: !!val,
                  })
              "
              :disabled="!lesson_default_allow_participation"
              class="ml-1"
            >Kunden können standardmäßig die Teilnahme anfragen</b-form-checkbox>
          <b-form-group label="Standard Teilnehmeranzahl">
            <div class="d-flex">
              <b-form-checkbox
                :checked="!!lesson_default_max_participators"
                @change="
                  (val) =>
                    $store.dispatch('setSetting', {
                      name: 'lesson_default_max_participators',
                      value: val ? ($store.getters.settings_obj.lesson_default_max_participators.value.default || 5) : null,
                    }).then(() => lesson_default_max_participators = val ? ($store.getters.settings_obj.lesson_default_max_participators.value.default || 5) : null)
                "
                :disabled="!lesson_default_allow_participation"
              />
              <b-input-group append="Teilnehmer">
                <b-form-input
                  size="sm"
                  :value="lesson_default_max_participators"
                  :disabled="lesson_default_max_participators === null"
                  @change="
                    (val) =>
                      $store.dispatch('setSetting', {
                        name: 'lesson_default_max_participators',
                        value: parseInt(val),
                      })
                  "
                  min="1"
                  type="number"
                  placeholder="Bitte zunächst aktivieren"
                  debounce="2000"
                />
              </b-input-group>
            </div>
          </b-form-group>


          <h4>Kurse</h4>
          <b-form-checkbox
              :checked="!!course_default_allow_participation"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'course_default_allow_participation',
                    value: !!val,
                  }).then(() => course_default_allow_participation = !!val)
              "
            >Standardmäßig aktiv</b-form-checkbox>
          <b-form-checkbox
              :checked="!!course_default_register_mode_direct"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'course_default_register_mode_direct',
                    value: !!val,
                  })
              "
              :disabled="!course_default_allow_participation"
              class="ml-1"
            >Kunden können standardmäßig direkt teilnehmen</b-form-checkbox>
          <b-form-checkbox
              :checked="!!course_default_register_mode_request"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'course_default_register_mode_request',
                    value: !!val,
                  })
              "
              :disabled="!course_default_allow_participation"
              class="ml-1"
            >Kunden können standardmäßig die Teilnahme anfragen</b-form-checkbox>
          <b-form-group label="Standard Teilnehmeranzahl">
            <div class="d-flex">
              <b-form-checkbox
                :checked="!!course_default_max_participators"
                @change="
                  (val) =>
                    $store.dispatch('setSetting', {
                      name: 'course_default_max_participators',
                      value: val ? ($store.getters.settings_obj.course_default_max_participators.value.default || 5) : null,
                    }).then(() => course_default_max_participators = val ? ($store.getters.settings_obj.course_default_max_participators.value.default || 5) : null)
                "
                :disabled="!course_default_allow_participation"
              />
              <b-input-group append="Teilnehmer">
                <b-form-input
                  size="sm"
                  :value="course_default_max_participators"
                  :disabled="course_default_max_participators === null"
                  @change="
                    (val) =>
                      $store.dispatch('setSetting', {
                        name: 'course_default_max_participators',
                        value: parseInt(val),
                      })
                  "
                  min="1"
                  type="number"
                  placeholder="Bitte zunächst aktivieren"
                  debounce="2000"
                />
              </b-input-group>
            </div>
          </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { addSetting } from '../used_settings';
addSetting([
  "participation_available",
  "lesson_default_allow_participation",
  "lesson_default_max_participators",
  "lesson_default_register_mode_direct",
  "lesson_default_register_mode_request",
  "course_default_allow_participation",
  "course_default_max_participators",
  "course_default_register_mode_direct",
  "course_default_register_mode_request",
]);export default {
  data() {
    return {
      participation_available: null,
      participation_allow_partcipator_rerequest: null,
      course_default_allow_participation: null,
      course_default_max_participators: null,
      course_default_register_mode_direct: null,
      course_default_register_mode_request: null,
      lesson_default_allow_participation: null,
      lesson_default_max_participators: null,
      lesson_default_register_mode_direct: null,
      lesson_default_register_mode_request: null,
    };
  },
  methods: {
    refresh() {
      // General
      this.participation_available=this.$store.getters.settings_obj.participation_available.value.current;
      this.participation_allow_partcipator_rerequest=this.$store.getters.settings_obj.participation_allow_partcipator_rerequest.value.current;

      // Lessons
      this.lesson_default_allow_participation=this.$store.getters.settings_obj.lesson_default_allow_participation.value.current;
      this.lesson_default_max_participators=this.$store.getters.settings_obj.lesson_default_max_participators.value.current;
      this.lesson_default_register_mode_direct=this.$store.getters.settings_obj.lesson_default_register_mode_direct.value.current;
      this.lesson_default_register_mode_request=this.$store.getters.settings_obj.lesson_default_register_mode_request.value.current;

      // Courses
      this.course_default_allow_participation=this.$store.getters.settings_obj.course_default_allow_participation.value.current;
      this.course_default_max_participators=this.$store.getters.settings_obj.course_default_max_participators.value.current;
      this.course_default_register_mode_direct=this.$store.getters.settings_obj.course_default_register_mode_direct.value.current;
      this.course_default_register_mode_request=this.$store.getters.settings_obj.course_default_register_mode_request.value.current;
    }
  },
  async created() {
    this.refresh();
  },
};
</script>
