var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card.description),expression:"settings_obj.payment_card.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_vm._v(" Kartenzahlung erlaubt: "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":"","checked":_vm.settings_obj.payment_card.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'payment_card',
            value: !!val,
          }); }}})],1),_c('b-collapse',{staticClass:"ml-2 small",model:{value:(_vm.settings_obj.payment_card.value.current),callback:function ($$v) {_vm.$set(_vm.settings_obj.payment_card.value, "current", $$v)},expression:"settings_obj.payment_card.value.current"}},[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card_giro.description),expression:"settings_obj.payment_card_giro.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex font-weight-bold mt-2"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_giro.value.current,"switch":""},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_card_giro',
              value: !!val,
            }); }}}),_vm._v(" Giro / EC-Karte ")],1),_c('div',{staticClass:"d-flex font-weight-bold mt-2"},[_c('b-form-checkbox',{attrs:{"switch":"","checked":_vm.settings_obj.payment_card_amex.value.current ||
          _vm.settings_obj.payment_card_mastercard.value.current ||
          _vm.settings_obj.payment_card_visa.value.current,"disabled":""}}),_vm._v(" Kreditkarten ")],1),_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card_amex.description),expression:"settings_obj.payment_card_amex.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"ml-5"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_amex.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_card_amex',
              value: !!val,
            }); }}}),_vm._v(" American Express ")],1),_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(
        _vm.settings_obj.payment_card_mastercard.description
      ),expression:"\n        settings_obj.payment_card_mastercard.description\n      ",modifiers:{"bottom":true,"hover":true}}],staticClass:"ml-5"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_mastercard.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_card_mastercard',
              value: !!val,
            }); }}}),_vm._v(" MasterCard ")],1),_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card_visa.description),expression:"settings_obj.payment_card_visa.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"ml-5"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_visa.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_card_visa',
              value: !!val,
            }); }}}),_vm._v(" Visa ")],1),_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(
        _vm.settings_obj.payment_card_contactless.description
      ),expression:"\n        settings_obj.payment_card_contactless.description\n      ",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex font-weight-bold mt-2"},[_c('b-form-checkbox',{attrs:{"switch":"","checked":_vm.settings_obj.payment_card_contactless.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_card_contactless',
              value: !!val,
            }); }}}),_vm._v(" Kontaktloses Zahlen ")],1),_c('b-collapse',{staticClass:"ml-5",model:{value:(_vm.settings_obj.payment_card_contactless.value.current),callback:function ($$v) {_vm.$set(_vm.settings_obj.payment_card_contactless.value, "current", $$v)},expression:"settings_obj.payment_card_contactless.value.current"}},[_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(
          _vm.settings_obj.payment_card_google.description
        ),expression:"\n          settings_obj.payment_card_google.description\n        ",modifiers:{"bottom":true,"hover":true}}]},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_google.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_card_google',
                value: !!val,
              }); }}}),_vm._v(" Google Pay ")],1),_c('b-input-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card_apple.description),expression:"settings_obj.payment_card_apple.description",modifiers:{"bottom":true,"hover":true}}]},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_apple.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_card_apple',
                value: !!val,
              }); }}}),_vm._v(" Apple Pay ")],1)],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_card_min.description),expression:"settings_obj.payment_card_min.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"mt-2",attrs:{"label":"Mindestumsatz"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.payment_card_min.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_card_min',
                value: val ? '50 €' : null,
              }); }}}),_c('b-input-group',{attrs:{"append":"€"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_card_min.value.current,"disabled":_vm.settings_obj.payment_card_min.value.current === null,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'payment_card_min',
                  value:
                    (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                }); }}})],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }