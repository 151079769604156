<template>
  <d-card class="card-small">
    <!-- Component Header -->
    <d-card-header class="border-bottom">
      <h6 class="m-0">Ankündigung: {{ type ? type.label : '' }}</h6>
    </d-card-header>

    <d-card-body class="d-flex flex-column">
      <div v-if="!loaded" class="text-center p-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Lädt...</span>
        </div>
      </div>
      <div v-else-if="!type">
        Die Ankündigung {{ type_name }} konnte nicht gefunden werden.
      </div>
      <div v-else>
        <div class="quick-post-form">
          <!-- Text -->
          <b-list-group v-if="filteredAnnouncements.length > 0" flush>
            <b-list-group-item
              class="p-0"
              style="cursor: pointer"
              v-for="announcement in filteredAnnouncements"
              :key="announcement.id"
              @click="
                $router.push({
                  name: 'announcement',
                  params: { id: announcement.id },
                })
              "
            >
              <div
                class="border-left pl-4"
                style="border-width: 4px !important"
                :style="
                  announcement.status == 'public' &&
                  moment(
                    announcement.start_date,
                    'YYYY-MM-DD HH:mm:ss'
                  ).isBefore(moment()) &&
                  moment(announcement.end_date, 'YYYY-MM-DD HH:mm:ss').isAfter(
                    moment()
                  ) // & aktuell
                    ? 'border-color: #007bff !important;'
                    : announcement.status == 'public' // & vergangen oder in der Zukunft
                    ? 'border-color: #abd3ff !important;'
                    : ''
                "
              >
                <div v-if="announcement.title">
                  {{ announcement.title }}
                </div>
                <div
                  class="small text-muted"
                  v-html="announcement.content.plain"
                ></div>
              </div>
            </b-list-group-item>
          </b-list-group>
          <div v-else class="ml-1">keine Ankündigungen vorhanden</div>

          <!-- Create Draft -->
          <div class="mt-3">
            <d-button
              class="btn-accent m-1"
              @click="
                $router.push({
                  name: 'new_announcement',
                  query: { types: [type.name] },
                })
              "
              >Hinzufügen</d-button
            >
            <d-button
              class="btn-light m-1"
              @click="$router.push({ name: 'announcements' })"
              >Alle Anzeigen</d-button
            >
          </div>
        </div>
      </div>
    </d-card-body>
  </d-card>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
export default {
  name: "AnnouncementCard",
  props: {
    /**
     * The component's type.
     */
    type_name: {
      type: String,
      required: true,
      default: "global",
    },
  },
  data() {
    return {
      loaded: null,
      moment,
    };
  },
  computed: {
    ...mapGetters(["announcement_types", "announcements"]),
    type() {
      return this.announcement_types.find(
        (type) => this.type_name == type.name
      );
    },
    filteredAnnouncements() {
      return this.announcements.filter((announcement) =>
        announcement.types.find((type_name) => type_name == this.type_name)
      );
    },
  },
  async created() {
    await Promise.all([
      (async () => {
        this.auth_modify = await this.$store.dispatch(
          "haveUserRight",
          "announcements_modify"
        );
      })(),
      this.$store.dispatch("initAnnouncementModule"),
    ]);
    this.loaded = true;
  },
};
</script>
