import Error404 from '@/views/Error404.vue';
export default [
  {
    path: '/users',
    name: 'user-management',
    component: Error404,
    meta: {
      title: 'Nutzerverwaltung',
      description: 'Generelle Verwaltung aller im System registrierten Benutzer',
      material_icon: 'work',
      tags: [
        'user',
        'more',
        'mehr',
        'weiteres',
        'management',
        'rights',
        'roles',
        'Berechtigungen',
        'Rollen',
      ]
    }
  }
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "more";
  return el;
});
