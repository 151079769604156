<template>
  <b-container fluid class="main-content-container px-4">
    <b-row no-gutters class="page-header py-4">
      <b-col cols="12" sm="8" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weiteres</span>
        <h3 class="page-title">Kalenderlinkgenerator</h3>
      </b-col>
      <b-col cols="12" sm="4" class="text-center text-sm-right">
        <b-button variant="light" v-b-modal.help_modal
          ><i class="material-icons">info</i> Hilfe</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="p-0 p-md-3 mb-4">
        <b-card class="card-small">
          Hier können Sie einen Link zur Einbindung in einen externen Kalender erstellen:
          <b-form-select
            v-model="calendar_type"
            :options="calendar_types"
            class="mb-3"
          ></b-form-select>
          <template v-if="calendar_type">
            <template v-if="calendar_type == 1">
              <b-container fluid>
                <b-row>
                  <b-col cols="6">
                    <b-form-group label="Auf Unterrichtskategorie einschränken">
                      <b-form-checkbox
                        v-for="(category, i) in selected_categories"
                        :key="category.name"
                        v-model="selected_categories[i].selected"
                        >{{ category.title }}</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Auf Filiale einschränken">
                      <b-form-checkbox
                        v-for="(branch, i) in selected_branches"
                        :key="branch.id"
                        v-model="selected_branches[i].selected"
                        >{{ branch.name }}</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group label="Weiteres">
                      <b-form-checkbox v-model="noLessons"
                        >keine Unterrichtsstunden</b-form-checkbox
                      >
                      <b-form-checkbox v-model="noCourses">keine Kurse</b-form-checkbox>
                      <b-form-checkbox v-model="admin">Verlinkung zur Adminoberfläche</b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-input-group>
                      <b-form-input
                        readonly
                        v-model="calendar_link"
                        v-clipboard="calendar_link"
                        v-b-popover.hover.bottom="'Klicken zum Kopieren'"
                        style="cursor: pointer;"
                      ></b-form-input>
                      <b-input-group-append>
                        <b-button
                          variant="outline-dark"
                          v-clipboard="calendar_link"
                          ><i class="material-icons">content_copy</i></b-button
                        >
                      </b-input-group-append>
                    </b-input-group>
                    <b-button variant="link" :href="`https://larrybolt.github.io/online-ics-feed-viewer/#feed=${calendar_link}&cors=true&title=${company.name}&hideinput=true`" target="_blank">Vorschau</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </template>
            <template v-if="calendar_type == 2 || calendar_type == 3">
              <b-form-input v-model="user_id" :readonly="calendar_type == 2" />
              <b-input-group>
                <b-input-group-prepend>
                  <b-button variant="outline-success">Link erstellen</b-button>
                </b-input-group-prepend>
                <b-form-input
                  readonly
                  v-model="user_link"
                  placeholder="Link muss erstellt werden."
                  v-clipboard="calendar_link"
                  v-b-popover.hover.bottom="'Klicken zum Kopieren'"
                  style="cursor: pointer;"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    variant="outline-dark"
                    :disabled="!user_link"
                    v-clipboard="user_link"
                    ><i class="material-icons">content_copy</i></b-button
                  >
                </b-input-group-append>
              </b-input-group>
              <b-button variant="link" :href="`https://larrybolt.github.io/online-ics-feed-viewer/#feed=${user_link}&cors=true&title=${company.name}&hideinput=true`" target="_blank">Vorschau</b-button>
            </template>
          </template>
        </b-card>
      </b-col>
    </b-row>
    <b-modal title="Hilfe" id="help_modal" size="lg" hide-footer hide-header>
      <h3>Linkgenerator und Einbindung in externe Kalender</h3>
      <h4 class="pt-0">Globale Kalender</h4>
      Standardmäßig beinhaltet ein globaler Kalender alle Unterrichtsstunden und
      Kurse aus allen Unterrichtssystemen. Sie haben jedoch die Möglichkeit
      diese Auswahl einzuschränken. <br />
      Anwendungsmöglichkeiten:
      <ul>
        <li>mit allen Unterrichtsstunden und Kursen</li>
        <li>für ein bestimmtes Unterrichtssystem</li>
        <li>für eine bestimmte Filiale</li>
      </ul>

      <h4>Personenbezogene Kalender</h4>
      Personenbezogende Kalender zeigen standardmäßig alle relevanten
      Unterrichtsstunden und Kurse aus allen Unterrichtssystemen, an welchen die
      Person in irgendeiner Art teilnimmt / teilgenommen hat, an. Sie können
      hier ebenfalls Einschränkungen oder Erweiterungen vornehmen. <br />
      <strong>Hinweis:</strong> Zur Erstellung von personenbezogenen Kalendern
      wird das Recht user_calendar benötigt.<br /><br />

      <h4>Einbindung am Beispiel Outlook</h4>
      <div>
        Im folgenden Abschnitt werden Ihnen die Schritte zur Einbindung eines
        Kalenders in das Programm Outlook 365 gezeigt. Bitte beachten Sie, dass
        die Einbindung in ein anderes Programm von der hier beschriebenen
        Vorgehensweise abweichen kann.
      </div>
      <ol>
        <li>
          Erstellen Sie sich einen Link zum Abruf des von Ihnen gewünschten
          Kalenders.
        </li>
        <li>
          Klicken Sie in der Kalenderansicht mit Rechtsklick auf eine
          Kalendergruppe und im Anschluss auf die Schaltfläche Internetkalender
          hinzufügen. <br />Alternativ können Sie Internetkalender wie folgt
          verwalten: <br /><strong
            >Datei -> Kontoeinstellungen -> Internetkalender -> Neu</strong
          >
        </li>
        <li>
          Fügen Sie hier den obigen Link ein und bearbeiten Sie ggf. die
          Einstellungen für den Kalender.<br /><span class="text-danger"
            ><strong>Wichtig:</strong> Bitte bestätigen Sie das Abonnement für
            Aktualisierungen, damit Ihr Kalender auf dem neuesten Stand
            bleibt.</span
          >
        </li>
      </ol>
      <br /><small
        >Wenn Sie Fragen hierzu haben sprechen Sie uns gerne an. <br />Wir
        unterstützen bei Bedarf.</small
      >
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "calendar_configurator",
  data() {
    return {
      auth_pers: null,
      calendar_type: null,
      user_link: null,
      selected_branches: [],
      selected_categories: [],
      noLessons: false,
      noCourses: false,
      admin: true
    };
  },
  methods: {
    reset() {
      this.selected_branches = JSON.parse(JSON.stringify(this.branches)).map(
        (el) => {
          el.selected = true;
          return el;
        }
      );
      this.selected_categories = JSON.parse(JSON.stringify(this.categories)).map(
        (el) => {
          el.selected = true;
          return el;
        }
      );
      this.noLessons = false;
      this.noCourses = false;
      this.admin = true;
    },
  },
  computed: {
    ...mapGetters(["company", "branches", "categories"]),
    calendar_types() {
      return [
        { text: "Kalendertyp wählen", value: null },
        { text: "Globaler Kalender", value: 1 },
        { text: "Persönlicher Kalender", value: 2, disabled: true},
        {
          text: "Personenbezogener Kalender",
          value: 3,
          disabled: true || !this.auth_pers,
        },
      ];
    },
    calendar_link() {
        let query = "";
        if (this.selected_branches.find(el => !el.selected)) {
          if (query.length==0) query='?';
          let selected_branches = this.selected_branches.filter(el => el.selected)
          if (selected_branches.length==0) (query=='?') ? query+=`branch_0` : query+=`&branch_0`;
          selected_branches.forEach(selected_branch => (query=='?') ? query+=`branch_${selected_branch.id}` : query+=`&branch_${selected_branch.id}`);
        }
        if (this.selected_categories.find(el => !el.selected)) {
          if (query.length==0) query='?';
          let selected_categories = this.selected_categories.filter(el => el.selected);
          if (selected_categories.length==0) (query=='?') ? query+=`category_false` : query+=`&category_false`;
          selected_categories.forEach(selected_category => (query=='?') ? query+=`category_${selected_category.name}` : query+=`&category_${selected_category.name}`);
        }
        if (this.noLessons) {
          if (query.length==0) query='?';
          (query=='?') ? query+=`lesson_0` : query+=`&lesson_0`;
        }
        if (this.noCourses) {
          if (query.length==0) query='?';
          (query=='?') ? query+=`course_0` : query+=`&course_0`;
        }
        if (this.admin) {
          if (query.length==0) query='?';
          (query=='?') ? query+=`admin` : query+=`&admin`;
        }
        return `https://fahrschule-api.de/${this.$store.getters.company.id}/calendar/ical${query}`;
      },
  },
  async created() {
    this.auth_pers = await this.$store.dispatch(
      "haveUserRight",
      "user_calendar_link"
    );
    this.reset();
  },
};
</script>
