<template>
  <div class="mb-2">
    <h4 v-b-toggle.settings-payment-collapse>Zahlungsmethoden</h4>
    <b-collapse id="settings-payment-collapse" v-model="value">
      <PaymentAll />
    </b-collapse>
  </div>
</template>

<script>
import PaymentAll from "../../../components/payment/PaymentAll.vue";
import { addSetting } from "../used_settings";
addSetting([
  "payment_bar",
  "payment_bar_max",
  "payment_card",
  "payment_card_amex",
  "payment_card_apple",
  "payment_card_contactless",
  "payment_card_giro",
  "payment_card_google",
  "payment_card_mastercard",
  "payment_card_min",
  "payment_card_visa",
  "payment_credit",
  "payment_credit_advertisement_text",
  "payment_credit_bank",
  "payment_credit_bank_link",
  "payment_transfer",
  "payment_transfer_bank_name",
  "payment_transfer_bic",
  "payment_transfer_iban",
  "payment_transfer_text",
]);

export default {
  components: { PaymentAll },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: false,
    }
  },
  created() {
    this.value = this.visible;
  }
};
</script>

<style>
</style>
