import Vue from 'vue';
import API from '@/plugins/api.js';
import APIMethod from '@/plugins/apimethod';
import GlobalMethod from '@/plugins/globals';
// import manifest from '../../public/manifest.json';

Vue.use(API);
Vue.use(APIMethod);
Vue.use(GlobalMethod);

// eslint-disable-next-line
const app = new Vue(); // use the store var for request api
function available() {
  return ('Notification' in window);
}
function checkNotificationPromise() {
  // Check if Browser supports promise based ord callback based request
  try {
    Notification.requestPermission().then();
  } catch (e) {
    return false;
  }
  return true;
}

function handlePermission(permission) {
  // Whatever the user answers, we make sure Chrome stores the information
  if (!('permission' in Notification)) {
    Notification.permission = permission;
  }
}

export default {
  available() { return available(); },
  activated() {
    if (!(available())) return false;
    return (Notification.permission === 'granted');
  },
  activate(sendTest = true) {
    if (!(available())) { throw new Error('NOT_SUPPORTED - This browser does not support notifications.'); }

    return new Promise((resolve) => {
      if (checkNotificationPromise()) {
        Notification.requestPermission()
          .then((permission) => {
            handlePermission(permission);
            if (sendTest) this.test();
            resolve(permission);
          });
      } else {
        Notification.requestPermission((permission) => {
          handlePermission(permission);
          if (sendTest) this.test();
          resolve(permission);
        });
      }
    }).then((permission) => {
      app.$apimethod.push_registration();
      return permission;
    });
  },
  test() {
    if (!(available())) { throw new Error('NOT_SUPPORTED - This browser does not support notifications.'); }
    this.sendNotification({ title: 'Test', text: `Dies is eine Testbenachichtigung von ${manifest.name}` });
  },
  sendNotification({
    title,
    text,
    tag,
    onClick = function () {},
  } = {}, windowFokus = null) {
    if (!(available())) { throw new Error('NOT_SUPPORTED - This browser does not support notifications.'); }
    if (Notification.permission !== 'granted') throw new Error('NO_PERMISSION - You first need to grand permissions to use this method');
    if (onClick && typeof onClick !== 'function') throw new Error('INVALID_PARAMETER(onClicked) - onClicked have to be Type of Function.');
    switch (windowFokus) {
      case null:
        break;
      case true:
        if (!(document.hasFocus())) return;
        break;
      case false:
        if (document.hasFocus()) return;
        break;
      default:
        throw new Error('INVALID_PARAMETER(windowFokus) - The parameter have to be boolean or null');
    }
    const n = new Notification(title, {
      body: text,
      icon: null, // TODO load icon from manifest
      tag,
    });
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        // The tab has become visible so clear the now-stale Notification.
        n.close();
      }
    });
    n.onclick = function (event) {
      onClick();
    };
  },
};
