<template>
  <footer class="main-footer d-flex p-2 px-3 bg-white border-top">
    <div :class="[contained ? 'container' : 'container-fluid']">
      <div class="row">
        <ul class="nav">
          <li v-for="(item, idx) in menuItems" :key="idx" class="nav-item">
            <b-link class="nav-link" :to="(item.to) ? item.to : null" :href="(item.href) ? item.href : null">{{ item.title }}</b-link>
          </li>
        </ul>
        <span class="copyright ml-auto my-auto mr-">
          <a class="text-secondary" href="https://wer-ner.de/" target="_blank">Copyright © {{ copyright }} WER-NER.de</a>
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "main-footer",
  props: {
    /**
     * The copyright information.
     */
    copyright: {
      type: Number,
      default: new Date().getFullYear()
    },
    /**
     * Whether the footer should be wrapped in a container, or not.
     */
    contained: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      company: "company"
    }),
    menuItems() {
      var menuItems = [];
      if (this.company && this.company.website)
        menuItems.push({
          title: "Eigene Webseite",
          href: this.company.website
        });
      menuItems.push({
          title: "Feedback / Fehler melden",
          to: { name: "report" }
        });
      return menuItems;
    }
  }
};
</script>
