<template>
  <b-container fluid>
    <b-row v-if="auth_modify">
      <b-col class="text-center">
        <b-button-group>
          <b-button @click="$router.push({ name: 'unit_new' })" class="pr-2">
            <i class="material-icons">add</i> Hinzufügen
            <i class="material-icons ml-3">double_arrow</i>
          </b-button>
        </b-button-group>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Suche..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      hover
      ref="unit_table"
      show-empty
      small
      :busy.sync="loading"
      :items="items"
      :fields="fields"
      :filter="filter"
      selectable
      @row-selected="onRowSelected"
      select-mode="single"
    >
      <template v-slot:cell(duration)="row">
        <template v-if="row.item.duration">
          {{ row.item.duration.substr(0,5) }}
        </template>
        <div v-else>keine Angabe</div>
      </template>
      <!-- Error's -->
      <template v-slot:empty>
        <div
          v-if="loading !== null"
          class="text-center mt-2 small"
          @click="
            selectedCategory == null
              ? null
              : $store.dispatch('updateSelectedCategory', null)
          "
        >
          <template v-if="selectedCategory == null">
            Es sind keine Inhalte definiert...
          </template>
          <template v-else>
            Es wurden leider keine Inhalte für die Kategorie
            <strong>{{
              categories.find((elem) => elem.name == selectedCategory).title
            }}</strong>
            gefunden...
            <br />
            <br />
            <small>Hier klicken für <strong>Alle Kategorien</strong></small>
            <br />
          </template>
        </div>
        <div v-else class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:emptyfiltered>
        <div class="text-center mt-2 small">
          Keine Theorieeinheit für diese Filtereinstellung gefunden...
        </div>
      </template>
    </b-table>
    <small>Es werden max. 100 Einträge angezeigt</small>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

export default {
  name: "LessonOverview",
  props: {
    /**
     * The component's type.
     */
  },
  data() {
    return {
      loading: null,
      auth_modify: null,
      selected: [],
      totalRows: 1,
      filter: null,
      items: [],
    };
  },
  computed: {
    ...mapGetters(["branches", "units", "selectedCategory", "categories"]),
    fields: function () {
      return [
        {
          key: "key",
          label: "",
          sortable: true,
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "duration",
          label: "Dauer",
          sortable: true,
        },
      ];
    },
  },
  watch: {
    selectedCategory: function () {
      this.init();
    },
  },
  methods: {
    async init() {
      if (this.loading !== null) this.loading = true;
      let units = await this.$store.dispatch("getUnits", {
        category: this.selectedCategory,
      });
      this.totalRows = units.length;
      this.loading = false;
      this.items = units;
    },
    onRowSelected(items) {
      if (items[items.length - 1]) this.view(items[items.length - 1].id);
    },
    view(unit_id) {
      this.$router.push({ name: "unit", params: { id: unit_id } });
    },
    async remove(row) {
      let result = await Swal.fire({
        title: "Wollen Sie diese Theoriestunde wirklich löschen?",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Bestätigen",
        cancelButtonText: "Abbrechen",
      });
      if (!result.value) return;
      await this.$store
        .dispatch(`removeLesson`, {
          id: row.item.id,
        })
        .then((response) => {
          this.$refs.unit_table.refresh();
          this.$globalmethod.toast_success("Erfolgreich gelöscht.");
        })
        .catch((error) => {
          this.$apimethod.defaulthandler(error);
        });
      this.init();
    },
    removeselected() {},
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "unit_modify"
    );
    await this.init();
  },
};
</script>
