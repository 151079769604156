<template>
  <b-container fluid>
    <b-row
      align-v="center"
      :class="!gmb_activated || !api_modify ? 'text-muted' : ''"
    >
      <div
        v-if="!gmb_activated"
        class="mr-3 text-danger"
        style="position: absolute; top: 0; right: 0"
      >
        <i class="material-icons">warning</i> derzeit deaktiviert
      </div>
      <div
        v-else-if="!api_modify"
        class="mr-3 text-danger"
        style="position: absolute; top: 0; right: 0"
      >
        <i class="material-icons">warning</i> keine Berechtigung
      </div>
      <b-col cols="4" class="d-none d-md-flex justify-content-center">
        <img
          style="max-width: 100%"
          :style="
            !gmb_activated || !api_modify ? 'filter: grayscale(0.75);' : ''
          "
          src="@/assets/images/Google-My-Business.png"
        />
      </b-col>
      <b-col>
        <h3>
          Google My Business
          <a
            :href="INFO_LINK"
            target="_blank"
            class="material-icons"
            style="cursor: pointer"
            >link</a
          >
        </h3>
        <div>
          <span class="font-weight-bold">Status: </span>
          <template v-if="gmb_connected">Verbunden</template
          ><template v-else>Nicht Verbunden</template>
        </div>
        <div class="font-weight-bold">Funktionen:</div>
        <ul class="small mb-0">
          <li>
            Syncronisierung von Filialdaten mit Orten in Google Maps / Google My
            Business
          </li>
          <li>Zugriff auf Bewertungen / Nachrichten / Fragen</li>
          <li>Veröffentlichen von Ankündigungen</li>
        </ul>
        <b-link
          class="ml-4 small text-muted text-secondary"
          v-b-modal.modal-gmb-details
          >weitere Informationen</b-link
        >
        <div class="d-flex justify-content-between">
          <b-button
            v-if="gmb_connected"
            variant="link"
            v-b-modal.modal-gmb
            size="lg"
            :disabled="!gmb_activated || !api_modify"
            >Konfigurieren</b-button
          >
          <b-button
            v-if="gmb_connected"
            variant="link"
            class="text-danger"
            @click="
              $store.dispatch('removeAPIConnections', {
                api_name: 'google_mybusiness',
              })
            "
            size="lg"
            :disabled="!gmb_activated || !api_modify"
            >Trennen</b-button
          >
          <b-button
            v-else
            variant="link"
            class="text-success"
            @click="
              $store.dispatch('connectAPI', {
                api_name: 'google_mybusiness',
              })
            "
            size="lg"
            :disabled="!gmb_activated || !api_modify"
            >Jetzt verbinden</b-button
          >
        </div>
        <b-modal
          id="modal-gmb"
          title="Google My Business"
          hide-footer
          body-class="p-0"
          @show="modal_view = 1"
        >
          <b-container fluid>
            <b-row v-if="modal_view == 1" class="py-2">
              <b-col cols="12" class="mb-3">
                <div class="font-weight-bold">Ort-Filialen Verknüpfungen</div>
                <b-list-group>
                  <template
                    v-for="(location, branch_id) in gmb_branch_connections"
                  >
                    <template
                      v-for="branch in [
                        branches.find((branch) => branch.id == branch_id),
                      ]"
                    >
                      <b-list-group-item
                        button
                        :key="JSON.stringify(location) + branch.id"
                        class="p-2"
                        @click="handleConnectionClick(branch.id)"
                      >
                        <div class="d-flex justify-content-between">
                          <span class="font-weight-bold">{{
                            branch.name
                          }}</span>
                          <b-button
                            v-if="location != null"
                            variant="link"
                            class="text-danger small"
                            @click.stop="
                              $store.dispatch(
                                'gmb_removeBranchToLocationConnection',
                                { branch_id: branch.id }
                              )
                            "
                            >Verknüpfung aufheben</b-button
                          >
                        </div>
                        <div v-if="location != null" class="ml-2">
                          Verbunden mit
                          {{ location.address.addressLines.join(" ") }} ({{
                            location.id
                          }})
                        </div>
                      </b-list-group-item>
                    </template>
                  </template>
                </b-list-group>
              </b-col>
              <b-col cols="12">
                <div
                  class="border-bottom font-weight-bold"
                  style="cursor: pointer"
                >
                  Weitere Einstellungen
                </div>
                <b-form-group label="Filialen Syncronisation">
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_location"
                    switch
                  >
                    Aktiviert
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_location_name"
                    :disabled="!api_google_mybusiness_sync_location"
                    switch
                  >
                    Syncronisation des Filialnames
                  </b-form-checkbox>
                  <b-form-group
                    :disabled="
                      !api_google_mybusiness_sync_location_name ||
                      !api_google_mybusiness_sync_location
                    "
                    label="Name des Orts (Format)"
                  >
                    <b-row>
                      <b-col cols="11">
                        <b-form-input
                          v-model="api_google_mybusiness_location_name_format"
                        ></b-form-input>
                      </b-col>
                      <b-col cols="1" class="p-0">
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover="{
                            html: true,
                            content: `<div>Sie können hierfür entweder einen festen Wert hinterlegen oder Variablen, welche durch den dazugehörigen Wert ermitteln verwenden:</div><div><span class='font-italic'>Verfügbare Variablen: <ul><li>company_name</li><li>branch_name</li></ul></span></div><div class='small'>Variablen müssen mit der folgenden Schreibweise verwendet werden: {{variablenname}} z.B: {{company_name}}<br>Bsp.: {{company_name}} - Filiale {{branch_name}}</div>`,
                          }"
                          >info</i
                        >
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_location_hours"
                    :disabled="!api_google_mybusiness_sync_location"
                    switch
                  >
                    Syncronisation der Öffnungszeiten
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group label="Bewertungen Syncronisation">
                  Syncronisierungstyp
                  <b-form-select
                    v-model="api_google_mybusiness_sync_review_type"
                    :options="api_google_mybusiness_sync_review_types"
                    :disabled="!api_google_mybusiness_sync_review_type == null"
                  ></b-form-select>
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_review_restore_old"
                    :disabled="api_google_mybusiness_sync_review_type == null"
                    switch
                  >
                    Wiederherstellung des Status alter Bewertungen
                    <i
                      class="material-icons"
                      v-b-popover.hover="
                        'Wenn aktiviert wird beim Laden einer neuen Bewertung überprüft, ob diese zu einem früheren Zeitpunkt schoneinmal existiert hat und der Veröffentlichungsstatus wiederhergestellt.'
                      "
                      >info</i
                    >
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group>
                  <template slot="label">
                    Ankündigungen Syncronisation
                    <i
                      @click.stop="$router.push({ name: 'gmb_announcements' })"
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken für Übersicht aller Ankündigungen für Google My Business.'
                      "
                      >link</i
                    >
                  </template>
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation aktiv
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_google_mybusiness_sync_announcement_type_global
                    "
                    :disabled="!api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation von Globalen Ankündigung
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'global' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_google_mybusiness_sync_announcement_type_branches
                    "
                    :disabled="!api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation von Filialankündigungen
                    <i
                      @click.stop="
                        $router.push({ name: 'branch_announcements' })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungsgruppe zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="api_google_mybusiness_sync_announcement_type_index"
                    :disabled="!api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation von Ankündigungen auf der Startseite
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'index' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_google_mybusiness_sync_announcement_type_search_job
                    "
                    :disabled="!api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation von Jobangeboten
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'search_job' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                  <b-form-checkbox
                    v-model="
                      api_google_mybusiness_sync_announcement_type_covid_19
                    "
                    :disabled="!api_google_mybusiness_sync_announcements"
                    switch
                  >
                    Syncronisation von Hinweisen zu Covid-19
                    <i
                      @click.stop="
                        $router.push({
                          name: 'announcement',
                          params: { name: 'covid_19' },
                        })
                      "
                      class="material-icons"
                      v-b-popover.hover="
                        'Hier klicken um Ankündigungstyp zu öffnen.'
                      "
                      >link</i
                    >
                  </b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else-if="modal_view == 2" class="py-2">
              <b-col cols="12" v-if="modal_helper_loading" class="text-center">
                <b-spinner
                  style="width: 3rem; height: 3rem"
                  label="Large Spinner"
                ></b-spinner
                ><br />
                Bitte warten...
              </b-col>
              <template v-else-if="modal_helper_account_id == null">
                <div
                  class="pl-2 w-100 font-weight-bold d-flex justify-content-between"
                >
                  <div>Account auswählen</div>
                  <div>
                    <b-button
                      variant="link"
                      @click="
                        modal_view = 1;
                        modal_helper_branch_id = null;
                      "
                      >Zurück</b-button
                    >
                  </div>
                </div>
                <b-list-group flush class="w-100">
                  <b-list-group-item
                    button
                    v-for="account in gmb_accounts"
                    :key="account.id"
                    @click="modal_helper_account_id = account.id"
                    :disabled="
                      gmb_locations.accounts[account.id].filter((location) =>
                        modal_helper_available_locations.find(
                          (avlocation) => avlocation == location.name
                        )
                      ).length == 0
                    "
                  >
                    <div class="font-weight-bold">
                      {{ account.accountName }}
                    </div>
                    <div class="pl-2 small">
                      {{
                        gmb_locations.accounts[account.id].filter((location) =>
                          modal_helper_available_locations.find(
                            (avlocation) => avlocation == location.name
                          )
                        ).length == 0
                          ? "keine Orte verfügbar"
                          : `${
                              gmb_locations.accounts[
                                account.id
                              ].filter((location) =>
                                modal_helper_available_locations.find(
                                  (avlocation) => avlocation == location.name
                                )
                              ).length
                            } Orte verfügbar`
                      }}
                    </div>
                  </b-list-group-item>
                </b-list-group>
              </template>
              <template v-else>
                <div
                  class="pl-2 w-100 font-weight-bold d-flex justify-content-between"
                >
                  <div>Ort auswählen</div>
                  <div>
                    <b-button
                      variant="link"
                      @click="modal_helper_account_id = null"
                      >Zurück</b-button
                    >
                  </div>
                </div>
                <b-list-group flush class="w-100">
                  <b-list-group-item
                    button
                    v-for="location in gmb_locations.accounts[
                      modal_helper_account_id
                    ]"
                    :key="location.id"
                    class="d-flex justify-content-between"
                    :disabled="
                      !modal_helper_available_locations.find(
                        (avlocation) => avlocation == location.name
                      )
                    "
                    @click="handleSetBranchLocation(location.id)"
                  >
                    <div>
                      <div class="font-weight-bold">
                        {{ location.locationName }}
                      </div>
                      <div class="pl-2 small">
                        {{ location.address.addressLines.join(" ") }},
                        {{ location.address.postalCode }}
                        {{ location.address.locality }}
                      </div>
                      <div
                        class="pl-2 small text-primary"
                        v-if="
                          !modal_helper_available_locations.find(
                            (avlocation) => avlocation == location.name
                          )
                        "
                      >
                        wird bereits verwendet
                      </div>
                    </div>
                    <a
                      :href="location.metadata.mapsUrl"
                      target="_blank"
                      style="text-decoration: none; pointer-events: all"
                      @click.stop
                    >
                      <div class="w-100 text-center material-icons">
                        navigation
                      </div>
                      <div class="w-100 text-center">Maps</div>
                    </a>
                  </b-list-group-item>
                </b-list-group>
              </template>
            </b-row>
          </b-container>
        </b-modal>
        <!-- Details -->
        <b-modal
          id="modal-gmb-details"
          hide-footer
          hide-header
          body-class="p-0"
        >
          <b-container fluid>
            <b-row>
              <b-col>
                <div class="font-weight-bold">Was ist das?</div>
                <div>
                  Google My Business bietet Ihnen die Möglichkeit Ihre
                  Brancheneinträge in Google Maps zu verwalten. Ihre Kunden
                  erhalten somit auf diesem Wege aktuelle Informationen zu Ihrer
                  Firma, Ihren Öffnungszeiten und können über Ankündigungen
                  informiert werden. Gleichzeitig haben Sie Zugriff auf
                  Bewertungen und Nachrichten ihrer Kunden, sowie auf alle
                  veröffentlichte Beiträge Ihrer Firma.
                </div>
                <div class="font-weight-bold">Warum wichtig?</div>
                <div>
                  Mit der Nutzung von Google My Business in der Fahrschule API
                  werden Ihnen die wichtigsten Informationen zusammen mit den
                  Daten aus anderen Platformen an einem Ort übersichtlich
                  zusammengetragen. Somit können Sie über viele Kanäle
                  einheitlich mit Ihren Kunden komunizieren.
                </div>
                <br />
                <b-link href="https://policies.google.com/" target="_blank"
                  >Hinweise zu Datenschutz und Nutzungsbedingungen</b-link
                ><br />
                <b-link
                  href="https://support.google.com/contributionpolicy#topic=7422769"
                  target="_blank"
                  >Hinweise für Inhalte auf Google Maps</b-link
                >
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import { addSetting } from '../used_settings';
addSetting([
  "api_google_mybusiness_active",
  "api_google_mybusiness_sync_location",
  "api_google_mybusiness_sync_location_name",
  "api_google_mybusiness_location_name_format",
  // "api_google_mybusiness_location_description",
  "api_google_mybusiness_sync_location_hours",
  "api_google_mybusiness_sync_review_type",
  "api_google_mybusiness_sync_review_restore_old",
  "api_google_mybusiness_sync_announcements",
  "api_google_mybusiness_sync_announcement_type_global",
  "api_google_mybusiness_sync_announcement_type_branches",
  "api_google_mybusiness_sync_announcement_type_index",
  "api_google_mybusiness_sync_announcement_type_search_job",
  "api_google_mybusiness_sync_announcement_type_covid_19"
]);
export default {
  data() {
    return {
      loaded: false,
      api_modify: null,
      INFO_LINK: process.env.VUE_APP_GMB_INFO_LINK,
      api_google_mybusiness_sync_location: null,
      api_google_mybusiness_sync_location_name: null,
      api_google_mybusiness_location_name_format: null,
      api_google_mybusiness_sync_location_hours: null,
      api_google_mybusiness_sync_review_type: null,
      api_google_mybusiness_sync_review_types: [
        {
          value: 0,
          text: "Keine Syncronisation"
        },
        {
          value: 1,
          text: "Alle Bewertungen, welche mit einer Filiale verbunden sind"
        },
        {
          value: 2,
          text: "Alle Bewertungen"
        }
      ],
      api_google_mybusiness_sync_review_restore_old: null,
      api_google_mybusiness_sync_announcements: null,
      api_google_mybusiness_sync_announcement_type_global: null,
      api_google_mybusiness_sync_announcement_type_branches: null,
      api_google_mybusiness_sync_announcement_type_index: null,
      api_google_mybusiness_sync_announcement_type_search_job: null,
      api_google_mybusiness_sync_announcement_type_covid_19: null,
      // Modal
      modal_view: 1,
      modal_helper_loading: false,
      modal_helper_available_locations: null,
      modal_helper_branch_id: null,
      modal_helper_account_id: null,
    };
  },
  methods: {
    async handleConnectionClick(branch_id) {
      let location = this.gmb_branch_connections[branch_id];
      if (location) window.open(location.mapsUrl, "_blank");
      else {
        this.modal_helper_loading = true;
        this.modal_helper_branch_id = branch_id;
        this.modal_helper_account_id = null;
        this.modal_view = 2;
        this.modal_helper_available_locations = await this.$store.dispatch('gmb_getAvailableLocations', branch_id);
        this.modal_helper_loading = false;
      }
    },
    async handleSetBranchLocation(location_id) {
      this.modal_helper_loading = true;
      await this.$store.dispatch('gmb_createBranchToLocationConnection', { branch_id: this.modal_helper_branch_id, location: location_id});
      this.modal_helper_loading = false;
      this.modal_view = 1;
    },
    async refresh() {
      this.api_google_mybusiness_sync_location=this.$store.getters.settings_obj.api_google_mybusiness_sync_location.value.current;
      this.api_google_mybusiness_sync_location_name=this.$store.getters.settings_obj.api_google_mybusiness_sync_location_name.value.current;
      this.api_google_mybusiness_location_name_format=this.$store.getters.settings_obj.api_google_mybusiness_location_name_format.value.current;
      this.api_google_mybusiness_sync_location_hours=this.$store.getters.settings_obj.api_google_mybusiness_sync_location_hours.value.current;
      this.api_google_mybusiness_sync_review_type=this.$store.getters.settings_obj.api_google_mybusiness_sync_review_type.value.current;
      this.api_google_mybusiness_sync_review_restore_old=this.$store.getters.settings_obj.api_google_mybusiness_sync_review_restore_old.value.current;
      this.api_google_mybusiness_sync_announcements=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcements.value.current;
      this.api_google_mybusiness_sync_announcement_type_global=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcement_type_global.value.current;
      this.api_google_mybusiness_sync_announcement_type_branches=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcement_type_branches.value.current;
      this.api_google_mybusiness_sync_announcement_type_index=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcement_type_index.value.current;
      this.api_google_mybusiness_sync_announcement_type_search_job=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcement_type_search_job.value.current;
      this.api_google_mybusiness_sync_announcement_type_covid_19=this.$store.getters.settings_obj.api_google_mybusiness_sync_announcement_type_covid_19.value.current;
    },
  },
  computed: {
    ...mapGetters([
      "branches",
      "gmb_activated",
      "gmb_connected",
      "gmb_branch_connections",
      "gmb_accounts",
      "gmb_locations",
    ]),
  },
  watch: {
    async api_google_mybusiness_sync_location(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_location", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_location_name(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_location_name", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_location_name_format(val) {
      if (this.loaded) await setTimeout(async () => {if (val==this.api_google_mybusiness_location_name_format) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_location_name_format", value: val}).catch(async err => { await this.refresh(); throw err; });}, 5000)
    },
    async api_google_mybusiness_sync_location_hours(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_location_hours", value: val ? 1 : 0}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_review_type(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_review_type", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_review_restore_old(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_review_restore_old", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_announcements(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcements", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_announcement_type_global(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcement_type_global", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_announcement_type_branches(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcement_type_branches", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_mybusiness_sync_announcement_type_index(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcement_type_index", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_my_business_sync_announcement_type_search_job(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcement_type_search_job", value: val}).catch(async err => { await this.refresh(); throw err; });
    },
    async api_google_my_business_sync_announcement_type_covid_19(val) {
      if (this.loaded) await this.$store.dispatch('setSetting', {name: "api_google_mybusiness_sync_announcement_type_covid_19", value: val}).catch(async err => { await this.refresh(); throw err; });
    }
  },
  async created() {
    this.loaded = false;
    this.api_modify = await this.$store.dispatch(
      "haveUserRight",
      "api_modify"
    );
    this.refresh();
    this.loaded = true;
  },
};
</script>

<style>
</style>
