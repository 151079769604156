var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-navbar-nav',{staticClass:"border-left flex-row"},[_c('li',{staticClass:"nav-item border-right dropdown notifications"},[_c('a',{directives:[{name:"d-toggle",rawName:"v-d-toggle.notifications",modifiers:{"notifications":true}}],staticClass:"nav-link nav-link-icon text-center",on:{"click":_vm.handleClickOpen}},[_c('div',{staticClass:"nav-link-icon__wrapper"},[_c('i',{staticClass:"material-icons"},[_vm._v("")]),(_vm.count >= 1)?_c('d-badge',{attrs:{"pill":"","theme":"danger"}},[_vm._v(_vm._s(_vm.count))]):_vm._e()],1)]),_c('d-collapse',{staticClass:"dropdown-menu dropdown-menu-small",attrs:{"id":"notifications"},model:{value:(_vm.notifications_visible),callback:function ($$v) {_vm.notifications_visible=$$v},expression:"notifications_visible"}},[(_vm.formated_notifications.length == 0)?_c('d-dropdown-item',{attrs:{"disabled":""}},[_c('div',{staticClass:"notification__all text-center"},[_vm._v(" Derzeit keine Benachichtigungen! ")])]):_vm._e(),_vm._l((_vm.formated_notifications),function(element){return _c('d-dropdown-item',{key:element.create_date + '_' + element.id,staticStyle:{"position":"relative"},style:(element.onClick ? '' : 'pointer-events: none;')},[_c('div',{staticClass:"notification__icon-wrapper",on:{"click":function () {
            if (!element.onClick) { return; }
            element.onClick();
            _vm.handleClickClickNotification(element);
          }}},[_c('div',{staticClass:"notification__icon"},[_c('i',{staticClass:"material-icons"},[_vm._v(_vm._s(element.material_icon))])])]),_c('div',{staticClass:"notification__content",on:{"click":function () {
            if (!element.onClick) { return; }
            element.onClick();
            _vm.handleClickClickNotification(element);
          }}},[_c('span',{staticClass:"notification__category"},[_vm._v(_vm._s(element.title))]),_c('p',[_vm._v(_vm._s(element.text))])]),_c('div',{staticClass:"p-3",staticStyle:{"position":"absolute","right":"5%","top":"0","pointer-events":"all"},on:{"click":function($event){return _vm.handleClickCloseNotification(element)}}},[_vm._v(" X ")])])}),_c('d-dropdown-item',{staticClass:"notification__all text-center"},[_c('div',{on:{"click":_vm.handleClickOpenNotificationRoute}},[_vm._v(" Alle Benachichtigungen ")])])],2)],1),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{directives:[{name:"b-toggle",rawName:"v-b-toggle.user-actions",modifiers:{"user-actions":true}}],staticClass:"nav-link dropdown-toggle text-nowrap px-3"},[_c('b-avatar',{staticClass:"mr-3 text-light",attrs:{"variant":"light","size":"2.5rem","src":_vm.picture_url}}),_c('span',{staticClass:"d-none d-md-inline-block mt-2"},[_vm._v(_vm._s(_vm.user.name))])],1),_c('b-collapse',{staticClass:"dropdown-menu dropdown-menu-small",attrs:{"id":"user-actions"},model:{value:(_vm.profile_visible),callback:function ($$v) {_vm.profile_visible=$$v},expression:"profile_visible"}},[_c('b-dropdown-item',{on:{"click":_vm.openProfile}},[_c('i',{staticClass:"material-icons"},[_vm._v("")]),_vm._v(" Profil")]),_c('d-dropdown-divider'),_c('d-dropdown-item',{staticClass:"text-danger"},[_c('div',{on:{"click":_vm.handleLogout}},[_c('i',{staticClass:"material-icons text-danger"},[_vm._v("")]),_vm._v(" Abmelden ")])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }