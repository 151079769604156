<template>
  <b-container fluid class="main-content-container px-4">
    <b-row no-gutters class="page-header py-4">
      <b-col cols="12" sm="8" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weiteres</span>
        <h3 class="page-title">Integration</h3>
      </b-col>
      <b-col cols="12" sm="4" class="text-center text-sm-right">
        <b-button
          variant="light"
          @click="
            window.open(
              'https://hilfe.fahrschule-api.de/applets-für-ihre-webseite',
              '_blank'
            )
          "
          ><i class="material-icons">info</i> Hilfe</b-button
        >
      </b-col>
    </b-row>
    <b-row>
      <b-col class="p-0 p-md-3 mb-4">
        <b-card class="card-small">
          <b-container fluid>
            <b-row>
              <b-col>
                Hier können Sie einen Link zur Einbindung eines Applets in Ihre
                Firmenwebseite erstellen:
                <b-form-select
                  v-model="type"
                  :options="type_options"
                  class="mb-3"
                ></b-form-select>
              </b-col>
            </b-row>
            <template v-if="type">
              <template v-if="type == 'announcement'">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Ankündigungstyp">
                      <b-form-input
                        v-model="config_announcement.announcement_type"
                        :state="
                          announcement_types.find(
                            (el) =>
                              el.name == config_announcement.announcement_type
                          )
                            ? true
                            : null
                        "
                        placeholder="Suche..."
                        @keypress.enter="
                          () => {
                            if (
                              announcement_types.filter(
                                (el) =>
                                  el.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ) ||
                                  el.label
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    )
                              )[0]
                            )
                              config_announcement.announcement_type =
                                announcement_types.filter(
                                  (el) =>
                                    el.name
                                      .trim()
                                      .toLowerCase()
                                      .includes(
                                        config_announcement.announcement_type
                                          .trim()
                                          .toLowerCase()
                                      ) ||
                                    el.label
                                      .trim()
                                      .toLowerCase()
                                      .includes(
                                        config_announcement.announcement_type
                                          .trim()
                                          .toLowerCase()
                                      )
                                )[0].name;
                          }
                        "
                      />
                      <b-collapse
                        :visible="
                          config_announcement.announcement_type.trim() != '' &&
                          !announcement_types.find(
                            (el) =>
                              el.name == config_announcement.announcement_type
                          )
                        "
                        class="mt-1"
                      >
                        <template
                          v-if="
                            announcement_types.filter(
                              (el) =>
                                el.name
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  ) ||
                                el.label
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  )
                            ).length > 0
                          "
                        >
                          <b-list-group>
                            <b-list-group-item
                              button
                              v-for="announcement_type in announcement_types
                                .filter(
                                  (el) =>
                                    el.name
                                      .trim()
                                      .toLowerCase()
                                      .includes(
                                        config_announcement.announcement_type
                                          .trim()
                                          .toLowerCase()
                                      ) ||
                                    el.label
                                      .trim()
                                      .toLowerCase()
                                      .includes(
                                        config_announcement.announcement_type
                                          .trim()
                                          .toLowerCase()
                                      )
                                )
                                .slice(0, 5)"
                              :key="announcement_type.name"
                              @click="
                                config_announcement.announcement_type =
                                  announcement_type.name
                              "
                              class="m-0"
                            >
                              {{ announcement_type.label }} ({{
                                announcement_type.name
                              }})
                              <br />
                              <small>{{ announcement_type.description }}</small>
                            </b-list-group-item>
                          </b-list-group>
                          <span
                            v-if="
                              announcement_types.filter(
                                (el) =>
                                  el.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ) ||
                                  el.label
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    )
                              ).length -
                                5 >
                              0
                            "
                          >
                            {{
                              announcement_types.filter(
                                (el) =>
                                  el.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ) ||
                                  el.label
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    )
                              ).length - 5
                            }}
                            weitere Ergebnisse gefunden...
                          </span>
                        </template>
                        <span v-else>keine Ankündigungstypen gefunden</span>
                      </b-collapse>
                    </b-form-group>
                    <b-form-group label="Optik">
                      <b-form-checkbox
                        switch
                        v-model="config_announcement.collapsible"
                        >Ankündigungen ausklappbar (spart Platz, benötigt
                        Interaktion für Datenansicht)</b-form-checkbox
                      >
                      <b-form-checkbox
                        switch
                        v-model="config_announcement.hideEmpty"
                        >Ausblenden, wenn keine Daten vorhanden</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="type == 'branches_cards'">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Auf Filiale einschränken">
                      <b-form-checkbox
                        :checked="config_branches_cards.branches === null"
                        @change="
                          (val) =>
                            (config_branches_cards.branches = val
                              ? null
                              : branches.map((el) => el.id))
                        "
                      >
                        Alle
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_branches_cards.branches"
                        stacked
                        :disabled="config_branches_cards.branches === null"
                        @change="
                          (val) =>
                            (config_branches_cards.branches = val.length
                              ? config_branches_cards.branches
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="branch in branches"
                          :key="branch.id"
                          :value="branch.id"
                          >{{ branch.name }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Thumbnail">
                      <b-form-select
                        v-model="config_branches_cards.thumbnail_style"
                        :options="[
                          {
                            text: 'Keine Anzeige',
                            value: 'hidden',
                          },
                          {
                            text: 'Kartenkopf',
                            value: 'top',
                          },
                          {
                            text: 'Avatar-Bild neben Name',
                            value: 'avatar',
                          },
                        ]"
                        size="sm"
                      />
                      <small
                        >Standardbild
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover.top="
                            'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'
                          "
                          >info</i
                        ></small
                      >
                      <b-form-input
                        v-model="config_branches_cards.thumbnail_default_url"
                        placeholder="https://webseite.de/url/zum/bild.jpg"
                        size="sm"
                      />
                    </b-form-group>
                    <b-form-group label="Weiteres">
                      <b-form-checkbox
                        v-model="config_branches_cards.hide_phone_numbers"
                        >Telefon / Fax ausblenden</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_cards.hide_open_hours"
                        @change="
                          (val) =>
                            (config_branches_cards.hide_open_hours_none = val
                              ? false
                              : config_branches_cards.hide_open_hours_none)
                        "
                        >Öffnungszeiten ausblenden</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_cards.hide_open_hours_none"
                        :disabled="config_branches_cards.hide_open_hours"
                        >Tage ohne Öffnungszeiten ausblenden
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover.top="
                            'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                          "
                          >info</i
                        ></b-form-checkbox
                      >
                    </b-form-group>
                    <b-form-group label="Ausrichtung">
                      <b-form-checkbox
                        v-model="config_branches_cards.left"
                        @change="
                          (val) => {
                            if (val) {
                              config_branches_cards.center = false;
                              config_branches_cards.right = false;
                            }
                          }
                        "
                        ><b-icon-justify-left /> linksbündig</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_cards.center"
                        @change="
                          (val) => {
                            if (val) {
                              config_branches_cards.left = false;
                              config_branches_cards.right = false;
                            }
                          }
                        "
                        ><b-icon icon="justify" /> zentral</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_cards.right"
                        @change="
                          (val) => {
                            if (val) {
                              config_branches_cards.left = false;
                              config_branches_cards.center = false;
                            }
                          }
                        "
                        ><b-icon icon="justify-right" />
                        rechtsbündig</b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="type == 'branches_accordion'">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Auf Filiale einschränken">
                      <b-form-checkbox
                        :checked="config_branches_accordion.branches === null"
                        @change="
                          (val) =>
                            (config_branches_accordion.branches = val
                              ? null
                              : branches.map((el) => el.id))
                        "
                      >
                        Alle
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_branches_accordion.branches"
                        stacked
                        :disabled="config_branches_accordion.branches === null"
                        @change="
                          (val) =>
                            (config_branches_accordion.branches = val.length
                              ? config_branches_accordion.branches
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="branch in branches"
                          :key="branch.id"
                          :value="branch.id"
                          >{{ branch.name }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Thumbnail">
                      <b-form-select
                        v-model="config_branches_accordion.thumbnail_style"
                        :options="[
                          {
                            text: 'Keine Anzeige',
                            value: 'hidden',
                          },
                          {
                            text: 'Avatar-Bild neben Name',
                            value: 'avatar',
                          },
                        ]"
                        size="sm"
                      />
                      <small
                        >Standardbild
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover.top="
                            'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'
                          "
                          >info</i
                        ></small
                      >
                      <b-form-input
                        v-model="
                          config_branches_accordion.thumbnail_default_url
                        "
                        placeholder="https://webseite.de/url/zum/bild.jpg"
                        size="sm"
                      />
                    </b-form-group>
                    <b-form-group label="Weiteres">
                      <b-form-checkbox
                        v-model="config_branches_accordion.hide_phone_numbers"
                        >Telefon / Fax ausblenden</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_accordion.hide_open_hours"
                        @change="
                          (val) =>
                            (config_branches_accordion.hide_open_hours_none =
                              val
                                ? false
                                : config_branches_accordion.hide_open_hours_none)
                        "
                        >Öffnungszeiten ausblenden</b-form-checkbox
                      >
                      <b-form-checkbox
                        v-model="config_branches_accordion.hide_open_hours_none"
                        :disabled="config_branches_accordion.hide_open_hours"
                        >Tage ohne Öffnungszeiten ausblenden
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover.top="
                            'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                          "
                          >info</i
                        ></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-if="type == 'branch_openHours'">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Filiale auswählen">
                      <b-form-radio-group
                        v-model="config_branch_openHours.branch"
                        stacked
                        :options="
                          branches.map((branch) => {
                            return { text: branch.name, value: branch.id };
                          })
                        "
                      >
                      </b-form-radio-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-group label="Weiteres">
                      <b-form-checkbox
                        v-model="config_branch_openHours.hide_none"
                        >Tage ohne Öffnungszeiten ausblenden
                        <i
                          class="material-icons"
                          style="cursor: pointer"
                          v-b-popover.hover.top="
                            'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                          "
                          >info</i
                        ></b-form-checkbox
                      >
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="type == 'chatbot'">
                <b-row>
                  <b-col cols="12">
                    <b-form-group
                      label="Optik"
                      v-b-popover.hover.bottom="{
                        content:
                          'CSS Eigenschaft: <a href=&quot;https://developer.mozilla.org/de/docs/Web/CSS/max-height&quot; target=&quot;_blank&quot;>max-height</a>',
                        html: true,
                      }"
                    >
                      <label>Max. Höhe</label>
                      <b-form-input v-model="config_chatbot.max_height" />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="type == 'education_calendar'">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Auf Unterrichtskategorie einschränken">
                      <b-form-checkbox
                        :checked="config_education_calendar.category === null"
                        @change="
                          (val) =>
                            (config_education_calendar.category = val
                              ? null
                              : categories.map((el) => el.name))
                        "
                      >
                        Alle Kategorien
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_education_calendar.category"
                        stacked
                        :disabled="config_education_calendar.category === null"
                        @change="
                          (val) =>
                            (config_education_calendar.category = val.length
                              ? config_education_calendar.category
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="category in categories"
                          :key="category.name"
                          :value="category.name"
                          >{{ category.title }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-checkbox
                      v-model="config_education_calendar.hideCourses"
                      >Keine Kurse anzeigen</b-form-checkbox
                    >
                    <b-form-checkbox
                      v-model="config_education_calendar.hideLessons"
                      >Keine Unterrichtsstunden anzeigen</b-form-checkbox
                    >
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Auf Filiale einschränken">
                      <b-form-checkbox
                        :checked="config_education_calendar.branches === null"
                        @change="
                          (val) =>
                            (config_education_calendar.branches = val
                              ? null
                              : [...branches.map((el) => el.id), null])
                        "
                      >
                        Alle
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_education_calendar.branches"
                        stacked
                        :disabled="config_education_calendar.branches === null"
                        @change="
                          (val) =>
                            (config_education_calendar.branches = val.length
                              ? config_education_calendar.branches
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="branch in branches"
                          :key="branch.id"
                          :value="branch.id"
                          >{{ branch.name }}</b-form-checkbox
                        >
                        <b-form-checkbox value="null"
                          >ohne Filialzuordnung</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="type == 'education_list'">
                <b-row>
                  <b-col cols="12" md="6">
                    <b-form-group label="Auf Unterrichtskategorie einschränken">
                      <b-form-checkbox
                        :checked="config_education_list.category === null"
                        @change="
                          (val) =>
                            (config_education_list.category = val
                              ? null
                              : categories.map((el) => el.name))
                        "
                      >
                        Alle Kategorien
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_education_list.category"
                        stacked
                        :disabled="config_education_list.category === null"
                        @change="
                          (val) =>
                            (config_education_list.category = val.length
                              ? config_education_list.category
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="category in categories"
                          :key="category.name"
                          :value="category.name"
                          >{{ category.title }}</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" md="6">
                    <b-form-checkbox v-model="config_education_list.hideCourses"
                      >Keine Kurse anzeigen</b-form-checkbox
                    >
                    <b-form-checkbox v-model="config_education_list.hideLessons"
                      >Keine Unterrichtsstunden anzeigen</b-form-checkbox
                    >
                  </b-col>
                  <b-col cols="12">
                    <b-form-group label="Auf Filiale einschränken">
                      <b-form-checkbox
                        :checked="config_education_list.branches === null"
                        @change="
                          (val) =>
                            (config_education_list.branches = val
                              ? null
                              : [...branches.map((el) => el.id), null])
                        "
                      >
                        Alle
                      </b-form-checkbox>
                      <b-form-checkbox-group
                        v-model="config_education_list.branches"
                        stacked
                        :disabled="config_education_list.branches === null"
                        @change="
                          (val) =>
                            (config_education_list.branches = val.length
                              ? config_education_list.branches
                              : null)
                        "
                      >
                        <b-form-checkbox
                          v-for="branch in branches"
                          :key="branch.id"
                          :value="branch.id"
                          >{{ branch.name }}</b-form-checkbox
                        >
                        <b-form-checkbox value="null"
                          >ohne Filialzuordnung</b-form-checkbox
                        >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="type == 'mail_list'">
                <b-row>
                  <b-col cols="12">
                    <b-form-group label="Auswahl">
                      <b-form-select
                        v-model="config_mail_list.id"
                        :options="[
                          { text: 'Bitte Newsletter auswählen', value: null },
                          ...mail_lists,
                        ]"
                      />
                    </b-form-group>
                    <b-form-group label="Ausrichtung">
                      <b-form-select
                        value="left"
                        @change="
                          (val) => {
                            switch (val) {
                              case 'left':
                                config_mail_list.center = false;
                                config_mail_list.right = false;
                                break;
                              case 'center':
                                config_mail_list.center = true;
                                config_mail_list.right = false;
                                break;
                              case 'right':
                                config_mail_list.center = false;
                                config_mail_list.right = true;
                                break;
                            }
                          }
                        "
                        :options="[
                          { text: 'Links', value: 'left' },
                          { text: 'Mitte', value: 'center' },
                          { text: 'Rechts', value: 'right' },
                        ]"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </template>
              <b-row>
                <b-col class="mb-2"
                  ><i class="material-icons">info</i> Benötigen Sie noch feinere
                  Einstellungen? Sprechen Sie uns gerne an, wir helfen Ihnen
                  gerne weiter!</b-col
                >
              </b-row>
            </template>
            <b-row class="border-top">
              <b-col cols="12" class="my-4">
                <h6>HTML:</h6>
                <code v-if="type">{{ code }}</code>
                <div v-else class="small">Bitte Anwendung wählen!</div>
                <br />
                <h6>Vorschau:</h6>
                <div v-if="type && type != 'fa-messenger'" :key="link">
                  <fa-element :url="link" />
                </div>
                <div v-else-if="type && type == 'fa-messenger'" :key="link">
                  <small
                    >Die Vorschau sehen sie als runden Button am rechten unteren
                    Bildschirmrand.</small
                  >
                  <fa-messenger :client_id="company.id" />
                </div>
                <div v-else class="small">Bitte Anwendung wählen!</div>
                <div v-if="type && type != 'fa-messenger'">
                  <small>Einbettungslink:</small>
                  <b-input-group :key="link">
                    <b-form-input
                      readonly
                      v-model="link"
                      v-clipboard="link"
                      v-b-popover.hover.bottom="'Klicken zum Kopieren'"
                      style="cursor: pointer"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button variant="outline-dark" v-clipboard="link"
                        ><i class="material-icons">content_copy</i></b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
let script = document.createElement("script");
script.setAttribute(
  "src",
  "https://integration.fahrschule-api.de/js/integrationHandler.js"
);
document.head.appendChild(script);
import { mapGetters } from "vuex";
export default {
  name: "integration_configurator",
  data() {
    return {
      // DATA
      type: null,
      config_announcement: {
        announcement_type: "",
        collapsible: true,
        hideEmpty: true,
        hideLoader: true,
      },
      config_branches_cards: {
        branches: null,
        thumbnail_style: "hidden",
        thumbnail_default_url: null,
        hide_phone_numbers: false,
        hide_open_hours: false,
        hide_open_hours_none: false,
        left: false,
        center: true,
        right: false,
      },
      config_branches_accordion: {
        branches: null,
        thumbnail_style: "hidden",
        thumbnail_default_url: null,
        hide_phone_numbers: false,
        hide_open_hours: false,
        hide_open_hours_none: false,
      },
      config_branch_openHours: {
        branch: null,
        hide_none: false,
      },
      config_chatbot: {
        max_height: null,
      },
      config_education_calendar: {
        category: null,
        hideCourses: false,
        hideLessons: false,
        branches: null,
      },
      config_education_list: {
        category: null,
        hideCourses: false,
        hideLessons: false,
        branches: null,
      },
      config_mail_list: {
        id: null,
        center: false,
        right: false,
      },
      // OPTIONS
      type_options: [
        {
          text: "Anwendung auswählen",
          value: null,
        },
        {
          text: "Ankündigung",
          value: "announcement",
        },
        {
          label: "ChatBot",
          options: [
            {
              text: "Messenger",
              value: "fa-messenger",
            },
            {
              text: "Web-Komponente",
              value: "chatbot",
            },
          ],
        },
        {
          label: "Filialen",
          options: [
            {
              text: "Karten",
              value: "branches_cards",
            },
            {
              text: "Accordion",
              value: "branches_accordion",
            },
            {
              text: "Öffnungszeiten (Einzeln)",
              value: "branch_openHours",
            },
          ],
        },
        {
          text: "Newsletter",
          value: "mail_list",
        },
        {
          label: "Unterrichtssystem",
          options: [
            {
              text: "Unterrichtsübersicht (Kalender)",
              value: "education_calendar",
            },
            {
              text: "Unterrichtsübersicht (Liste)",
              value: "education_list",
            },
          ],
        },
      ],
      // other
      window,
    };
  },
  computed: {
    ...mapGetters([
      "announcement_types",
      "company",
      "branches",
      "categories",
      "units",
      "unit_groups",
      "exams",
      "mail_lists",
    ]),
    link() {
      let query = `?type=${this.type}`;
      let query_obj = {
        ...(this.type && this[`config_${this.type}`]
          ? this[`config_${this.type}`]
          : {}),
      };
      for (let name in query_obj) {
        if (query_obj[name])
          if (
            typeof query_obj[name] == "number" ||
            typeof query_obj[name] == "string" ||
            typeof query_obj[name] == "boolean"
          )
            query += `&${name}${
              query_obj[name] !== true ? `=${query_obj[name]}` : ""
            }`;
        if (Array.isArray(query_obj[name]))
          query += `&${name}=${query_obj[name]
            .map((el) => (el === null ? "null" : el))
            .toString()}`;
      }
      return `https://integration.fahrschule-api.de/${this.company.id}${query}`;
    },
    code() {
      let query = "";
      let query_obj = {
        ...(this.type && this[`config_${this.type}`]
          ? this[`config_${this.type}`]
          : {}),
      };
      for (let name in query_obj) {
        if (query_obj[name])
          if (
            typeof query_obj[name] == "string" ||
            typeof query_obj[name] == "number" ||
            typeof query_obj[name] == "boolean"
          )
            query += `\r\n${name}${
              query_obj[name] !== true ? `="${query_obj[name]}"` : ""
            }`;
        if (Array.isArray(query_obj[name]))
          query += `\r\n${name}="${query_obj[name]
            .map((el) => (el === null ? "null" : el))
            .toString()}"`;
      }
      return `<script src="https://integration.fahrschule-api.de/js/integrationHandler.js" />\r\n\r\n<fa-${
        this.type == "fa-messenger" ? "messenger" : "element"
      }\r\nclient_id="${this.company.id}"\r\ntype="${this.type}"${query}/>`;
    },
  },
  watch: {
    link: function (val, oldval) {
      if (val == oldval) return;
      if ("FA_Handler" in window)
        this.$nextTick(() => window.FA_Handler.refresh());
    },
  },
  async created() {
    this.$store.dispatch("getMailLists");
  },
};
</script>

<style scoped>
code {
  display: block;
  white-space: pre-wrap;
}
</style>
