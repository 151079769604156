export default function () {
  return [
    {
      id: "dashbaord",
      title: 'Übersicht',
      htmlBefore: '<i class="material-icons">home</i>',
      to: {
        name: 'dashboard',
      },
    }, {
    id: 'classes',
    title: 'Unterricht',
    htmlBefore: '<i class="material-icons">account_balance</i>',
    items: [
      {
        title: "Stunden", // Lessons
        to: {
          name: 'lessons',
        },
      },
      {
        title: "Kurse", // Courses
        to: {
          name: 'courses',
        },
      },
      {
        title: "Inhalte", // Units
        to: {
          name: 'units',
        },
      },
    ]
  }, {
    id: "company",
    title: 'Unternehmen',
    htmlBefore: '<i class="material-icons">work</i>',
    items: [
      {
        title: 'Basisinformationen',
        to: {
          name: 'company',
        },
      }, {
        title: "Filialen",
        to: {
          name: 'branches',
        },
      },
      {
        title: "Vermögenswerte",
        to: {
          name: 'assets',
        },
      },
      {
        title: "Mitarbeiter",
        to: {
          name: 'employees',
        },
        hidden: true
      },
    ]
  }, {
    id: "crm",
    title: 'Kundenkontakt',
    htmlBefore: '<i class="material-icons">speaker_notes</i>',
    items: [
      {
        title: 'Ankündigungen',
        to: {
          name: 'announcements',
        },
      },
      {
        title: 'Bewertungen',
        to: {
          name: 'reviews',
        },
      },
      {
        title: 'Beiträge',
        to: {
          name: 'content',
        },
        hidden: true
      }, {
        title: "Nachrichten",
        to: {
          name: 'messages',
        },
        hidden: true
      }, {
        title: "Newsletter",
        to: {
          name: 'newsletter',
        },
        hidden: true
      }, {
        title: "Umfragen",
        to: {
          name: 'surveys',
        },
        hidden: true
      }
    ]
  }, {
    id: "more",
    title: 'Weiteres',
    htmlBefore: '<i class="material-icons">settings</i>',
    items: [
      {
        title: 'Benutzerverwaltung',
        to: {
          name: 'user-management',
        },
        hidden: true
      }, {
        title: "Abrechnung & Vertrag",
        to: {
          name: 'contract',
        },
        hidden: true,
      }, {
        title: "Einstellungen",
        to: {
          name: 'settings',
        },
      }, {
        title: "Hilfe & Support",
        to: {
          name: 'support',
        },
        hidden: true,
      }, {
        title: "zusätzliche Funktionen",
        to: {
          name: 'more_list',
        },
      }
    ]
  }];
}
