import axios from 'axios';
// All additional defaults, like URL and Token(Authorization-Header) will be set in Vuex index.js
const API = {
  install(Vue) {
    Vue.prototype.$api = axios.create({
      baseURL: null,
      responseType: 'json',
    })
  }
};

export default API;
