<template>
  <b-form-select v-if="selectable" size="sm" v-model="selected_category" :options="mappedCategories"/>
  <div v-else>
    <span id="popover-category" class="ml-1 text-secondary font-weight-bold" style="cursor: pointer;">{{ mappedCategories.find(el => el.value == selected_category).text }}</span>
    <b-popover target="popover-category" triggers="hover click" custom-class="m-2" :delay="{ show: 0, hide: 400 }">
      Kategorien können nach Erstellung nicht mehr verändert werden.
    </b-popover>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: {
    // if choosen the user is not allowed to change the category
    selectable: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selected_category: null,
    }
  },
  computed: {
    ...mapGetters([
      "categories",
      "selectedCategory"
    ]),
    mappedCategories: function() {
      return [
        {
          text: "Alle",
          value: null
        },
        ...this.categories.map(category => {
          return {
            text: category.title,
            value: category.name
          }
        })
      ]
    },
  },
  watch: {
    selectedCategory: function(val) {
      this.selected_category = val;
    },
    selected_category: function(val) {
      this.$store.commit("setSelectedCategory", val);
    }
  },
  created() {
    this.selected_category = this.$store.getters.selectedCategory;
  }
};
</script>
