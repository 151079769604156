var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('d-card',{staticClass:"card-small"},[_c('d-card-header',{staticClass:"border-bottom"},[_c('h6',{staticClass:"m-0"},[_vm._v("Ankündigung: "+_vm._s(_vm.type ? _vm.type.label : ''))])]),_c('d-card-body',{staticClass:"d-flex flex-column"},[(!_vm.loaded)?_c('div',{staticClass:"text-center p-3"},[_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}},[_c('span',{staticClass:"sr-only"},[_vm._v("Lädt...")])])]):(!_vm.type)?_c('div',[_vm._v(" Die Ankündigung "+_vm._s(_vm.type_name)+" konnte nicht gefunden werden. ")]):_c('div',[_c('div',{staticClass:"quick-post-form"},[(_vm.filteredAnnouncements.length > 0)?_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.filteredAnnouncements),function(announcement){return _c('b-list-group-item',{key:announcement.id,staticClass:"p-0",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
                name: 'announcement',
                params: { id: announcement.id },
              })}}},[_c('div',{staticClass:"border-left pl-4",staticStyle:{"border-width":"4px !important"},style:(announcement.status == 'public' &&
                _vm.moment(
                  announcement.start_date,
                  'YYYY-MM-DD HH:mm:ss'
                ).isBefore(_vm.moment()) &&
                _vm.moment(announcement.end_date, 'YYYY-MM-DD HH:mm:ss').isAfter(
                  _vm.moment()
                ) // & aktuell
                  ? 'border-color: #007bff !important;'
                  : announcement.status == 'public' // & vergangen oder in der Zukunft
                  ? 'border-color: #abd3ff !important;'
                  : '')},[(announcement.title)?_c('div',[_vm._v(" "+_vm._s(announcement.title)+" ")]):_vm._e(),_c('div',{staticClass:"small text-muted",domProps:{"innerHTML":_vm._s(announcement.content.plain)}})])])}),1):_c('div',{staticClass:"ml-1"},[_vm._v("keine Ankündigungen vorhanden")]),_c('div',{staticClass:"mt-3"},[_c('d-button',{staticClass:"btn-accent m-1",on:{"click":function($event){return _vm.$router.push({
                name: 'new_announcement',
                query: { types: [_vm.type.name] },
              })}}},[_vm._v("Hinzufügen")]),_c('d-button',{staticClass:"btn-light m-1",on:{"click":function($event){return _vm.$router.push({ name: 'announcements' })}}},[_vm._v("Alle Anzeigen")])],1)],1)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }