<template>
  <d-container fluid class="main-content-container px-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Nutzer</span>
        <h3 class="page-title">Benachrichtigungen</h3>
      </d-col>
    </d-row>

    <!-- Small Stats Blocks -->
    <d-row>
      <d-col cols="12" md="10" class="pr-md-0">
        <d-card class="card-small pb-2 mb-2">
          <d-card-body class="py-2 px-0">
            <b-list-group flush >
              <b-list-group-item v-for="notification in formated_notifications" :key="notification.create_date+'_'+notification.id" button @click="notification.onClick" :style="notification.onClick ? '' : 'pointer-events: none;'">
                <div class="d-flex justify-content-between mt-2">
                  <div>
                    <div class="font-weight-bold">{{ notification.title }}</div>
                    <small>{{ notification.text }}</small>
                  </div>
                  <div class="align-self-center"><b-link role="button" class="text-secondary material-icons" style="pointer-events: all;" @click.stop="handleCLickCloseNotification(notification)">delete</b-link></div>
                  <small class="font-italic ml-3 text-secondary" style="position: absolute; top: 0; left: 0;">{{ notification.create_date | moment("DD. MMM YY")}}</small>
                </div>
              </b-list-group-item>
            </b-list-group>
            <small class="ml-3">{{ notifications.length }} Benachrichtigungen</small>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import format_notifications from "@/plugins/format_notifications";

export default {
  data() {
    return {};
  },
  methods: {
    async handleCLickCloseNotification(notification) {
      await this.$store.dispatch('closeNotification', notification.id);
    }
  },
  computed: {
    ...mapGetters(["notifications", "company"]),
    formated_notifications() {
      return format_notifications(this.notifications, this);
    },
  },
  mounted() {
    this.formated_notifications.forEach(notification => {
      if (!notification.viewed) this.$store.dispatch('viewNotification', notification.id);
    })
  }
};
</script>

