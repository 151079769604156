var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_c('b-form-checkbox',{attrs:{"checked":!!_vm.participation_available,"disabled":""},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'participation_available',
                value: !!val,
              }); }}},[_vm._v("Teilnahmesystem aktiviert")]),_c('h4',[_vm._v("Stunden")]),_c('b-form-checkbox',{attrs:{"checked":!!_vm.lesson_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'lesson_default_allow_participation',
                  value: !!val,
                }).then(function () { return _vm.lesson_default_allow_participation = !!val; }); }}},[_vm._v("Standardmäßig aktiv")]),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"checked":!!_vm.lesson_default_register_mode_direct,"disabled":!_vm.lesson_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'lesson_default_register_mode_direct',
                  value: !!val,
                }); }}},[_vm._v("Kunden können standardmäßig direkt teilnehmen")]),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"checked":!!_vm.lesson_default_register_mode_request,"disabled":!_vm.lesson_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'lesson_default_register_mode_request',
                  value: !!val,
                }); }}},[_vm._v("Kunden können standardmäßig die Teilnahme anfragen")]),_c('b-form-group',{attrs:{"label":"Standard Teilnehmeranzahl"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.lesson_default_max_participators,"disabled":!_vm.lesson_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                    name: 'lesson_default_max_participators',
                    value: val ? (_vm.$store.getters.settings_obj.lesson_default_max_participators.value.default || 5) : null,
                  }).then(function () { return _vm.lesson_default_max_participators = val ? (_vm.$store.getters.settings_obj.lesson_default_max_participators.value.default || 5) : null; }); }}}),_c('b-input-group',{attrs:{"append":"Teilnehmer"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.lesson_default_max_participators,"disabled":_vm.lesson_default_max_participators === null,"min":"1","type":"number","placeholder":"Bitte zunächst aktivieren","debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                      name: 'lesson_default_max_participators',
                      value: parseInt(val),
                    }); }}})],1)],1)]),_c('h4',[_vm._v("Kurse")]),_c('b-form-checkbox',{attrs:{"checked":!!_vm.course_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'course_default_allow_participation',
                  value: !!val,
                }).then(function () { return _vm.course_default_allow_participation = !!val; }); }}},[_vm._v("Standardmäßig aktiv")]),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"checked":!!_vm.course_default_register_mode_direct,"disabled":!_vm.course_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'course_default_register_mode_direct',
                  value: !!val,
                }); }}},[_vm._v("Kunden können standardmäßig direkt teilnehmen")]),_c('b-form-checkbox',{staticClass:"ml-1",attrs:{"checked":!!_vm.course_default_register_mode_request,"disabled":!_vm.course_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'course_default_register_mode_request',
                  value: !!val,
                }); }}},[_vm._v("Kunden können standardmäßig die Teilnahme anfragen")]),_c('b-form-group',{attrs:{"label":"Standard Teilnehmeranzahl"}},[_c('div',{staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.course_default_max_participators,"disabled":!_vm.course_default_allow_participation},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                    name: 'course_default_max_participators',
                    value: val ? (_vm.$store.getters.settings_obj.course_default_max_participators.value.default || 5) : null,
                  }).then(function () { return _vm.course_default_max_participators = val ? (_vm.$store.getters.settings_obj.course_default_max_participators.value.default || 5) : null; }); }}}),_c('b-input-group',{attrs:{"append":"Teilnehmer"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.course_default_max_participators,"disabled":_vm.course_default_max_participators === null,"min":"1","type":"number","placeholder":"Bitte zunächst aktivieren","debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                      name: 'course_default_max_participators',
                      value: parseInt(val),
                    }); }}})],1)],1)])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }