<template>
  <d-container fluid class="main-content-container px-lg-4">
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span
          class="text-uppercase page-subtitle"
          @click="$router.push({ name: 'assets' })"
          style="cursor: pointer"
          >Vermögenswerte</span
        >
        <h3 class="page-title">Neuen Vermögenswert anlegen</h3>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <div class="m-3" v-if="!auth">
            Sie besitzen nicht die benötigten Rechte. Bitte wenden Sie sich an
            einen Administrator.
          </div>
          <d-card-body v-else class="d-flex flex-column px-2 px-lg-3">
            <b-form-group label="Typ">
              <small
                >Der Typ eines Vermögenswertes beeinflusst neben der Darstellung
                in Anwendungen auch, wann zum Beispiel Ihrem Fahrlehrer welches
                Fahrzeug für eine Unterrichtsstunde vorgeschlagen werden
                kann.</small
              >
              <b-form-select
                v-model="type"
                :options="type_options"
                :state="state_type"
              />
            </b-form-group>
            <b-form-group label="Name">
              <b-form-input
                placeholder="Name"
                v-model="name"
                :state="state_name"
              />
            </b-form-group>
            <b-form-group label="Kurzbeschreibung">
              <b-form-textarea
                placeholder="Kurbeschreibung"
                v-model="description"
              />
            </b-form-group>

            <div class="d-flex">
              <div>
                <b-button @click="submit" variant="success" class="mr-3"
                  >Anlegen</b-button
                >
              </div>
              <div class="small my-auto">
                Sobald das Objekt angelegt ist, können Sie weitere Einstellungen
                z.B. zur Vermietung festlegen.
              </div>
            </div>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BranchNew",
  data() {
    return {
      auth: null,
      validated: false,
      name: null,
      description: null,
      type: null,
      // OPTIONS
      type_options: [
        {
          text: "keine Auswahl",
          value: null,
        },
        {
          label: "Fahrzeuge",
          options: [
            {
              text: "PKW",
              value: "pkw",
            },
            {
              text: "LKW",
              value: "lkw",
            },
            {
              text: "Mofa",
              value: "mofa",
            },
            {
              text: "Moped (AM)",
              value: "bike_am",
            },
            {
              text: "Motorrad (A1)",
              value: "bike_a1",
            },
            {
              text: "Motorrad (A2)",
              value: "bike_a2",
            },
            {
              text: "Motorrad (A)",
              value: "bike_a",
            },
          ],
        },
        {
          label: "Geräte",
          options: [
            {
              text: "Simulator",
              value: "simulator",
            },
            {
              text: "PC",
              value: "pc",
            },
            {
              text: "Laptop",
              value: "laptop",
            },
            {
              text: "Beamer",
              value: "beamer",
            },
          ],
        },
        {
          text: "Anderes",
          value: "other",
        },
      ],
    };
  },
  methods: {
    validate() {
      this.validated = true;
      return this.state_name && this.state_type;
    },
    async submit() {
      if (!this.validate()) return;
      let asset = await this.$store.dispatch("createAsset", {
        name: this.name,
        description: this.description,
        type: this.type,
      });
      this.$router.push({ name: "asset", params: { id: asset.id } });
    },
  },
  computed: {
    state_name() {
      return !this.validated
        ? null
        : typeof this.name == "string" && this.name.length > 0;
    },
    state_type() {
      return !this.validated ? null : this.type != null;
    },
  },
  async created() {
    this.auth = await this.$store.dispatch("haveUserRight", "asset_modify");
  },
};
</script>
