<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div class="error" v-if="!data">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Filiale konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else>
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span
            class="text-uppercase page-subtitle"
            @click="$router.push({ name: 'branches' })"
            style="cursor: pointer"
            >Filialen</span
          >
          <h3 class="page-title">
            <template v-if="edit_title == false">
              {{ data.name }}<br class="d-sm-none" /><small
                style="cursor: pointer"
                @click="
                  edit.name = data.name;
                  edit_title = true;
                "
                v-if="auth_modify"
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span></small
              >
            </template>
            <div v-else class="d-flex mr-5">
              <b-form-input v-model="edit.name" />
              <b-button class="ml-2" @click="handleEditTitle">Ändern</b-button>
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_title = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3">
          <b-dropdown variant="light" right text="Weitere Aktionen">
            <b-dropdown-item @click="handleViewManageBranchesOccupation" disabled>Dienstplan öffnen</b-dropdown-item>
            <b-dropdown-item @click="handleClose" v-if="!data.closed">Filiale schließen</b-dropdown-item>
            <b-dropdown-item @click="hanldeReopen" v-else>Filiale wieder öffnen</b-dropdown-item>
            <b-dropdown-item @click="handleRemove">Filiale löschen</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="8">
          <d-card class="card-small mt-2">
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between">
                    Beschreibung
                    <div v-if="auth_modify">
                      <span v-if="edit_main==false" class="material-icons" @click="edit_main=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveMain" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_main=false; edit = {description: data.description, address: {...data.address}}; validated = false;" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                  <b-form-textarea v-if="edit_main" v-model="edit.description" placeholder="keine Beschreibung angegeben"/>
                  <small v-else>{{ data.description ? data.description : 'keine Beschreibung angegeben' }}</small>
              </b-form-group>
              <b-form-group label="Addresse">
              <b-row v-if="edit_main">
                <b-col cols="12" sm="9" class="mb-1">
                  <b-form-input
                    v-model="edit.address.street"
                    placeholder="Straße"
                    :state="state_street"
                  />
                </b-col>
                <b-col cols="12" sm="3" class="mb-1">
                  <b-form-input
                    v-model="edit.address.streetnumber"
                    min="0"
                    placeholder="Nr."
                    :state="state_streetnumber"
                  />
                </b-col>
                <b-col cols="12" sm="4" class="mb-1">
                  <b-form-input
                    v-model="edit.address.zip"
                    min="0"
                    max="99999"
                    type="number"
                    placeholder="PLZ"
                    :state="state_zip"
                  />
                </b-col>
                <b-col cols="12" sm="8" class="mb-1">
                  <b-form-input
                    placeholder="Stadt"
                    v-model="edit.address.city"
                    :state="state_city"
                  />
                </b-col>
              </b-row>
              <template v-else>
                <div>{{ data.address.street + " " + data.address.streetnumber }}</div>
                <div>{{ data.address.zip + " " + data.address.city }}</div>
              </template>
            </b-form-group>
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between">
                    Emails
                    <div v-if="auth_modify">
                      <span v-if="edit_emails==false" class="material-icons" @click="edit_emails=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveEmails" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_emails=false; emails=[...data.emails];" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <Emails v-model="emails" class="ml-1" :edit="edit_emails ? null : undefined" :key="edit_emails"/>
              </b-form-group>
              <b-form-group label="Telefonnummern/Fax">
                <template slot="label">
                  <div class="d-flex justify-content-between">
                    Telefonnummern/Fax
                    <div v-if="auth_modify">
                      <span v-if="edit_phone_numbers==false" class="material-icons" @click="edit_phone_numbers=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSavePhoneNumbers" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_phone_numbers=false; phone_numbers=[...data.phone_numbers];" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <PhoneNumbers v-model="phone_numbers" class="ml-1" :edit="edit_phone_numbers ? null : undefined" :key="edit_phone_numbers"/>
              </b-form-group>
            </d-card-body>
          </d-card>
          <!-- Current Lessons -->
          <d-card class="card-small mt-4" v-if="currentLessons.length">
            <d-card-header class="border-bottom d-flex justify-content-between">Aktuelle Unterrichtsstunden
            </d-card-header>
            <d-card-body class="p-0 pb-2">
              <b-list-group flush>
                <b-list-group-item href="#" v-for="lesson in currentLessons" :key="lesson.id" @click="$router.push({name: 'lesson', params: {id: lesson.id}})"><strong v-if="moment(lesson.date.start,'YYYY-MM-DD').year()==moment().year()">{{ lesson.date.start | moment("D.MM.") }}</strong><strong v-else>{{ lesson.date.start | moment("D.MM.YYYY") }}</strong> <span class="d-none d-md-inline">{{ lesson.processed_unit_name }}</span><span class="d-md-none">{{ lesson.processed_unit_key_name }}</span></b-list-group-item>
              </b-list-group>
            </d-card-body>
          </d-card>
          <div v-else class="m-3 small">derzeit sind keine Unterrichtsstunden für diese Filiale geplant.</div>
        </b-col>
        <d-col cols="12" lg="4">
          <d-card class="card-small mt-2">
            <d-card-header class="border-bottom d-flex justify-content-between">Öffnungszeiten
              <template v-if="auth_modify">
                <span v-if="edit_openHours==false" class="material-icons" @click="edit_openHours=true" style="cursor:pointer;">create</span>
                <div v-else>
                  <span class="material-icons" @click="handleSaveOpenHours" style="cursor:pointer;">check</span>
                  <span class="material-icons" @click="edit_openHours=false; openHours=[...data.openHours];" style="cursor:pointer;">close</span>
                </div>
              </template>
            </d-card-header>
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              <OpenHours v-if="openHours" v-model="openHours" :edit="edit_openHours ? null : undefined" :key="edit_openHours"/>
            </d-card-body>
          </d-card>
          <d-card class="card-small mt-2">
            <d-card-header class="border-bottom d-flex justify-content-between">
              Medien
            </d-card-header>
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              Hier können Sie später Bilder hinterlegen
            </d-card-body>
          </d-card>
        </d-col>
      </b-row>
    </template>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import Emails from "@/components/communication/Emails.vue";
import PhoneNumbers from "@/components/communication/PhoneNumbers.vue";
import OpenHours from "@/components/company/branch/OpenHours.vue";
import LessonList from '../../../components/education/lesson/LessonList.vue';
import moment from "moment";
export default {
  name: "CourseShow",
  components: {
    Emails,
    PhoneNumbers,
    OpenHours,
    LessonList,
  },
  data() {
    return {
      moment,
      auth_modify: false,
      data: null,
      // TITLE
      edit_title: false,
      // MAIN
      edit: null, // Copy of data, which can altered by user
      edit_main: false,
      validated: false,
      // OpenHours
      edit_openHours: false,
      openHours: null,
      // Emails
      edit_emails: false,
      emails: null,
      // PhoneNumbers
      edit_phone_numbers: false,
      phone_numbers: null,
      // CurrentLessons
      loading_currentLessons: null,
      currentLessons: []
    };
  },
  computed: {
    ...mapGetters(["branches"]),
    state_street() {
      return !this.validated
        ? null
        : typeof this.edit.address.street == "string" &&
            this.edit.address.street.length > 0;
    },
    state_streetnumber() {
      return !this.validated
        ? null
        : typeof this.edit.address.streetnumber == "string" &&
            this.edit.address.streetnumber.length > 0;
    },
    state_zip() {
      return !this.validated
        ? null
        : typeof this.edit.address.zip == "string" && this.edit.address.zip.length > 0;
    },
    state_city() {
      return !this.validated
        ? null
        : typeof this.edit.address.city == "string" && this.edit.address.city.length > 0;
    },
    state_address() {
      return !this.validated
        ? null
        : this.state_street &&
            this.state_streetnumber &&
            this.state_zip &&
            this.state_city;
    },
  },
  methods: {
    async init() {
      this.data = this.branches.find(
        (branch) => branch.id == this.$route.params.id
      );
      if (!this.data) return;
      this.edit = {
        name: this.data.name,
        description: this.data.description,
        address: {...this.data.address},
      };
      this.openHours = [...this.data.openHours];
      this.emails = [...this.data.emails];
      this.phone_numbers = [...this.data.phone_numbers];
      this.load_currentLessons();
    },
    async load_currentLessons() {
      this.loading_currentLessons = true;
      this.currentLessons = await this.$store.dispatch("getLessons", {
        branch: this.data.id,
      });
      this.loading_currentLessons = false;
    },
    async handleEditTitle() {
      if (
        !this.data.processed_unit_name &&
        (this.edit.name == null || this.edit.name.trim() == "")
      )
        return;
      await this.$store.dispatch("updateBranch", {
        id: this.data.id,
        name: this.edit.name,
      });
      this.edit_title = false;
    },
    validate() {
      this.validated = true;
      return this.state_address;
    },
    async handleSaveMain() {
      if (!this.validate()) return;
      await this.$store.dispatch('updateBranch', {
        id: this.data.id,
        description: this.edit.description,
        address: this.edit.address
      });
      this.validated = false;
      this.edit = {
        name: this.data.name,
        description: this.data.description,
        address: {...this.data.address},
      };
      this.edit_main = false;
    },
    async handleSaveEmails() {
      await this.$store.dispatch('updateBranch', {
        id: this.data.id,
        emails: this.emails
      });
      this.emails = [...this.data.emails];
      this.edit_emails = false;
    },
    async handleSavePhoneNumbers() {
      await this.$store.dispatch('updateBranch', {
        id: this.data.id,
        phone_numbers: this.phone_numbers
      });
      this.phone_numbers = [...this.data.phone_numbers];
      this.edit_phone_numbers = false;
    },
    async handleSaveOpenHours() {
      await this.$store.dispatch('updateBranch', {
        id: this.data.id,
        openHours: this.openHours
      });
      this.openHours = [...this.data.openHours];
      this.edit_openHours = false;
    },
    handleViewManageBranchesOccupation() {

    },
    async handleClose() {
      let result = await this.$store
        .dispatch("closeBranch", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.closed = true;
    },
    async handleReopen() {
      let result = await this.$store
        .dispatch("reopenBranch", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.data.closed = false;
    },
    async handleRemove() {
      // Check currentLessons
      let result = await this.$store
        .dispatch("removeBranch", { id: this.data.id })
        .catch((err) => {
          this.init();
          throw err;
        });
      if (!result) return;
      this.$router.push({ name: "branches" });
    },
  },
  watch: {
    branches() {
      this.init();
    }
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "branch_modify"
    );
    this.init();
  },
};
</script>
