<template>
  <div v-if="edit!==undefined">
    <b-row
      v-for="day in weekdays"
      :key="day.id"
    >
      <b-col>{{ day.name }}</b-col>
      <b-col class="text-right">
        <div
          v-if="
            openHours.filter((elem) => elem.open_day == day.value)
              .length == 0
          "
          class="font-italic"
        >
          nicht geöffnet
          <i v-if="edit!==undefined" class="material-icons" style="cursor: pointer;" @click="add(day)">add</i>
        </div>
        <template v-else>
          <div v-if="edit!==undefined" class="text-right"><span class="material-icons" style="cursor: pointer;" @click="add(day)">add</span></div>
          <div
            v-for="(elem, index) in openHours.filter(
              (elem) => elem.open_day == day.value
            )"
            :key="index"
            class="d-flex text-right"
          >
              <b-form-input
                id="open_time"
                type="time"
                v-model="elem.open_time"
                :state="validate_time(elem)"
                size="sm"
                class="mr-2"
              ></b-form-input>
            -
              <b-form-input
                type="time"
                v-model="elem.close_time"
                :state="validate_time(elem)"
                size="sm"
                class="ml-2"
              ></b-form-input>
            <span v-if="edit!==undefined" class="material-icons ml-2 my-auto" style="cursor: pointer;" @click="remove(elem)">delete</span>
            <div v-if="elem.open_day != elem.close_day" class="small font-italic">
              (schließt am
              {{ weekdays.find((el) => el.value == elem.close_day).name }})
            </div>
          </div>
        </template>
      </b-col>
    </b-row>
  </div>
  <div v-else>
    <div
      v-for="day in weekdays"
      :key="day.id"
      class="d-flex justify-content-between"
    >
      <div>{{ day.name }}</div>
      <div>
        <div
          v-if="
            openHours.filter((elem) => elem.open_day == day.value)
              .length == 0
          "
          class="font-italic"
        >
          nicht geöffnet
        </div>
          <div
            v-for="elem in openHours.filter(
              (elem) => elem.open_day == day.value
            )"
            :key="elem.open_day"
            class="text-right"
          >
            {{ [elem.open_time, ["HH:mm:ss", "HH:mm"]] | moment("H:mm") }} -
            {{ [elem.close_time, ["HH:mm:ss", "HH:mm"]] | moment("H:mm") }}
            <div v-if="elem.open_day != elem.close_day" class="small font-italic">
              (schließt am
              {{ weekdays.find((el) => el.value == elem.close_day).name }})
            </div>
          </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";
import weekdays from "@/data/weekdays";
import {
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
export default {
  props: {
    data: {
      type: Array,
      required: true,
      validator(data) {
        data.forEach(el => {
          if (!el.open_day || !el.open_time || !el.close_day || !el.close_time) return false;
        })
        return true;
      }
    },
    edit: {
      required: false
    }
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  data() {
    return {
      openHours: null,
      weekdays,
      TimePickerConfig,
    };
  },
  methods: {
    reset() {
      this.openHours = this.data.map(el => {
        return el;
      });
    },
    add(day) {
      if (this.openHours.find(el => el.open_day==day.value && (el.open_time==null || el.close_time==null))) return;
      this.openHours.push({
        open_day: day.value,
        open_time: null,
        close_day: day.value,
        close_time: null,
      });
    },
    remove(elem) {
      this.openHours = this.openHours.filter(el => !(el.open_day==elem.open_day && el.open_time==elem.open_time && el.close_day==elem.close_day && el.close_time==elem.close_time));
    },
    validate() {
      let openHours = this.openHours.filter(el => el.open_time!==null && el.close_time!==null);
      for (let i in openHours) {
        let el = openHours[i];
        let open_time = moment(el.open_time, "HH:mm:ss");
        if (!open_time.isValid()) return false;
        let close_time = moment(el.close_time, "HH:mm:ss");
        if (!close_time.isValid()) return false;
        if (open_time.isSameOrAfter(close_time)) return false;
      }
      return true;
    },
    validate_time(elem) {
      if (elem.open_time == null || elem.close_time == null) return null;
      let open_time = moment(elem.open_time, "HH:mm:ss");
      if (!open_time.isValid()) return false;
      let close_time = moment(elem.close_time, "HH:mm:ss");
      if (!close_time.isValid()) return false;
      if (open_time.isSameOrAfter(close_time)) return false;
      return null;
    },
  },
  watch: {
    openHours: {
      handler(val) {
        if (!this.validate()) return;
        let output = val
          .filter(el => el.open_time!==null && el.close_time!==null)
          .map(el => {
            return {
              open_day: el.open_day,
              open_time: moment(el.open_time, "HH:mm:ss").format("HH:mm:ss"),
              close_day: el.close_day,
              close_time: moment(el.close_time, "HH:mm:ss").format("HH:mm:ss"),
            }
          });
        this.$emit('input', output);
      },
      deep: true
    }
  },
  created() {
    this.reset();
  }
}
</script>
