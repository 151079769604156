import Vue from 'vue'
import API from '@/plugins/api.js'
import APIMethod from '@/plugins/apimethod.js'
import GlobalMethod from '@/plugins/globals.js'
import Swal from "sweetalert2";
// import weekdays from "@/data/weekdays";

Vue.use(API)
Vue.use(APIMethod)
Vue.use(GlobalMethod)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: {
    updateIntervalID: null,
    data: null,
    branches: [],
    assets: [],
  },
  mutations: {
    setCompany(state, input) {
      state.data = input;
    },
    setBranches(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.branches = input;
    },
    setAssets(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.assets = input;
    },
    resetCompanyData(state) {
      state.data = null;
      state.branches = [];
    }
  },
  actions: {
    async updateCompanyData(context) {
      await Promise.all([
        context.dispatch('updateBranches'),
        context.dispatch('updateAssets'),
        context.dispatch('updateCompanyState'),
      ]);
      if (!context.state.updateIntervalID) context.state.updateIntervalID = setInterval(() => context.dispatch('updateCompanyData'), 5*60*1000);
    },
    async updateCompanyState(context) {
      await store.$api.get("company")
        .then(response => context.commit("setCompany", response.data.result));
    },
    async updateBranches(context) {
      await store.$api.get("company/branch")
        .then(response => context.commit("setBranches", response.data.result));
    },
    async updateAssets(context) {
      await store.$api.get("company/asset")
        .then(response => context.commit("setAssets", response.data.result));
    },
    async updateCompany(context, input) {
      let response = await store.$api.put(`company`, input).catch(store.$apimethod.defaulthandler);
      context.commit('setCompany', response.data.result);
      store.$globalmethod.toast_success("Basisdaten aktualisiert.");
      return response.data.result;
    },
    async createBranch(context, input) {
      let response = await store.$api.post('company/branch', input);
      let newBranch = response.data.result;
      context.commit('setBranches', [...context.state.branches, newBranch]);
      store.$globalmethod.toast_success("Filiale angelegt.");
      return newBranch;
    },
    async updateBranch(context, input) {
      if (!input || !input.id) throw new Error("INVALID_PARAMETER(id)");
      let branch = context.getters.branches.find(el => el.id == input.id);
      if (!branch) throw new Error("NOT_FOUND");
      // Change
      let response = await store.$api.put(`company/branch/${branch.id}`, input).catch(store.$apimethod.defaulthandler);
      let newBranch = response.data.result;
      store.$globalmethod.toast_success("Filiale aktualisiert.");
      // Update State
      context.commit('setBranches', context.state.branches.map(el => {
        if (el.id == branch.id) return newBranch;
        return el;
      }));
      return newBranch;
    },
    async closeBranch(context, { id, hideAlert } = {}) {
      let branch = context.getters.branches.find(el => el.id == id);
      if (!branch) throw new Error("NOT_FOUND");
      if (branch.closed) throw new Error("NOT_ALLOWED");
      // Check current Lessons
      let lessons = await context.dispatch('getLessons', { branch: branch.id });
      if (lessons.length > 0) {
        store.$globalmethod.toast_info("Die Filiale kann nicht geschlossen werden, da noch Unterrichtsstunden in der Zukunft bei dieser Filiale stattfinden.");
        return false;
      }
      // If hideAlert not set, ask the user if he is sure
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Filiale wirklich schließen?",
          text: "Sie können Sie jederzeit wieder eröffnen.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.post(`company/branch/${id}/close`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Filiale geschlossen.");
      // Update State
      context.commit('setBranches', context.state.branches.map(el => {
        if (el.id == id) el.closed = true;
        return el;
      }));
      return true;
    },
    async reopenBranch(context, { id, hideAlert } = {}) {
      let branch = context.getters.branches.find(el => el.id == id);
      if (!branch) throw new Error("NOT_FOUND");
      if (branch.closed == false) throw new Error("NOT_ALLOWED");
      // If hideAlert not set, ask the user if he is sure
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Filiale wieder eröffnen?",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.post(`company/branch/${id}/reopen`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Filiale wiedereröffnet.");
      // Update State
      context.commit('setBranches', context.state.branches.map(el => {
        if (el.id == id) el.closed = false;
        return el;
      }));
      return true;
    },
    async removeBranch(context, { id, hideAlert } = {}) {
      let branch = context.getters.branches.find(el => el.id == id);
      if (!branch) throw new Error("NOT_FOUND");
      // Check current Lessons
      let lessons = await context.dispatch('getLessons', { branch: branch.id });
      if (lessons.length > 0) {
        store.$globalmethod.toast_info("Die Filiale kann nicht gelöscht werden, da noch Unterrichtsstunden in der Zukunft bei dieser Filiale stattfinden.");
        return false;
      }
      // If hideAlert not set, ask the user if he is sure
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Wollen Sie diese Filiale wirklich löschen?",
          text: "Damit ist diese nicht mehr aufrufbar und es können hier auch keine Theoriestunden mehr stattfinden.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`company/branch/${id}`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Filiale gelöscht.");
      // Update State
      context.commit('setBranches', context.state.branches.filter(el => el.id != id));
      return true;
    },
    // ASSETS
    async createAsset(context, input) {
      let response = await store.$api.post('company/asset', input);
      let newAsset = response.data.result;
      context.commit('setAssets', [...context.state.assets, newAsset]);
      // if (!hideAlert)
      store.$globalmethod.toast_success("Vermögenswert angelegt.");
      return newAsset;
    },
    async updateAsset(context, input) {
      if (!input || !input.id) throw new Error("INVALID_PARAMETER(id)");
      let asset = context.getters.assets.find(el => el.id == input.id);
      if (!asset) throw new Error("NOT_FOUND");
      let response = await store.$api.put(`company/asset/${asset.id}`, input).catch(store.$apimethod.defaulthandler);
      let newAsset = response.data.result;
      store.$globalmethod.toast_success("Vermögenswert aktualisiert.");
      // Update State
      context.commit('setAssets', context.state.assets.map(el => {
        if (el.id == asset.id) return newAsset;
        return el;
      }));
      return newAsset;
    },
    async removeAsset(context, { id, hideAlert } = {}) {
      let asset = context.getters.assets.find(el => el.id == id);
      if (!asset) throw new Error("NOT_FOUND");

      // If hideAlert not set, ask the user if he is sure
      if (!hideAlert) {
        let result = await Swal.fire({
          title: "Wollen Sie diesen Vermögenswert wirklich löschen?",
          text: "Hierdurch wird dieser an allen Verwendungsstellen entfernt und kann nicht mehr aufgerufen werden. Falls sie diesen Vermögenswert lediglich nicht mehr verwenden wollen, sollten Sie die Teilnahmefunktionen zunächst deaktivieren.",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Bestätigen",
          cancelButtonText: "Abbrechen",
        });
        if (!result.value) return false;
      }
      await store.$api.delete(`company/asset/${id}`).catch(store.$apimethod.defaulthandler);
      store.$globalmethod.toast_success("Vermögenswert gelöscht.");
      // Update State
      context.commit('setAssets', context.state.assets.filter(el => el.id != id));
    },
  },
  getters: {
    company(state) {
      return state.data;
    },
    company_loaded(state) {
      return (state.data && state.data !== null)
    },
    company_name(state) {
      if (!(state.data && state.data.name)) return null
      return state.data.name;
    },
    branches(state) {
      return state.branches;
    },
    assets(state) {
      return state.assets;
    },
  }
}
export default module_branch
