<template>
  <d-container fluid class="main-content-container px-lg-4">
    <template v-if="!loaded">
      <d-container fluid class="main-content-container px-4 pb-4">
        <!-- Error -->
        <div class="error">
          <div class="error__content">
            <h2>Lädt...</h2>
            <h3>Bitte warten</h3>
            <p>Benötigte Daten werden geladen.</p>
          </div>
        </div>
      </d-container>
    </template>
    <!-- Overview -->
    <template v-else-if="view == 1">
      <d-row no-gutters class="page-header py-4 justify-content-between">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span class="text-uppercase page-subtitle">Kundenkontakt</span>
          <h3 class="page-title">
            Ankündigungen
            {{
              filter == "branches"
                ? "zu Filialen"
                : filter == "classes"
                ? "zum Unterricht"
                : filter == "google_mybusiness"
                ? "auf Google Maps"
                : filter == "branch" && branches.find((el) => el.id == id)
                ? `zur Filiale ${branches.find((el) => el.id == id).name}`
                : ""
            }}
          </h3>
          <b-button
            class="mt-4"
            variant="outline-dark"
            @click="createAnnouncementForType()"
            >Neue Ankündigung erstellen</b-button
          >
        </b-col>
        <b-col cols="auto" class="px-2">
          <div class="text-left">
            <div
              v-if="filter"
              style="cursor: pointer"
              @click="$router.push({ name: 'announcements' })"
            >
              <i class="material-icons">label_important</i>
              Alle
            </div>
            <div
              v-if="filter != 'branches'"
              style="cursor: pointer"
              @click="$router.push({ name: 'branches_announcements' })"
            >
              <i class="material-icons">home</i>
              Filialen
            </div>
            <div
              v-if="filter != 'classes'"
              style="cursor: pointer"
              @click="$router.push({ name: 'classes_announcements' })"
            >
              <i class="material-icons">account_balance</i>
              Unterrichtssystem
            </div>
            <div
              v-if="filter != 'google_mybusiness'"
              style="cursor: pointer"
              @click="$router.push({ name: 'gmb_announcements' })"
            >
              <i class="fab fa-google" />
              Google My Business
            </div>
            <div
              class="text-danger"
              style="cursor: pointer"
              @click="
                $router.push({
                  name: 'announcements',
                  query: { name: 'covid_19' },
                })
              "
            >
              <i class="material-icons">coronavirus</i>
              Covid-19
            </div>
          </div>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col>
          <b-card no-body>
            <b-tabs card content-class="border-top">
              <b-tab title="Nach Typen" class="p-0">
                <b-card-body class="p-0 pb-4">
                  <b-list-group flush>
                    <b-list-group-item
                      class="border"
                      style="cursor: pointer"
                      v-for="type in filteredAnnouncementTypes"
                      :key="type.name"
                      @click="
                        $router.push({
                          name: 'announcements',
                          query: { name: type.name },
                        })
                      "
                    >
                      <div class="font-weight-bold">{{ type.label }}</div>
                      <div class="ml-2 small text-muted">
                        <span
                          >{{
                            announcements.filter(
                              (announcement) =>
                                announcement.status == "public" &&
                                announcement.types.find(
                                  (type_name) => type_name == type.name
                                )
                            ).length
                          }}
                          <template v-if="type.max">/ {{ type.max }} </template
                          >aktiv</span
                        >
                        <!-- GEPLANT -->
                        <span
                          v-if="
                            announcements.filter(
                              (announcement) =>
                                announcement.status == 'private' &&
                                announcement.types.find(
                                  (type_name) => type_name == type.name
                                )
                            ).length > 0
                          "
                          >,
                          {{
                            announcements.filter(
                              (announcement) =>
                                announcement.status == "private" &&
                                announcement.types.find(
                                  (type_name) => type_name == type.name
                                )
                            ).length
                          }}
                          nicht veröffentlicht</span
                        >
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-tab>
              <b-tab title="Alle" class="p-0">
                <b-card-body class="p-0 pb-4">
                  <b-list-group v-if="filteredAnnouncements.length > 0" flush>
                    <b-list-group-item
                      class="p-0"
                      style="cursor: pointer"
                      v-for="announcement in filteredAnnouncements"
                      :key="announcement.id"
                      @click="
                        $router.push({
                          name: 'announcement',
                          params: { id: announcement.id },
                        })
                      "
                    >
                      <div
                        class="border-left pl-4"
                        style="border-width: 4px !important"
                        :style="
                          announcement.status == 'public' &&
                          moment(
                            announcement.start_date,
                            'YYYY-MM-DD HH:mm:ss'
                          ).isBefore(moment()) &&
                          moment(
                            announcement.end_date,
                            'YYYY-MM-DD HH:mm:ss'
                          ).isAfter(moment()) // & aktuell
                            ? 'border-color: #007bff !important;'
                            : announcement.status == 'public' // & vergangen oder in der Zukunft
                            ? 'border-color: #abd3ff !important;'
                            : ''
                        "
                      >
                        <div class="font-weight-bold">
                          {{
                            announcement_types
                              .filter((type) =>
                                announcement.types.find(
                                  (type_name) => type_name == type.name
                                )
                              )
                              .map((el) => el.label)
                              .join(", ")
                          }}
                        </div>
                        <div v-if="announcement.title">
                          {{ announcement.title }}
                        </div>
                        <div
                          class="small text-muted"
                          v-html="announcement.content.html"
                        ></div>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <div v-else class="text-center mt-3">
                    keine Ankündigungen vorhanden
                  </div>
                </b-card-body>
              </b-tab>
            </b-tabs>
          </b-card>
          <div class="mt-2 d-flex justify-content-center">
            <div
              class="border-left mx-2 pl-2"
              style="
                border-width: 4px !important;
                border-color: #007bff !important;
              "
            >
              Veröffentlicht
            </div>
            <div
              class="border-left mx-2 pl-2"
              style="
                border-width: 4px !important;
                border-color: #abd3ff !important;
              "
            >
              Geplant oder abgelaufen
            </div>
            <div
              class="border-left mx-2 pl-2"
              style="border-width: 4px !important"
            >
              Privat
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
    <!-- Type-Overview -->
    <template v-else-if="view == 2">
      <d-row no-gutters class="page-header py-4 justify-content-between">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span class="text-uppercase page-subtitle">Kundenkontakt</span>
          <h3 class="page-title">
            Ankündigungen zum Typ:
            <span class="text-muted">{{ currentType.label }}</span>
          </h3>
          <div v-if="currentType.description" class="text-muted small mt-2">
            {{ currentType.description }}
          </div>
        </b-col>
        <b-col cols="auto" class="px-2">
          <div class="text-right">
            <div
              style="cursor: pointer"
              @click="$router.push({ name: 'announcements' })"
            >
              <i class="material-icons">keyboard_backspace</i>
              Zurück
            </div>
            <div class="mt-3">
              <b-button
                variant="outline-dark"
                @click="createAnnouncementForType(type_name)"
                >Neue Ankündigung erstellen</b-button
              >
            </div>
          </div>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col>
          <b-card body-class="p-0">
            <b-card-body body-class="p-0 py-2">
              <b-list-group v-if="filteredAnnouncements.length > 0" flush>
                <b-list-group-item
                  class="p-0"
                  style="cursor: pointer"
                  v-for="announcement in filteredAnnouncements"
                  :key="announcement.id"
                  @click="
                    $router.push({
                      name: 'announcement',
                      params: { id: announcement.id },
                    })
                  "
                >
                  <div
                    class="border-left pl-4"
                    style="border-width: 4px !important"
                    :style="
                      announcement.status == 'public' &&
                      moment(
                        announcement.start_date,
                        'YYYY-MM-DD HH:mm:ss'
                      ).isBefore(moment()) &&
                      moment(
                        announcement.end_date,
                        'YYYY-MM-DD HH:mm:ss'
                      ).isAfter(moment()) // & aktuell
                        ? 'border-color: #007bff !important;'
                        : announcement.status == 'public' // & vergangen oder in der Zukunft
                        ? 'border-color: #abd3ff !important;'
                        : ''
                    "
                  >
                    <div class="font-weight-bold">
                      {{
                        announcement_types
                          .filter((type) =>
                            announcement.types.find(
                              (type_name) => type_name == type.name
                            )
                          )
                          .map((el) => el.label)
                          .join(", ")
                      }}
                    </div>
                    <div v-if="announcement.title">
                      {{ announcement.title }}
                    </div>
                    <div
                      class="small text-muted"
                      v-html="announcement.content.html"
                    ></div>
                  </div>
                </b-list-group-item>
              </b-list-group>
              <div v-else class="text-center">
                keine Ankündigungen vorhanden
              </div>
            </b-card-body>
          </b-card>
          <div class="mt-2 d-flex justify-content-center">
            <div
              class="border-left mx-2 pl-2"
              style="
                border-width: 4px !important;
                border-color: #007bff !important;
              "
            >
              Veröffentlicht
            </div>
            <div
              class="border-left mx-2 pl-2"
              style="
                border-width: 4px !important;
                border-color: #abd3ff !important;
              "
            >
              Geplant oder abgelaufen
            </div>
            <div
              class="border-left mx-2 pl-2"
              style="border-width: 4px !important"
            >
              Privat
            </div>
          </div>
        </b-col>
      </b-row>
    </template>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
// TODO: abruf alter Ankündigungen
export default {
  name: "AnnouncementOverview",
  props: {
    filter: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      auth_modify: false,
      loaded: false,
      view: 1,
      id: null, // id for better filtering
      type_name: null, // name of an announcement_type to display all active announcements
      moment,
    };
  },
  computed: {
    // announcement_types
    ...mapGetters(["branches", "announcement_types", "announcements"]),
    filteredAnnouncementTypes() {
      return this.announcement_types.filter((announcement_type) => {
        if (
          this.filter == "branches" &&
          !announcement_type.name.includes("branch_")
        )
          return false;
        if (
          this.filter == "branch" &&
          this.id &&
          !announcement_type.name.includes("branch_" + this.id)
        )
          return false;
        if (
          this.filter == "classes" &&
          !announcement_type.name.includes("category_")
        )
          return false;
        if (
          this.filter == "google_mybusiness" &&
          !announcement_type.name.includes("gmb_")
        )
          return false;
        return true;
      });
    },
    filteredAnnouncements() {
      return this.announcements.filter((announcement) =>
        announcement.types.find(
          (type_name) =>
            this.filteredAnnouncementTypes.find(
              (type) => type.name == type_name
            ) &&
            (!this.type_name || this.type_name == type_name)
        )
      );
    },
    currentType() {
      return this.type_name
        ? this.announcement_types.find((type) => type.name == this.type_name)
        : null;
    },
  },
  watch: {
    filter() {
      this.init();
    },
    "$route.query.name": function (val) {
      if (val) this.showType(val);
      else this.showOverview();
    },
  },
  methods: {
    init() {
      // get old view of this component
      if (localStorage && localStorage.getItem("announcement_view")) {
        try {
          let obj = JSON.parse(localStorage.getItem("announcement_view"));
          if ([1, 2].includes(obj.view)) this.view = obj.view;
          if (typeof obj.type_name == "string" || obj.type_name === null)
            this.type_name = obj.type_name;
        } catch (err) {
          console.warn(
            "Error while parsing localStorageItem: 'announcement_view'",
            err
          );
        }
      }
      if (this.$route.query.name) this.showType(this.$route.query.name);
      else this.showOverview();
      switch (this.filter) {
        case undefined:
        case "branches":
        case "classes":
        case "google_mybusiness":
          this.id = null;
          break;
        case "branch":
          this.id = this.$route.params.id;
        default:
          break;
      }
    },
    setView(view_id, type_name = null) {
      this.view = view_id;
      this.type_name = type_name;
      if (localStorage)
        localStorage.setItem(
          "announcement_view",
          JSON.stringify({
            view: this.view,
            type_name: this.type_name,
          })
        );
    },
    showOverview() {
      this.setView(1, null);
      if (this.$route.query.name) throw new Error();
    },
    showType(type_name) {
      this.setView(2, type_name);
    },
    createAnnouncementForType(type_name) {
      this.$router.push({
        name: "new_announcement",
        query: { default_types: [type_name] },
      });
    },
  },
  async created() {
    this.init();
    await Promise.all([
      (async () => {
        this.auth_modify = await this.$store.dispatch(
          "haveUserRight",
          "announcements_modify"
        );
      })(),
      this.$store.dispatch("initAnnouncementModule"),
    ]);
    this.loaded = true;
  },
};
</script>
