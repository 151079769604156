<template>
  <d-container fluid class="main-content-container px-lg-4">
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span
          class="text-uppercase page-subtitle"
          @click="$router.push({ name: 'courses' })"
          style="cursor: pointer"
          >Kurse</span
        >
        <h3 class="page-title">Neuen Kurs anlegen</h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3">
        <b-form-group
          label="Kategorie"
          label-size="sm"
          label-align="center"
          label-align-sm="left"
          label-class="pb-1"
          class="text-center text-sm-left"
        >
          <CategorySelector />
        </b-form-group>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <!-- Component Header -->
          <d-card-header class="border-bottom d-none d-md-block">
            <h6 class="m-0">Kurs anlegen</h6>
          </d-card-header>

          <d-card-body class="d-flex flex-column px-0 px-lg-3">
            <Course
              ref="course_card"
              :redirect="redirect"
              v-on:finish="finish"
            />
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import Course from "@/components/education/course/Course";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
export default {
  name: "LessonNew",
  props: {
    redirect: {
      default: true,
      type: Boolean,
    },
  },
  components: {
    Course,
    CategorySelector,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["categories"]),
  },
  methods: {
    finish(result) {
      if (this.redirect) this.$router.push({name: "course", params: { id: result.id }});
      this.$refs.course_card.init();
    },
  },
};
</script>
