<template>
  <d-container fluid class="main-content-container px-4">
    <div v-if="!(loading === false)">Loading</div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Dieser Nutzer konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else-if="!notFound">
      <!-- Page Header -->
      <d-row no-gutters class="page-header py-4">
        <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
          <span class="text-uppercase page-subtitle">Nutzerverwaltung</span>
          <h3 class="page-title">Nutzer</h3>
        </d-col>
        <b-col class="text-center text-md-right" v-if="false">
          <b-button
            :disabled="!auth_auth_modify"
            variant="link"
            class="text-secondary"
            @click="openAuth"
            >Berechtigungen anpassen</b-button
          >
        </b-col>
      </d-row>

      <!-- Content -->
      <d-row>
        <d-col lg="4">
          <d-card class="card-small mb-4 pt-3">
            <!-- Card Header -->
            <d-card-header class="text-center mb-2">
              <!-- User Avatar -->
              <div class="mb-3 mx-auto">
                <b-avatar :src="profile_url" :alt="user.name" size="6rem" />
              </div>

              <!-- User Name -->
              <h4 class="mb-0">{{ user.name }}</h4>

              <!-- username -->
              <div class="text-muted d-block">
                {{ user.username }}{{ isCurrentUser ? " (Sie selbst)" : "" }}
              </div>
              <!-- username -->
              <div v-if="user.email" class="text-muted d-block">
                {{ user.email }}
              </div>
              <div class="mb-2"></div>

              <!-- User Pills -->
              <b-badge
                v-if="isEmployee"
                pill
                variant="outline-primary"
                class="mx-1"
              >
                <i class="material-icons mr-1">face</i> Mitarbeiter
              </b-badge>
              <b-badge
                v-if="isInstructor"
                pill
                variant="outline-secondary"
                class="mx-1"
              >
                <i class="material-icons mr-1">account_balance</i> Lehrer
              </b-badge>
              <b-badge
                v-if="isOfficeWorker"
                pill
                variant="outline-secondary"
                class="mx-1"
              >
                <i class="material-icons mr-1">work</i> Büro
              </b-badge>
            </d-card-header>
          </d-card>
          <template>
          <b-button
            :disabled="!auth_email_modify"
            variant="link"
            class="text-secondary"
            @click="openChangeEmail"
            >Email ändern</b-button
          ><br />
          <b-button
            :disabled="!auth_password_modify"
            variant="link"
            class="text-secondary"
            @click="openChangePW"
            >Passwort ändern</b-button
          ><br />
          <b-button
            :disabled="!auth_password_reset"
            variant="link"
            class="text-secondary"
            @click="changePWRequest"
            >Passwort zurücksetzen</b-button
          ><br />
          <b-button
            :disabled="!auth_auth_modify"
            variant="link"
            class="text-warning"
            @click="revertLoginRight"
            >Nutzer deaktivieren</b-button
          ><br />
          <b-button
            :disabled="!auth_user_phys_delete"
            variant="link"
            class="text-danger"
            @click="phys_delete"
            >Nutzer löschen</b-button
          >
          </template>
        </d-col>
        <d-col lg="8">
          <d-card class="card-small mb-4">
            <!-- Card Header -->
            <d-card-header class="border-bottom">
              <h6 class="m-0">Personendaten</h6>
            </d-card-header>

            <div v-if="!extended" style="postion: relative">
              <div
                style="
                  position: absolute;
                  top: 60%;
                  left: 50%;
                  transform: translate(-50%, -50%);
                  z-index: 1000;
                "
              >
                <h3>Kein Zugriff</h3>
                <p>
                  Sie besitzen nicht das benötigte Recht zum Abruf von
                  Personendaten.<br />
                  <small>user_data_all</small>
                </p>
              </div>
              <d-list-group flush style="opacity: 50%">
                <d-list-group-item class="p-3 mb-2">
                  <d-row>
                    <d-col>
                      <d-form>
                        <d-form-row>
                          <!-- First Name -->
                          <d-col md="4" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>

                          <!-- Middle Name -->
                          <d-col md="4" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>

                          <!-- Last Name -->
                          <d-col md="4" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>
                        </d-form-row>

                        <!-- Address -->
                        <div class="form-group">
                          <b-skeleton animation width="100%" height="2rem" />
                        </div>

                        <d-form-row>
                          <!-- City -->
                          <d-col md="6" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>

                          <!-- State -->
                          <d-col md="4" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>

                          <!-- Zip Code -->
                          <d-col md="2" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>
                        </d-form-row>

                        <d-form-row>
                          <!-- Description -->
                          <d-col md="12" class="form-group">
                            <b-skeleton animation width="100%" height="2rem" />
                          </d-col>
                        </d-form-row>
                      </d-form>
                    </d-col>
                  </d-row>
                </d-list-group-item>
              </d-list-group>
            </div>
            <d-list-group flush v-else>
              <d-list-group-item class="p-3">
                <d-row>
                  <d-col>
                    <d-form>
                      <b-form-group>
                        <b-row>
                          <!-- First Name -->
                          <b-col md="4">
                            <b-form-group label="Vorname" label-size="sm">
                              <b-form-input
                                type="text"
                                id="feFirstName"
                                placeholder="First Name"
                                v-model="user.first_name"
                                :disabled="!auth_data_modify"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Middle Name -->
                          <b-col md="4">
                            <b-form-group label="2. Vorname" label-size="sm">
                              <b-form-input
                                type="text"
                                id="feLastName"
                                placeholder="Last Name"
                                v-model="user.middle_name"
                                :disabled="!auth_data_modify"
                              />
                            </b-form-group>
                          </b-col>

                          <!-- Last Name -->
                          <b-col md="4" class="form-group">
                            <b-form-group label="Nachname" label-size="sm">
                              <b-form-input
                                type="text"
                                id="feLastName"
                                placeholder="Last Name"
                                v-model="user.last_name"
                                :disabled="!auth_data_modify"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </b-form-group>

                      <b-form-group label="Addresse" label-size="sm">
                        <b-row>
                          <b-col cols="12" sm="9">
                            <b-form-input
                              v-model="user.address.street"
                              placeholder="Straße"
                              :disabled="!auth_data_modify"
                            />
                          </b-col>
                          <b-col cols="12" sm="3" class="mb-1">
                            <b-form-input
                              v-model="user.address.streetnumber"
                              min="0"
                              placeholder="Nr."
                              :disabled="!auth_data_modify"
                            />
                          </b-col>
                          <b-col cols="12" sm="4" class="mb-1">
                            <b-form-input
                              v-model="user.address.zip"
                              min="0"
                              max="99999"
                              type="number"
                              placeholder="PLZ"
                              :disabled="!auth_data_modify"
                            />
                          </b-col>
                          <b-col cols="12" sm="8" class="mb-1">
                            <b-form-input
                              placeholder="Stadt"
                              v-model="user.address.city"
                              :disabled="!auth_data_modify"
                            />
                          </b-col>
                        </b-row>
                      </b-form-group>

                      <b-button
                        variant="primary"
                        class="mr-1"
                        @click="saveUserData"
                        :disabled="!auth_data_modify"
                        >Änderungen speichern</b-button
                      >
                      <b-button v-if="auth_data_modify" variant="outline-light" @click="init"
                        >Zurücksetzen</b-button
                      >
                    </d-form>
                  </d-col>
                </d-row>
              </d-list-group-item>
            </d-list-group>
          </d-card>
        </d-col>
      </d-row>
    </template>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "user-profile",
  data() {
    return {
      user_id: null,
      user: null,
      participations: null,
      loading: null,
      participations_loading: null,
      notFound: null,
      auth_data_all: null,
      auth_data_modify: null,
      auth_auth_modify: null,
      auth_email_modify: null,
      auth_password_reset: null,
      auth_password_modify: null,
      auth_user_phys_delete: null,
      validated: false,
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    isCurrentUser() {
      return this.user_id == this.currentUser.id;
    },
    profile_url() {
      return `${this.$api.defaults.baseURL}user/${this.user.id}/avatar`;
    },
    extended() {
      return this.isCurrentUser || this.auth_data_all;
    },
    editable() {
      return this.auth_data_modify || this.isCurrentUser;
    },
    isEmployee() {
      return !!this.$store.getters.employees.find(
        (user) => this.user_id == user.id
      );
    },
    isInstructor() {
      return !!this.$store.getters.instructors.find(
        (user) => this.user_id == user.id
      );
    },
    isOfficeWorker() {
      return !!this.$store.getters.office_workers.find(
        (user) => this.user_id == user.id
      );
    },
    // VALIDATION
    state_street() {
      return !this.validated
        ? null
        : typeof this.user.address.street == "string" &&
            this.user.address.street.length > 0;
    },
    state_streetnumber() {
      return !this.validated
        ? null
        : typeof this.user.address.streetnumber == "string" &&
            this.user.address.streetnumber.length > 0;
    },
    state_zip() {
      return !this.validated
        ? null
        : typeof this.user.address.zip == "string" &&
            this.user.address.zip.length > 0;
    },
    state_city() {
      return !this.validated
        ? null
        : typeof this.user.address.city == "string" &&
            this.user.address.city.length > 0;
    },
    state_address() {
      return !this.validated
        ? null
        : this.state_street &&
            this.state_streetnumber &&
            this.state_zip &&
            this.state_city;
    },
  },
  methods: {
    async init() {
      this.loading = true;
      this.notFound = false;
      this.user_id = parseInt(
        this.$route.params.id == "current" || !this.$route.params.id
          ? this.currentUser.id
          : this.$route.params.id
      );
      this.user = await this.$store
        .dispatch("getUser", {
          id: this.user_id,
          extended: this.extended,
        })
        .catch((err) => {
          if (err.response.status == 404) {
            this.notFound = true;
            this.loading = false;
          } else throw err;
        });
      if (!this.user) return;
      this.loading = false;
      this.initParticipations();
    },
    async initParticipations() {
      this.participation = null;
      if (!this.extended) return (this.participations_loading = false);
      this.participations_loading = true;
      let participations = await this.$store.dispatch(
        "getUserParticipations",
        this.user_id
      );
      await Promise.all(
        participations.map((participation, index) =>
          (async () => {
            switch (participation.object_type) {
              case "lesson":
              case 10:
              case "10":
                participations[index].object = await this.$store.dispatch(
                  "getLesson",
                  participation.object_id
                );
                break;
              case "course":
              case 11:
              case "11":
                participations[index].object = await this.$store.dispatch(
                  "getCourse",
                  participation.object_id
                );
                break;
              default:
                delete participations[index];
            }
          })()
        )
      );
      this.participations = participations.filter((el) => el);
      this.participations_loading = false;
    },
    validateUserData() {
      this.validated = true;
      return this.state_address;
    },
    async saveUserData() {
      if (!this.validateUserData()) return;
      // TODO
      await this.$store
        .dispatch("updateUserData", {
          id: this.user_id,
        })
        .catch((err) => {
          this.init();
          throw err;
        });
      this.validated = false;
    },
    async openChangeEmail() {},
    async changeEmail() {},
    async changePWRequest() {},
    async openChangePW() {},
    async changePW() {},
    openAuth() {
      // this.$router.push({ name: "auth" });
    },
    async revertLoginRight() {},
    async phys_delete() {
      // wenn eigener Nutzer, sofort abmelden
    },
  },
  watch: {
    "$route.params.id"() {
      this.init();
    },
  },
  async created() {
    await Promise.all([
      (async () => {
        this.auth_data_all = await this.$store.dispatch(
          "haveUserRight",
          "user_data_all"
        );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_data_modify = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_data_modify"
        // );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_auth_modify = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_auth_modify"
        // );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_email_modify = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_email_modify"
        // );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_password_reset = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_password_reset"
        // );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_password_modify = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_password_modify"
        // );
      })(),
      (async () => {
        this.auth_data_modify = false;
        // this.auth_user_phys_delete = await this.$store.dispatch(
        //   "haveUserRight",
        //   "user_phys_delete"
        // );
      })(),
    ]);
    await this.init();
  },
};
</script>

