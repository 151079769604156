export var used_settings = [
  'admintools_onboarding'
];

export function addSetting(settings) {
    if (!Array.isArray(settings)) settings = [settings];
    settings.forEach(setting_name => {
      used_settings = used_settings.filter(el => el != setting_name);
      used_settings.push(setting_name);
    });
  };
