import Error404 from '@/views/Error404.vue';
import Contract from '@/views/more/Contract.vue';
export default [
  {
    path: '/contract',
    name: 'contract',
    component: Contract,
    meta: {
      title: 'Abrechnung und Vertrag',
      description: 'Vertragsinformationen, -änderungen, Abrechnungen',
      material_icon: 'work',
      tags: [
        'invoice',
        'contract',
        'more',
        'mehr',
        'weiteres',
        'Abrechnung & Vertrag',
        'vertragsinformationen',
        'vertragsänderungen',
        'abrechnungen',
      ]
    }
  }
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "more";
  return el;
});
