<template>

        <b-list-group flush>
          <b-list-group-item
            v-for="(category, i) in categories"
            :key="category.id"
            class="p-0"
          >
            <div
              v-b-toggle="'collapse-category-' + category.name"
              class="m-1"
              style="cursor: pointer"
            >
              {{ category.title }}
            </div>
            <b-collapse
              :id="'collapse-category-' + category.name"
              accordion="category-details"
            >
              <b-container>
                <b-row>
                  <b-col cols="2">
                    <b-form-group label="id" label-size="sm">
                      <b-form-input v-model="data[i].name" disabled />
                    </b-form-group>
                  </b-col>
                  <b-col cols="4">
                    <b-form-group label="Bezeichnung" label-size="sm">
                      <b-form-input v-model="data[i].title" />
                    </b-form-group>
                  </b-col>
                  <b-col cols="6">
                    <b-form-group label="Beschreibung" label-size="sm">
                      <b-form-textarea v-model="data[i].description" />
                    </b-form-group>
                  </b-col>
                </b-row>

                <b-form-group
                  label="Einstellungen"
                  label-class="font-weight-bold"
                >
                  <b-row>
                    <b-col>
                      <b-form-group label="Prefix für Inhalte" label-size="sm">
                        <b-form-input v-model="data[i].unit_key_prefix"/>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form-group>

                <b-row class="mb-2">
                  <b-col>
                    <b-button variant="outline-success" size="sm" @click="save(i)"
                      >Ändern</b-button
                    >
                    <b-button variant="outline-light" size="sm" @click="refresh"
                      >Zurücksetzen</b-button
                    >
                  </b-col>
                </b-row>
              </b-container>
            </b-collapse>
          </b-list-group-item>
        </b-list-group>
        <!-- Create -->
        <!-- Update -->
        <!-- Help -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      data: null,
      category_max_count: null,
    };
  },
  computed: {
    ...mapGetters(["categories"]),
  },
  methods: {
    refresh() {
      this.category_max_count = this.$store.getters.settings_obj.category_max_count.value.current;
      this.data = JSON.parse(JSON.stringify(this.categories));
    },
    async save(index) {
      await this.$store.dispatch('updateCategory', this.data[index]);
    }
  },
  async created() {
    this.refresh();
  },
};
</script>
