export const AddressRoles = [
    {
      value: "primary",
      text: "Standard"
    },
    {
      value: "invoice",
      text: "Rechnungsanschrift"
    },
  ];
export const EmailRoles = [
    {
      value: "primary",
      text: "Standard"
    },
    {
      value: "support",
      text: "Support"
    },
  ];
export const  PhoneNumberRoles = [
    {
      value: "primary",
      text: "Standard"
    },
    {
      value: "mobile",
      text: "Mobil"
    },
    {
      value: "fax",
      text: "Fax"
    },
  ];
