<template>
  <b-container fluid>
    <b-row v-if="auth_modify">
      <b-col class="text-center">
        <b-button-group>
          <b-button @click="$router.push({ name: 'lesson_new' })" class="pr-2">
            <i class="material-icons">add</i> Hinzufügen
          </b-button>
          <b-button
            v-b-tooltip.hover
            title="Schnellanlage"
            class="pl-2"
            @click="
              $router.push({
                name: 'lesson_new',
                params: { redirect: false },
              })
            "
          >
            <i class="material-icons">double_arrow</i>
          </b-button>
        </b-button-group>
        <i
          class="material-icons ml-3"
          v-b-popover.hover.top="{
            html: true,
            content: `Die <span class='font-weight-bold'>Schnellanlage</span> ist eine Möglichkeit Theoriestunden nacheinander anlegen zu können, ohne zwischen Theoriestundenübersicht und Anlage-Formular wechseln zu müssen.<br><small>Nach erfolgreicher Anlage erscheint ein Hinweis in der oberen rechten Ecke, welche die Anlage bestätigt.</small>`,
          }"
          >help_outline</i
        >
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col lg="6" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Suche..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Auswahl ab"
          label-cols-lg="3"
          label-align-lg="right"
          label-size="sm"
          label-for="executionTime"
          class="mb-0"
        >
          <b-form-select
            v-model="currentTime"
            id="executionTime"
            size="sm"
            :options="[
              { value: false, text: 'Heute' },
              { value: null, text: 'Datum wählen' },
              ...customTimes,
            ]"
          ></b-form-select>
          <b-modal
            ref="modal-time-select"
            title="Begin des Zeitraums auswählen..."
            @ok="handleOKModalTimeSelect"
            @hidden="handleCancelModalTimeSelect"
          >
            <b-form-datepicker
              v-model="select_time"
              v-bind="DatePickerConfig"
            ></b-form-datepicker>
          </b-modal>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      hover
      ref="lesson_table"
      show-empty
      small
      :busy.sync="loading"
      :items="items"
      :fields="fields"
      :filter="filter"
      selectable
      @row-selected="onRowSelected"
      :select-mode="selectMode"
    >
      <!-- Selected Coloumn @fullscreen -->
      <template v-slot:head(selected)>
        <div class="d-none d-lg-block"></div>
      </template>
      <template v-slot:cell(selected)="{ rowSelected }">
        <div class="d-none d-lg-block">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </div>
      </template>
      <template v-slot:cell(name)="row">
          <del
            @click="view(row.item.id)"
            :class="!row.item.canceled ? 'text-decoration-none' : ''"
            v-b-popover.hover="unitpopup(row.item.units)"
          >
            {{
              row.item.name != null && row.item.name.length > 0
                ? row.item.name
                : row.item.processed_unit_key_name != null &&
                  row.item.processed_unit_key_name.length > 0
                ? row.item.processed_unit_key_name
                : "Achtung: Kein Name!"
            }}</del
          >
      </template>
      <!-- //TODO Popup missing-->
      <template v-slot:cell(location)="row">
        <template v-if="typeof row.item.branch == 'object'">
          {{ row.item.branch.name }}
          <i
            class="material-icons"
            @click="view_branch(row.item.branch.id)"
            style="cursor: pointer"
            >link</i
          >
        </template>
        <template v-else-if="typeof row.item.address == 'object'">
          {{ row.item.address.street + " " + row.item.address.streetnumber }}
          <i
            class="material-icons"
            v-b-popover.hover="{
              content:
                row.item.address.street +
                ' ' +
                row.item.address.streetnumber +
                '<br>' +
                row.item.address.zip +
                ' ' +
                row.item.address.city,
              html: true,
            }"
            style="cursor: pointer"
            >info</i
          >
        </template>
        <template v-else></template>
      </template>
      <!-- //TODO Popup missing-->
      <template v-slot:cell(date)="row">
        {{
          row.item.date ? (row.item.date.start.year() == year
            ? row.item.date.start.format("dddd, D. MMM HH:mm")
            : row.item.date.start.format("D.M.YYYY HH:mm"))
          : 'noch nicht festgelegt'
        }}
      </template>

      <!-- Actions Coloumn @fullscreen -->
      <template v-slot:head(actions)>
        <div class="text-info text-right d-none d-lg-block" v-if="auth_modify">
          <span @click="cancelselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: orange; font-size: 1.3rem;"
            >do_disturb_on</span
          >
          <span @click="reactivateselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: green; font-size: 1.3rem;"
            >do_disturb_off</span
          >
          <span @click="removeselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: red; font-size: 1.3rem;"
            >delete</span
          >
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <div v-if="auth_modify" class="text-info text-right d-none d-lg-block">
          <span v-if="!row.item.canceled" @click="cancel(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: orange; font-size: 1.2rem;"
            >do_disturb_on</span
          >
          <span v-else @click="reactivate(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: green; font-size: 1.2rem;"
            >do_disturb_off</span
          >
          <span @click="remove(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: red; font-size: 1.2rem;"
            >delete</span
          >
        </div>
      </template>
      <!-- Error's -->
      <template v-slot:empty>
        <div
          v-if="loading !== null"
          class="text-center mt-2 small"
          @click="
            selectedCategory == null
              ? null
              : $store.dispatch('updateSelectedCategory', null)
          "
        >
          <template v-if="selectedCategory == null">
            Es sind keine Theoriestunden vorhanden...
          </template>
          <template v-else>
            Es wurden leider keine Theoriestunden für die Kategorie
            <strong>{{
              categories.find((elem) => elem.name == selectedCategory).title
            }}</strong>
            gefunden...
            <br />
            <br />
            <small>Hier klicken für <strong>Alle Kategorien</strong></small>
            <br />
          </template>
        </div>
        <div v-else class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:emptyfiltered>
        <div class="text-center mt-2 small">
          Keine Theoriestunden für diese Filtereinstellung gefunden...
        </div>
      </template>
    </b-table>
    <small>Es werden max. 100 Einträge angezeigt</small>
  </b-container>
</template>

<script>
import moment from "moment";
import { mapGetters } from "vuex";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";

export default {
  name: "LessonOverview",
  props: {
    /**
     * The component's type.
     */
    time: {
      default: false,
      validator: function (val) {
        if (val == false) return true;
        if (moment(val, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"]).isValid())
          return true;
        return false;
      },
    },
    acceptClosedCourseDependencies: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      loading: null,
      auth_modify: null,
      selectMode: "multi",
      selected: [],
      totalRows: 1,
      filter: null,
      items: [],
      customTimes: [],
      currentTime: this.time,
      year: moment().year(),
      // Modal Time Select
      select_time: null,
      DatePickerConfig,
      TimePickerConfig,
    };
  },
  computed: {
    ...mapGetters(["branches", "units", "selectedCategory", "categories"]),
    fields: function () {
      return [
        {
          key: "selected",
          label: "",
          class: "d-none d-lg-table-cell",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        {
          key: "date",
          label: "Wann?",
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "location",
          label: "Wo?",
          sortable: true,
        },
        { key: "actions", class: this.auth_modify ? "" : "d-none" },
      ];
    },
  },
  watch: {
    selectedCategory: function () {
      this.init();
    },
    currentTime: function (val) {
      if (val === null) return this.openModalTimeSelect();
      this.init();
    },
  },
  methods: {
    async init() {
      if (this.loading !== null) this.loading = true;
      let time = this.currentTime
        ? moment(this.currentTime, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"])
        : false;
      let lessons = await this.$store.dispatch("getLessons", {
        category: this.selectedCategory,
        min_end_date:
          time && time.isValid() ? time.format("YYYY-MM-DD") : undefined,
        accept_closed_course_dependencies: this.acceptClosedCourseDependencies
      });
      // Setup lessons
      lessons.map((lesson) => {
        lesson.branch = lesson.branch_id
          ? this.branches.find((branch) => branch.id == lesson.branch_id)
          : undefined;
        // Settings
        if (lesson.date) {
          lesson.date.start = moment(lesson.date.start, "YYYY-MM-DD HH:mm:ss");
          lesson.date.end = moment(lesson.date.end, "YYYY-MM-DD HH:mm:ss");
        }
        return lesson;
      });
      this.totalRows = lessons.length;
      this.loading = false;
      this.items = lessons;
    },
    unitpopup(units) {
      var list = "";
      units.forEach((unit) => {
        // TODO Link for open it up
        unit = this.units.find((elem) => elem.id == unit);
        list += `<li>${unit.name}</li>`;
      });
      return {
        content: `<ul class="m-0 p-0">${list}</ul>`,
        html: true,
      };
    },
    onRowSelected(items) {
      if (window.innerWidth <= 991 && items[items.length - 1])
        this.view(items[items.length - 1].id);
      this.selected = items;
    },
    view(lesson_id) {
      this.$router.push({ name: "lesson", params: { id: lesson_id } });
    },
    async cancel(row) {
      if (row.item.canceled) return;
      let result = await this.$store
        .dispatch(`cancelLesson`, {
          id: row.item.id,
          direct: false,
          hideAlert: true
        }).catch(err => {
          this.init();
          throw err;
        });
        if (!result) return;
        this.items.map(elem => {
          if (elem.id==row.item.id) elem.canceled = true;
        });
    },
    async cancelselected() {
      if (this.selected.filter(elem => elem.canceled==false).length==0) return;
      let result = await this.$swal({
        title: "Theoriestunden absagen",
        text: "Alle selektierten Theoriestunden werden abgesagt",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Fortfahren',
        cancelButtonText: 'Abbrechen',
        });
        if (!result.isConfirmed) return;
      await Promise.all(this.selected.map(lesson => (lesson.canceled == false) ? this.$store.dispatch('cancelLesson', {id: lesson.id, hideAlert: true}) : undefined))
      .catch(err => {
          this.init();
          throw err;
        });
      this.items.map(elem => {
          if (this.selected.find(lesson => lesson.id==elem.id)) elem.canceled = true;
        });
    },
    async reactivate(row) {
      if (!row.item.canceled) return;
      let result = await this.$store
        .dispatch(`reactivateLesson`, {
          id: row.item.id,
          direct: false,
          hideAlert: true
        }).catch(err => {
          this.init();
          throw err;
        });
        if (!result) return;
        this.items.map(elem => {
          if (elem.id==row.item.id) elem.canceled = false;
        });
    },
    async reactivateselected() {
      if (this.selected.filter(elem => elem.canceled==true).length==0) return;
      let result = await this.$swal({
        title: "Theoriestunden reaktivieren",
        text: "Alle selektierten Theoriestunden werden reaktiviert",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Fortfahren',
        cancelButtonText: 'Abbrechen',
        });
        if (!result.isConfirmed) return;
      await Promise.all(this.selected.map(lesson => (lesson.canceled == true) ? this.$store.dispatch('reactivateLesson', {id: lesson.id, hideAlert: true}) : undefined))
      .catch(err => {
          this.init();
          throw err;
        });
      this.items.map(elem => {
          if (this.selected.find(lesson => lesson.id==elem.id)) elem.canceled = false;
        });
    },
    async remove(row) {
      let result = await this.$store
        .dispatch(`removeLesson`, {
          id: row.item.id,
        }).catch(err => {
          this.init();
          throw err;
        });
        if (!result) return;
        this.items = this.items.filter(elem => elem.id!=row.item.id);
    },
    async removeselected() {
      if (this.selected.length==0) return;
      let result = await this.$swal({
        title: "Theoriestunden löschen",
        text: "Alle selektierten Theoriestunden werden gelöscht",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Fortfahren',
        cancelButtonText: 'Abbrechen',
        });
        if (!result.isConfirmed) return;
      await Promise.all(this.selected.map(lesson => this.$store.dispatch('removeLesson', {id: lesson.id, noConfirm: true, hideAlert: true})))
      .catch(err => {
          this.init();
          throw err;
        });
      this.items = this.items.filter(elem => !this.selected.find(lesson => lesson.id==elem.id));
      this.$globalmethod.toast_success("Unterrichtsstunden gelöscht.");
      this.selected = [];
    },
    // MODAL Time Select
    openModalTimeSelect() {
      this.select_time = null;
      this.$refs["modal-time-select"].show();
    },
    handleOKModalTimeSelect(bvModalEvt) {
      if (this.select_time == null) return bvModalEvt.preventDefault();
      this.customTimes = this.customTimes.filter(
        (elem) => elem.value != this.select_time
      );
      this.customTimes.push({
        value: this.select_time,
        text: moment(this.select_time, "YYYY-MM-DD").format("D. MMM YYYY"),
      });
      this.currentTime = this.select_time;
    },
    handleCancelModalTimeSelect() {
      this.$nextTick(() => {
        if (this.currentTime === null) this.currentTime = false;
      });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "lesson_modify"
    );
    await this.init();
  },
};
</script>
