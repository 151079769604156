import Vue from 'vue';
import API from '@/plugins/api.js';
import GlobalMethod from '@/plugins/globals.js';

Vue.use(API);
Vue.use(GlobalMethod);

// TODO: Save all roles and rights into localStorage
const app = new Vue();
const APIMethod = {
  install(Vue) {
    Vue.prototype.$apimethod = {
      /**
       * this function handle the result and givves back a popup if needed
       * @param httprequest.catch error
       */
      defaulthandler: function (error) {
        switch (error.response.status) {
          case 400:
            if (error.response.data.message) console.log("Bad Request: " + error.response.data.message);
            app.$globalmethod.toast_unknown_error();
            break;
          case 401:
            if (error.data.right) app.$globalmethod.toast_missing_right(error.data.right);
            if (error.data.role) app.$globalmethod.toast_missing_role(error.data.role);
            break;
          case 500:
            app.$globalmethod.toast_internal_server_error();
            break;
          case 509:
            console.log(error)
            break;
          default:
            console.log(error);
            app.$globalmethod.toast_unknown_error();
            break;
        }
        throw error;
      },
      async push_registration() {
        if (!(process.env.VUE_APP_PUBLIC_VAPID_KEY)) throw new Error(`MISSING_PARAMETER(VUE_APP_PUBLIC_VAPID_KEY) - You need to provide VUE_APP_PUBLIC_VAPID_KEY as an environment variable. Envs: ${process.env}`);
        function urlBase64ToUint8Array(base64String) {
          const padding = '='.repeat((4 - base64String.length % 4) % 4);
          const base64 = (base64String + padding)
            .replace(/-/g, '+')
            .replace(/_/g, '/');

          const rawData = window.atob(base64);
          const outputArray = new Uint8Array(rawData.length);

          for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
          }
          return outputArray;
        }
        if ('serviceWorker' in navigator) {
          const swregistration = await navigator.serviceWorker.ready;
          if (swregistration.pushManager) {
            const subscription = await swregistration.pushManager
              .subscribe({
                userVisibleOnly: true,
                // The `urlBase64ToUint8Array()` function is the same as in
                // https://www.npmjs.com/package/web-push#using-vapid-key-for-applicationserverkey
                applicationServerKey: urlBase64ToUint8Array(process.env.VUE_APP_PUBLIC_VAPID_KEY),
              });
            try {
              await app.$api.post('/user/current/push/subscribe', subscription);
              console.log('Push-Service registration successfull');
            } catch (err) {
              console.log(`Registration Error: ${err}`);
            }
          }
        }
      }
    };
  }
};

export default APIMethod;
