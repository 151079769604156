var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(
      _vm.settings_obj.company_consultance_language.description
    ),expression:"\n      settings_obj.company_consultance_language.description\n    ",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"In welchen Sprachen erfolgt die Beratung?"}},[_c('b-form-checkbox-group',{attrs:{"checked":_vm.settings_obj.company_consultance_language.value.current
          ? _vm.settings_obj.company_consultance_language.value.current.filter(function (el) { return ['de', 'en', 'ru', 'it', 'fr', 'tk', 'zp'].includes(el); }
            )
          : [],"options":_vm.languages,"disabled":_vm.settings_obj.company_consultance_language.value.current == null},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'company_consultance_language',
            value: val,
          }); }}}),_c('b-form-checkbox',{attrs:{"checked":_vm.settings_obj.company_consultance_language.value.current == null},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'company_consultance_language',
            value: val ? null : ['de'],
          }); }}},[_vm._v("keine Angabe")])],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(
      _vm.settings_obj.company_education_language.description
    ),expression:"\n      settings_obj.company_education_language.description\n    ",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"In welchen Sprachen kann die Ausbildung erfolgen?"}},[_c('b-form-checkbox-group',{attrs:{"checked":_vm.settings_obj.company_education_language.value.current
          ? _vm.settings_obj.company_education_language.value.current.filter(function (el) { return ['de', 'en', 'ru', 'it', 'fr', 'tk', 'zp'].includes(el); }
            )
          : [],"options":_vm.languages,"disabled":_vm.settings_obj.company_education_language.value.current == null},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'company_education_language',
            value: val,
          }); }}}),_c('b-form-checkbox',{attrs:{"checked":_vm.settings_obj.company_education_language.value.current == null},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'company_education_language',
            value: val ? null : ['de'],
          }); }}},[_vm._v("keine Angabe")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }