<template>
  <div class="mb-2">
    <h4 v-b-toggle.settings-company-language-collapse>Spracheeinstellungen</h4>
    <b-collapse id="settings-company-language-collapse" v-model="value">
      <div class="mb-2">
        Systemsprache: <span class="font-italic">deutsch</span>
      </div>
      <CustomerSalutation />
      <CompanyLanguage />
    </b-collapse>
  </div>
</template>

<script>
import CustomerSalutation from "../../../components/language/CustomerSalutation.vue";
import CompanyLanguage from "../../../components/language/CompanyLanguage.vue";
import { addSetting } from "../used_settings";
addSetting([
  "customer_salutation_personal",
  "company_consultance_language",
  "company_education_language",
]);

export default {
  components: { CustomerSalutation, CompanyLanguage },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: false,
    }
  },
  created() {
    this.value = this.visible;
  }
};
</script>

<style>
</style>
