<template>
  <d-container fluid class="main-content-container px-lg-4">
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span
            class="text-uppercase page-subtitle"
            >Basisdaten</span
          >
          <h3 class="page-title">
            <template v-if="edit_name == false">
              {{ data.name }}<br class="d-sm-none" />
              <small
                style="cursor: pointer"
                @click="
                  name = data.name;
                  edit_name = true;
                "
                v-if="auth_modify"
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span>
                </small
              >
            </template>
            <div v-else class="d-flex mr-5">
              <b-form-input v-model="name"/>
              <b-button class="ml-2" @click="handleEditName">Ändern</b-button>
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_name = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3">
          <b-dropdown variant="light" right text="Weitere Aktionen">
            <b-dropdown-item>Aktion 1</b-dropdown-item>
          </b-dropdown>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="8">
          <d-card class="card-small mt-2">
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Beschreibung
                    <div v-if="auth_modify">
                      <span v-if="edit_description==false" class="material-icons" @click="edit_description=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveDescription" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_description=false; description = data.description;" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                  <b-form-textarea v-if="edit_description" v-model="description" placeholder="keine Beschreibung angegeben"/>
                  <small v-else>{{ data.description ? data.description : 'keine Beschreibung hinterlegt' }}</small>
              </b-form-group>
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Adresse
                    <div v-if="auth_modify">
                      <span v-if="edit_address==false" class="material-icons" @click="edit_address=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveAddress" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_address=false; address= {...data.address}; validated_address = false;" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
              <b-row v-if="edit_address">
                <b-col cols="12" sm="9" class="mb-1">
                  <b-form-input
                    v-model="address.street"
                    placeholder="Straße"
                    :state="state_street"
                  />
                </b-col>
                <b-col cols="12" sm="3" class="mb-1">
                  <b-form-input
                    v-model="address.streetnumber"
                    min="0"
                    placeholder="Nr."
                    :state="state_streetnumber"
                  />
                </b-col>
                <b-col cols="12" sm="4" class="mb-1">
                  <b-form-input
                    v-model="address.zip"
                    min="0"
                    max="99999"
                    type="number"
                    placeholder="PLZ"
                    :state="state_zip"
                  />
                </b-col>
                <b-col cols="12" sm="8" class="mb-1">
                  <b-form-input
                    placeholder="Stadt"
                    v-model="address.city"
                    :state="state_city"
                  />
                </b-col>
              </b-row>
              <template v-else>
                <div class="small">{{ data.address.street + " " + data.address.streetnumber }}</div>
                <div class="small">{{ data.address.zip + " " + data.address.city }}</div>
              </template>
            </b-form-group>
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Emails
                    <div v-if="auth_modify">
                      <span v-if="edit_emails==false" class="material-icons" @click="edit_emails=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveEmails" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_emails=false; emails=[...data.emails];" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <Emails v-if="emails" v-model="emails" class="ml-1" :edit="edit_emails ? null : undefined" :key="edit_emails"/>
              </b-form-group>
              <b-form-group label="Telefonnummern/Fax">
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Telefonnummern/Fax
                    <div v-if="auth_modify">
                      <span v-if="edit_phone_numbers==false" class="material-icons" @click="edit_phone_numbers=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSavePhoneNumbers" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_phone_numbers=false; phone_numbers=[...data.phone_numbers];" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <PhoneNumbers v-if="phone_numbers" v-model="phone_numbers" class="ml-1" :edit="edit_phone_numbers ? null : undefined" :key="edit_phone_numbers"/>
              </b-form-group>
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Steuer-ID
                    <div v-if="auth_modify">
                      <span v-if="edit_tax==false" class="material-icons" @click="edit_tax=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveTax" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_tax=false; taxidentificationnumber=[...data.taxidentificationnumber];" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <b-form-input v-if="edit_tax" v-model="taxidentificationnumber"/>
                <small v-else>{{ data.taxidentificationnumber }}</small>
              </b-form-group>
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between mb-0">
                    Bank
                    <div v-if="auth_modify">
                      <span v-if="edit_bank==false" class="material-icons" @click="edit_bank=true" style="cursor:pointer;">create</span>
                      <div v-else>
                        <span class="material-icons" @click="handleSaveBank" style="cursor:pointer;">check</span>
                        <span class="material-icons" @click="edit_bank=false; bank={ iban: data.iban, bic: data.bic }; validated_bank=false;" style="cursor:pointer;">close</span>
                      </div>
                    </div>
                  </div>
                </template>
                <div>
                  <b-form-input v-if="edit_bank" v-model="bank.iban" :state="state_iban" class="mb-1"/>
                  <small v-else>{{ data.iban }}</small>
                </div>
                <div>
                  <b-form-input v-if="edit_bank" v-model="bank.bic" :state="state_bic"/>
                  <small v-else>{{ data.bic }}</small>
                </div>
              </b-form-group>
            </d-card-body>
          </d-card>
        </b-col>
        <d-col cols="12" lg="4">
          <!-- <AnnouncementCard type="company"/> -->
          <d-card class="card-small mt-2">
            <d-card-header class="border-bottom d-flex justify-content-between">Geschlossene Tage
            </d-card-header>
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              hier werden für Firma globale geschlossene Tage angezeigt
            </d-card-body>
          </d-card>
        </d-col>
      </b-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import Emails from "@/components/communication/Emails.vue";
import PhoneNumbers from "@/components/communication/PhoneNumbers.vue";
import moment from "moment";
import AnnouncementCard from '../../components/announcement/AnnouncementCard.vue';
const ibantools = require("ibantools");

export default {
  name: "CompanyShow",
  components: {
    Emails,
    PhoneNumbers,
    AnnouncementCard,
  },
  data() {
    return {
      auth_modify: false,
      // Name
      edit_name: false,
      name: null,
      // Description
      description: null, // Copy of data, which can altered by user
      edit_description: false,
      // Address
      edit_address: false,
      address: null,
      validated_address: false,
      // Emails
      edit_emails: false,
      emails: null,
      // PhoneNumbers
      edit_phone_numbers: false,
      phone_numbers: null,
      // Tax
      edit_tax: false,
      taxidentificationnumber: null,
      // Bank
      edit_bank: false,
      bank: null,
      validated_bank: false,
      // Persons
      owner: null,
      data_protection_responsible: null,
      content_responsible: null,
    };
  },
  computed: {
    data() {
      return this.$store.getters.company;
    },
    state_street() {
      return !this.validated_address
        ? null
        : typeof this.address.street == "string" &&
            this.address.street.length > 0;
    },
    state_streetnumber() {
      return !this.validated_address
        ? null
        : typeof this.address.streetnumber == "string" &&
            this.address.streetnumber.length > 0;
    },
    state_zip() {
      return !this.validated_address
        ? null
        : typeof this.address.zip == "string" && this.address.zip.length > 0;
    },
    state_city() {
      return !this.validated_address
        ? null
        : typeof this.address.city == "string" && this.address.city.length > 0;
    },
    state_address() {
      return !this.validated_address
        ? null
        : this.state_street &&
            this.state_streetnumber &&
            this.state_zip &&
            this.state_city;
    },
    state_iban() {
      return !this.validated_bank
        ? null
        : ibantools.isValidIBAN(this.bank.iban);
    },
    state_bic() {
      return !this.validated_bank
        ? null
        : ibantools.isValidBIC(this.bank.bic);
    }
  },
  methods: {
    async init() {
      if (!this.data) return;
      this.name=this.data.name;
      this.description = this.data.description;
      this.address= {...this.data.address},
      this.emails = [...this.data.emails];
      this.phone_numbers = [...this.data.phone_numbers];
      this.taxidentificationnumber = this.data.taxidentificationnumber;
      this.bank = { iban: this.data.iban, bic: this.data.bic};
      this.owner = this.data.owner;
      this.data_protection_responsible = this.data.data_protection_responsible;
      this.content_responsible = this.data.content_responsible;
    },
    async handleEditName() {
      if (
        (this.name == null || this.name.trim() == "")
      )
        return;
      await this.$store.dispatch("updateCompany", {
        name: this.name,
      });
      this.edit_name = false;
      this.name = this.data.name;
    },
    async handleSaveDescription() {
      await this.$store.dispatch('updateCompany', {
        description: this.description,
      });
      this.description = this.data.description;
      this.edit_description = false;
    },
    async handleSaveAddress() {
      this.validated_address = true;
      if (!this.state_address) return;
      await this.$store.dispatch('updateCompany', {
        address: this.address,
      });
      this.validated_address = false;
      this.address = {...this.data.address};
      this.edit_address = false;
    },
    async handleSaveEmails() {
      await this.$store.dispatch('updateCompany', {
        emails: this.emails
      });
      this.emails = [...this.data.emails];
      this.edit_emails = false;
    },
    async handleSavePhoneNumbers() {
      await this.$store.dispatch('updateCompany', {
        phone_numbers: this.phone_numbers
      });
      this.phone_numbers = [...this.data.phone_numbers];
      this.edit_phone_numbers = false;
    },
    async handleSaveOpenHours() {
      await this.$store.dispatch('updateCompany', {
        openHours: this.openHours
      });
      this.openHours = [...this.data.openHours];
      this.edit_openHours = false;
    },
    async handleSaveTax() {
      await this.$store.dispatch('updateCompany', {
        taxidentificationnumber: this.taxidentificationnumber
      });
      this.taxidentificationnumber = [...this.data.taxidentificationnumber];
      this.edit_tax = false;
    },
    async handleSaveBank() {
      this.validated_bank = true;
      if (!this.state_iban || !this.state_bic) return;
      await this.$store.dispatch('updateCompany', {
        iban: this.bank.iban,
        bic: this.bank.bic,
      });
      this.bank = { iban: this.data.iban, bic: this.data.bic};
      this.edit_bank = false;
      this.validated_bank = false;
    },
  },
  watch: {
    data() {
      this.init();
    }
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "company_modify"
    );
    this.init();
  },
};
</script>
