<template>
  <b-form-group>
    <div class="d-flex" v-b-popover.bottom.hover="settings_obj.payment_transfer.description">
      Überweisung erlaubt:
      <b-form-checkbox
        class="ml-2"
        switch
        :checked="settings_obj.payment_transfer.value.current"
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'payment_transfer',
              value: !!val,
            })
        "
      />
    </div>
    <b-collapse
      v-model="settings_obj.payment_transfer.value.current"
      class="ml-2 small"
    >
      <label>Abweichendes Zahlungskonto</label>
      <b-row>
        <b-col cols="8">
        <b-form-group label="IBAN" v-b-popover.bottom.hover="settings_obj.payment_transfer_iban.description">
          <b-form-input
            size="sm"
            :value="settings_obj.payment_transfer_iban.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_transfer_iban',
                  value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                })
            "
            debounce="2000"
          />
        </b-form-group>
        </b-col>
        <b-col cols="4">
        <b-form-group label="BIC" v-b-popover.bottom.hover="settings_obj.payment_transfer_bic.description">
          <b-form-input
            size="sm"
            :value="settings_obj.payment_transfer_bic.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_transfer_bic',
                  value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                })
            "
            debounce="2000"
          />
        </b-form-group>
        </b-col>
      </b-row>
      <b-form-group label="Name der Bank" v-b-popover.bottom.hover="settings_obj.payment_transfer_bank_name.description">
        <b-form-input
          size="sm"
          :value="settings_obj.payment_transfer_bank_name.value.current"
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_transfer_bank_name',
                value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
              })
          "
          debounce="2000"
        />
      </b-form-group>
      <b-form-group
        label="Was gibt es zu beachten?"
        v-b-popover.bottom.hover="settings_obj.payment_transfer_text.description"
      >
        <b-form-textarea class="mt-2"
          size="sm"
          :value="
            settings_obj.payment_transfer_text.value.current === null
              ? ''
              : settings_obj.payment_transfer_text.value.current
          "
          @change="
            (val) =>
              $store.dispatch('setSetting', {
                name: 'payment_transfer_text',
                value: val.trim() == '' ? null : val.trim(),
              })
          "
        />
      </b-form-group>
    </b-collapse>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "payment_transfer",
      "payment_transfer_iban",
      "payment_transfer_bic",
      "payment_transfer_bank_name",
      "payment_transfer_text",
    ]);
  },
};
</script>

<style>
</style>
