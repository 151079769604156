<template>
  <b-form-select
  :options="formated_selectableUsers"
  v-model="value"
  />
</template>

<script>
export default {
  props: {
    input: {
      type: Number,
      required: true
    },
    /**
     * this prop will filter the selectable user pool by a default role of a user
     */
    role: {
      type: String,
      required: false,
      default: null,
      validator(input) {
        switch(input) {
          case "employee":
          case "office_worker":
          case "instructor":
            return true;
          default:
            return false;
        }
      }
    },
    /**
     * this prop will add a button, to remove a user and set the value null
     */
    nullable: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  model: {
    prop: 'input',
    event: 'changed',
  },
  data() {
    return {
      value: this.input,
    }
  },
  computed: {
    formated_selectableUsers() {
      return this.selectableUsers.map(user => {
        return {
          text: user.name ? `${user.name} (${user.username})` : user.username,
          value: user.id
        }
      });
    },
    selectableUsers() {
      switch(this.role) {
        case null:
        case "employee":
          return this.$store.getters.employees;
        case "office_worker":
          return this.$store.getters.office_worker;
        case "instructor":
          return this.$store.getters.instructors;
        default:
         throw new Error("NOT_VALID");
      }
    }
  },
  watch: {
    input(val, oldval) {
      if (val != oldval) this.value = val;
    },
    value(val) {
      this.$emit("changed", val);
    }
  }
}
</script>

<style>

</style>
