<template>
  <b-container fluid>
    <b-row>
      <b-col>

        <!-- CATEGORIES -->
        <div class="w-100 d-flex justify-content-between">
          <h3>Unterrichtskategorien</h3>
          <span
            class="material-icons"
            v-b-modal.modal-categories-help
            style="cursor: pointer"
            >help</span
          >
        </div>
        <systems />

        <!-- PRESETS -->
        <div class="w-100 d-flex justify-content-between mt-3">
          <h3>Voreinstellungen</h3>
          <!-- <span
            class="material-icons"
            style="cursor: pointer"
            >help</span
          > -->
        </div>
        <presets />

        <!-- PARTICIPATION -->
        <div class="w-100 d-flex justify-content-between">
          <h3>Teilnahmesystem</h3>
          <span
            class="material-icons"
            v-b-modal.modal-participation-help
            style="cursor: pointer"
            >help</span
          >
        </div>
        <participation />

        <!-- INFO-MODALS -->
        <b-modal
          id="modal-categories-help"
          title="Hilfe: Unterrichtskategorien"
          hide-footer
          body-class="p-1"
        >
          <b-container fluid>
            <b-row>
              <b-col>
                <div class="font-weight-bold">
                  Welchen Mehrwert haben Unterrichtskategorien?
                </div>
                <div>
                  Unterrichtskategorien bieten die Möglichkeit Ihre Ausbildung in mehrere Bereiche zu unterteilen, wodurch eine bessere Übersicht und Organisation für Sie und für Ihren Kunden erreicht wird.<br/>
                  Für jeden Ausbildungsbereich in Ihrer Firma sollten Sie daher eine
                  einzelne Kategorie erstellen. <br/>
                  z.B. Fahrschulausbildung, Qualifikationsmaßnahmen, Bootsausbildung
                </div>
                <template v-if="category_max_count">
                  <div class="font-weight-bold">
                    Warum kann ich nur eine begrenzte Anzahl an Unterrichtskategorien
                    erstellen?
                  </div>
                  <div>
                    Abhängig von Ihrem Vertrag kann die Anzahl von
                    Unterrichtskategorien beschränkt sein. Sie können dann
                    innerhalb der festgelegten Anzahl
                    selbsständig Änderungen vornehmen.<br />
                    Falls Sie weitere Kategorien benötigen, sprechen Sie uns
                    gerne an.
                    <p>
                      In Ihrem Vertrag sind aktuell
                      {{ category_max_count }} Kategorien enthalten.
                    </p>
                  </div>
                </template>
                <br />
                <div>
                  Falls Sie Fragen haben sprechen Sie uns gerne an. <br />
                  Wir finden eine Lösung für Ihre Anforderung!
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>

        <b-modal
          id="modal-participation-help"
          title="Hilfe: Teilnahmesystem"
          hide-footer
          body-class="p-1"
        >
          <b-container fluid>
            <b-row>
              <b-col>
                <div class="font-weight-bold">
                  Was ist das Teilnahmesystem
                </div>
                <div>
                  Über das Teilnahmesystem können Ihre Kunden Plätze an Unterrichtsstunden oder Kursen reservieren.
                </div>
                <br />
                <div>
                  Falls Sie Fragen haben sprechen Sie uns gerne an. <br />
                  Wir finden eine Lösung für Ihre Anforderung!
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-modal>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Presets from "./Presets";
import Systems from "./Systems";
import { addSetting } from "../used_settings";
import Participation from "./Participation.vue";
addSetting("apis_active");
export default {
  components: {
    Systems,
    Presets,
    Participation,
  },
  computed: {
    category_max_count() {
      return this.$store.getters.settings_obj.category_max_count.value.current;
    }
  }
};
</script>
