<template>
  <div v-if="!auth_modify && !id">Sie besitze nicht die erforderlichen Rechte</div>
  <b-container v-else fluid>
    <b-row v-if="category == null" class="justify-content-center">
      <b-col cols="12" class="text-center mb-2"
        >Bitte Kategorie auswählen</b-col
      >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="3"
        class="d-none d-sm-flex text-center mb-2"
      >
        <CategorySelector />
      </b-col>
    </b-row>
    <div v-else-if="notfound" class="text-center">
      Theorieeinheit nicht gefunden
    </div>
    <div v-else-if="loading" class="text-center">
      <i class="fa fa-spinner fa-spin"></i>
    </div>
    <div v-else id="panel-unit-form">
      <b-row>
        <b-col cols="12" md="3">
          <b-form-group>
            <label class="mb-1"
              >Kennzeichen
              <i
                class="material-icons"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Das Kennzeichen dient der eindeutigen Identifizierung einer Theorieeinheit. <br>Meist wie folgt verwendet: <br>\'Thema <strong>Kennzeichenname</strong>\'<br><br>z.B. Thema 5<br><small><strong>max. Länge:</strong> 4 Zeichen</small>',
                }"
                >help_outline</i
              >
            </label>
            <b-form-input
              v-model="key"
              :disabled="!auth_modify"
              :state="key == null || key.length <= 4 ? null : false"
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col cols="12" md="9">
          <b-form-group label="Name">
            <b-form-input v-model="name" :disabled="!auth_modify"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label class="mb-1"
              >Kurzbeschreibung
              <i
                class="material-icons"
                v-b-popover.hover.top="
                  'Die Kurzbeschreibung dient dazu in 1-3 Sätzen dem Kunden zu verdeutlichen, welche Inhalte diese Theorieeinheit vermittelt.'
                "
                >help_outline</i
              >
            </label>
            <b-form-textarea
              :disabled="!auth_modify"
              v-model="description"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label class="mb-1"
              >Standarddauer
              <i
                class="material-icons"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    'Die Standarddauer gibt an, welcher Zeitraum für die Absolvierung dieser Einheit standardmäßig benötigt wird.<br><br><small>Z.B. wird in der Anlage einer <strong>Theoriestunde</strong> aus diesem Wert die Dauer der Theoriestunde vorgeschlagen.</small>',
                }"
                >help_outline</i
              >
            </label>
            <div v-if="duration === null">
              <b-button :disabled="!auth_modify" variant="light" @click="duration = (default_duration) ? default_duration : '01:30:00'"
                >Hinzufügen</b-button>
            </div>
            <b-row v-else>
              <b-col>
                <b-form-timepicker
                  :disabled="!auth_modify"
                  v-model="duration"
                  v-bind="TimePickerConfig"
                ></b-form-timepicker>
              </b-col>
              <b-col cols="auto" class="pl-0">
                <b-button variant="light" class="mt-1" :disabled="!auth_modify" @click="duration = null"
                  >Entfernen</b-button
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col v-b-toggle.accordion-contents cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Inhalte
              <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="
                'In diesem Abschnitt können Sie ausführlicher definieren, welche Inhalte in der Unterrichtseinheit behandelt werden'
              "
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-contents" class="col">
          <b-row>
            <b-col cols="12">
              <h3>Diese Funktionalität ist noch in Arbeit</h3>
              <small>Editor zur strukturierung der Daten</small>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
      <b-row>
        <b-col v-b-toggle.accordion-more cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Informationsmaterial
              <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="
                'In diesem Abschnitt können Sie Informationsmaterial zur Theorieeinheit hinterlegen, welche dem Kunden im Rahmen eines Kurses bzw. einer Unterrichtsstunde zur Verfügung gestellt werden kann.'
              "
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-more" class="col">
          <b-row>
            <b-col cols="12">
              <h3>Diese Funktionalität ist noch in Arbeit</h3>
              <small
                >anhängen von Dateien, welche in anderen Objekten verwendet
                werden können. z.B. Kurse, Stunden</small
              >
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
      <b-row class="mx-1">
        <b-button variant="success" @click="submit()" :disabled="!auth_modify">{{
          id ? "Aktualisieren" : "Anlegen"
        }}</b-button>
        <b-button class="ml-2" variant="outline-danger" v-if="id" @click="handleRemove()" :disabled="!auth_modify">Löschen</b-button>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import { TimePickerConfig } from "@/data/bootstrap-form-config.js";
// TODO: blur component, if the use has not the right "lesson_modify"
export default {
  name: "Unit",
  components: {
    CategorySelector,
  },
  props: {
    /**
     * ID for a Lesson, to update an existing lesson, if set the lesson can be updated
     */
    id: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: null,
      notfound: false,
      auth_modify: null,
      category: null,
      key: null,
      name: null,
      description: null,
      duration: null,
      default_duration: null,
      TimePickerConfig,
    };
  },
  computed: {
    ...mapGetters({
      selectedCategory: "selectedCategory",
    }),
  },
  watch: {
    selectedCategory: async function () {
      await this.init();
    },
    category: function (val) {
      if (val != this.selectedCategory)
        this.$store.dispatch("updateSelectedCategory", val);
    },
  },
  methods: {
    async init() {
      this.category = this.selectedCategory;
      let unit;
      if (this.category == null && this.id != null) return;
      if (this.id) {
        await this.$store
          .dispatch(`getUnit`, this.id)
          .then((result) => {
            this.notfound = false;
            // Name
            this.name = result.name;
            this.description = result.description;
            this.key = result.key;
            this.duration = result.duration;

            this.loading = false;
          })
          .catch((err) => {
            if (err && err.code == 404) {
              this.notfound = true;
              this.loading == false;
            } else {
              this.$apimethod.defaulthandler(err);
              this.loading = false;
            }
          });
      } else {
        // Get Default Duration Setting
        this.duration = this.default_duration ? this.default_duration : null;
        this.loading = false;
      }
    },
    validate() {
      this.validated = true;
    },
    async submit() {
      // Precheck
      // Branch OR Address!!!
      // Create a new Lesson
      await this.$store
        .dispatch(this.id ? "updateUnit" : "createUnit", {
          id: this.id ? this.id : undefined,
          category: this.category,
          key: this.key,
          name: this.name == null || this.name.trim() == "" ? null : this.name,
          duration: this.duration,
          description:
            this.description == null || this.description.trim() == ""
              ? null
              : this.description,
        })
        .then((result) => {
          if (this.id)
            this.$globalmethod.toast_success("Erfolgreich aktualisiert.");
          else this.$globalmethod.toast_success("Erfolgreich angelegt.");
          this.$emit("finish", result);
        })
        .catch((err) => {
          console.log(err);
          this.$apimethod.defaulthandler(err);
        });
    },
    async handleRemove() {
      let result = await this.$store.dispatch('removeUnit', { id: this.id});
      if (!result) return;
      this.$router.back();
    }
  },
  async created() {
    await Promise.all([
      (async () => {
        this.auth_modify = await this.$store.dispatch(
          "haveUserRight",
          "unit_modify"
        );
      })(),
      (async () => {
        let setting = await this.$store.dispatch(
          "getSetting",
          "unit_default_duration_time"
        );
        this.default_duration = setting
          ? setting
          : this.default_duration;
      })(),
    ]);
    await this.init();
  },
};
</script>
