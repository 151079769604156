var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_transfer.description),expression:"settings_obj.payment_transfer.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_vm._v(" Überweisung erlaubt: "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":"","checked":_vm.settings_obj.payment_transfer.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
            name: 'payment_transfer',
            value: !!val,
          }); }}})],1),_c('b-collapse',{staticClass:"ml-2 small",model:{value:(_vm.settings_obj.payment_transfer.value.current),callback:function ($$v) {_vm.$set(_vm.settings_obj.payment_transfer.value, "current", $$v)},expression:"settings_obj.payment_transfer.value.current"}},[_c('label',[_vm._v("Abweichendes Zahlungskonto")]),_c('b-row',[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_transfer_iban.description),expression:"settings_obj.payment_transfer_iban.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"IBAN"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_transfer_iban.value.current,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_transfer_iban',
                value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
              }); }}})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_transfer_bic.description),expression:"settings_obj.payment_transfer_bic.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"BIC"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_transfer_bic.value.current,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_transfer_bic',
                value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
              }); }}})],1)],1)],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_transfer_bank_name.description),expression:"settings_obj.payment_transfer_bank_name.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"Name der Bank"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_transfer_bank_name.value.current,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_transfer_bank_name',
              value: (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
            }); }}})],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_transfer_text.description),expression:"settings_obj.payment_transfer_text.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"Was gibt es zu beachten?"}},[_c('b-form-textarea',{staticClass:"mt-2",attrs:{"size":"sm","value":_vm.settings_obj.payment_transfer_text.value.current === null
            ? ''
            : _vm.settings_obj.payment_transfer_text.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
              name: 'payment_transfer_text',
              value: val.trim() == '' ? null : val.trim(),
            }); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }