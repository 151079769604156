<template>
  <d-container fluid class="main-content-container px-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Weiteres</span>
        <h3 class="page-title">Einstellungen</h3>
      </d-col>
    </d-row>

    <!-- Select Section -->
    <b-row class="mb-3 d-none d-md-flex justify-content-center">
      <b-col
        v-for="elem in views.filter((el) => el.value != 0)"
        :key="elem.id"
        md="4"
        lg="3"
        xl="2"
      >
        <b-card
          body-class="text-center px-0"
          style="cursor: pointer"
          @click="view = elem.value"
        >
          <h1 class="material-icons" style="font-size: 3rem;">{{ elem.icon }}</h1>
          <div>{{ elem.text }}</div>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mb-3 d-flex d-md-none">
      <b-col>
        <b-form-select :options="views" v-model="view"></b-form-select>
      </b-col>
    </b-row>

    <b-row v-if="loading===false">
      <b-col cols="12" v-if="view == 0" class="my-5 text-center">
        <span class="font-italic"
          >Bitte wählen Sie einen der obigen Bereiche</span
        >
      </b-col>
      <b-col cols="12" v-else>
        <template v-for="viewelement in views.filter(el => el.component && !el.hidden)">
          <b-card v-if="(view == viewelement.value)" class="mb-3" :key="viewelement.value">
            <b-card-title class="h4 d-none d-md-block" v-if="viewelement.showTitle">{{ viewelement.text }}</b-card-title>
            <component :is="viewelement.component"></component>
          </b-card>
        </template>
      </b-col>
    </b-row>
  </d-container>
</template>

<script>
import API from "./api/Index";
import GENERAL from "./general/Index";
import EDUCATION from "./education/Index";
import OTHER from "./other/Index";
export default {
  name: "Settings",
  data() {
    return {
      loading: null,
      view: null,
      views: [
        {
          value: 1,
          name: "general",
          icon: "settings",
          text: "Allgemeines",
          component: GENERAL,
          hidden: false
        },
        {
          value: 2,
          name: "education",
          icon: "account_balance",
          text: "Unterricht",
          component: EDUCATION,
          hidden: false
        },
        {
          value: 3,
          name: "api",
          icon: "extension",
          text: "Schnittstellen",
          component: API,
          showTitle: true,
          hidden: false
        },
        {
          value: 4,
          name: "more",
          icon: "more_horiz",
          text: "Anderes",
          component: OTHER,
          hidden: false
        },
      ],
    };
  },
  watch: {
    view(val) {
      sessionStorage.setItem("settings_view", val);
    },
  },
  async created() {
    this.loading = true;
    this.view = this.views[0].value;
    let session_view = parseInt(sessionStorage.getItem("settings_view"));
    if (this.$route.query && this.$route.query.name && this.views.find(el => el.value == this.$route.query.name || el.name.trim().toLowerCase() == this.$route.query.name.trim().toLowerCase())){
      this.view = this.views.find(el => el.value == this.$route.query.name || el.name.trim().toLowerCase() == this.$route.query.name.trim().toLowerCase()).value;
      this.$router.replace({
        ...this.$router.currentRoute,
        query: {}
      });
    } else if (session_view != NaN && this.views.find(el => el.value == session_view)) this.view = session_view;
    await this.$store.dispatch('initAllSettings');
    this.loading = false;
},
};
</script>

