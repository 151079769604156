import Vue from 'vue'
import API from '@/plugins/api.js'
import APIMethod from '@/plugins/apimethod.js'
import moment from "moment";
Vue.use(API)
Vue.use(APIMethod)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: {
    loaded: false,
    data: [],
    types: [],
  },
  mutations: {
    setAnnouncementTypes(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.types=input;
    },
    setAnnouncements(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.data=input;
    },
    updateAnnouncement(state, announcement) {
      state.data = state.data.filter(el => el.id != announcement.id);
      state.data.push(announcement);
    },
    resetAnnouncementModule(state) {
      state.loaded = false;
      state.data = [];
      state.types = [];
    }
  },
  actions: {
    async initAnnouncementModule(context) {
      if (context.state.loaded) return;
      await Promise.all([
        (async () => {
          let response = await store.$api.get("announcement?admin");
          context.commit("setAnnouncementTypes", response.data.result);
        })(),
        (async () => {
          let response = await store.$api.get(`announcement/all?admin&min_end_date=${moment().subtract(2, "week").format("YYYY-MM-DD HH:mm:ss")}`);
          context.commit("setAnnouncements", response.data.result);
        })(),
      ]);
      context.state.loaded = true;
    },
    async createAnnouncement(context, { title, content, status, types, start_date, end_date } = {}) {
      let response = await store.$api.post(`announcement`, {title, content, status, types, start_date, end_date});
      context.commit('setAnnouncements', [...context.state.data, response.data.result]);
      return response.data.result;
    },
    async updateAnnouncement(context, { id, title, content, status, types, start_date, end_date } = {}) {
      let response = await store.$api.put(`announcement/${id}`, {title, content, status, types, start_date, end_date});
      context.commit('setAnnouncements', context.state.data.map(announcement => {
        if (announcement.id == response.data.result.id) return response.data.result;
        return announcement;
      }));
      return response.data.result;
    },
    async deleteAnnouncement(context, {id} = {}) {
      await store.$api.delete(`announcement/${id}`);
      context.commit('setAnnouncements', context.state.data.filter(announcement => announcement.id!=id));
      return true;
    }
  },
  getters: {
    announcement_types(state) {
      return state.types;
    },
    announcements(state) {
      return state.data;
    },
   }
}
export default module_branch
