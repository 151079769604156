import Vue from 'vue'
import API from '@/plugins/api.js'
import APIMethod from '@/plugins/apimethod.js'
Vue.use(API)
Vue.use(APIMethod)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: {
    loaded: false,
    data: [],
  },
  mutations: {
    setReviews(state, input) {
      if (!Array.isArray(input)) throw new Error("NOT_ARRAY");
      state.data=input;
      state.loaded = true;
    },
    updateReview(state, review) {
      state.data = state.data.filter(el => el.id != review.id);
      state.data.push(review);
    }
  },
  actions: {
    async initReviewModule(context) {
      if (context.state.loaded) return;
      let response = await store.$api.get("review");
      context.commit("setReviews", response.data.result);
    },
    async publishReview(context, {id}) {
     let response = await store.$api.post(`review/${id}/publish`).catch(err => store.$apimethod.defaulthandler(err));
      context.commit('updateReview', response.data.result);
    },
    async unpublishReview(context, {id}) {
      let response = await store.$api.post(`review/${id}/unpublish`).catch(err => store.$apimethod.defaulthandler(err));
      context.commit('updateReview', response.data.result);
    },
    async updateReviewAnswer(context, { id, answer }) {
      if (answer===null) await store.$api.delete(`review/${id}/answer`).then(response => response.data.result).catch(err => store.$apimethod.defaulthandler(err));
      else await store.$api.post(`review/${id}/answer`, { text: answer }).then(response => response.data.result).catch(err => store.$apimethod.defaulthandler(err));
      context.commit('updateReview', { ...context.getters.reviews.find(el => el.id == id), answer});
    },
  },
  getters: {
    reviews(state) {
      return state.data;
    },
   }
}
export default module_branch
