<template>
  <b-form-group>
    <div
      class="d-flex"
      v-b-popover.bottom.hover="settings_obj.payment_bar.description"
    >
      Barzahlung erlaubt:
      <b-form-checkbox
        class="ml-2"
        switch
        :checked="settings_obj.payment_bar.value.current"
        @change="
          (val) =>
            $store.dispatch('setSetting', { name: 'payment_bar', value: !!val })
        "
      />
    </div>
    <b-collapse
      v-model="settings_obj.payment_bar.value.current"
      class="ml-2 small"
    >
      <b-form-group label="Maximalbetrag">
        <div
          class="d-flex"
          v-b-popover.bottom.hover="settings_obj.payment_bar_max.description"
        >
          <b-form-checkbox
            :checked="!!settings_obj.payment_bar_max.value.current"
            @change="
              (val) =>
                $store.dispatch('setSetting', {
                  name: 'payment_bar_max',
                  value: val ? '1000 €' : null,
                })
            "
          />
          <b-input-group append="€">
            <b-form-input
              size="sm"
              :value="settings_obj.payment_bar_max.value.current"
              :disabled="settings_obj.payment_bar_max.value.current === null"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'payment_bar_max',
                    value:
                      (val || '').trim() + !isNaN(parseInt(val)) ? ' €' : '',
                  })
              "
              debounce="2000"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </b-collapse>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "payment_bar",
      "payment_bar_max",
    ]);
  },
};
</script>

<style>
</style>
