import CalendarConfigurator from '@/views/more/CalendarConfigurator.vue';
import IntegrationConfigurator from '@/views/more/IntegrationConfigurator.vue';
import MoreList from '@/views/more/MoreList.vue';
import OnboardingProcess from '@/views/more/OnboardingProcess.vue';
export default [
  {
    path: '/more/list',
    name: 'more_list',
    component: MoreList,
    meta: {
      title: 'zusätzliche FUnktionen',
      description: 'Hier finden Sie zusätzliche Funktionen, welche Ihnen verschiedene Aufgaben erleichtern können.',
      material_icon: 'settings',
      tags: [
        "more",
        "weiteres",
        "services",
      ]
    }
  },
  // {
  //   path: '/services/eBrief',
  //   name: 'services_eBrief',
  //   component: Error404,
  //   meta: {
  //     title: 'Briefe versenden',
  //     description: 'Physische Briefe online hochladen und versenden lassen spart Ihnen Zeit und Geld - eBrief.',
  //     material_icon: 'settings',
  //     tags: [
  //       "ebrief",
  //     ],
  //     shortcuts: [
  //       "ebrief"
  //     ]
  //   }
  // },
  {
    path: '/more/integration',
    name: 'integration_generator',
    component: IntegrationConfigurator,
    meta: {
      title: 'Webseitenintegration-Generator',
      description: 'Der Webseitenintegration-Linkgenerator erleichtert Ihnen die Erstellung eines für Sie passenden Links zur Einbettung verschiedener Anzeigen in eine andere Webseite.',
      material_icon: 'event',
      tags: [
        "anwendung",
        "applet",
        "link",
        "url",
        "generator",
        "erstellen",
        "einbinden",
        "einbindung",
        "extern",
        "external",
        "website",
        "webseite",
        "firmenwebseite",
        "integration",
        "übersicht",
        "kalender",
        "calendar"
      ],
      shortcuts: [
        "cg"
      ]
    }
  },
  {
    path: '/calendar/link',
    name: 'calendar_generator',
    component: CalendarConfigurator,
    meta: {
      title: 'Kalender-Generator',
      description: 'Der Kalenderlinkgenerator erleichtert Ihnen die Erstellung eines für Sie passenden Links zur Abruf von Unterrichtsstunden und Kursen in externen Kalenderprogrammen.',
      material_icon: 'event',
      tags: [
        "kalender",
        "calendar",
        "link",
        "url",
        "outlook",
        "generator",
        "erstellen",
        "ical",
        "einbinden",
        "extern"
      ],
      shortcuts: [
        "ig"
      ]
    }
  },
  {
    path: '/onboarding',
    name: 'onboarding_process',
    component: OnboardingProcess,
    meta: {
      title: 'Herzlich Willkommen bei Fahrschule-API',
    }
  },
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "more";
  return el;
});
