import Vue from 'vue'
import API from '@/plugins/api.js'
Vue.use(API)
let store = new Vue() // use the store var for request api
const module_mail_list = {
  state: {
    mail_lists: [],
  },
  mutations: {
    setMailLists(state, input) {
      state.mail_lists = input;
    },
    resetMailListModule(state) {
      state.mail_lists = [];
    }
  },
  actions: {
    async getMailLists(context) {
      let response = await store.$api.get(`mail_list`);
      context.commit('setMailLists', response.data.result);
      return context.state.forms;
    },
    async addMailList() {

    },
    async updateMailList() {

    },
    async removeMailList() {

    },
    async getMailListParticipantsCSV() {

    },
    async addMailListPost() {

    },
    async updateMailListPost() {

    },
    async removeMailListPost() {

    }
  },
  getters: {
    mail_lists(state) {
      return state.mail_lists;
    }
  }
}
export default module_mail_list;
