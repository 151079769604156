<template>
  <d-container fluid class="main-content-container px-lg-4">
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span class="text-uppercase page-subtitle">Unterricht</span>
        <h3 class="page-title">Inhalte<small> | </small><small style="cursor: pointer;" @click="$router.push({ name: 'unit_group_groups'})">Gruppen</small><small> | </small><small style="cursor: pointer;" @click="$router.push({ name: 'unit_price_groups'})">Preise</small></h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3">
        <b-form-group
          label="Kategorie"
          label-size="sm"
          label-align="center"
          label-align-sm="left"
          label-class="pb-1"
          class="text-center text-sm-left"
        >
          <CategorySelector />
        </b-form-group>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <!-- Component Header -->
          <d-card-header class="border-bottom d-none d-md-block">
            <h6 class="m-0 d-flex justify-content-between">
              Übersicht
            </h6>
          </d-card-header>

          <d-card-body class="d-flex flex-column px-0 px-lg-3">
            <UnitList :time="new Date().getTime() / 1000" />
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import UnitList from "@/components/education/unit/UnitList";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
export default {
  name: "Units",
  components: {
    UnitList,
    CategorySelector,
  },
};
</script>
