<template>
  <div v-if="!auth_modify && !id">Sie besitzen nicht die benötigten Rechte</div>
  <b-container v-else fluid>
    <b-row v-if="category == null" class="justify-content-center">
      <b-col cols="12" class="text-center mb-2"
        >Bitte Kategorie auswählen</b-col
      >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="3"
        class="d-none d-sm-flex text-center mb-2"
        ><CategorySelector
      /></b-col>
    </b-row>
    <b-row v-if="selectedType == null" class="justify-content-center">
      <b-col cols="12" class="text-center mb-2"
        >Bitte Art der Gruppe auswählen</b-col
      >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="3"
        class="d-none d-sm-flex text-center mb-2"
        ><div>
          <b-button class="mr-1" variant="light" @click="selectedType = 'group'"
            >Sortiergruppe</b-button
          >
          <b-button variant="light" @click="selectedType = 'price'"
            >Preiskategorie</b-button
          >
        </div>
      </b-col>
    </b-row>
    <div v-else-if="notfound" class="text-center">
      Gruppe nicht gefunden
    </div>
    <div v-else id="panel-unit_group-form">
      <b-row>
        <b-col cols="12">
          <b-form-group label="Name">
            <b-form-input
              :disabled="!auth_modify"
              v-model="name"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label class="mb-1"
              >Kurzbeschreibung
              <i
                class="material-icons"
                v-b-popover.hover.top="
                  'Die Kurzbeschreibung dient dazu in 1-3 Sätzen dem Kunden zu verdeutlichen, welche Inhalte diese Einheitsgruppe abbildet.'
                "
                >help_outline</i
              >
            </label>
            <b-form-textarea
              v-model="description"
              :disabled="!auth_modify"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <template slot="label">
              <div class="d-flex justify-content-between">
                <span>Zugeordnete Unterrichtsinhalte</span>
                <span
                  class="material-icons"
                  v-b-popover.hover.top="
                    'Inhalte sind Unterrichtseinheiten, welche Bestandteile eines Kurses oder einer Unterrichtsstunde sein können.'
                  "
                  >help_outline</span
                >
              </div>
            </template>
            <b-form-tags
              v-model="selectedUnits"
              no-outer-focus
              class="mb-2 border-0"
            >
              <template v-slot="{ tags, addTag, removeTag }">
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-2"
                >
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      @remove="remUnitTag({ unit_id: tag, removeTag })"
                      :disabled="!auth_modify"
                      variant="secondary"
                      class="mb-1"
                    >
                      <div :id="`unit_${tag}`" href="#" class="text-white">
                        {{ units.find((unit) => unit.id == tag).key }} -
                        {{ units.find((unit) => unit.id == tag).name }}
                      </div>
                    </b-form-tag>
                    <b-popover :target="`unit_${tag}`" triggers="hover">
                      <div>
                        <strong>Standarddauer: </strong
                        >{{
                          [units.find((unit) => tag == unit.id).duration]
                            | duration("humanize")
                        }}
                      </div>
                      <div>
                        <strong>Beschreibung: </strong
                        >{{
                          units.find((unit) => tag == unit.id)
                            .description_short !== null &&
                          units.find((unit) => tag == unit.id).description_short
                            .length > 0
                            ? units.find((unit) => tag == unit.id)
                                .description_short
                            : "keine"
                        }}
                      </div>
                      <router-link :to="{ name: 'unit', params: { id: tag } }"
                        >Zur Detailseite</router-link
                      >
                    </b-popover>
                  </li>
                </ul>
                <b-dropdown
                  size="sm"
                  variant="outline-secondary"
                  block
                  menu-class="w-100"
                  :disabled="!auth_modify"
                >
                  <template v-slot:button-content no-outer-focus
                    >Theorieeinheit auswählen</template
                  >
                  <b-dropdown-form @submit.stop.prevent="() => {}">
                    <b-form-group
                      label-for="unit-search-input"
                      label="Suche:"
                      label-cols-md="auto"
                      class="mb-0"
                      label-size="sm"
                    >
                      <b-form-input
                        v-model="searchUnits"
                        id="unit-search-input"
                        type="search"
                        size="sm"
                        autocomplete="off"
                      ></b-form-input>
                    </b-form-group>
                  </b-dropdown-form>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-group style="overflow: auto; max-height: 30vh">
                    <b-dropdown-item
                      v-for="unit in filteredUnits"
                      :key="unit.id"
                      @click="addUnitTag({ unit_id: unit.id, addTag })"
                      >{{ unit.key }} - {{ unit.name }}</b-dropdown-item
                    >
                  </b-dropdown-group>
                  <b-dropdown-text v-if="units.length === 0"
                    >Keine Inhalte definiert</b-dropdown-text
                  >
                  <b-dropdown-text v-else-if="filteredUnits.length === 0"
                    >Keinen Inhalt gefunden</b-dropdown-text
                  >
                </b-dropdown>
              </template>
            </b-form-tags>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="selectedType != 'price'">
        <b-col v-b-toggle.accordion-more cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Vererbung
              <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="{
                html: true,
                content:
                  'In diesem Abschnitt können Sie festlegen, ob die Einheitsgruppe <strong>(Kindgruppe)</strong> die Inhalte einer anderen Gruppe <strong>(Elterngruppe)</strong> erben soll. <br>Werden Änderungen an der Elterngruppe vorgenommen, hat dies automatisch einen Einfluss auf die Kindgruppe.<br>Besitzt eine Gruppe eine Kindgruppe, kann diese <strong>nicht gelöscht</strong> werden.',
              }"
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse
          id="accordion-more"
          class="col"
          :visible="selectedParents > 0"
        >
          <b-row>
            <b-col cols="12" style="margin-bottom: 0.5em">
              <b-form-group>
                <b-form-tags
                  v-model="selectedParents"
                  no-outer-focus
                  class="mb-2 border-0"
                >
                  <template v-slot="{ tags, addTag, removeTag }">
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-2"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          @remove="remParentTag({ parent_id: tag, removeTag })"
                          :disabled="!auth_modify"
                          variant="secondary"
                          class="mb-1"
                        >
                          <div
                            :id="`parent_${tag}`"
                            href="#"
                            class="text-white"
                          >
                            {{
                              unit_groups.find(
                                (unit_group) => tag == unit_group.id
                              ).name
                            }}
                          </div>
                        </b-form-tag>
                        <b-popover :target="`parent_${tag}`" triggers="hover">
                          <div>
                            <strong>Beschreibung: </strong
                            >{{
                              unit_groups.find(
                                (unit_group) => tag == unit_group.id
                              ).description == "" ||
                              unit_groups.find(
                                (unit_group) => tag == unit_group.id
                              ).description == null
                                ? "keine"
                                : unit_groups.find(
                                    (unit_group) => tag == unit_group.id
                                  ).description
                            }}
                          </div>
                          <router-link
                            :to="{
                              name: 'unit_group',
                              params: { id: tag },
                            }"
                            >Zur Detailseite</router-link
                          >
                        </b-popover>
                      </li>
                    </ul>
                    <b-dropdown
                      size="sm"
                      variant="outline-secondary"
                      block
                      menu-class="w-100"
                      :disabled="!auth_modify"
                    >
                      <template v-slot:button-content no-outer-focus
                        >Einheitsgruppe auswählen</template
                      >
                      <b-dropdown-form @submit.stop.prevent="() => {}">
                        <b-form-group
                          label-for="unit-search-input"
                          label="Suche:"
                          label-cols-md="auto"
                          class="mb-0"
                          label-size="sm"
                        >
                          <b-form-input
                            v-model="searchParents"
                            id="unit-search-input"
                            type="search"
                            size="sm"
                            autocomplete="off"
                          ></b-form-input>
                        </b-form-group>
                      </b-dropdown-form>
                      <b-dropdown-divider></b-dropdown-divider>
                      <b-dropdown-group
                        style="overflow: auto; max-height: 30vh"
                      >
                        <b-dropdown-item
                          v-for="parent in filteredParents"
                          :key="parent.id"
                          @click="
                            addParentTag({ parent_id: parent.id, addTag })
                          "
                          >{{ parent.name }}</b-dropdown-item
                        >
                      </b-dropdown-group>
                      <b-dropdown-text v-if="units.length === 0"
                        >Keine Einheitsgruppe vorhanden</b-dropdown-text
                      >
                      <b-dropdown-text v-else-if="filteredUnits.length === 0"
                        >Keine Einheitsgruppe gefunden</b-dropdown-text
                      >
                    </b-dropdown>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
          </b-row>
        </b-collapse>
      </b-row>
      <b-row class="mx-1">
        <b-button variant="success" @click="submit()">{{
          id ? "Aktualisieren" : "Anlegen"
        }}</b-button>
        <b-button
          v-if="id"
          class="ml-2"
          variant="outline-danger"
          :disabled="!auth_modify"
          >Löschen</b-button
        >
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
export default {
  name: "UnitGroup",
  components: {
    CategorySelector,
  },
  props: {
    /**
     * ID for a Lesson, to update an existing lesson, if set the lesson can be updated
     */
    id: {
      type: Number,
      default: null,
    },
    /**
     * TYPE for creating/filter unit_groups
     */
    type: {
      type: String,
      default: null,
      validator(input) {
        if (input === null) return true;
        if (typeof input != "string") return false;
        return ["group", "price"].includes(input);
      },
    },
  },
  data() {
    return {
      notfound: false,
      auth_modify: null,
      searchUnits: "",
      searchParents: "",

      // general data
      category: null,
      selectedType: null,
      name: null,
      description: null,
      selectedUnits: [],
      // Type-specific:
      // GROUP
      selectedParents: [],
      // PRICE
    };
  },
  computed: {
    ...mapGetters({
      units: "units",
      unit_groups: "unit_groups",
      selectedCategory: "selectedCategory",
    }),
    start() {
      return moment(this.start_date + " " + start_time, "YYYY-MM-DD HH:mm:ss");
    },
    filteredUnits() {
      const criteria = this.searchUnits.trim().toLowerCase();
      // Filter out already selected options
      const options = this.units.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.selectedUnits.find((unit_id) => unit_id == option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    filteredParents() {
      const criteria = this.searchParents.trim().toLowerCase();
      // Filter out already selected options
      const options = this.unit_groups.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.selectedUnits.find((unit_id) => unit_id == option.id) &&
          (this.id == null || this.id != option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter((option) =>
          option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
  },
  watch: {
    selectedCategory: function (val, oldval) {
      if (val!=oldval) this.init();
    },
    category: function (val, oldval) {
      if (val != this.selectedCategory)
        this.$store.dispatch("updateSelectedCategory", val);
      if (val == oldval) return;
      this.init();
    },
    selectedType: function (val, oldval) {
      if (val!=oldval) this.init();
    },
  },
  methods: {
    init() {
      this.category = this.selectedCategory;
      if (this.type) this.selectedType = this.type;
      this.selectedUnits = [];
      this.selectedParents = [];
      this.notfound = false;
      if (this.category == null && this.id != null) return;
      if (this.selectedType == null && this.id != null) return;
      if (this.id) {
        let result = JSON.parse(
          JSON.stringify(this.unit_groups.find((el) => el.id == this.id))
        );
        if (!result || (this.type && this.type != result.type))
          return (this.notfound = true);
        if (result.category != this.selectedCategory)
          this.$store.dispatch("updateSelectedCategory", result.category);
        // Name
        this.name = result.name;
        this.category = result.category;
        this.description = result.description;
        this.selectedType = result.type;

        // UNIT/GROUP: Set parents
        this.selectedParents = result.parents
          ? result.parents.direct.filter((parent_id) =>
              this.unit_groups.find((unit_group) => parent_id == unit_group.id)
            )
          : [];
        // Set Units
        this.selectedUnits = result.units.direct.filter((lesson_unit) =>
          this.units.find((unit) => lesson_unit == unit.id)
        );
      }
    },
    async submit() {
      // Precheck
      // Create/Update UnitGroup
      await this.$store
        .dispatch(this.id ? "updateUnitGroup" : "createUnitGroup", {
          id: this.id ? this.id : undefined,
          category: this.category,
          type: this.id ? undefined : this.selectedType,
          name: this.name == null || this.name.trim() == "" ? null : this.name,
          description:
            this.description == null || this.description.trim() == ""
              ? null
              : this.description,
          parents:
            this.selectedType == "group"
              ? this.selectedParents.map((elem) => parseInt(elem))
              : undefined,
          units: this.selectedUnits.map((elem) => parseInt(elem)),
        })
        .then((result) => {
          if (this.id)
            this.$globalmethod.toast_success("Erfolgreich aktualisiert.");
          else this.$globalmethod.toast_success("Erfolgreich angelegt.");
          this.$emit("finish", result);
        })
        .catch(this.$apimethod.defaulthandler);
    },
    addUnitTag({ unit_id, addTag }) {
      addTag(unit_id.toString());
    },
    remUnitTag({ unit_id, removeTag }) {
      removeTag(unit_id.toString());
    },
    addParentTag({ parent_id, addTag }) {
      addTag(parent_id.toString());
      // Filter this.selectedUnits
      this.selectedUnits = this.selectedUnits.filter(
        (unit_id) =>
          !this.unit_groups
            .find((unit_group) =>
              this.selectedParents.find(
                (parent_id) => parent_id == unit_group.id
              )
            )
            .units.find((parent_unit_id) => parent_unit_id == unit_id)
      );
    },
    remParentTag({ parent_id, removeTag }) {
      removeTag(parent_id.toString());
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "unit_group_modify"
    );
    this.init();
  },
};
</script>
