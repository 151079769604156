<template>
  <d-form class="main-navbar__search w-100 d-none d-md-flex d-lg-flex">
    <b-input-group class="ml-3" seamless>
      <d-input-group-text slot="prepend" class="border-0">
        <i class="material-icons">search</i>
      </d-input-group-text>
      <b-form-input
        class="navbar-search"
        placeholder="Suchbegriff eingeben..."
        v-model="search"
        @focus="show=true"
        @blur="hideResults()"
        @keydown.enter.native="resolveAction"
        autocomplete="aus"
      />
    </b-input-group>
    <b-collapse
      id="searchresults"
      v-if="results!=null"
      :visible="show"
      class="dropdown-menu rounded-top-0"
    >
      <b-dropdown-item v-if="results.length==0">
        <div class="text-center font-weight-bold">Keine Ergebnisse zur Suche gefunden.</div>
      </b-dropdown-item>
      <b-dropdown-item
        v-else
        v-for="element in results"
        :key="element.name"
        :to="{name: element.name}"
        active-class
      >
        <b-container>
          <b-col cols="1" class="text-center">
            <div class="notification__icon">
              <i class="material-icons">{{element.meta.material_icon}}</i>
            </div>
          </b-col>
          <b-col cols="11" class="text-left">
            <span class="font-weight-bold">{{element.meta.title}}</span>
            <p v-if="element.meta">{{element.meta.description}}</p>
          </b-col>
        </b-container>
      </b-dropdown-item>
    </b-collapse>
  </d-form>
</template>
<script>
export default {
  data() {
    return {
      search: "",
      show: false,
    };
  },
  methods: {
    hideResults() {
      setTimeout(() => (this.show = false), 0);
    },
    resolveAction(e) {
      e.preventDefault();
      try {
        this.$router.dispatchShortcut(this.search);
        this.search = "";
        this.show = false;
      } catch (err) {
        if (err.message.includes("NOT_FOUND")) {
          if (this.results.length >= 1) {
            if (this.$router.history.current.name!=this.results[0].name) this.$router.push({ name: this.results[0].name });
            this.search = "";
            this.show = false;
          } else {
            this.$swal.fire({
              title: "Shortcut nicht gefunden.",
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000
            });
          }
        } else throw err;
      }
    },
  },
  watch: {
    search(val) {
      this.show = true;
      setTimeout(() => {
        if (this.show == true) return;
        if (val === this.search) this.search = "";
      }, 15000);
    },
    show(val) {
      if (val == false) {
        setTimeout(() => {
          if (this.show == true) return;
          this.search = "";
        }, 15000);
      }
    },
  },
  computed: {
    results() {
      return this.$router.dictionary(this.search);
    },
  },
};
</script>
