import axios from 'axios';
const GlobalAPI = {
  install(Vue) {
    Vue.prototype.$globalapi = axios.create({
      baseURL: process.env.VUE_APP_API_BASE_URL,
      responseType: 'json',
    })
  }
};

export default GlobalAPI;
