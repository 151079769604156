import Error404 from '@/views/Error404.vue';
import announcement from "./announcement";
import review from "./review";
export default [
  ...announcement,
  ...review,
  {
    path: '/content',
    name: 'content',
    component: Error404,
    meta: {
      title: 'Beiträge',
      description: 'Erstellen und Verwalten Sie Beiträge für Ihre Webseite. Sie können außerdem aus einer großen Anzahl an Vorlagen wählen und diese in Ihre Webseite einbinden',
      material_icon: 'work',
      tags: [
        'content',
        'crm',
        'kundenkontakt',
        'posts',
        'articles',
        'artikel',
      ]
    }
  },
  {
    path: '/messages',
    name: 'messages',
    component: Error404,
    meta: {
      title: 'Nachichten',
      description: 'Erlauben Sie Kunden Ihnen Fragen zu stellen. Reagieren Sie so bald wie möglich.',
      material_icon: 'work',
      tags: [
        'messages',
        'crm',
        'kundenkontakt',
        'mybusiness',
        'google',
        'kontaktformular',
        'fragen',
      ]
    }
  },
  {
    path: '/newsletter',
    name: 'newsletter',
    component: Error404,
    meta: {
      title: 'Newsletter',
      description: 'Erstellen und Verwalten Sie Newsletter, womit Sie IHre Kunden über verschiedene Themen informieren können',
      material_icon: 'work',
      tags: [
        'email',
        'crm',
        'kundenkontakt',
        'e-mail',
        'rundschreiben'
      ]
    }
  },
  {
    path: '/surveys',
    name: 'surveys',
    component: Error404,
    meta: {
      title: 'Umfragen',
      description: 'Erstellen und Verwalten Sie Umfragen, anhand welchen Sie weitere Daten ermitteln können. Egal, ob für den Eigengebrauch oder als Nachweis, durch eine freie Konfiguration sind sie für jede Anforderung gerüstet',
      material_icon: 'work',
      tags: [
        'surveys',
        'crm',
        'kundenkontakt',
        'meinungen',
        'auswertungen',
      ]
    }
  },
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "crm";
  return el;
});
