<template>
  <b-form-group>
    <div
      class="d-flex"
      v-b-popover.bottom.hover="settings_obj.chatbot_active.description"
    >
      Aktiv
      <b-form-checkbox
        class="ml-2"
        switch
        :checked="settings_obj.chatbot_active.value.current"
        disabled
      />
    </div>
    <template
      v-if="settings_obj.chatbot_active.value.current"
      class="ml-2 small"
    >
      <b-form-group>
        <template #label>Name des ChatBots <small>{{ !!settings_obj.chatbot_name.value.current ? `Aktuell stellt sich der ChatBot als ${settings_obj.chatbot_name.value.current} vor.` : 'Aktuell stellt sich der ChatBot ohne Name vor.'}}</small></template>
        <div
          class="d-flex"
          v-b-popover.bottom.hover="settings_obj.chatbot_name.description"
        >
          <b-form-checkbox
            :checked="!!settings_obj.chatbot_name.value.current"
            v-model="chatbot_name_active"
            @change="
              (val) => !val ?
                $store.dispatch('setSetting', {
                  name: 'chatbot_name',
                  value: null,
                }) : undefined
            "
          />
          <b-input-group>
            <b-form-input
              size="sm"
              :value="settings_obj.chatbot_name.value.current"
              :disabled="!chatbot_name_active"
              @change="
                (val) =>
                  $store.dispatch('setSetting', {
                    name: 'chatbot_name',
                    value: val,
                  })
              "
              debounce="2000"
            />
          </b-input-group>
        </div>
      </b-form-group>
    </template>
    <div v-else>
      für Ihre Firma ist der ChatBot nicht aktiviert. Bitte sprechen Sie uns zur Aktivierung gerne an.
    </div>
  </b-form-group>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      chatbot_name_active: false,
    }
  },
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "chatbot_active",
      "chatbot_name",
    ]);
  },
};
</script>

<style>
</style>
