<template>
  <div class="mb-2">
    <h4 v-b-toggle.settings-chatbot-collapse>ChatBot</h4>
    <b-collapse id="settings-chatbot-collapse" v-model="value">
      <ChatBotSettings />
    </b-collapse>
  </div>
</template>

<script>
import ChatBotSettings from "../../../components/chatbot/ChatBotSettings.vue";
import { addSetting } from "../used_settings";
addSetting([
  "chatbot_active",
  "chatbot_name",
]);

export default {
  components: { ChatBotSettings },
  props: {
    visible: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      value: false,
    };
  },
  created() {
    this.value = this.visible;
  }
};
</script>

<style>
</style>
