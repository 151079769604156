<template>
  <div>
    <facebook-pages/>
    <google-my-business/>
  </div>
</template>

<script>
import FacebookPages from "./FacebookPages";
import GoogleMyBusiness from "./GoogleMyBusiness";
import { addSetting } from '../used_settings';
addSetting("apis_active");
export default {
  components: {
    FacebookPages,
    GoogleMyBusiness,
  },
}
</script>
