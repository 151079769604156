import Vue from 'vue';
import Vuex from 'vuex';
import API from '@/plugins/api.js';
import GlobalAPI from '@/plugins/global_api.js';
import jwt from "jsonwebtoken";
import notification_service from "@/plugins/notification-api";

Vue.use(Vuex);
Vue.use(API);
Vue.use(GlobalAPI);
const store = new Vue(); // use the store var for request api
const module_branch = {
  state: {
    currentUser: localStorage.getItem('currentUser') || null,
    refresh_token: sessionStorage.getItem('refresh_token') || null,
    access_token: sessionStorage.getItem('access_token') || null,
    rights: [],
    settings: [],
    steps: [],
    notifications: [],
    notification_intervall: null,
  },
  mutations: {
    destroySession(state) {
      delete store.$api.defaults.headers.common['Authorization'];    // Unset Authorization-Header for API
      sessionStorage.removeItem('refresh_token');
      sessionStorage.removeItem('access_token');
      state.refresh_token = null;
      state.access_token = null;
      state.currentUser = null;
      state.rights = [];
      state.settings = [];
      state.steps = [];
      clearInterval(state.notification_intervall);
      state.notifications = [];
      state.notification_intervall = null;
    },
    setTokens(state, data) {
      var TokenData = jwt.decode(data.access_token);
      if (TokenData.exp <= new Date().getTime() / 1000) {
        throw new Error("Cannot set Token. The Token was expired. Please make sure. You can only set valid Tokens!");
      }
      store.$api.defaults.headers.common['Authorization'] = `Bearer ${data.access_token}`;   // Set Authorization-Header for API
      sessionStorage.setItem('access_token', data.access_token);
      sessionStorage.setItem('refresh_token', data.refresh_token);
      state.access_token = data.access_token;
      state.refresh_token = data.refresh_token;
      state.currentUser = TokenData.user;
      if (TokenData.user.rights) TokenData.user.rights.forEach(right => {
        state.rights.filter(el => el.name!=right);
        state.rights.push({ name: right, value: true });
      });
    },
    // RIGHTS
    addUserRight(state, { name, value } = {}) {
      state.rights = state.rights.filter(right => right.name != name);
      state.rights.push({ name, value });
    },
    // CONFIRMATIONS
    addUserConfirmation(state, { name, value } = {}) {
      state.confirmations = state.confirmations.filter(confirmation => confirmation.name != name);
      state.confirmations.push({ name, value });
    },
    // SETTINGS
    addUserSetting(state, { name, value } = {}) {
      state.settings = state.settings.filter(setting => setting.name != name);
      state.settings.push({ name, value });
    },
    // STEPS
    addUserStep(state, { name, value } = {}) {
      state.steps = state.steps.filter(step => step.name != name);
      state.steps.push({ name, value });
    },
    // Notifications
    setNotifications(state, input) {
      state.notifications = input;
    },
  },
  actions: {
    async logout(context) {
      context.commit('setLoadingStatus', "Nutzer wird abgemeldet...");
      if (context.getters.refresh_token) store.$api.post('system/logout', { refresh_token: context.getters.refresh_token })
        .catch(err => {
          if (!err.response || !err.response.status || (err.response.status != 400 && err.response.status != 404)) throw err;
        });
      if (context.getters.currentUser) context.commit('destroySession');
      await context.dispatch('resetAll');
      context.commit('setLoadingStatusOK');
    },
    async login(context, credentials) {
      var crypto = require('crypto');
      if (!context.getters.companys.find((company) => (company.id == credentials.company))) throw new Error("COMPANY_INVALID(NOT_FOUND");
      context.commit('setcurrentCompany', credentials.company);
      let response = await store.$api.post('system/login', { input: credentials.user, password: crypto.createHash('sha512').update(credentials.password).digest('hex'), new_password: credentials.new_password ? crypto.createHash('sha512').update(credentials.new_password).digest('hex') : undefined })
        .catch(err => {
          throw err;
        });
      context.commit('setTokens', response.data);
      if (!(await context.dispatch('haveUserRight', "admintools_access"))) throw new Error("NOT_AUTHORIZED");
      await context.dispatch('refreshSession');
      context.commit('setLoadingStatusOK');
    },
    async refreshSession(context) {
      if (context.getters.access_token != null) {
        if (context.getters.TokenData.exp <= new Date().getTime() / 1000) { // Token is invalid
          console.log("Token expired...");
          context.commit('setLoadingStatus', 'Token abgelaufen. Bitte erneut einloggen...');
        } else {
          context.commit('setLoadingStatus', 'Versuche Session wiederherzustellen...');
          if (!context.getters.TokenData.client) {
            context.commit('setLoadingStatus', 'Token-Daten sind falsch');
          } else {
            context.commit('setcurrentCompany', context.getters.TokenData.client.id);
            // Authorisierung
            context.commit('setLoadingStatus', 'Prüfe Berechtigung...');
            context.commit('setTokens', { access_token: context.getters.access_token, refresh_token: context.getters.refresh_token });
            if (await context.dispatch('haveUserRight', "admintools_access")) {
              context.commit('setLoadingStatus', 'Lade Daten...');
              await context.dispatch('updateAll');
              await context.dispatch('setupNotifications');
              context.commit('setLoadingStatusOK');
              // Setup Token Expiration Handler
              let access_token = context.getters.access_token.toString();
              let refresh_token = context.getters.refresh_token.toString();
              let token_expiration_handler = () => setTimeout(async () => {
                if (context.getters.access_token !== access_token) return;
                console.log("[API] tokens expired. Refreshing now...");
                try {
                  let new_tokens_response = await store.$api.post('system/access_token', { refresh_token,
                    headers: {
                      'Authorization': null
                    }});
                  access_token = new_tokens_response.data.access_token;
                  refresh_token = new_tokens_response.data.refresh_token;
                  context.commit('setTokens', new_tokens_response.data);
                  console.log("[API] Done.");
                } catch (err) {
                  context.dispatch('logout');
                  throw err;
                }
                token_expiration_handler();
              }, (context.getters.TokenData.exp * 1000) - new Date().getTime() - 15000 );
              console.log(`[API] System will update tokens after ${((context.getters.TokenData.exp * 1000) - new Date().getTime()) / 1000} seconds.`);
              token_expiration_handler();
              return; // Login erfolgreich
            }
            // Else
            context.commit('setLoadingStatus', 'Berechtigung nicht vorhanden...');
          }
        }
      }
      context.commit('destroySession');
    },
    async haveUserRight(context, name) {
      let cache_right = context.state.rights.find(right => right.name == name);
      if (cache_right != undefined) return cache_right.value;
      let response = await store.$api.get(`user/current/auth/right/${name}`);
      context.commit('addUserRight', { name, value: response.data.result });
      return response.data.result;
    },
    async haveUserConfirmation(context, name) {
      let cache_confirmation = context.state.confirmations.find(confirmation => confirmation.name == name);
      if (cache_confirmation) return cache_confirmation.value;
      let response = await store.$api.get(`user/current/confirmation/${name}`);
      context.commit('addUserConfirmation', { name, value: response.data.result });
      return response;
    },
    async haveUserStep(context, name) {
      let cache_step = context.state.steps.find(step => step.name == name);
      if (cache_step) return cache_step.value;
      let response = await store.$api.get(`user/current/step/${name}`);
      context.commit('addUserStep', { name, value: response.data.result });
      return response;
    },
    async addUserStep(context, name) {
      await store.$api.post(`user/current/step/${name}`);
      context.commit('addUserStep', { name, value: true });
    },
    async getUserSetting(context, name) {
      let cache_setting = context.state.settings.find(step => step.name == name);
      if (cache_setting) return cache_setting.value;
      let response = await store.$api.get(`user/current/setting/${name}`);
      context.commit('addUserSetting', { name, value: response.data.result });
      return response;
    },
    async setUserSetting(context, name) {
      await store.$api.post(`user/current/setting/${name}`);
      context.commit('addUserSetting', { name, value: true });
    },
    async setupNotifications(context) {
      if (context.state.notification_intervall) return;
      context.state.notification_intervall = setInterval(() => context.dispatch('updateNotifications'), 60 * 1000);
      await context.dispatch('updateNotifications');
    },
    async updateNotifications(context) {
      let response = await store.$api.get(`user/current/notification`);
      if (!notification_service.activated()) {  // send request to user to activate

      } else {                                  // send new Notifications

      }
      context.commit('setNotifications', response.data.result);
    },
    async viewNotification(context, id) {
      context.commit('setNotifications', context.state.notifications.map(el => {
        if (el.id == id) el.viewed = true;
        return el;
      }));
      await store.$api.post(`user/current/notification/${id}/view`)
        .catch(err => {
          context.dispatch('updateNotifications');
          throw err;
        });
    },
    async clickNotification(context, id) {
      context.commit('setNotifications', context.state.notifications.map(el => {
        if (el.id == id) el.viewed = true;
        if (el.id == id) el.clicked = true;
        return el;
      }));
      await store.$api.post(`user/current/notification/${id}/click`)
        .catch(err => {
          context.dispatch('updateNotifications');
          throw err;
        });
    },
    async closeNotification(context, id) {
      context.commit('setNotifications', context.state.notifications.filter(el => el.id != id));
      await store.$api.post(`user/current/notification/${id}/close`)
        .catch(err => {
          context.dispatch('updateNotifications');
          throw err;
        });
    }
  },
  getters: {
    refresh_token(state) {
      return state.refresh_token;
    },
    access_token(state) {
      return state.access_token;
    },
    TokenData(state) {
      if (!state.access_token) throw new Error("Token does not exist");
      return jwt.decode(state.access_token);
    },
    currentUser(state) {
      return state.currentUser;
    },
    notifications(state) {
      return state.notifications;
    }
  }
}
export default module_branch
