import Reviews from '@/views/crm/Reviews.vue';

export default [
{
  path: '/reviews',
  name: 'reviews',
  component: Reviews,
  meta: {
    title: 'Bewertungen',
    description: 'Kommen Sie mit Ihren Kunden ins Gespräch, reagieren Sie auf Bewertungen und feilen Sie an Ihren Schwachstellen',
    material_icon: 'star_rate',
    tags: [
      'ratings',
      'company',
      'mybusiness',
      'gmb',
      'maps',
      'google',
      'maps',
      'reviews',
      'feedback',
      'kunden'
    ]
  }
},
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "crm";
  return el;
});
