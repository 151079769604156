<template>
  <div ref="editor" class="add-new-post__editor mb-1"></div>
</template>

<script>
import "quill/dist/quill.snow.css";
import Quill from "quill";
var Delta = Quill.import("delta");

export default {
  name: "Editor",
  props: {
    input: {
      type: String,
      required: false,
    },
  },
  model: {
    prop: "input",
    event: "change",
  },
  data() {
    return {
      quill: null
    };
  },
  watch: {
    input(val) {
      if (val != this.quill.root.innerHTML.split("<p><br></p>").join("<br>").split("</p><p>").join("<br>").split("<p>").join("").split("</p>").join("<br>")) this.quill.setContents(this.quill.clipboard.convert(val), 'silent'); // Parse Input
    }
  },
  mounted() {
    // Init the Quill RTE
    if (this.quill) return;
    this.quill = new Quill(this.$refs.editor, {
      modules: {
        toolbar: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ["bold", "italic", "underline", "strike"],
          ["blockquote", "link", "image"],
          [{ list: "ordered" }, { list: "bullet" }],
          [{ indent: "-1" }, { indent: "+1" }],
        ],
      },
      theme: "snow",
    });
    this.quill.setContents(this.quill.clipboard.convert(this.input), 'silent'); // Parse Input
    this.quill.on("text-change", () => {                                        // Emit Changes
      this.$emit("change", this.quill.root.innerHTML.split("<p><br></p>").join("<br>").split("</p><p>").join("<br>").split("<p>").join("").split("</p>").join("<br>"));
    });
  },
  created() {
    this.model = this.input ? this.input : this.model;
  },
};
</script>

