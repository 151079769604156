<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div v-if="!(loading === false)">Loading</div>
    <div class="error" v-else-if="notFound">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Dieser Vorlage-Kurs konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <d-row v-else no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span
          class="text-uppercase page-subtitle"
          @click="$router.push({ name: 'course_templates' })"
          style="cursor: pointer"
          >Vorlagen-Kurse</span
        >
        <h3 class="page-title">
          <template v-if="edit_title == false">
            {{
              data.name
            }}<br class="d-sm-none" /><small
              style="cursor: pointer"
              @click="edit_title = true"
              v-if="auth_modify"
              ><span class="material-icons text-secondary ml-2">edit</span
              ><span class="d-sm-none">bearbeiten</span></small
            >
          </template>
          <div v-else class="d-flex mr-5">
            <b-form-input
              v-model="edit.name"
            />
            <b-button
              class="mx-2"
              @click="handleEditTitle"
              :disabled="
                (!this.data.processed_unit_name && this.edit_name == null) ||
                this.edit_name.trim() == ''
              "
              >Ändern</b-button
            >
            <span
              class="material-icons text-secondary ml-2 my-auto"
              style="cursor: pointer"
              @click="edit_title = false"
              >close</span
            >
          </div>
        </h3>
      </b-col>
      <b-col cols="12" sm="4" lg="3">
        <b-form-group
          label="Kategorie"
          label-size="sm"
          label-align="center"
          label-align-sm="left"
          label-class="pb-1"
          class="text-center text-sm-left"
        >
          <CategorySelector :selectable="false" />
        </b-form-group>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <!-- Component Header -->
          <d-card-header class="border-bottom d-none d-md-block">
            <h6 class="m-0">Details</h6>
          </d-card-header>

          <d-card-body class="d-flex flex-column px-0 px-lg-3">
            <Course
              ref="course_card"
              :id="id"
              :template="true"
              v-on:finish="finish"
            />
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import { mapGetters } from "vuex";
import Course from "@/components/education/course/Course";
export default {
  name: "CourseTemplateShow",
  components: {
    CategorySelector,
    Course,
  },
  data() {
    return {
      notFound: false,
      loading: null,
      auth_modify: false,
      id: this.$route.params.id,
      edit_title: false,
    };
  },
  computed: {
    ...mapGetters({
      units: "units",
      branches: "branches",
      selectedCategory: "selectedCategory",
    }),
  },
  methods: {
    async init() {
      this.loading = true;
      // get data
      this.data = null;
      this.data = await this.$store
        .dispatch("getTemplateCourse", this.id)
        .catch((err) => {
          if (!err.message.includes("NOT_FOUND")) throw err;
          // notFound
          this.notFound = true;
          this.loading = false;
        });
      if (!this.data) return;
      this.edit = {...this.data};
      if (this.selectedCategory!=this.data.category) this.$store.dispatch("updateSelectedCategory", this.data.category);
      this.loading = false;
    },
    async handleEditTitle() {
      if (!this.edit.name || this.edit.name.trim() == "") return;
      await this.$store.dispatch("updateTemplateCourse", {
        id: this.data.id,
        name: this.edit.name,
      });
      // this.init();
      this.edit_title = false;
    },
    async handleSave() {

    },
    finish(result) {
      this.$router.push({ name: "course_templates"});
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "course_modify"
    );
    await this.init();
  },
};
</script>
