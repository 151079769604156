<template>
  <div>
    <!-- <Customers/> -->
    <Notifications/>
    <Language :visible="$route.query.category=='language'"/>
    <Payment :visible="$route.query.category=='payment'"/>
    <ChatBot :visible="$route.query.category=='chatbot'"/>
  </div>
</template>

<script>
import Notification from "./Notification.vue";
import Payment from "./Payment.vue";
import ChatBot from "./ChatBot.vue";
import Language from "./Language.vue";
import { addSetting } from '../used_settings';
addSetting();
export default {
  components: {
    Notification,
    Payment,
    ChatBot,
    Language,
  },
}
</script>
