<template>
  <d-navbar-nav class="border-left flex-row">
    <li class="nav-item border-right dropdown notifications">
      <a
        class="nav-link nav-link-icon text-center"
        v-d-toggle.notifications
        @click="handleClickOpen"
      >
        <div class="nav-link-icon__wrapper">
          <i class="material-icons">&#xE7F4;</i>
          <d-badge v-if="count >= 1" pill theme="danger">{{ count }}</d-badge>
        </div>
      </a>
      <d-collapse
        id="notifications"
        class="dropdown-menu dropdown-menu-small"
        v-model="notifications_visible"
      >
        <d-dropdown-item v-if="formated_notifications.length == 0" disabled>
          <div class="notification__all text-center">
            Derzeit keine Benachichtigungen!
          </div>
        </d-dropdown-item>
        <d-dropdown-item
          v-for="element in formated_notifications"
          :key="element.create_date + '_' + element.id"
          style="position: relative"
          :style="element.onClick ? '' : 'pointer-events: none;'"
        >
          <div
            class="notification__icon-wrapper"
            @click="() => {
              if (!element.onClick) return;
              element.onClick();
              handleClickClickNotification(element);
            }
            "
          >
            <div class="notification__icon">
              <i class="material-icons">{{ element.material_icon }}</i>
            </div>
          </div>
          <div
            class="notification__content"
            @click="() => {
              if (!element.onClick) return;
              element.onClick();
              handleClickClickNotification(element);
            }
            "
          >
            <span class="notification__category">{{ element.title }}</span>
            <p>{{ element.text }}</p>
          </div>
          <div
            class="p-3"
            style="position: absolute; right: 5%; top: 0; pointer-events: all;"
            @click="handleClickCloseNotification(element)"
          >
            X
          </div>
        </d-dropdown-item>
        <d-dropdown-item class="notification__all text-center"
          ><div @click="handleClickOpenNotificationRoute">
            Alle Benachichtigungen
          </div></d-dropdown-item
        >
      </d-collapse>
    </li>
    <li class="nav-item dropdown">
      <a
        class="nav-link dropdown-toggle text-nowrap px-3"
        v-b-toggle.user-actions
      >
        <b-avatar
          class="mr-3 text-light"
          variant="light"
          size="2.5rem"
          :src="picture_url"
        ></b-avatar>
        <span class="d-none d-md-inline-block mt-2">{{ user.name }}</span>
      </a>
      <b-collapse id="user-actions" class="dropdown-menu dropdown-menu-small" v-model="profile_visible">
        <b-dropdown-item @click="openProfile"
          ><i class="material-icons">&#xE7FD;</i> Profil</b-dropdown-item
        >
        <d-dropdown-divider />
        <d-dropdown-item class="text-danger">
          <div @click="handleLogout">
            <i class="material-icons text-danger">&#xE879;</i> Abmelden
          </div>
        </d-dropdown-item>
      </b-collapse>
    </li>
  </d-navbar-nav>
</template>
<script>
import { mapGetters } from "vuex";
import format_notifications from "@/plugins/format_notifications";

export default {
  data() {
    return {
      notifications_visible: false,
      profile_visible: false,
    };
  },
  computed: {
    count() {
      return this.notifications.filter((el) => !el.viewed).length;
    },
    ...mapGetters({
      notifications: "notifications",
      user: "currentUser",
      company: "company",
    }),
    formated_notifications() {
      return format_notifications(this.notifications.slice(0,5), this);
    },
    picture_url() {
      return `${this.$api.defaults.baseURL}user/${this.user.id}/avatar`;
    }
  },
  methods: {
    openProfile() {
      this.profile_visible = false;
      if (this.$route.name!="current-user") this.$router.push({ name: "current-user" });
    },
    handleLogout: function () {
      this.profile_visible = false;
      this.$store.dispatch("logout");
    },
    async handleClickOpen() {
      this.formated_notifications.forEach((element) =>
        element.viewed
          ? undefined
          : this.$store.dispatch("viewNotification", element.id)
      );
    },
    async handleClickClickNotification(element) {
      this.notifications_visible = false;
      if (element.clicked) return;
      await this.$store.dispatch("clickNotification", element.id);
    },
    async handleClickCloseNotification(element) {
      await this.$store.dispatch("closeNotification", element.id);
    },
    handleClickOpenNotificationRoute() {
      if (this.$route.name != "notifications")
        this.$router.push({ name: "notifications" });
      this.notifications_visible = false;
    },
  },
};
</script>
<style>
.nav-link:hover {
  cursor: pointer;
}
</style>
