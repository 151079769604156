<template>
  <d-card class="card-small blog-comments">
    <!-- Card Header -->
    <d-card-header class="border-bottom">
      <h6 class="m-0">Bewertungen</h6>
    </d-card-header>

    <d-card-body class="p-0">
      <div v-if="!loaded" class="w-100 text-center p-3">
        <div class="spinner-border" role="status">
          <span class="sr-only">Lädt...</span>
        </div>
      </div>
      <div v-if="loaded">
        <div v-if="filtered_reviews.length==0" class="p-3">Keine neuen Bewertungen vorhanden.</div>
        <div v-for="(review, idx) in filtered_reviews.slice( 0, show_count)" :key="idx" class="blog-comments__item d-flex p-3">
          <!-- Avatar -->
          <div v-if="review.author_image" class="blog-comments__avatar mr-3">
            <img :src="review.author_image" :alt="review.author_name" />
          </div>

          <!-- Content -->
          <div class="blog-comments__content">
            <!-- Content - Title -->
            <div class="blog-comments__meta text-muted">
              <a
                :id="review.author_email"
                class="text-secondary"
                :href="(review.author_email) ? 'mailto:'+review.author_email : null"
              >{{ review.author_name }}</a>
              <d-tooltip :target="'#'+review.author_email">
                <i class="material-icons">mail</i> Mail
              </d-tooltip>
              <span class="text-secondary">
                {{ Math.round (review.rating / 20 * 100) / 100 }}
                <i class="material-icons">star</i>
              </span>
              <span class="text-muted">– {{ new Date(review.create_date) | moment("from") }}</span>
            </div>

            <!-- Content - Body -->
            <p class="m-0 my-1 mb-2 text-muted">{{ review.text && review.text.split('(Translated by Google)')[0].length > 180 ? review.text.substr(0,180).split(" ").slice(0,-1).join(" ")+"..." : review.text }}</p>

            <!-- Content - Actions -->
            <div class="blog-comments__actions">
              <d-button-group size="small">
                <d-button
                  class="btn-white"
                  v-if="review.rating>70 || review.answer || !review.text || review.text.split(' ').length<5"
                  @click="approve(review.id)"
                >
                  <span class="text-success">
                    <i class="material-icons">check</i>
                  </span> Veröffentlichen
                </d-button>
                <d-button class="btn-white" v-else @click="answer(review.id)">
                  <span class="text-warning">
                    <i class="material-icons">chat_bubble_outline</i>
                  </span> Antworten
                </d-button>
                <d-button
                  class="btn-white"
                  @click="reject(review.id)"
                  :disabled="!review.answer && review.rating<70 && review.text && review.text.split(' ').length>5"
                >
                  <span class="text-danger">
                    <i class="material-icons">clear</i>
                  </span> Ablehnen
                </d-button>
                <d-button class="btn-white" @click="view(review.id)">
                  <span class="text-light">
                    <i class="material-icons">search</i>
                  </span> Anzeigen/Antworten
                </d-button>
              </d-button-group>
            </div>
          </div>
        </div>
      </div>
    </d-card-body>

    <d-card-footer class="border-top">
      <d-row>
        <!-- View All Comments -->
        <d-col class="text-center view-report">
          <d-button type="submit" class="btn-white" @click="view_all">
            Alle Reviews
            <span v-if="count>0">({{ count }})</span>
          </d-button>
        </d-col>
      </d-row>
    </d-card-footer>
  </d-card>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: "DashboardCardReviews",
  data() {
    return {
      authentificated: false,
      loaded: null,
      show_count: 3,
    };
  },
  computed: {
    ...mapGetters(["reviews"]),
    filtered_reviews() {
      return this.reviews.filter(review => review.status=="created").map(review => {
        review.text = review.text ? review.text.split('(Translated by Google)')[0] : review.text;
        return review;
      });
    },
    count() {
      return this.reviews.length;
    }
  },
  methods: {
    /**
     * Emits an action and passes the discussions' ID for reference.
     * @param  {String} action The action type. One of: approve,reject,edit,view-all-comments
     */
    async approve(id) {
      await this.$store.dispatch('publishReview', { id });
    },
    async reject(id) {
      await this.$store.dispatch('unpublishReview', { id });
    },
    answer(id) {
      this.$router.push({ name: "reviews", params: { id: id, answer: true } });
    },
    view(id) {
      this.$router.push({ name: "reviews", params: { id: id } });
    },
    view_all() {
      this.$router.push({ name: "reviews" });
    },
  },
  async created() {
    await this.$store.dispatch("initReviewModule");
    this.loaded = true;
  }
};
</script>
