import Error404 from '@/views/Error404.vue';

import Lesson from "@/views/education/lesson/Lesson.vue"
import LessonNew from "@/views/education/lesson/LessonNew.vue"
import Lessons from "@/views/education/lesson/Lessons.vue"
import Unit from "@/views/education/unit/Unit.vue"
import UnitNew from "@/views/education/unit/UnitNew.vue"
import Units from "@/views/education/unit/Units.vue"
import UnitGroupIndex from "@/views/education/unit_group/UnitGroupIndex.vue"
import UnitGroupGroup from "@/views/education/unit_group/group/UnitGroupGroup.vue"
import UnitGroupGroupNew from "@/views/education/unit_group/group/UnitGroupGroupNew.vue"
import UnitGroupGroups from "@/views/education/unit_group/group/UnitGroupGroups.vue"
import UnitPriceGroup from "@/views/education/unit_group/price/UnitPriceGroup.vue"
import UnitPriceGroupNew from "@/views/education/unit_group/price/UnitPriceGroupNew.vue"
import UnitPriceGroups from "@/views/education/unit_group/price/UnitPriceGroups.vue"
import Course from "@/views/education/course/Course.vue"
import CourseNew from "@/views/education/course/CourseNew.vue"
import Courses from "@/views/education/course/Courses.vue"
import CourseTemplate from "@/views/education/course_template/CourseTemplate.vue"
import CourseTemplateNew from "@/views/education/course_template/CourseTemplateNew.vue"
import CourseTemplates from "@/views/education/course_template/CourseTemplates.vue"
export default [
  {
    path: '/courses',
    name: 'courses',
    component: Courses,
    meta: {
      title: 'Kursübersicht',
      description: '',
      material_icon: 'home',
      tags: [
        'kurse'
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/course/new",
    name: "course_new",
    component: CourseNew,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/course/templates',
    name: 'course_templates',
    component: CourseTemplates,
    meta: {
      title: 'Kursvorlagenübersicht',
      description: '',
      material_icon: 'home',
      tags: [
        'kurse'
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/course/template/new",
    name: "course_template_new",
    component: CourseTemplateNew,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/course/template/:id',
    name: 'course_template',
    component: CourseTemplate,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/course/:id',
    name: 'course',
    component: Course,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/units',
    name: 'units',
    component: Units,
    meta: {
      title: 'Inhalte',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/unit/new",
    name: "unit_new",
    component: UnitNew,
    meta: {
      title: 'Inhalt hinzufügen',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/unit/group',
    name: 'unit_groups',
    component: UnitGroupIndex,
  },
  {
    path: '/unit/group/group',
    name: 'unit_group_groups',
    component: UnitGroupGroups,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/unit/group/group/new",
    name: "unit_group_group_new",
    component: UnitGroupGroupNew,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/unit/group/group/:id',
    name: 'unit_group_group',
    component: UnitGroupGroup,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/unit/group/price',
    name: 'unit_price_groups',
    component: UnitPriceGroups,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/unit/group/price/new",
    name: "unit_price_group_new",
    component: UnitPriceGroupNew,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/unit/group/price/:id',
    name: 'unit_price_group',
    component: UnitPriceGroup,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/unit/:id',
    name: 'unit',
    component: Unit,
    meta: {
      title: '',
      description: '',
      material_icon: 'home',
      tags: [
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: '/lessons',
    name: 'lessons',
    component: Lessons,
    meta: {
      title: 'Stunden',
      description: 'Termine, an welchen Unterricht durchgeführt wird',
      material_icon: 'account_balance',
      tags: [
        'stundenuebersicht',
        'termine',
        'unterricht',
        'lessons',
        'theory',
        'education',
        'ausbildung',
        'theorie'
      ],
      shortcuts: [
      ]
    }
  },
  {
    path: "/lesson/new",
    name: "lesson_new",
    component: LessonNew,
    props: true,
    meta: {
      title: 'Theoriestunde anlegen',
      description: 'Kostenkontrolle und Verwaltung Ihrer gebuchten Leistungen',
      material_icon: 'account_balance',
      tags: [
        'stundeanlegen',
        'termine',
        'unterricht',
        'lessons',
        'theory',
        'education',
        'ausbildung',
        'theorie',
      ],
      shortcuts: [
        'neu',
        'new',
      ]
    }
  },
  {
    path: '/lesson/:id',
    name: 'lesson',
    component: Lesson,
    meta: {
      title: 'Theoriestunde Details',
      description: 'Überblick über eine Theoriestunde',
    }
  },
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "classes";
  return el;
});
