<template>
  <div v-if="loading" class="text-center">
    <i class="fa fa-spinner fa-spin"></i>
  </div>
  <div v-else-if="!auth_modify">Sie besitzen nicht die benötigten Rechte</div>
  <div v-else-if="notfound" class="text-center">Kurs nicht gefunden</div>
  <b-container v-else fluid>
    <b-row v-if="data.category == null" class="justify-content-center">
      <b-col cols="12" class="text-center mb-2"
        >Bitte Kategorie auswählen</b-col
      >
      <b-col
        cols="12"
        sm="8"
        md="6"
        lg="4"
        xl="3"
        class="d-none d-sm-flex text-center mb-2"
        ><CategorySelector
      /></b-col>
    </b-row>
    <template v-else>
      <b-row>
        <b-col order="3" order-md="1" cols="12" md="9">
          <b-form-group label="Name">
            <b-input-group>
              <b-form-input v-model="data.name"></b-form-input>
              <b-input-group-append v-if="!template">
                <b-button
                  v-b-popover.hover.top="
                    'Durch Auswahl einer Kursvorlage wird dieses Formular vorausgefüllt. Sie können im Anschluss weitere Änderungen vornehmen.'
                  "
                  @click="openModalSelectTemplate"
                >
                  Vorlage
                </b-button>
                <b-modal
                  v-if="!template"
                  ref="modal-select-template"
                  title="Vorlage wählen"
                  @submit.stop.prevent="confirmModalSelectTemplate"
                  body-class="px-0 pb-0"
                  hide-footer
                >
                  <b-form-group
                    label-for="template-search-input"
                    label="Suche:"
                    label-cols-md="auto"
                    label-size="sm"
                    class="mx-1"
                  >
                    <b-form-input
                      v-model="searchTemplates"
                      id="template-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <b-list-group>
                    <b-list-group-item
                      :flush="true"
                      v-for="template in filtered_template_courses"
                      :key="template.name"
                      href="#"
                      @click="confirmModalSelectTemplate(template.id)"
                    >
                      <div class="font-weight-bold">
                        {{ template.name }}
                      </div>
                      <div>
                        <span>Inhalte: </span>
                        {{
                          template.processed_unit_key_name.trim().length == 0
                            ? "keine"
                            : template.processed_unit_key_name
                        }}
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-modal>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="9" md="3" order-md="2" order="1">
          <b-form-group label-for="selectCourseType">
            <label class="mb-1"
              >Kursart
              <i
                class="material-icons"
                v-b-popover.hover.top="{
                  html: true,
                  content:
                    '<strong>geschlossener Kurs: </strong>Hinterlegte Termine sind nur innerhalb des Kurses sichtbar und werden daher nicht in der Theoriestundenübersicht angezeigt. Eine Teilnahme an den Terminen ist nur bei bereits erfolgter Kursteilnahme möglich.<br><br><strong>offener Kurs: </strong>Auf Termine kann über Kurs und über Theoriestundenübersicht zugegriffen werden. Eine evtl. Teilnahme ist auch ohne Kursteilnahme möglich.',
                }"
                >help_outline</i
              ></label
            >
            <b-form-select
              id="selectCourseType"
              v-model="data.type"
              :options="course_types"
            ></b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="mb-2">
        <b-col cols="12">
          <b-form-group>
            <b-row v-if="!template">
              <b-col>
                <b-form-group label="Begin" label-size="sm">
                  <b-form-datepicker
                    v-model="start_date"
                    v-bind="DatePickerConfig"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Ende" label-size="sm">
                  <b-form-datepicker
                    v-model="end_date"
                    v-bind="DatePickerConfig"
                    :state="end_date_state"
                  ></b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col cols="2" class="d-none d-sm-flex">
                <b-form-group label="Dauer" label-size="sm">
                  <div class="mt-1">{{ duration }} Tage</div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-else>
              <b-col cols="12" sm="8" md="6" lg="4">
                <b-form-group>
                  <label class="mb-1"
                    >Standarddauer
                    <i
                      class="material-icons"
                      v-b-popover.hover.top="{
                        html: true,
                        content:
                          'Die Standarddauer gibt an, welcher Zeitraum für die Absolvierung dieser Einheit standardmäßig benötigt wird.<br><br><small>Z.B. wird in der Anlage einer <strong>Theoriestunde</strong> aus diesem Wert die Dauer der Theoriestunde vorgeschlagen.</small>',
                      }"
                      >help_outline</i
                    >
                  </label>
                  <div v-if="data.duration === null">
                    <b-button variant="light" @click="data.duration = 1"
                      >Hinzufügen</b-button
                    >
                  </div>
                  <b-row v-else>
                    <b-col class="d-flex justify-content-between">
                      <b-form-input
                        type="number"
                        v-model="data.duration"
                        min="1"
                        max="365"
                      ></b-form-input>
                      <span class="ml-2 my-auto">Tage</span>
                    </b-col>
                    <b-col cols="auto" class="pl-0">
                      <b-button
                        variant="light"
                        class="mt-1"
                        @click="data.duration = null"
                        >Entfernen</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form-group>
              </b-col>
            </b-row>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="data.type != null">
        <b-col cols="12">
          <b-form-group>
            <template slot="label">
              <div class="d-flex justify-content-between">
                <span>Theoriebestandteile</span>
                <span
                  class="material-icons"
                  v-b-popover.hover.top="
                    'Inhalte definieren die Bestandteile, welche unterrichtet werden.'
                  "
                  >help_outline</span
                >
              </div>
            </template>
            <ul
              v-if="data.units.length > 0"
              class="list-inline d-inline-block mb-2"
            >
              <li
                v-for="unit in selectedUnits"
                :key="unit.id"
                class="list-inline-item"
              >
                <b-form-tag
                  @remove="remUnit(unit.id)"
                  :disabled="
                    !!data.lessons.find((lesson_id) =>
                      lessons
                        .find((lesson) => lesson.id == lesson_id)
                        .units.find((unit_id) => unit_id == unit.id)
                    )
                  "
                  variant="secondary"
                  class="mb-1"
                >
                  <div :id="`unit_${unit.id}`" href="#" class="text-white">
                    {{ unit.key }} -
                    {{ unit.name }}
                  </div>
                </b-form-tag>
                <b-popover :target="`unit_${unit.id}`" triggers="hover">
                  <router-link :to="{ name: 'unit', params: { id: unit.id } }"
                    >Übersicht öffnen</router-link
                  >
                </b-popover>
              </li>
            </ul>
            <b-dropdown
              ref="dropdown-select-units"
              size="sm"
              variant="outline-secondary"
              block
              menu-class="w-100"
            >
              <template v-slot:button-content no-outer-focus
                >Theorieeinheit auswählen</template
              >
              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  label-for="unit-search-input"
                  label="Suche:"
                  label-cols-md="auto"
                  class="mb-0"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="searchUnits"
                    id="unit-search-input"
                    type="search"
                    size="sm"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
              </b-dropdown-form>
              <b-dropdown-group class="mx-4 small">
                <div class="d-flex justify-content-between">
                  <span>
                    <b-button
                      class="py-0 pr-1"
                      variant="link"
                      @click="openModalSelectUnitGroup"
                      >Aus Gruppe wählen</b-button
                    >
                    <i
                      class="material-icons"
                      v-b-popover.hover.top="
                        'Inhalte können in Gruppen gegliedert werden. Durch Auswahl einer Gruppe, werden alle dazugehörigen Inhalte dem Kurs hinzugefügt.'
                      "
                      >help_outline</i
                    >
                  </span>
                  <b-button
                    class="py-0 pr-1"
                    variant="link"
                    @click="remUnusedUnits"
                    >{{
                      template
                        ? "Lösche alle Bestandteile"
                        : "Lösche unverwendete Bestandteile"
                    }}</b-button
                  >
                </div>
                <b-modal
                  ref="modal-select-unit-group"
                  title="Gruppe wählen"
                  @submit.stop.prevent="confirmModalSelectUnitGroup"
                  body-class="px-0 pb-0"
                  hide-footer
                >
                  <b-form-group
                    label-for="template-search-input"
                    label="Suche:"
                    label-cols-md="auto"
                    label-size="sm"
                    class="mx-1"
                  >
                    <b-form-input
                      v-model="searchUnitGroups"
                      id="template-search-input"
                      type="search"
                      size="sm"
                      autocomplete="off"
                    ></b-form-input>
                  </b-form-group>
                  <b-list-group v-if="filtered_unit_groups.length > 0">
                    <b-list-group-item
                      :flush="true"
                      v-for="unit_group in filtered_unit_groups"
                      :key="unit_group.name"
                      href="#"
                      @click="confirmModalSelectUnitGroup(unit_group.id)"
                    >
                      <div class="font-weight-bold">
                        {{ unit_group.name }}
                      </div>
                      <div>
                        <span>Inhalte: </span>
                        {{ unit_group.processed_unit_key_name }}
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                  <small v-else>keine Gruppen vorhanden</small>
                </b-modal>
              </b-dropdown-group>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group style="overflow: auto; max-height: 30vh">
                <b-dropdown-item
                  v-for="unit in filteredUnits"
                  :key="unit.id"
                  @click="addUnit(unit.id)"
                  >{{ unit.key }} - {{ unit.name }}</b-dropdown-item
                >
              </b-dropdown-group>
              <b-dropdown-text v-if="state_units.length === 0"
                >Keine Inhalte definiert</b-dropdown-text
              >
              <b-dropdown-text v-else-if="filteredUnits.length === 0"
                >Keine Inhalte gefunden</b-dropdown-text
              >
            </b-dropdown>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="!template && data.type != null">
        <b-col cols="12">
          <b-form-group>
            <template slot="label">
              <div class="d-flex justify-content-between">
                <span>Termine</span>
                <span
                  class="material-icons"
                  v-b-popover.hover.top="{
                    html: true,
                    content:
                      'Termine sind Theoriestunden, welche dem Kurs zugeordnet sind.',
                  }"
                  >help_outline</span
                >
              </div>
            </template>
            <ul
              v-if="lessons.length > 0"
              class="list-inline d-inline-block mb-2"
            >
              <li
                v-for="lesson in selectedLessons"
                :key="lesson.id"
                class="list-inline-item"
              >
                <b-form-tag
                  @remove="remLesson(lesson.id ? lesson.id : lesson.uniqueId)"
                  variant="secondary"
                  class="mb-1"
                >
                  <div
                    style="cursor: pointer"
                    class="text-white"
                    @click="
                      $root.$emit(
                        'bv::show::modal',
                        `modal-edit-lesson-${
                          lesson.id ? lesson.id : lesson.uniqueId
                        }`
                      )
                    "
                  >
                    <template v-if="lesson.id">
                      <template v-if="lesson.date">{{ lesson.date.start | moment("D. MMM YYYY") }}</template>
                    </template>
                    <template v-else>
                      Neu:
                      <template v-if="lesson.date">{{ lesson.date.start | moment("D. MMM YYYY") }}</template>
                    </template>
                  </div>
                </b-form-tag>
                <b-modal
                  :ref="`modal-edit-lesson-${
                    lesson.id ? lesson.id : lesson.uniqueId
                  }`"
                  :id="`modal-edit-lesson-${
                    lesson.id ? lesson.id : lesson.uniqueId
                  }`"
                >
                  <Lesson
                    v-if="lesson.id"
                    :id="lesson.id"
                    :allowedUnits="data.units"
                    :min_date="data.date.start"
                    :max_date="data.date.end"
                    hideSubmit
                  />
                  <Lesson
                    v-else
                    preventSubmit
                    hideSubmit
                    v-bind="lesson"
                    :allowedUnits="data.units"
                    :min_date="data.date.start"
                    :max_date="data.date.end"
                    :date="{ start: data.date.start }"
                    v-on:finish="
                      (result) => {
                        newLessons = newLessons.filter(
                          (el) => el.uniqueId != lesson.uniqueId
                        );
                        handleNewLesson(result);
                      }
                    "
                  />
                </b-modal>
              </li>
            </ul>
            <b-dropdown
              v-if="data.type == 'open'"
              ref="dropdown-select-lessons"
              size="sm"
              variant="outline-secondary"
              class="mb-2"
              block
              menu-class="w-100"
            >
              <template v-slot:button-content no-outer-focus
                >existierende Unterrichtsstunde/n auswählen</template
              >
              <b-dropdown-form @submit.stop.prevent="() => {}">
                <b-form-group
                  label-for="lesson-search-input"
                  label="Suche:"
                  label-cols-md="auto"
                  class="mb-0"
                  label-size="sm"
                >
                  <b-form-input
                    v-model="searchLessons"
                    id="lesson-search-input"
                    type="search"
                    size="sm"
                    autocomplete="off"
                  ></b-form-input>
                </b-form-group>
                <b-dropdown-group class="mx-4 small">
                  <div class="d-flex justify-content-between">
                    <b-button
                      class="pb-0 pr-1"
                      variant="link"
                      @click="handleAddAllLessons"
                      >Alle Hinzufügen</b-button
                    >
                  </div>
                </b-dropdown-group>
              </b-dropdown-form>
              <b-dropdown-divider></b-dropdown-divider>
              <b-dropdown-group style="overflow: auto; max-height: 30vh">
                <b-dropdown-item
                  v-for="lesson in filteredLessons"
                  :key="lesson.id"
                  @click="addLesson(lesson.id)"
                >
                  <div class="d-flex justify-content-between">
                    <span class="mr-2">{{ lesson.processed_unit_name }}</span>
                    <span v-if="lesson.date">{{
                      lesson.date.start | moment("D. MMM HH:mm")
                    }}</span>
                  </div>
                </b-dropdown-item>
              </b-dropdown-group>
              <b-dropdown-text v-if="lessons.length === 0"
                >Keine Theoriestunden in diesem Zeiraum
                vorhanden</b-dropdown-text
              >
              <b-dropdown-text v-else-if="filteredLessons.length === 0"
                >Keine Theoriestunden gefunden</b-dropdown-text
              >
            </b-dropdown>
            <b-button
              class="w-100"
              @click="
                $refs['modal-create-lesson'].show();
                $nextTick(() => $refs['component-create-lesson'].init());
              "
              >Neue Theoriestunde hinzufügen</b-button
            >
            <b-modal
              ref="modal-create-lesson"
              title="Neue Theoriestunde anlegen"
              @ok="handleModalCreateLessonOK"
            >
              <Lesson
                ref="component-create-lesson"
                hideSubmit
                preventSubmit
                :allowedUnits="data.units"
                v-on:finish="handleCreateLesson"
              />
            </b-modal>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-b-toggle.accordion-participation
          cols="12"
          style="cursor: pointer"
        >
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Teilnahme <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="{
                content:
                  'In diesem Abschnitt legen Sie fest, wie die <strong>Teilnahme</strong> an dem Kurs geregelt ist.<br><br>Sie können zum Beispiel entscheiden, ob Ihre Kunden sich selbstständig, nur durch eine Anmeldung bei einem Mitarbeiter oder garnicht anmelden können.<br>Auch eine max. Teilnehmeranzahl ist auswählbar.',
                html: true,
              }"
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-participation" class="col pb-4">
          <h3>Diese Funktionalität ist noch in Arbeit</h3>
          <small
            >Teilnahme-Form: wer darf entscheiden? wie kann Teilgenommen werden?
            + an den Theoriestunden</small
          ><br />
          <small
            >Teilnahme-Abschluss-Art: Muss ein Teilnehmer bei allen Stunden
            anwensend sein um den Kurs abzuschließen? (nur bei geschlossenen
            Kursen)</small
          >
        </b-collapse>
      </b-row>
      <b-row>
        <b-col v-b-toggle.accordion-actions cols="12" style="cursor: pointer">
          <div class="d-flex justify-content-between mx-1 mb-0 pb-0">
            <div class="pb-0">
              Aktionen <small class=""> (klicken zum Öffnen)</small>
            </div>
            <span
              class="material-icons"
              v-b-popover.hover.top="{
                content:
                  'In diesem Abschnitt legen Sie Aktionen fest, welche bei bestimmten Bedingungen automatisch ausgeführt werden sollen. <br>Das kann zum Beispiel eine Begrüßung eines neuen Teilnehmers oder das Versenden von interessanten Informationen oder Angeboten nach Kursende sein.',
                html: true,
              }"
              >help_outline</span
            >
          </div>
          <hr class="mt-1" />
        </b-col>
        <b-collapse id="accordion-actions" class="col pb-4">
          <h3>Diese Funktionalität ist noch in Arbeit</h3>
          <small
            >z.B. Versenden von Begrüßungsnachichten o. Infomaterial an
            Angemeldete</small
          >
        </b-collapse>
      </b-row>
      <b-row class="mx-1 d-flex justify-content-between">
        <div>
          <b-button variant="success" @click="submit()">{{
            id ? "Aktualisieren" : "Anlegen"
          }}</b-button>
          <b-button
            v-if="id && !canceled && !template"
            variant="warning"
            class="ml-2"
            >Absagen</b-button
          >
          <b-button v-if="id && canceled" variant="outline-warning" class="ml-2"
            >Reaktivieren</b-button
          >
          <b-button
            v-if="id"
            variant="outline-danger"
            class="ml-2"
            @click="handleDelete"
            >Löschen</b-button
          >
        </div>
        <b-form-checkbox v-if="!template" v-model="save_template" switch>
          Als Vorlage speichern
        </b-form-checkbox>
      </b-row>
    </template>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import Vue from "vue";
import { mapGetters } from "vuex";
import moment from "moment";
import randomstring from "randomstring";
import CategorySelector from "@/components/education/other_elements/CategorySelector";
import Lesson from "@/components/education/lesson/Lesson";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";
// TODO: blur component, if the use has not the right "lesson_modify"
export default {
  name: "Course",
  components: {
    CategorySelector,
    Lesson,
  },
  props: {
    /**
     * ID for a Lesson, to update an existing lesson, if set the lesson can be updated
     */
    id: {
      type: Number,
      default: null,
    },
    template: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: null,
      notfound: false,
      auth_modify: null,
      searchTemplates: "",
      searchUnits: "",
      searchUnitGroups: "",
      searchLessons: "",
      DatePickerConfig,
      TimePickerConfig,
      lessons: [], // selectable Lessons
      template_courses: [], // selectable TemplateCourses
      course_types: [
        {
          value: null,
          text: "Bitte auswählen",
        },
        {
          value: "open",
          text: "Offener Kurs",
        },
        {
          value: "closed",
          text: "Geschlossener Kurs",
        },
      ],
      // DATA
      data: {
        name: null,
        description: null,
        category: null,
        type: null,
        units: [],
        lessons: [],
        date: {
          start: moment().format("YYYY-MM-DD"),
          end: moment().add(1, "d").format("YYYY-MM-DD"),
        },
        duration: null, // Template Only
        canceled: false,
        postponed: false,
      },
      newLessons: [],
      // Actions
      action_events: [],
      action_types: [],
      action_name: null,
      actions: [],
      // More
      save_template: false,
    };
  },
  computed: {
    ...mapGetters({
      state_units: "units",
      state_unit_groups: "unit_groups",
      selectedCategory: "selectedCategory",
    }),
    filteredUnits() {
      const criteria = this.searchUnits.trim().toLowerCase();
      // Filter out already selected options
      const options = this.state_units.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !this.data.units.find((unit_id) => unit_id == option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    filteredLessons() {
      const criteria = this.searchLessons.trim().toLowerCase();
      // Filter out already selected options
      const options = this.lessons.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          !option.units.find(
            (lesson_unit_id) =>
              !this.data.units.find((unit_id) => unit_id == lesson_unit_id)
          ) &&
          !this.data.lessons.find((lesson_id) => lesson_id == option.id)
      );
      if (criteria) {
        // Show only options that match criteria (key or name)
        return options.filter(
          (option) =>
            option.key.toLowerCase().includes(criteria) ||
            option.name.toLowerCase().includes(criteria)
        );
      }
      // Show all options available
      return options;
    },
    filtered_template_courses() {
      const criteria = this.searchTemplates.trim().toLowerCase();
      return this.template_courses.filter((option) =>
        option.name.trim().toLowerCase().includes(criteria)
      );
    },
    filtered_unit_groups() {
      const criteria = this.searchUnitGroups.trim().toLowerCase();
      return this.state_unit_groups.filter(
        (option) =>
          option.category.trim().toLowerCase() == this.selectedCategory &&
          option.name.trim().toLowerCase().includes(criteria)
      );
    },
    // Template Variables
    start_date: {
      get() {
        return moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss").format(
          "YYYY-MM-DD"
        );
      },
      set(value) {
        this.data.date.start = value;
      },
      deep: true,
    },
    end_date: {
      get() {
        return moment(this.data.date.end, "YYYY-MM-DD").format("YYYY-MM-DD");
      },
      set(value) {
        this.data.date.end = value;
      },
      deep: true,
    },
    duration: {
      get() {
        let duration = moment.duration(
          moment(this.data.date.end, "YYYY-MM-DD HH:mm:ss").diff(
            moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss")
          )
        );
        return duration.get("d") + 1;
      },
      set(value) {
        this.end_date = moment(this.data.date.start, "YYYY-MM-DD HH:mm:ss")
          .add({
            days: value - 1,
          })
          .format("YYYY-MM-DD");
      },
      deep: true,
    },
    selectedUnits() {
      return this.state_units.filter((unit) =>
        this.data.units.find((unit_id) => unit_id == unit.id)
      );
    },
    selectedLessons() {
      return [
        ...this.lessons.filter((unit) =>
          this.data.lessons.find((unit_id) => unit_id == unit.id)
        ),
        ...this.newLessons,
      ];
    },
    // State
    end_date_state() {
      return moment(this.end_date, "YYYY-MM-DD HH:mm:ss").isSameOrAfter(
        moment(this.start_date, "YYYY-MM-DD HH:mm:ss")
      )
        ? null
        : false;
    },
  },
  watch: {
    selectedCategory: async function (val) {
      await this.init();
    },
    category: function (val, oldval) {
      if (val != this.selectedCategory)
        this.$store.dispatch("updateSelectedCategory", val);
      if (val == oldval) return;
      this.data.lessons = [];
      this.data.units = [];
    },
    start_date: async function (val, oldval) {
      await this.updateLessons(oldval);
      if (val == oldval) return;
      this.end_date = moment(val, "YYYY-MM-DD")
        .add(
          moment.duration(
            moment(this.end_date, "YYYY-MM-DDs").diff(
              moment(oldval, "YYYY-MM-DD")
            )
          )
        )
        .format("YYYY-MM-DD");
    },
    end_date: function (val, oldval) {
      this.updateLessons(undefined, oldval);
    },
  },
  methods: {
    async setDefaults() {
      // Load from Settings (Kursart, Teilnahme, Aktionen, Berechtigung)
      await Promise.all([
        (async () => {
          this.auth_modify = await this.$store.dispatch(
            "haveUserRight",
            "course_modify"
          );
        })(),
        (async () => {
          let type = (
            await this.$store.dispatch("getSetting", "course_default_type")
          );
          if (
            this.course_types.find((course_type) => course_type.value == type)
          )
            this.data.type = type;
          else
            console.warn(
              `Setting: course_default_type has not a valid value: ${type}`
            );
        })(),
      ]);
    },
    async init() {
      this.loading = true;
      // Minimal Reset
      this.data.name = null;
      this.data.lessons = [];
      this.data.units = [];
      this.data.actions = [];

      this.data.category = this.selectedCategory;
      await Promise.all([this.updateLessons(), this.updateTemplates()]);
      if (this.id) {
        let result = await this.$store
          .dispatch(this.template ? "getTemplateCourse" : "getCourse", this.id)
          .catch((err) => {
            if (err && err.code == 404) {
              this.notfound = true;
              this.loading == false;
            } else {
              this.$apimethod.defaulthandler(err);
              this.loading = false;
            }
            throw err;
          });
        this.notfound = false;
        // HEAD
        this.data.name = result.name;
        this.data.type = result.type;
        this.data.description = result.description;
        this.data.date.start = !this.template
          ? result.date.start
          : this.data.date.start;
        this.data.date.end = !this.template
          ? result.date.end
          : this.data.date.end;
        this.data.duration = this.template ? result.duration : null;
        this.data.category = result.category;
        this.actions = result.action;

        this.data.canceled = result.canceled;
        this.data.postponed = result.postponed;

        // Set Units
        this.data.units = result.units.filter((unit_id) =>
          this.state_units.find((unit) => unit_id == unit.id)
        );
        // Set Lessons
        this.data.lessons = result.lessons
          ? this.lessons.filter((lesson) =>
              result.lessons.find((course_lesson) => course_lesson == lesson.id)
            )
          : [];
      }
      this.loading = false;
    },
    async updateLessons(old_start_date, old_end_date) {
      if (this.template) return;
      // Get available lessons
      let lessons = await this.$store.dispatch("getLessons", {
        category: this.selectedCategory,
        start_date: this.start_date,
        end_date: this.end_date,
      });

      // Check if changes will happen to selected Lessons
      let change = true;
      if (
        this.data.lessons.length !=
        this.data.lessons.filter((lesson_id) =>
          lessons.find((lesson) => lesson.id == lesson_id)
        ).length
      )
        await Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, delete it!",
        }).then((result) => {
          if (!result.isConfirmed) {
            change = false;
            if (old_start_date) this.start_date = old_start_date;
            if (old_end_date) this.end_date = old_end_date;
          }
        });
      // If it has to be changes it will
      if (!change) return;

      this.lessons = lessons; // Make sure, to set new lessons, after the user descides, if he wants the changes or not
      // Check selected Lessons, if valid
      this.data.lessons = this.data.lessons.filter((lesson_id) =>
        this.lessons.find((lesson) => lesson.id == lesson_id)
      );
    },
    async updateTemplates() {
      if (this.template) return;
      // Get available TemplateCourses
      this.template_courses = await this.$store.dispatch("getTemplateCourses", {
        category: this.selectedCategory,
      });
    },
    validate() {},
    async submit() {
      // Precheck
      // Branch OR Address!!!
      // Create a new Lesson
      if (this.template || this.save_template)
        await this.$store
          .dispatch(
            this.save_template || !this.id
              ? "createTemplateCourse"
              : "updateTemplateCourse",
            {
              id: this.id && !this.save_template ? this.id : undefined,
              type: this.data.type,
              name: this.data.name,
              description:
                this.data.description == null ||
                this.data.description.trim() == ""
                  ? null
                  : this.data.description,
              units: this.data.units.map((el) => parseInt(el)),
              duration: parseInt(
                this.save_template ? this.duration : this.data.duration
              ),
            }
          )
          .then((result) => {
            if (this.save_template) return;
            if (this.id)
              this.$globalmethod.toast_success("Erfolgreich aktualisiert.");
            else this.$globalmethod.toast_success("Erfolgreich angelegt.");
            this.$emit("finish", result);
          })
          .catch((err) => {
            if (this.save_template)
              this.$globalmethod.toast_error(
                "Fehler beim Anlegen der Vorlage."
              );
            this.$apimethod.defaulthandler(err);
          });
      if (!this.template)
        await this.$store
          .dispatch(this.id ? "updateCourse" : "createCourse", {
            id: this.id ? this.id : undefined,
            type: this.data.type,
            name: this.data.name,
            description:
              this.data.description == null ||
              this.data.description.trim() == ""
                ? null
                : this.data.description,
            units: this.data.units.map((el) => parseInt(el)),
            lessons: [
              ...this.data.lessons.map((el) => parseInt(el)),
              ...this.newLessons.map((elem) => {
                return {
                  name: elem.name,
                  category: elem.category,
                  description: elem.description,
                  date: elem.date,
                  units: elem.units,
                  branch_id: elem.branch_id,
                  address: elem.branch_id ? null : elem.address,
                };
              }),
            ],
            date: {
              start: this.data.date.start,
              end: this.data.date.end,
            },
          })
          .then((result) => {
            if (this.id)
              this.$globalmethod.toast_success("Erfolgreich aktualisiert.");
            else this.$globalmethod.toast_success("Erfolgreich angelegt.");
            this.$emit("finish", result);
          })
          .catch((err) => {
            this.$apimethod.defaulthandler(err);
          });
    },
    loadTemplate(id) {
      if (template) return;
      let template = this.template_courses.find((elem) => elem.id == id);
      if (!template) throw new Error("NOT_FOUND");

      // HEAD
      this.data.name = template.name;
      this.data.description = template.description;
      this.data.type = template.type;
      this.actions = template.action;

      // Set Units
      this.data.units = [...new Set([...this.data.units, ...template.units])];
      // Date
      this.end_date = moment(this.start_date, "YYYY-MM-DD")
        .add(template.duration, "d")
        .format("YYYY-MM-DD");
    },
    loadUnitGroup(id) {
      let unit_group = this.state_unit_groups.find((elem) => elem.id == id);
      if (!unit_group) throw new Error("NOT_FOUND");
      this.data.units = [
        ...new Set([
          ...this.data.units,
          ...this.state_unit_groups.find((unit_group) => unit_group.id == id)
            .units.all,
        ]),
      ];
    },
    addUnit(unit_id) {
      var unit = this.state_units.find((elem) => elem.id == unit_id);
      if (!unit) return;
      this.data.units.push(parseInt(unit_id));
    },
    remUnit(unit_id) {
      this.data.units = this.data.units.filter(
        (id) => id !== parseInt(unit_id)
      );
    },
    remUnusedUnits() {
      this.data.units = this.data.units.filter((unit) =>
        this.lessons.find(
          (lesson) =>
            this.selectedLessons.find((lesson2) => lesson2.id == lesson.id) &&
            lesson.units.find((unit_id) => unit == unit_id)
        )
      );
      this.$refs["dropdown-select-units"].hide();
    },
    addLesson(lesson_id) {
      var lesson = this.lessons.find((elem) => elem.id == lesson_id);
      if (!lesson) return;
      this.data.lessons.push(parseInt(lesson_id));
    },
    remLesson(lesson_id) {
      this.data.lessons = this.data.lessons.filter(
        (id) => id != parseInt(lesson_id)
      );
      this.newLessons = this.newLessons.filter(
        (el) => el.uniqueId != parseInt(lesson_id)
      );
    },
    handleAddAllLessons() {
      this.filteredLessons.forEach((lesson) => this.addLesson(lesson.id));
      this.$refs["dropdown-select-lessons"].hide();
    },
    // Modal Select Template
    openModalSelectTemplate() {
      this.$refs["modal-select-template"].show();
    },
    confirmModalSelectTemplate(id) {
      this.loadTemplate(id);
      this.$refs["modal-select-template"].hide();
    },
    openModalSelectUnitGroup() {
      this.$refs["modal-select-unit-group"].show();
    },
    confirmModalSelectUnitGroup(id) {
      this.loadUnitGroup(id);
      this.$refs["modal-select-unit-group"].hide();
    },
    // Modal - createLesson
    async handleModalCreateLessonOK(modalEv) {
      modalEv.preventDefault();
      this.$refs["component-create-lesson"].submit();
    },
    handleCreateLesson(data) {
      data.uniqueId = randomstring.generate();
      this.newLessons.push(data);
      this.$nextTick(() => this.$refs["modal-create-lesson"].hide());
    },
    async handleDelete() {
      if (
        !(await this.$store.dispatch(
          this.template ? "removeTemplateCourse" : "removeCourse",
          { id: this.id }
        ))
      )
        return;
      this.$router.back();
    },
  },
  async created() {
    this.loading = true;
    await this.setDefaults();
    await this.init();
  },
};
</script>
