var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.chatbot_active.description),expression:"settings_obj.chatbot_active.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_vm._v(" Aktiv "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":"","checked":_vm.settings_obj.chatbot_active.value.current,"disabled":""}})],1),(_vm.settings_obj.chatbot_active.value.current)?[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Name des ChatBots "),_c('small',[_vm._v(_vm._s(!!_vm.settings_obj.chatbot_name.value.current ? ("Aktuell stellt sich der ChatBot als " + (_vm.settings_obj.chatbot_name.value.current) + " vor.") : 'Aktuell stellt sich der ChatBot ohne Name vor.'))])]},proxy:true}],null,false,866164937)},[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.chatbot_name.description),expression:"settings_obj.chatbot_name.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_c('b-form-checkbox',{attrs:{"checked":!!_vm.settings_obj.chatbot_name.value.current},on:{"change":function (val) { return !val ?
              _vm.$store.dispatch('setSetting', {
                name: 'chatbot_name',
                value: null,
              }) : undefined; }},model:{value:(_vm.chatbot_name_active),callback:function ($$v) {_vm.chatbot_name_active=$$v},expression:"chatbot_name_active"}}),_c('b-input-group',[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.chatbot_name.value.current,"disabled":!_vm.chatbot_name_active,"debounce":"2000"},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                  name: 'chatbot_name',
                  value: val,
                }); }}})],1)],1)])]:_c('div',[_vm._v(" für Ihre Firma ist der ChatBot nicht aktiviert. Bitte sprechen Sie uns zur Aktivierung gerne an. ")])],2)}
var staticRenderFns = []

export { render, staticRenderFns }