<template>
  <b-container fluid>
    <b-row v-if="auth_modify">
      <b-col class="text-center">
        <b-button
          @click="
            $router.push({
              name: template ? 'course_template_new' : 'course_new',
            })
          "
        >
          <i class="material-icons">add</i> Hinzufügen
          <i class="material-icons ml-3">double_arrow</i>
        </b-button>
      </b-col>
    </b-row>
    <b-row class="my-3" v-if="template">
      <b-col lg="12" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Suche..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="my-3" v-else>
      <b-col lg="6" class="my-1">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input
              v-model="filter"
              type="search"
              id="filterInput"
              placeholder="Suche..."
            ></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col lg="6" class="my-1">
        <b-form-group
          label="Auswahl ab"
          label-cols-lg="3"
          label-align-lg="right"
          label-size="sm"
          label-for="executionTime"
          class="mb-0"
        >
          <b-form-select
            v-model="currentTime"
            id="executionTime"
            size="sm"
            :options="[
              { value: false, text: 'Heute' },
              { value: null, text: 'Datum wählen' },
              ...customTimes,
            ]"
          ></b-form-select>
          <b-modal
            ref="modal-time-select"
            title="Begin des Zeitraums auswählen..."
            @ok="handleOKModalTimeSelect"
            @hidden="handleCancelModalTimeSelect"
          >
            <b-form-datepicker
              v-model="select_time"
              v-bind="DatePickerConfig"
            ></b-form-datepicker>
          </b-modal>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table
      hover
      ref="course_table"
      show-empty
      small
      :busy.sync="loading"
      :items="items"
      :fields="fields"
      :filter="filter"
      selectable
      @row-selected="onRowSelected"
      :select-mode="selectMode"
    >
      <!-- Selected Coloumn @fullscreen -->
      <template v-slot:head(selected)>
        <div class="d-none d-lg-block"></div>
      </template>
      <template v-slot:cell(selected)="{ rowSelected }">
        <div class="d-none d-lg-block">
          <template v-if="rowSelected">
            <span aria-hidden="true">&check;</span>
            <span class="sr-only">Selected</span>
          </template>
          <template v-else>
            <span aria-hidden="true">&nbsp;</span>
            <span class="sr-only">Not selected</span>
          </template>
        </div>
      </template>
      <template v-slot:cell(name)="row">
        <div class="d-flex justify-content-between">
          <del
            @click="view(row.item.id)"
            :class="!row.item.canceled ? 'text-decoration-none' : ''"
          >
            {{ row.item.name }}
          </del>
          <i
            v-if="row.item.units && row.item.units.length"
            class="material-icons"
            v-b-popover.hover="coursespopup(row.item.units)"
            style="cursor: pointer"
            >view_list</i
          >
        </div>
      </template>
      <!-- //TODO Popup missing-->
      <template v-slot:cell(location)="row">
        <template v-if="typeof row.item.branch == 'object'">
          {{ row.item.branch.name }}
          <i
            class="material-icons"
            @click="view_branch(row.item.branch.id)"
            style="cursor: pointer"
            >link</i
          >
        </template>
        <template v-else-if="typeof row.item.address == 'object'">
          {{ row.item.address.street + " " + row.item.address.streetnumber }}
          <i
            class="material-icons"
            v-b-popover.hover="{
              content:
                row.item.address.street +
                ' ' +
                row.item.address.streetnumber +
                '<br>' +
                row.item.address.zip +
                ' ' +
                row.item.address.city,
              html: true,
            }"
            style="cursor: pointer"
            >info</i
          >
        </template>
        <template v-else></template>
      </template>
      <!-- //TODO Popup missing-->
      <template v-slot:cell(date)="row">
        <span
          >{{ row.item.date.start.format("D. MMM") }} -
          {{
            row.item.date.start.year() == row.item.date.end.year()
              ? row.item.date.end.format("D. MMM")
              : row.item.date.end.format("D. MMM YYYY")
          }}</span
        >
      </template>
      <template v-slot:cell(duration)="row">
        <small v-if="row.item.duration == null || row.item.duration <= 0">
          nicht angegeben
        </small>
        <template v-else>
          {{ [row.item.duration, "days"] | duration("humanize") }}
        </template>
      </template>

      <!-- Actions Coloumn @fullscreen -->
      <template v-slot:head(actions)>
        <div class="text-info text-right d-none d-lg-block" v-if="auth_modify">
          <span @click="cancelselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: orange; font-size: 1.3rem;"
            >do_disturb_on</span
          >
          <span @click="reactivateselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: green; font-size: 1.3rem;"
            >do_disturb_off</span
          >
          <span @click="removeselected()" class="material-icons mr-1 p-1" style="cursor: pointer; color: red; font-size: 1.3rem;"
            >delete</span
          >
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <div v-if="auth_modify" class="text-info text-right d-none d-lg-block">
          <span v-if="!row.item.canceled && ['closed'].includes(row.item.type)" @click="cancel(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: orange; font-size: 1.2rem;"
            >do_disturb_on</span
          >
          <span v-else-if="['closed'].includes(row.item.type)" @click="reactivate(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: green; font-size: 1.2rem;"
            >do_disturb_off</span
          >
          <span @click="remove(row)" class="material-icons mr-1 p-1" style="cursor: pointer; color: red; font-size: 1.2rem;"
            >delete</span
          >
        </div>
      </template>

      <!-- Error's -->
      <template v-slot:empty>
        <div
          v-if="loading !== null"
          class="text-center mt-2 small"
          @click="
            selectedCategory == null
              ? null
              : $store.dispatch('updateSelectedCategory', null)
          "
        >
          <template v-if="selectedCategory == null">
            Es sind keine {{ template ? "Vorlagen" : "Kurse" }} vorhanden...
          </template>
          <template v-else>
            Es wurden leider keine {{ template ? "Vorlagen" : "Kurse" }} für die
            Kategorie
            <strong>{{
              categories.find((elem) => elem.name == selectedCategory).title
            }}</strong>
            gefunden...
            <br />
            <br />
            <small>Hier klicken für <strong>Alle Kategorien</strong></small>
            <br />
          </template>
        </div>
        <div v-else class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center">
          <i class="fas fa-cog fa-spin"></i>
        </div>
      </template>
      <template v-slot:emptyfiltered>
        <div class="text-center mt-2 small">
          Keine {{ template ? "Vorlagen" : "Kurse" }} für diese
          Filtereinstellung gefunden...
        </div>
      </template>
    </b-table>
    <small>Es werden max. 100 Einträge angezeigt</small>
  </b-container>
</template>

<script>
import Swal from "sweetalert2";
import moment from "moment";
import { mapGetters } from "vuex";
import {
  DatePickerConfig,
  TimePickerConfig,
} from "@/data/bootstrap-form-config.js";

export default {
  name: "CourseOverview",
  props: {
    /**
     * The component's type.
     */
    time: {
      default: false,
      validator: function (val) {
        if (val == false) return true;
        if (moment(val, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"]).isValid())
          return true;
        return false;
      },
    },
    template: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: null,
      auth_modify: null,
      selectMode: "multi",
      selected: [],
      totalRows: 1,
      filter: null,
      items: [],
      customTimes: [],
      currentTime: this.time,
      // Modal Time Select
      select_time: null,
      DatePickerConfig,
      TimePickerConfig,
    };
  },
  computed: {
    ...mapGetters(["units", "selectedCategory", "categories"]),
    fields: function () {
      return [
        {
          key: "selected",
          label: "",
          class: "d-none d-lg-table-cell",
        },
        {
          key: "name",
          label: "Name",
          sortable: true,
        },
        this.template
          ? undefined
          : {
              key: "date",
              label: "Wann?",
              sortable: true,
              sortDirection: "asc",
            },
        this.template
          ? undefined
          : { key: "actions", class: this.auth_modify ? "" : "d-none" },
        this.template ? { key: "duration", label: "Standarddauer" } : undefined,
      ];
    },
  },
  watch: {
    selectedCategory: function () {
      this.init();
    },
    currentTime: function (val) {
      if (val === null) return this.openModalTimeSelect();
      this.init();
    },
  },
  methods: {
    async init() {
      if (this.loading !== null) this.loading = true;
      let time = this.currentTime
        ? moment(this.currentTime, ["YYYY-MM-DD HH:mm:ss", "YYYY-MM-DD"])
        : false;
      let courses = await this.$store.dispatch(
        this.template ? "getTemplateCourses" : "getCourses",
        {
          category: this.selectedCategory,
          min_end_date:
            time && time.isValid() ? time.format("YYYY-MM-DD") : undefined,
        }
      );
      // Setup courses
      courses.map((course) => {
        if (this.template) return course;
        // Settings
        course.date.start = moment(course.date.start, "YYYY-MM-DD HH:mm:ss");
        course.date.end = moment(course.date.end, "YYYY-MM-DD HH:mm:ss");
        return course;
      });
      this.totalRows = courses.length;
      this.loading = false;
      this.items = courses;
    },
    coursespopup(units) {
      var list = "";
      units.forEach((unit) => {
        // TODO Link for open it up
        unit = this.units.find((elem) => elem.id == unit);
        list += `<li>${unit.name}</li>`;
      });
      return {
        content: `<span class="font-weight-bold">Inhalte:</span><br><ul class="m-0 p-0">${list}</ul>`,
        html: true,
      };
    },
    onRowSelected(items) {
      if (this.template || (window.innerWidth <= 991 && items[items.length - 1]))
        this.view(items[items.length - 1].id);
      this.selected = items;
    },
    view(course_id) {
      this.$router.push({
        name: !this.template ? "course" : "course_template",
        params: { id: course_id },
      });
    },
    async cancel(row) {
      if (this.template) return;
      if (row.item.canceled) return;
      let result = await Swal.fire({
        title: "Wollen Sie diese Kurs wirklich absagen?",
        html: `
        <form id="course_cancel_form_64apoa4daw87d8aw4">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="send_email" checked>
            <label class="form-check-label" for="send_email"> Teilnehmer benachichtigen</label>
          </div>
        </form>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Bestätigen",
        cancelButtonText: "Abbrechen",
      });
      if (!result.value) return;
      var form = document.getElementById(
        "course_cancel_form_64apoa4daw87d8aw4"
      );
      console.log(form.send_email.value);
      await this.$store
        .dispatch(`cancelCourse`, {
          id: row.item.id,
          email: form.send_email.checked,
          direct: false,
        })
        .then((response) => {
          this.$globalmethod.toast_success("Erfolgreich abgesagt.");
        })
        .catch((error) => {
          this.$apimethod.defaulthandler(error);
        });
      this.items = this.items.map(course => {
          if (course.id == row.item.id) course.canceled = true;
          return course;
        });
    },
    async cancelselected() {
      if (this.template) return;
      let selected = this.selected.filter(course => ['closed'].includes(course.type))
      if (selected.length==0) return;
      let result = await Swal.fire({
        title: "Kurse absagen",
        html: `
        <form id="course_cancel_form_64apoasada4daw87d8aw4">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="send_email">
            <label class="form-check-label" for="send_email"> Teilnehmer benachichtigen</label>
          </div>
        </form>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Bestätigen",
        cancelButtonText: "Abbrechen",
      });
      if (!result.value) return;
      var form = document.getElementById(
        "course_cancel_form_64apoasada4daw87d8aw4"
      );
      await Promise.all(selected.map(course => (!course.canceled) ? this.$store.dispatch(`cancelCourse`, {id: course.id, email: form.send_email.checked}) : undefined));
      this.items = this.items.map(course => {
          if (selected.find(el => el.id == course.id)) course.canceled = true;
          return course;
        });
    },
    async reactivate(row) {
      if (this.template) return;
      if (!row.item.canceled) return;
      let result = await this.$store
        .dispatch(`reactivateCourse`, {
          id: row.item.id,
          direct: false,
        })
        .catch((error) => {
          this.init();
        });
        this.items = this.items.map(course => {
          if (course.id == row.item.id) course.canceled = false;
          return course;
        });
    },
    async reactivateselected() {
      if (this.template) return;
      let selected = this.selected.filter(course => ['closed'].includes(course.type))
      if (selected.length==0) return;
      let result = await Swal.fire({
        title: "Kurse reaktivieren",
        html: `
        <form id="course_reactivate_form_64apoa4daw87d8aw4">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" id="send_email">
            <label class="form-check-label" for="send_email"> Teilnehmer benachichtigen</label>
          </div>
        </form>`,
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Bestätigen",
        cancelButtonText: "Abbrechen",
      });
      if (!result.value) return;
      var form = document.getElementById(
        "course_reactivate_form_64apoa4daw87d8aw4"
      );
      await Promise.all(selected.map(lesson => (lesson.canceled) ? this.$store.dispatch(`reactivateCourse`, {id: lesson.id, email: form.send_email.checked}) : undefined));
      this.items = this.items.map(course => {
          if (selected.find(el => el.id == course.id)) course.canceled = false;
          return course;
        });
    },
    async remove(row) {
      let result = await this.$store
        .dispatch(this.template ? `removeTemplateCourse` : `removeCourse`, {
          id: row.item.id,
        })
        .catch((error) => {
          this.init();
        });
        if (!result) return;
        this.items = this.items.filter(course => course.id != row.item.id)
    },
    async removeselected() {
      if (this.selected.length==0) return;
      let result = await this.$swal({
        title: `${this.template ? 'Vorlagen-Kurse' : 'Kurse'} löschen`,
        text: `Alle selektierten ${this.template ? 'Vorlagen-Kurse' : 'Kurse'} werden gelöscht`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Fortfahren',
        cancelButtonText: 'Abbrechen',
        });
        if (!result.isConfirmed) return;
      await Promise.all(this.selected.map(lesson => this.$store.dispatch(this.template ? `removeTemplateCourse` : `removeCourse`, {id: lesson.id, noConfirm: true, hideAlert: true})))
      .catch(err => {
          this.init();
          throw err;
        });
      this.items = this.items.filter(elem => !this.selected.find(lesson => lesson.id==elem.id));
      this.$globalmethod.toast_success("Kurse gelöscht.");
      this.selected = [];
    },
    // MODAL Time Select
    openModalTimeSelect() {
      this.select_time = null;
      this.$refs["modal-time-select"].show();
    },
    handleOKModalTimeSelect(bvModalEvt) {
      if (this.select_time == null) return bvModalEvt.preventDefault();
      this.customTimes = this.customTimes.filter(
        (elem) => elem.value != this.select_time
      );
      this.customTimes.push({
        value: this.select_time,
        text: moment(this.select_time, "YYYY-MM-DD").format("D. MMM YYYY"),
      });
      this.currentTime = this.select_time;
    },
    handleCancelModalTimeSelect() {
      this.$nextTick(() => {
        if (this.currentTime === null) this.currentTime = false;
      });
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "course_modify"
    );
    await this.init();
  },
};
</script>
