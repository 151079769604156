import Settings from "@/views/settings/Index.vue";
export default [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    meta: {
      title: 'Einstellungen',
      description: 'Einstellungen und Voreinstellungen',
      material_icon: 'work',
      tags: [
        'settings',
        'more',
        'mehr',
        'weiteres',
        'voreinstellungen',
        'Vorbelegungen',
        'apis',
        'google',
        'mybusiness',
        'gmb',
      ]
    }
  },
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "more";
  return el;
});
