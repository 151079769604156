<template>
  <div>
    <b-form-group
      label="Kundenansprache"
      v-b-popover.bottom.hover="
        settings_obj.customer_salutation_personal.description
      "
    >
      <b-form-checkbox
        :checked="
          settings_obj.customer_salutation_personal.value.current
        "
        @change="
          (val) =>
            $store.dispatch('setSetting', {
              name: 'customer_salutation_personal',
              value: val,
            })
        "
        switch
      >{{ settings_obj.customer_salutation_personal.value.current ? 'Kunden werden per \'DU\' angesprochen' : 'Kunden werden per \'SIE\' angesprochen' }}</b-form-checkbox>
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["settings_obj"]),
  },
  async created() {
    await this.$store.dispatch("getSetting", [
      "customer_salutation_personal",
    ]);
  },
};
</script>

<style>
</style>
