<template>
  <b-container fluid>
    <b-row>
      <b-col class="pl-0" cols="12" xl="4">
        <b-form-group label="Unterrichtsstunden">
          <b-form-group label="Uhrzeit für Beginn" label-size="sm">
            <b-form-timepicker
              v-model="lesson_default_time_start"
              v-bind="TimePickerConfig"
            ></b-form-timepicker>
          </b-form-group>
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="12" xl="4">
        <b-form-group label="Kurse">
          <b-form-group label="Standard Kurstyp" label-size="sm">
            <b-form-select
              v-model="course_default_type"
              :options="course_types"
            />
          </b-form-group>
        </b-form-group>
      </b-col>
      <b-col class="pl-0" cols="12" xl="4">
        <b-form-group label="Inhalte">
          <b-form-group label="Standarddauer" label-size="sm">
            <b-row class="mx-0">
              <b-col class="p-0">
                <b-form-timepicker
                  v-model="unit_default_duration_time"
                  v-bind="TimePickerConfig"
                ></b-form-timepicker>
              </b-col>
              <b-col class="p-0 align-self-center" cols="1">
                <b-link
                  @click="unit_default_duration_time = null"
                  class="text-secondary material-icons mt-1 ml-2"
                  >delete</b-link
                >
              </b-col>
            </b-row>
          </b-form-group>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { TimePickerConfig } from "@/data/bootstrap-form-config.js";
import { addSetting } from '../used_settings';
addSetting([
  "lesson_default_time_start",
  "course_default_type",
  "unit_default_duration_time",
]);
export default {
  data() {
    return {
      lesson_default_time_start: null,
      course_default_type: null,
      unit_default_duration_time: null,
      // CONFIG
      TimePickerConfig,
      course_types: [
        {
          value: null,
          text: "Bitte auswählen",
        },
        {
          value: "open",
          text: "Offener Kurs",
        },
        {
          value: "closed",
          text: "Geschlossener Kurs",
        },
      ],
    };
  },
  methods: {
    refresh() {
      // Lessons
      this.lesson_default_time_start = this.$store.getters.settings_obj.lesson_default_time_start.value.current;

      // Courses
      this.course_default_type = this.$store.getters.settings_obj.course_default_type.value.current;

      // Unit
      this.unit_default_duration_time = this.$store.getters.settings_obj.unit_default_duration_time.value.current;
    },
  },
  watch: {
    async lesson_default_time_start(val, oldval) {
      setTimeout(async () => {
        if (val != this.lesson_default_time_start) return;
        await this.$store
          .dispatch("setSetting", {
            name: "lesson_default_time_start",
            value: val,
          })
          .catch(async (err) => {
            this.refresh();
            throw err;
          });
      }, 5000);
    },
    async course_default_type(val, oldval) {
      setTimeout(async () => {
        if (val != this.course_default_type) return;
        await this.$store
          .dispatch("setSetting", { name: "course_default_type", value: val })
          .catch(async (err) => {
            this.refresh();
            throw err;
          });
      }, 5000);
    },
    async unit_default_duration_time(val, oldval) {
      setTimeout(async () => {
        if (val != this.unit_default_duration_time) return;
        await this.$store
          .dispatch("setSetting", {
            name: "unit_default_duration_time",
            value: val,
          })
          .catch(async (err) => {
            this.refresh();
            throw err;
          });
      }, 5000);
    },
  },
  async created() {
    this.refresh();
  },
};
</script>
