export default [
    {
      name: "Montag",
      value: "MONDAY"
    },
    {
      name: "Dienstag",
      value: "TUESDAY"
    },
    {
      name: "Mitwoch",
      value: "WEDNESDAY"
    },
    {
      name: "Donnerstag",
      value: "THURSDAY"
    },
    {
      name: "Freitag",
      value: "FRIDAY"
    },
    {
      name: "Samstag",
      value: "SATURDAY"
    },
    {
      name: "Sonntag",
      value: "SUNDAY"
    }
]
