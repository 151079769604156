var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{staticClass:"main-content-container px-4",attrs:{"fluid":""}},[_c('b-row',{staticClass:"page-header py-4",attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"text-center text-sm-left mb-4 mb-sm-0",attrs:{"cols":"12","sm":"8"}},[_c('span',{staticClass:"text-uppercase page-subtitle"},[_vm._v("Weiteres")]),_c('h3',{staticClass:"page-title"},[_vm._v("Integration")])]),_c('b-col',{staticClass:"text-center text-sm-right",attrs:{"cols":"12","sm":"4"}},[_c('b-button',{attrs:{"variant":"light"},on:{"click":function($event){return _vm.window.open(
            'https://hilfe.fahrschule-api.de/applets-für-ihre-webseite',
            '_blank'
          )}}},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" Hilfe")])],1)],1),_c('b-row',[_c('b-col',{staticClass:"p-0 p-md-3 mb-4"},[_c('b-card',{staticClass:"card-small"},[_c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',[_vm._v(" Hier können Sie einen Link zur Einbindung eines Applets in Ihre Firmenwebseite erstellen: "),_c('b-form-select',{staticClass:"mb-3",attrs:{"options":_vm.type_options},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1),(_vm.type)?[(_vm.type == 'announcement')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Ankündigungstyp"}},[_c('b-form-input',{attrs:{"state":_vm.announcement_types.find(
                          function (el) { return el.name == _vm.config_announcement.announcement_type; }
                        )
                          ? true
                          : null,"placeholder":"Suche..."},on:{"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
                          if (
                            _vm.announcement_types.filter(
                              function (el) { return el.name
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    _vm.config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  ) ||
                                el.label
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    _vm.config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  ); }
                            )[0]
                          )
                            { _vm.config_announcement.announcement_type =
                              _vm.announcement_types.filter(
                                function (el) { return el.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      _vm.config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ) ||
                                  el.label
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      _vm.config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ); }
                              )[0].name; }
                        })($event)}},model:{value:(_vm.config_announcement.announcement_type),callback:function ($$v) {_vm.$set(_vm.config_announcement, "announcement_type", $$v)},expression:"config_announcement.announcement_type"}}),_c('b-collapse',{staticClass:"mt-1",attrs:{"visible":_vm.config_announcement.announcement_type.trim() != '' &&
                        !_vm.announcement_types.find(
                          function (el) { return el.name == _vm.config_announcement.announcement_type; }
                        )}},[(
                          _vm.announcement_types.filter(
                            function (el) { return el.name
                                .trim()
                                .toLowerCase()
                                .includes(
                                  _vm.config_announcement.announcement_type
                                    .trim()
                                    .toLowerCase()
                                ) ||
                              el.label
                                .trim()
                                .toLowerCase()
                                .includes(
                                  _vm.config_announcement.announcement_type
                                    .trim()
                                    .toLowerCase()
                                ); }
                          ).length > 0
                        )?[_c('b-list-group',_vm._l((_vm.announcement_types
                              .filter(
                                function (el) { return el.name
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      _vm.config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ) ||
                                  el.label
                                    .trim()
                                    .toLowerCase()
                                    .includes(
                                      _vm.config_announcement.announcement_type
                                        .trim()
                                        .toLowerCase()
                                    ); }
                              )
                              .slice(0, 5)),function(announcement_type){return _c('b-list-group-item',{key:announcement_type.name,staticClass:"m-0",attrs:{"button":""},on:{"click":function($event){_vm.config_announcement.announcement_type =
                                announcement_type.name}}},[_vm._v(" "+_vm._s(announcement_type.label)+" ("+_vm._s(announcement_type.name)+") "),_c('br'),_c('small',[_vm._v(_vm._s(announcement_type.description))])])}),1),(
                            _vm.announcement_types.filter(
                              function (el) { return el.name
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    _vm.config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  ) ||
                                el.label
                                  .trim()
                                  .toLowerCase()
                                  .includes(
                                    _vm.config_announcement.announcement_type
                                      .trim()
                                      .toLowerCase()
                                  ); }
                            ).length -
                              5 >
                            0
                          )?_c('span',[_vm._v(" "+_vm._s(_vm.announcement_types.filter( function (el) { return el.name .trim() .toLowerCase() .includes( _vm.config_announcement.announcement_type .trim() .toLowerCase() ) || el.label .trim() .toLowerCase() .includes( _vm.config_announcement.announcement_type .trim() .toLowerCase() ); } ).length - 5)+" weitere Ergebnisse gefunden... ")]):_vm._e()]:_c('span',[_vm._v("keine Ankündigungstypen gefunden")])],2)],1),_c('b-form-group',{attrs:{"label":"Optik"}},[_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.config_announcement.collapsible),callback:function ($$v) {_vm.$set(_vm.config_announcement, "collapsible", $$v)},expression:"config_announcement.collapsible"}},[_vm._v("Ankündigungen ausklappbar (spart Platz, benötigt Interaktion für Datenansicht)")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.config_announcement.hideEmpty),callback:function ($$v) {_vm.$set(_vm.config_announcement, "hideEmpty", $$v)},expression:"config_announcement.hideEmpty"}},[_vm._v("Ausblenden, wenn keine Daten vorhanden")])],1)],1)],1)]:(_vm.type == 'branches_cards')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Auf Filiale einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_branches_cards.branches === null},on:{"change":function (val) { return (_vm.config_branches_cards.branches = val
                            ? null
                            : _vm.branches.map(function (el) { return el.id; })); }}},[_vm._v(" Alle ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_branches_cards.branches === null},on:{"change":function (val) { return (_vm.config_branches_cards.branches = val.length
                            ? _vm.config_branches_cards.branches
                            : null); }},model:{value:(_vm.config_branches_cards.branches),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "branches", $$v)},expression:"config_branches_cards.branches"}},_vm._l((_vm.branches),function(branch){return _c('b-form-checkbox',{key:branch.id,attrs:{"value":branch.id}},[_vm._v(_vm._s(branch.name))])}),1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Thumbnail"}},[_c('b-form-select',{attrs:{"options":[
                        {
                          text: 'Keine Anzeige',
                          value: 'hidden',
                        },
                        {
                          text: 'Kartenkopf',
                          value: 'top',
                        },
                        {
                          text: 'Avatar-Bild neben Name',
                          value: 'avatar',
                        } ],"size":"sm"},model:{value:(_vm.config_branches_cards.thumbnail_style),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "thumbnail_style", $$v)},expression:"config_branches_cards.thumbnail_style"}}),_c('small',[_vm._v("Standardbild "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                          'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'
                        ),expression:"\n                          'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'\n                        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons",staticStyle:{"cursor":"pointer"}},[_vm._v("info")])]),_c('b-form-input',{attrs:{"placeholder":"https://webseite.de/url/zum/bild.jpg","size":"sm"},model:{value:(_vm.config_branches_cards.thumbnail_default_url),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "thumbnail_default_url", $$v)},expression:"config_branches_cards.thumbnail_default_url"}})],1),_c('b-form-group',{attrs:{"label":"Weiteres"}},[_c('b-form-checkbox',{model:{value:(_vm.config_branches_cards.hide_phone_numbers),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "hide_phone_numbers", $$v)},expression:"config_branches_cards.hide_phone_numbers"}},[_vm._v("Telefon / Fax ausblenden")]),_c('b-form-checkbox',{on:{"change":function (val) { return (_vm.config_branches_cards.hide_open_hours_none = val
                            ? false
                            : _vm.config_branches_cards.hide_open_hours_none); }},model:{value:(_vm.config_branches_cards.hide_open_hours),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "hide_open_hours", $$v)},expression:"config_branches_cards.hide_open_hours"}},[_vm._v("Öffnungszeiten ausblenden")]),_c('b-form-checkbox',{attrs:{"disabled":_vm.config_branches_cards.hide_open_hours},model:{value:(_vm.config_branches_cards.hide_open_hours_none),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "hide_open_hours_none", $$v)},expression:"config_branches_cards.hide_open_hours_none"}},[_vm._v("Tage ohne Öffnungszeiten ausblenden "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                        ),expression:"\n                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'\n                        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons",staticStyle:{"cursor":"pointer"}},[_vm._v("info")])])],1),_c('b-form-group',{attrs:{"label":"Ausrichtung"}},[_c('b-form-checkbox',{on:{"change":function (val) {
                          if (val) {
                            _vm.config_branches_cards.center = false;
                            _vm.config_branches_cards.right = false;
                          }
                        }},model:{value:(_vm.config_branches_cards.left),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "left", $$v)},expression:"config_branches_cards.left"}},[_c('b-icon-justify-left'),_vm._v(" linksbündig")],1),_c('b-form-checkbox',{on:{"change":function (val) {
                          if (val) {
                            _vm.config_branches_cards.left = false;
                            _vm.config_branches_cards.right = false;
                          }
                        }},model:{value:(_vm.config_branches_cards.center),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "center", $$v)},expression:"config_branches_cards.center"}},[_c('b-icon',{attrs:{"icon":"justify"}}),_vm._v(" zentral")],1),_c('b-form-checkbox',{on:{"change":function (val) {
                          if (val) {
                            _vm.config_branches_cards.left = false;
                            _vm.config_branches_cards.center = false;
                          }
                        }},model:{value:(_vm.config_branches_cards.right),callback:function ($$v) {_vm.$set(_vm.config_branches_cards, "right", $$v)},expression:"config_branches_cards.right"}},[_c('b-icon',{attrs:{"icon":"justify-right"}}),_vm._v(" rechtsbündig")],1)],1)],1)],1)]:_vm._e(),(_vm.type == 'branches_accordion')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Auf Filiale einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_branches_accordion.branches === null},on:{"change":function (val) { return (_vm.config_branches_accordion.branches = val
                            ? null
                            : _vm.branches.map(function (el) { return el.id; })); }}},[_vm._v(" Alle ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_branches_accordion.branches === null},on:{"change":function (val) { return (_vm.config_branches_accordion.branches = val.length
                            ? _vm.config_branches_accordion.branches
                            : null); }},model:{value:(_vm.config_branches_accordion.branches),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "branches", $$v)},expression:"config_branches_accordion.branches"}},_vm._l((_vm.branches),function(branch){return _c('b-form-checkbox',{key:branch.id,attrs:{"value":branch.id}},[_vm._v(_vm._s(branch.name))])}),1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Thumbnail"}},[_c('b-form-select',{attrs:{"options":[
                        {
                          text: 'Keine Anzeige',
                          value: 'hidden',
                        },
                        {
                          text: 'Avatar-Bild neben Name',
                          value: 'avatar',
                        } ],"size":"sm"},model:{value:(_vm.config_branches_accordion.thumbnail_style),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "thumbnail_style", $$v)},expression:"config_branches_accordion.thumbnail_style"}}),_c('small',[_vm._v("Standardbild "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                          'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'
                        ),expression:"\n                          'Dieses Bild wird nur Verwendet, wenn kein Thumbnail zu Ihrer Filiale hinterlegt ist. Lassen Sie dieses Feld leer, wenn in diesem Fall kein Bild angezeigt werden soll.'\n                        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons",staticStyle:{"cursor":"pointer"}},[_vm._v("info")])]),_c('b-form-input',{attrs:{"placeholder":"https://webseite.de/url/zum/bild.jpg","size":"sm"},model:{value:(
                        _vm.config_branches_accordion.thumbnail_default_url
                      ),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "thumbnail_default_url", $$v)},expression:"\n                        config_branches_accordion.thumbnail_default_url\n                      "}})],1),_c('b-form-group',{attrs:{"label":"Weiteres"}},[_c('b-form-checkbox',{model:{value:(_vm.config_branches_accordion.hide_phone_numbers),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "hide_phone_numbers", $$v)},expression:"config_branches_accordion.hide_phone_numbers"}},[_vm._v("Telefon / Fax ausblenden")]),_c('b-form-checkbox',{on:{"change":function (val) { return (_vm.config_branches_accordion.hide_open_hours_none =
                            val
                              ? false
                              : _vm.config_branches_accordion.hide_open_hours_none); }},model:{value:(_vm.config_branches_accordion.hide_open_hours),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "hide_open_hours", $$v)},expression:"config_branches_accordion.hide_open_hours"}},[_vm._v("Öffnungszeiten ausblenden")]),_c('b-form-checkbox',{attrs:{"disabled":_vm.config_branches_accordion.hide_open_hours},model:{value:(_vm.config_branches_accordion.hide_open_hours_none),callback:function ($$v) {_vm.$set(_vm.config_branches_accordion, "hide_open_hours_none", $$v)},expression:"config_branches_accordion.hide_open_hours_none"}},[_vm._v("Tage ohne Öffnungszeiten ausblenden "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                        ),expression:"\n                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'\n                        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons",staticStyle:{"cursor":"pointer"}},[_vm._v("info")])])],1)],1)],1)]:_vm._e(),(_vm.type == 'branch_openHours')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Filiale auswählen"}},[_c('b-form-radio-group',{attrs:{"stacked":"","options":_vm.branches.map(function (branch) {
                          return { text: branch.name, value: branch.id };
                        })},model:{value:(_vm.config_branch_openHours.branch),callback:function ($$v) {_vm.$set(_vm.config_branch_openHours, "branch", $$v)},expression:"config_branch_openHours.branch"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Weiteres"}},[_c('b-form-checkbox',{model:{value:(_vm.config_branch_openHours.hide_none),callback:function ($$v) {_vm.$set(_vm.config_branch_openHours, "hide_none", $$v)},expression:"config_branch_openHours.hide_none"}},[_vm._v("Tage ohne Öffnungszeiten ausblenden "),_c('i',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(
                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'
                        ),expression:"\n                          'Wochenenden werden standardmäßig nicht angezeigt, wenn sie zu diesen Tagen keine Öffnungszeiten hinterlegt haben'\n                        ",modifiers:{"hover":true,"top":true}}],staticClass:"material-icons",staticStyle:{"cursor":"pointer"}},[_vm._v("info")])])],1)],1)],1)]:(_vm.type == 'chatbot')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:({
                      content:
                        'CSS Eigenschaft: <a href="https://developer.mozilla.org/de/docs/Web/CSS/max-height" target="_blank">max-height</a>',
                      html: true,
                    }),expression:"{\n                      content:\n                        'CSS Eigenschaft: <a href=\"https://developer.mozilla.org/de/docs/Web/CSS/max-height\" target=\"_blank\">max-height</a>',\n                      html: true,\n                    }",modifiers:{"hover":true,"bottom":true}}],attrs:{"label":"Optik"}},[_c('label',[_vm._v("Max. Höhe")]),_c('b-form-input',{model:{value:(_vm.config_chatbot.max_height),callback:function ($$v) {_vm.$set(_vm.config_chatbot, "max_height", $$v)},expression:"config_chatbot.max_height"}})],1)],1)],1)]:(_vm.type == 'education_calendar')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Auf Unterrichtskategorie einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_education_calendar.category === null},on:{"change":function (val) { return (_vm.config_education_calendar.category = val
                            ? null
                            : _vm.categories.map(function (el) { return el.name; })); }}},[_vm._v(" Alle Kategorien ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_education_calendar.category === null},on:{"change":function (val) { return (_vm.config_education_calendar.category = val.length
                            ? _vm.config_education_calendar.category
                            : null); }},model:{value:(_vm.config_education_calendar.category),callback:function ($$v) {_vm.$set(_vm.config_education_calendar, "category", $$v)},expression:"config_education_calendar.category"}},_vm._l((_vm.categories),function(category){return _c('b-form-checkbox',{key:category.name,attrs:{"value":category.name}},[_vm._v(_vm._s(category.title))])}),1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-checkbox',{model:{value:(_vm.config_education_calendar.hideCourses),callback:function ($$v) {_vm.$set(_vm.config_education_calendar, "hideCourses", $$v)},expression:"config_education_calendar.hideCourses"}},[_vm._v("Keine Kurse anzeigen")]),_c('b-form-checkbox',{model:{value:(_vm.config_education_calendar.hideLessons),callback:function ($$v) {_vm.$set(_vm.config_education_calendar, "hideLessons", $$v)},expression:"config_education_calendar.hideLessons"}},[_vm._v("Keine Unterrichtsstunden anzeigen")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Auf Filiale einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_education_calendar.branches === null},on:{"change":function (val) { return (_vm.config_education_calendar.branches = val
                            ? null
                            : _vm.branches.map(function (el) { return el.id; }).concat( [null])); }}},[_vm._v(" Alle ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_education_calendar.branches === null},on:{"change":function (val) { return (_vm.config_education_calendar.branches = val.length
                            ? _vm.config_education_calendar.branches
                            : null); }},model:{value:(_vm.config_education_calendar.branches),callback:function ($$v) {_vm.$set(_vm.config_education_calendar, "branches", $$v)},expression:"config_education_calendar.branches"}},[_vm._l((_vm.branches),function(branch){return _c('b-form-checkbox',{key:branch.id,attrs:{"value":branch.id}},[_vm._v(_vm._s(branch.name))])}),_c('b-form-checkbox',{attrs:{"value":"null"}},[_vm._v("ohne Filialzuordnung")])],2)],1)],1)],1)]:(_vm.type == 'education_list')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',{attrs:{"label":"Auf Unterrichtskategorie einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_education_list.category === null},on:{"change":function (val) { return (_vm.config_education_list.category = val
                            ? null
                            : _vm.categories.map(function (el) { return el.name; })); }}},[_vm._v(" Alle Kategorien ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_education_list.category === null},on:{"change":function (val) { return (_vm.config_education_list.category = val.length
                            ? _vm.config_education_list.category
                            : null); }},model:{value:(_vm.config_education_list.category),callback:function ($$v) {_vm.$set(_vm.config_education_list, "category", $$v)},expression:"config_education_list.category"}},_vm._l((_vm.categories),function(category){return _c('b-form-checkbox',{key:category.name,attrs:{"value":category.name}},[_vm._v(_vm._s(category.title))])}),1)],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-checkbox',{model:{value:(_vm.config_education_list.hideCourses),callback:function ($$v) {_vm.$set(_vm.config_education_list, "hideCourses", $$v)},expression:"config_education_list.hideCourses"}},[_vm._v("Keine Kurse anzeigen")]),_c('b-form-checkbox',{model:{value:(_vm.config_education_list.hideLessons),callback:function ($$v) {_vm.$set(_vm.config_education_list, "hideLessons", $$v)},expression:"config_education_list.hideLessons"}},[_vm._v("Keine Unterrichtsstunden anzeigen")])],1),_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Auf Filiale einschränken"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.config_education_list.branches === null},on:{"change":function (val) { return (_vm.config_education_list.branches = val
                            ? null
                            : _vm.branches.map(function (el) { return el.id; }).concat( [null])); }}},[_vm._v(" Alle ")]),_c('b-form-checkbox-group',{attrs:{"stacked":"","disabled":_vm.config_education_list.branches === null},on:{"change":function (val) { return (_vm.config_education_list.branches = val.length
                            ? _vm.config_education_list.branches
                            : null); }},model:{value:(_vm.config_education_list.branches),callback:function ($$v) {_vm.$set(_vm.config_education_list, "branches", $$v)},expression:"config_education_list.branches"}},[_vm._l((_vm.branches),function(branch){return _c('b-form-checkbox',{key:branch.id,attrs:{"value":branch.id}},[_vm._v(_vm._s(branch.name))])}),_c('b-form-checkbox',{attrs:{"value":"null"}},[_vm._v("ohne Filialzuordnung")])],2)],1)],1)],1)]:(_vm.type == 'mail_list')?[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Auswahl"}},[_c('b-form-select',{attrs:{"options":[
                        { text: 'Bitte Newsletter auswählen', value: null } ].concat( _vm.mail_lists )},model:{value:(_vm.config_mail_list.id),callback:function ($$v) {_vm.$set(_vm.config_mail_list, "id", $$v)},expression:"config_mail_list.id"}})],1),_c('b-form-group',{attrs:{"label":"Ausrichtung"}},[_c('b-form-select',{attrs:{"value":"left","options":[
                        { text: 'Links', value: 'left' },
                        { text: 'Mitte', value: 'center' },
                        { text: 'Rechts', value: 'right' } ]},on:{"change":function (val) {
                          switch (val) {
                            case 'left':
                              _vm.config_mail_list.center = false;
                              _vm.config_mail_list.right = false;
                              break;
                            case 'center':
                              _vm.config_mail_list.center = true;
                              _vm.config_mail_list.right = false;
                              break;
                            case 'right':
                              _vm.config_mail_list.center = false;
                              _vm.config_mail_list.right = true;
                              break;
                          }
                        }}})],1)],1)],1)]:_vm._e(),_c('b-row',[_c('b-col',{staticClass:"mb-2"},[_c('i',{staticClass:"material-icons"},[_vm._v("info")]),_vm._v(" Benötigen Sie noch feinere Einstellungen? Sprechen Sie uns gerne an, wir helfen Ihnen gerne weiter!")])],1)]:_vm._e(),_c('b-row',{staticClass:"border-top"},[_c('b-col',{staticClass:"my-4",attrs:{"cols":"12"}},[_c('h6',[_vm._v("HTML:")]),(_vm.type)?_c('code',[_vm._v(_vm._s(_vm.code))]):_c('div',{staticClass:"small"},[_vm._v("Bitte Anwendung wählen!")]),_c('br'),_c('h6',[_vm._v("Vorschau:")]),(_vm.type && _vm.type != 'fa-messenger')?_c('div',{key:_vm.link},[_c('fa-element',{attrs:{"url":_vm.link}})],1):(_vm.type && _vm.type == 'fa-messenger')?_c('div',{key:_vm.link},[_c('small',[_vm._v("Die Vorschau sehen sie als runden Button am rechten unteren Bildschirmrand.")]),_c('fa-messenger',{attrs:{"client_id":_vm.company.id}})],1):_c('div',{staticClass:"small"},[_vm._v("Bitte Anwendung wählen!")]),(_vm.type && _vm.type != 'fa-messenger')?_c('div',[_c('small',[_vm._v("Einbettungslink:")]),_c('b-input-group',{key:_vm.link},[_c('b-form-input',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.link),expression:"link"},{name:"b-popover",rawName:"v-b-popover.hover.bottom",value:('Klicken zum Kopieren'),expression:"'Klicken zum Kopieren'",modifiers:{"hover":true,"bottom":true}}],staticStyle:{"cursor":"pointer"},attrs:{"readonly":""},model:{value:(_vm.link),callback:function ($$v) {_vm.link=$$v},expression:"link"}}),_c('b-input-group-append',[_c('b-button',{directives:[{name:"clipboard",rawName:"v-clipboard",value:(_vm.link),expression:"link"}],attrs:{"variant":"outline-dark"}},[_c('i',{staticClass:"material-icons"},[_vm._v("content_copy")])])],1)],1)],1):_vm._e()])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }