<template>
  <d-container fluid class="main-content-container px-lg-4">
    <d-row no-gutters class="page-header py-4">
      <b-col
        cols="12"
        sm="8"
        lg="9"
        class="text-center text-sm-left mb-4 mb-sm-0"
      >
        <span
          class="text-uppercase page-subtitle"
          @click="$router.push({ name: 'branches' })"
          style="cursor: pointer"
          >Filialen</span
        >
        <h3 class="page-title">Neue Filiale anlegen</h3>
      </b-col>
    </d-row>
    <d-row>
      <d-col class="p-0 p-md-3 mb-4">
        <d-card class="card-small">
          <div class="m-3" v-if="!auth">Sie besitzen nicht die benötigten Rechte. Bitte wenden Sie sich an einen Administrator.</div>
          <d-card-body v-else class="d-flex flex-column px-2 px-lg-3">
            <b-form-group label="Name">
              <b-form-input
                placeholder="Name"
                v-model="name"
                :state="state_name"
              />
            </b-form-group>
            <b-form-group label="Kurzbeschreibung">
              <b-form-textarea
                placeholder="Kurbeschreibung"
                v-model="description"
              />
            </b-form-group>
            <b-form-group label="Addresse">
              <b-row>
                <b-col cols="12" sm="9" class="mb-1">
                  <b-form-input
                    v-model="address.street"
                    placeholder="Straße"
                    :state="state_street"
                  />
                </b-col>
                <b-col cols="12" sm="3" class="mb-1">
                  <b-form-input
                    v-model="address.streetnumber"
                    min="0"
                    placeholder="Nr."
                    :state="state_streetnumber"
                  />
                </b-col>
                <b-col cols="12" sm="4" class="mb-1">
                  <b-form-input
                    v-model="address.zip"
                    min="0"
                    max="99999"
                    type="number"
                    placeholder="PLZ"
                    :state="state_zip"
                  />
                </b-col>
                <b-col cols="12" sm="8" class="mb-1">
                  <b-form-input
                    placeholder="Stadt"
                    v-model="address.city"
                    :state="state_city"
                  />
                </b-col>
              </b-row>
            </b-form-group>
            <div class="d-flex">
              <div>
                <b-button @click="submit" variant="success" class="mr-3"
                  >Anlegen</b-button
                >
              </div>
              <div class="small my-auto">
                Sobald die Filiale angelegt ist, können Sie weitere
                Informationen, wie Öffnungszeiten, Telefonnummern, Emails usw.
                festlegen.
              </div>
            </div>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "BranchNew",
  data() {
    return {
      auth: null,
      validated: false,
      name: null,
      description: null,
      address: {
        street: null,
        streetnumber: null,
        zip: null,
        city: null,
      },
    };
  },
  methods: {
    validate() {
      this.validated = true;
      return this.state_name && this.state_address;
    },
    async submit() {
      if (!this.validate()) return;
      if (
        await this.$store.dispatch("createBranch", {
          name: this.name,
          description: this.description,
          address: this.address,
        })
      )
        this.$router.push({ name: "branches" });
    },
  },
  computed: {
    state_name() {
      return !this.validated
        ? null
        : typeof this.name == "string" && this.name.length > 0;
    },
    state_street() {
      return !this.validated
        ? null
        : typeof this.address.street == "string" &&
            this.address.street.length > 0;
    },
    state_streetnumber() {
      return !this.validated
        ? null
        : typeof this.address.streetnumber == "string" &&
            this.address.streetnumber.length > 0;
    },
    state_zip() {
      return !this.validated
        ? null
        : typeof this.address.zip == "string" && this.address.zip.length > 0;
    },
    state_city() {
      return !this.validated
        ? null
        : typeof this.address.city == "string" && this.address.city.length > 0;
    },
    state_address() {
      return !this.validated
        ? null
        : this.state_street &&
            this.state_streetnumber &&
            this.state_zip &&
            this.state_city;
    },
  },
  async created() {
    this.auth = await this.$store.dispatch('haveUserRight', "branch_modify");
  }
};
</script>
