var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[(!(_vm.loading === false))?void 0:[_c('b-row'),_c('b-row',[_c('b-col',{staticClass:"p-0"},[(_vm.output_request.length != 0 && !_vm.hideRequest)?[(!_vm.hideLabel)?_c('small',{staticClass:"ml-1"},[_vm._v("Anfragen")]):_vm._e(),_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.output_request),function(participator){return _c('b-list-group-item',{key:participator.id,staticClass:"p-0",staticStyle:{"background":"rgb(0, 0, 0, 0)","border-color":"rgb(0, 0, 0, 0)"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(!_vm.hideRole)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getRoleName(participator.role))+": ")]):_vm._e(),(participator.type == 'user')?[(participator.user.name)?[_vm._v(_vm._s(participator.user.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Profil öffnen'),expression:"'Profil öffnen'",modifiers:{"hover":true,"top":true}}],class:participator.user.name ? 'text-muted small' : 'text-secondary',attrs:{"to":{ name: 'user', params: { id: participator.id } }}},[_vm._v(_vm._s(participator.user.username))])]:[(participator.name)?[_vm._v(_vm._s(participator.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Email senden'),expression:"'Email senden'",modifiers:{"hover":true,"top":true}}],class:participator.name ? 'text-muted small' : 'text-secondary',attrs:{"href":'mailto:' + participator.id}},[_vm._v(_vm._s(participator.id))])]],2),(!_vm.noEvents)?_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"p-1",staticStyle:{"cursor":"pointer"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){return _vm.$emit('acceptParticipation', {
                        type: participator.type,
                        id: participator.id,
                        role: participator.role,
                      })}}},[_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_vm._v(" Akzeptieren ")]),_c('b-button',{staticClass:"p-1 ml-1",staticStyle:{"cursor":"pointer"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){return _vm.$emit('denieParticipation', {
                        type: participator.type,
                        id: participator.id,
                        role: participator.role,
                      })}}},[_c('i',{staticClass:"material-icons"},[_vm._v("cancel")]),_vm._v(" Ablehnen ")])],1):_vm._e()])])}),1)]:_vm._e(),(!_vm.hideActive)?[(!_vm.hideLabel)?_c('small',{staticClass:"ml-1"},[_vm._v("Aktiv"),_c('br')]):_vm._e(),(_vm.output_active.length == 0)?_c('small',{staticClass:"text-muted"},[_vm._v("keine aktiven Teilnehmer")]):_vm._e(),_c('b-list-group',{attrs:{"flush":""}},_vm._l((_vm.output_active),function(participator){return _c('b-list-group-item',{key:participator.id,staticClass:"p-0",staticStyle:{"background":"rgb(0, 0, 0, 0)","border-color":"rgb(0, 0, 0, 0)"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(!_vm.hideRole)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getRoleName(participator.role))+": ")]):_vm._e(),(participator.type == 'user')?[(participator.user.name)?[_vm._v(_vm._s(participator.user.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Profil öffnen'),expression:"'Profil öffnen'",modifiers:{"hover":true,"top":true}}],class:participator.user.name ? 'text-muted small' : 'text-secondary',attrs:{"to":{ name: 'user', params: { id: participator.id } }}},[_vm._v(_vm._s(participator.user.username))])]:[(participator.name)?[_vm._v(_vm._s(participator.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Email senden'),expression:"'Email senden'",modifiers:{"hover":true,"top":true}}],class:participator.name ? 'text-muted small' : 'text-secondary',attrs:{"href":'mailto:' + participator.id}},[_vm._v(_vm._s(participator.id))])]],2),(!_vm.noEvents)?_c('b-button',{staticClass:"p-1",staticStyle:{"cursor":"pointer"},attrs:{"variant":"outline-light","size":"sm"},on:{"click":function($event){return _vm.$emit('denieParticipation', {
                      type: participator.type,
                      id: participator.id,
                      role: participator.role,
                    })}}},[_c('i',{staticClass:"material-icons"},[_vm._v("cancel")]),_vm._v(" Ausladen ")]):_vm._e()],1)])}),1)]:_vm._e(),(_vm.output_inactive.length != 0 && !_vm.hideInactive)?[(!_vm.hideLabel)?_c('small',{staticClass:"ml-1"},[_vm._v("Inaktiv")]):_vm._e(),_c('b-list-group',{staticClass:"text-muted",attrs:{"flush":""}},_vm._l((_vm.output_inactive),function(participator){return _c('b-list-group-item',{key:participator.id,staticClass:"p-0",staticStyle:{"background":"rgb(0, 0, 0, 0)","border-color":"rgb(0, 0, 0, 0)"}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[(!_vm.hideRole)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.getRoleName(participator.role))+": ")]):_vm._e(),(participator.type == 'user')?[(participator.user.name)?[_vm._v(_vm._s(participator.user.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Profil öffnen'),expression:"'Profil öffnen'",modifiers:{"hover":true,"top":true}}],class:participator.user.name ? 'text-muted small' : 'text-secondary',attrs:{"to":{ name: 'user', params: { id: participator.id } }}},[_vm._v(_vm._s(participator.user.username))])]:[(participator.name)?[_vm._v(_vm._s(participator.name)+" ")]:_vm._e(),_c('b-link',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:('Email senden'),expression:"'Email senden'",modifiers:{"hover":true,"top":true}}],class:participator.name ? 'text-muted small' : 'text-secondary',attrs:{"href":'mailto:' + participator.id}},[_vm._v(_vm._s(participator.id))])]],2),(
                    participator.participator_status == 0 &&
                    participator.company_status == 1
                  )?_c('small',{staticClass:"font-italic"},[_vm._v("noch keine Antwort")]):(!_vm.noEvents)?_c('b-button',{staticClass:"p-1",staticStyle:{"cursor":"pointer"},attrs:{"variant":"outline-light","size":"sm","disabled":participator.participator_status == 2},on:{"click":function($event){return _vm.$emit('acceptParticipation', {
                      type: participator.type,
                      id: participator.id,
                      role: participator.role,
                    })}}},[_c('i',{staticClass:"material-icons"},[_vm._v("check")]),_vm._v(" Einladen ")]):_vm._e()],1)])}),1)]:_vm._e(),(_vm.showRequestCount && _vm.output_request.length>0)?_c('div',{staticClass:"small ml-2"},[_vm._v(_vm._s(_vm.output_request.length)+" Anfragen")]):_vm._e()],2)],1)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }