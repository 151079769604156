import Vue from 'vue'
import API from '@/plugins/api.js'
import APIMethod from '@/plugins/apimethod.js'
import GlobalMethod from '@/plugins/globals.js'
import Router from 'vue-router';
Vue.use(API)
Vue.use(APIMethod)
Vue.use(GlobalMethod)
Vue.use(Router)
let store = new Vue() // use the store var for request api
const module_branch = {
  state: {
    status: [],
    gmb: {
      accounts: null,
      locations: null,
      branch_connections: null,
    },
    fb: {
      pages: null,
    },
    fbp: {
      self: null
    },
    inteval: null
  },
  mutations: {
    setAPIStatus(state, input) {
      state.status = input;
    },
    setAPIGMBAccounts(state, input) {
      state.gmb.accounts = input;
    },
    setAPIGMBLocations(state, input) {
      state.gmb.locations = input;
    },
    setAPIGMBBranches(state, input) {
      state.gmb.branch_connections = input;
    },
    setAPIFBPages(state, input) {
      state.fb.pages = input;
    },
    setAPIFBPSelf(state, input) {
      state.fbp.self = input;
    },
    resetAPIModule(state) {
      clearInterval(state.interval);
      state = {
        status: [],
        gmb: {
          accounts: null,
          locations: null,
          branch_connections: null,
        },
        fb: {
          pages: null,
        },
        fbp: {
          self: null
        },
        inteval: null
      };
    },
  },
  actions: {
    async setupAPIModule(context) {
      context.dispatch('updateAPIModule');
      context.state.interval = setInterval(() => context.dispatch('updateAPIModule'), 5*60*1000);
      console.log("API-Module setup completed.")
    },
    async updateAPIModule(context) {
      context.commit('setAPIStatus', await store.$api.get('/system/api').then(response => response.data.result));
      await Promise.all([
        context.dispatch('updateAPIGMB'),
        context.dispatch('updateAPIFB'),
        context.dispatch('updateAPIFBP'),
      ]);
    },
    async updateAPIGMB(context) {
      if (context.state.status.find( api => api.name == "google_mybusiness").status.connected) await Promise.all([
        // GoogleMyBusiness - SETUP
        (async () => {
          let gmb_response = await store.$api.get('/system/api/google_mybusiness/accounts')
          .then(response => response.data.result);
          context.commit('setAPIGMBAccounts', gmb_response);
        })(),
        (async () => {
          let gmb_response = await store.$api.get('/system/api/google_mybusiness/locations')
          .then(response => response.data.result);
          context.commit('setAPIGMBLocations', gmb_response);
        })(),
        (async () => {
          let gmb_response = await store.$api.get('/system/api/google_mybusiness/branch')
          .then(response => response.data.result);
          context.commit('setAPIGMBBranches', gmb_response);
        })(),
      ]).catch(err => {
        console.error(err);
        setTimeout(() => context.dispatch('updateAPIGMB'), 5000);
      });
    },
    async updateAPIFB(context) {
      if (context.state.status.find( api => api.name == "facebook_graph").status.connected) await Promise.all([
        // Facebook Pages - SETUP
        (async () => {
          let gmb_response = await store.$api.get('/system/api/facebook_graph/pages')
          .then(response => response.data.result);
          context.commit('setAPIFBPages', gmb_response);
        })(),
      ]).catch(err => {
        console.error(err);
        setTimeout(() => context.dispatch('updateAPIFB'), 5000);
      });
    },
    async updateAPIFBP(context) {
      if (context.state.status.find( api => api.name == "facebook_pages").status.connected) await Promise.all([
        // Facebook Pages - SETUP
        (async () => {
          let gmb_response = await store.$api.get('/system/api/facebook_pages/self')
          .then(response => response.data.result);
          context.commit('setAPIFBPSelf', gmb_response);
        })(),
      ]).catch(err => {
        console.error(err);
        setTimeout(() => context.dispatch('updateAPIFBP'), 5000);
      });
    },
    async connectAPI(context, { api_name, api_key, oAuth_redirect, rerequest } = {}) {
      if (!oAuth_redirect) oAuth_redirect = window.location.href;
      switch(api_name) {
        case "google_mybusiness": //oAuth, with redirect after complete request
          window.location.href = await store.$api.get(`/system/api/google_mybusiness/oAuth?link${(oAuth_redirect) ? `&redirect=${oAuth_redirect}` : ''}${(rerequest) ? `&rerequest` : ''}`).then(response => response.data.result);
          break;
        case "facebook_graph": //oAuth, with redirect after complete request
          window.location.href = await store.$api.get(`/system/api/facebook_graph/oAuth?link${(oAuth_redirect) ? `&redirect=${oAuth_redirect}` : ''}${(rerequest) ? `&rerequest` : ''}`).then(response => response.data.result);
          break;
        case "facebook_pages": //oAuth, with redirect after complete request
          let link = await store.$api.get(`/system/api/facebook_pages/oAuth?link${(oAuth_redirect) ? `&redirect=${oAuth_redirect}` : ''}${(rerequest) ? `&rerequest` : ''}`).then(response => response.data.result);
          if (link) window.location.href = link;
          if (api_key) await store.$api.post(`/system/api/facebook_pages/apiKey?page_id=${api_key}`);
          break;
        default:
          throw new Error("NOT_FOUND");
      }
      await context.dispatch('updateAPIModule');
    },
    async removeAPIConnections(context, { api_name, key_id } = {}) {
      switch(api_name) {
        case "google_mybusiness": //oAuth, with redirect after complete request
          await store.$api.delete(`/system/api/google_mybusiness`).then(response => response.data.result);
          break;
          case "facebook_graph": //oAuth, with redirect after complete request
            await store.$api.delete(`/system/api/facebook_graph`).then(response => response.data.result);
            break;
            case "facebook_pages": //oAuth, with redirect after complete request
              await store.$api.delete(`/system/api/facebook_pages`).then(response => response.data.result);
              break;
        default:
          throw new Error("NOT_FOUND");
      }
      await context.dispatch('updateAPIModule');
      return true;
    },
    // GoogleMyBusiness https://developers.google.com/my-business/reference/rest?hl=de
    async gmb_getAccounts() {
      return await store.$api.get(`/system/api/google_mybusiness/accounts`).then(response => response.data.result);
    },
    async gmb_getLocations() {
      return await store.$api.get(`/system/api/google_mybusiness/locations`).then(response => response.data.result);
    },
    async gmb_getBranchLocations() {
      return await store.$api.get(`/system/api/google_mybusiness/branch`).then(response => response.data.result);
    },
    async gmb_getAvailableLocations(context, branch_id) {
      return await store.$api.get(`/system/api/google_mybusiness/branch/${branch_id}/availableLocations`).then(response => response.data.result);
    },
    async gmb_createBranchToLocationConnection(context, { branch_id, location }) {
      await store.$api.post(`/system/api/google_mybusiness/branch/${branch_id}/location?location=${location}`).then(response => response.data.result);
      context.commit('setAPIGMBBranches', await store.$api.get('/system/api/google_mybusiness/branch').then(response => response.data.result));
      return true;
    },
    async gmb_removeBranchToLocationConnection(context, { branch_id }) {
      await store.$api.delete(`/system/api/google_mybusiness/branch/${branch_id}/location`).then(response => response.data.result);
      context.commit('setAPIGMBBranches', await store.$api.get('/system/api/google_mybusiness/branch').then(response => response.data.result));
      return true;
    }
  },
  getters: {
    gmb_activated(state) {
      let status = state.status.find(el => el.name == 'google_mybusiness');
      if (!status) return null;
      return status.status.active;
    },
    gmb_connected(state) {
      let status = state.status.find(el => el.name == 'google_mybusiness');
      if (!status) return null;
      return status.status.connected;
    },
    gmb_accounts(state) {
      return state.gmb.accounts;
    },
    gmb_locations(state) {
      return state.gmb.locations;
    },
    gmb_branch_connections(state) {
      return state.gmb.branch_connections;
    },
    fb_graph_activated(state) {
      let status = state.status.find(el => el.name == 'facebook_graph');
      if (!status) return null;
      return status.status.active;
    },
    fb_graph_connected(state) {
      let status = state.status.find(el => el.name == 'facebook_graph');
      if (!status) return null;
      return status.status.connected;
    },
    fb_graph_pages(state) {
      return state.fb.pages;
    },
    fb_pages_activated(state) {
      let status = state.status.find(el => el.name == 'facebook_pages');
      if (!status) return null;
      return status.status.active;
    },
    fb_pages_connected(state) {
      let status = state.status.find(el => el.name == 'facebook_pages');
      if (!status) return null;
      return status.status.connected;
    },
    fb_pages_self(state) {
      return state.fbp.self;
    },
   }
}
export default module_branch
