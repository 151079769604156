import Error404 from '@/views/Error404.vue';
import Defaults from '@/views/company/Defaults.vue';
import Branches from '@/views/company/branch/Branches.vue';
import BranchNew from '@/views/company/branch/BranchNew.vue';
import Branch from '@/views/company/branch/Branch.vue';
import Assets from '@/views/company/asset/Assets.vue';
import AssetNew from '@/views/company/asset/AssetNew.vue';
import Asset from '@/views/company/asset/Asset.vue';

export default [
  {
  path: '/company',
  name: 'company',
  component: Defaults,
  meta: {
    title: 'Basisdaten Firma',
    description: 'Überblick Ihrer allgemeinen Firmendaten',
    material_icon: 'work',
    tags: [
      "Basisdaten",
      "Firmendaten",
      "Firma",
      "company",
    ]
  }
},
{
  path: '/branches',
  name: 'branches',
  component: Branches,
  meta: {
    title: 'Filialen',
    description: 'Ihre Filialen',
    material_icon: 'account_balance',
    tags: [
      "Standorte",
      "Firma",
      "company",
      "Fillialen",
    ]
  }
},
{
  path: '/branch/new',
  name: 'branch_new',
  component: BranchNew,
  meta: {
    title: 'Neue Filiale',
    description: 'Neue Filiale anlegen',
    material_icon: 'account_balance',
  }
},
{
  path: '/branch/:id',
  name: 'branch',
  component: Branch,
},
{
  path: '/assets',
  name: 'assets',
  component: Assets,
  meta: {
    title: 'Vermögenswerte',
    description: 'Alle Ihrer Vermögenswerte im Überblick',
    material_icon: 'savings',
    tags: [
      "Auto",
      "Car",
      "Motorrad",
      "Motorcycle",
      "Moped",
      "Simulator",
      "Simulation",
      "LKW",
      "PKW",
      "Boot",
      "Ship",
      "Flugzeug",
      "Plane",
      "Vermietung",
      "Ausleihen",
      "Verleihen",
      "Rent",
    ]
  }
},
{
  path: '/asset/new',
  name: 'asset_new',
  component: AssetNew,
  meta: {
    title: 'Neuer Vermögenswert',
    description: 'Neuen Vermögenswert anlegen',
    material_icon: 'savings',
  }
},
{
  path: '/asset/:id',
  name: 'asset',
  component: Asset,
},
{
  path: '/employees',
  name: 'employees',
  component: Error404,
  meta: {
    title: 'Mitarbeiter',
    description: 'Verwalten Sie Ihre Mitarbeiter',
    material_icon: 'accessibility_new',
    tags: [
      'firma',
      'company',
      'employees',
      'person',
    ],
  }
}
].map(el => {
  if (!el.meta) el.meta = {};
  el.nav_id = "company";
  return el;
});
