<template>
  <PreLoad v-if="!apploaded" />
  <component :is="layout" v-else-if="currentUser && company">
    <router-view />
  </component>
  <Login v-else />
</template>

<script>
import Login from "./Login";
import PreLoad from "./PreLoad";
import { mapGetters } from "vuex";
import { detectAnyAdblocker } from 'just-detect-adblock';
export default {
  components: {
    Login,
    PreLoad,
  },
  data() {
    return {
      secondsSinceLastActivity: 0,
      maxInactivity: 20,
      active: true,
      inactivity_interval: null,
      adblocker_swal: null,
    };
  },
  computed: {
    layout() {
      return `${this.$route.meta.layout || "default"}-layout`;
    },
    ...mapGetters({
      currentUser: "currentUser",
      company: "company",
      apploaded: "loaded",
      settings: 'settings',
    }),
  },
  watch: {
    currentUser: {
      deep: true,
      handler: function (val) {
        if (val) this.$router.checkRouteRights();
      },
    },
  },
  created() {
    // Init Vuex Store Data
    this.$store.dispatch("startup").then(async () => {
      // check route rights
      if (this.currentUser) this.$router.checkRouteRights();
      // init onboarding-Process
      // if (!(await this.$store.dispatch('getSetting', 'oboarding_complete'))) return this.$router.push({ name: "onboarding_process" });
    });
    // User promt on adblocker usage
    detectAnyAdblocker().then(detected => {
      if (!detected) return;
      let api_host = new URL(process.env.VUE_APP_API_BASE_URL).host;
      api_host = api_host.split(".")[api_host.split(".").length - 2];
      let curr_host = new URL(window.location.href).host;
      curr_host = curr_host.split(".")[curr_host.split(".").length - 2];
      if (api_host == curr_host) return console.warn(`Adblocker detected. This should only matter, when the admin_panel is not displayed at host: ${api_host}.`);
      console.warn(`Adblocker detected. This should be turned of to ensure, no errors to connect to api accurs.`);
      this.$swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        showConfirmButton: false,
        title: "Bitte Adblocker deaktivieren",
        footer: "Es können sonst ggf. Fehler auftreten."
      });
    })
  },
};
</script>
<style>
body {
  font: 1rem/1.7 var(--font-family-sans-serif);
}
</style>
