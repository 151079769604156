<template>
  <d-container fluid class="main-content-container px-lg-4">
    <!-- Error -->
    <div class="error" v-if="!data">
      <div class="error__content">
        <h2>404</h2>
        <h3>Nicht gefunden!</h3>
        <p>Diese Filiale konnte nicht gefunden werden.</p>
        <d-button pill @click="$router.go(-1)">&larr; Zurück</d-button>
      </div>
    </div>
    <template v-else>
      <d-row no-gutters class="page-header py-4">
        <b-col
          cols="12"
          sm="8"
          lg="9"
          class="text-center text-sm-left mb-4 mb-sm-0"
        >
          <span
            class="text-uppercase page-subtitle"
            @click="$router.push({ name: 'assets' })"
            style="cursor: pointer"
            >Vermögenswerte</span
          >
          <h3 class="page-title">
            <template v-if="edit_title == false">
              {{ data.name }}<br class="d-sm-none" /><small
                style="cursor: pointer"
                @click="
                  edit.name = data.name;
                  edit_title = true;
                "
                v-if="auth_modify"
                ><span class="material-icons text-secondary ml-2">edit</span
                ><span class="d-sm-none">bearbeiten</span></small
              >
            </template>
            <div v-else class="d-flex mr-5">
              <b-form-input v-model="edit.name" />
              <b-button class="ml-2" @click="handleEditTitle">Ändern</b-button>
              <span
                class="material-icons text-secondary ml-2 my-auto"
                style="cursor: pointer"
                @click="edit_title = false"
                >close</span
              >
            </div>
          </h3>
        </b-col>
        <b-col cols="12" sm="4" lg="3" class="text-md-right">
          <b-dropdown variant="light" right text="Weitere Aktionen">
            <b-dropdown-item disabled
              >Verfügbarkeitsübersicht öffnen</b-dropdown-item
            >
            <b-dropdown-item @click="handleRemove"
              >Objekt löschen</b-dropdown-item
            >
          </b-dropdown>
        </b-col>
      </d-row>
      <b-row class="mb-2">
        <b-col cols="12" lg="8">
          <d-card class="card-small mt-2">
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              <b-form-group>
                <template slot="label">
                  <div class="d-flex justify-content-between">
                    Beschreibung
                    <div v-if="auth_modify">
                      <span
                        v-if="edit_main == false"
                        class="material-icons"
                        @click="edit_main = true"
                        style="cursor: pointer"
                        >create</span
                      >
                      <div v-else>
                        <span
                          class="material-icons"
                          @click="handleSaveMain"
                          style="cursor: pointer"
                          >check</span
                        >
                        <span
                          class="material-icons"
                          @click="
                            edit_main = false;
                            edit = {
                              description: data.description,
                              address: { ...data.address },
                            };
                            validated = false;
                          "
                          style="cursor: pointer"
                          >close</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <b-form-textarea
                  v-if="edit_main"
                  v-model="edit.description"
                  placeholder="keine Beschreibung angegeben"
                />
                <small v-else>{{
                  data.description
                    ? data.description
                    : "keine Beschreibung angegeben"
                }}</small>
              </b-form-group>
            </d-card-body>
          </d-card>
          <!-- TODO: ggf. Anzeige in welchen Unterrichtsstunden der Asset verwendet wird -->
        </b-col>
        <d-col cols="12" lg="4">
          <d-card class="card-small mt-2">
            <d-card-header class="border-bottom d-flex justify-content-between">
              Medien
            </d-card-header>
            <d-card-body class="d-flex flex-column px-2 px-lg-3">
              Hier können Sie später Bilder hinterlegen
            </d-card-body>
          </d-card>
        </d-col>
      </b-row>
    </template>
  </d-container>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
export default {
  name: "CourseShow",
  data() {
    return {
      moment,
      auth_modify: false,
      data: null,
      // TITLE
      edit_title: false,
      // MAIN
      edit: null, // Copy of data, which can altered by user
      edit_main: false,
      validated: false,
    };
  },
  computed: {
    ...mapGetters(["assets"]),
  },
  methods: {
    async init() {
      this.data = this.assets.find(
        (asset) => asset.id == this.$route.params.id
      );
      if (!this.data) return;
      this.edit = {
        name: this.data.name,
        description: this.data.description,
      };
    },
    async handleEditTitle() {
      if (
        !this.data.processed_unit_name &&
        (this.edit.name == null || this.edit.name.trim() == "")
      )
        return;
      await this.$store.dispatch("updateAsset", {
        id: this.data.id,
        name: this.edit.name,
      });
      this.edit_title = false;
    },
    validate() {
      this.validated = true;
      return true;
    },
    async handleSaveMain() {
      if (!this.validate()) return;
      await this.$store.dispatch("updateAsset", {
        id: this.data.id,
        description: this.edit.description,
      });
      this.validated = false;
      this.edit = {
        name: this.data.name,
        description: this.data.description,
      };
      this.edit_main = false;
    },
    async handleRemove() {
      await this.$store.dispatch('removeAsset', { id: this.data.id })
    }
  },
  watch: {
    assets() {
      this.init();
    },
  },
  async created() {
    this.auth_modify = await this.$store.dispatch(
      "haveUserRight",
      "asset_modify"
    );
    this.init();
  },
};
</script>
