import Vue from 'vue';
import Router from 'vue-router';
import store from '../store';

import Dashboard from '@/views/Dashboard.vue';
import UserProfile from '@/views/UserProfile.vue';
import Error404 from '@/views/Error404.vue';
import ComponentsOverview from '@/views/ComponentsOverview.vue';
import Tables from '@/views/Tables.vue';
import BlogPosts from '@/views/BlogPosts.vue';
import Notifications from '@/views/Notifications.vue';
import API from '@/plugins/api.js';

import education from './education';
import company from "./company";
import crm from './crm';
import settings from "./settings";
import userManagement from './user-management';
import more from './more';
import contract from './contract';
import support from './support';

Vue.use(API);
Vue.use(Router);

var router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'active',
  linkExactActiveClass: 'exact-active',
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboard,
      meta: {
        title: 'Übersicht',
        description: 'Eine übersichtliche Zusammenstellung aller Informationen zu anstehenden Aufgaben und Status der Anwendung',
        material_icon: 'home',
        tags: [
          'uebersicht',
          'dashboard',
          'home',
          'haus',
          'start'
        ],
        shortcuts: [
          'start'
        ],
        rights: [
        ]
      }
    },
    {
      path: '/notifications',
      name: 'notifications',
      component: Notifications,
    },
    {
      path: '/user/current',
      name: 'current-user',
      component: UserProfile,
    },
    {
      path: '/user/:id',
      name: 'user',
      component: UserProfile,
    },
    {
      path: '/errors',
      name: 'errors',
      component: Error404,
    },
    {
      path: '/authorization_required',
      name: 'authorization_required',
      component: Error404,
    },
    {
      path: '/components-overview',
      name: 'components-overview',
      component: ComponentsOverview,
    },
    {
      path: '/tables',
      name: 'tables',
      component: Tables,
    },
    {
      path: '/blog-posts',
      name: 'blog-posts',
      component: BlogPosts,
    },
    ...education,
    ...company,
    ...crm,
    ...userManagement,
    ...contract,
    ...settings,
    ...more,
    ...support,
    {
      path: '*',
      redirect: '/errors',
    },
  ],
});
var global_shortcuts = [];
router.options.routes.forEach(route => {
  if (route.meta && route.meta.shortcuts) route.meta.shortcuts.forEach(keyword => {
    keyword = keyword.trim().toLowerCase();
    if (global_shortcuts.find(elem => elem.keyword == keyword)) throw new Error(`Shortcut '${keyword}' is already used.`);
    global_shortcuts.push({
      keyword: keyword,
      name: route.name
    });
  });
});
router.dispatchShortcut = (command) => {
  command = command.trim().toLowerCase();
  if (command == "") return null;
  switch (command) {  // custom actions NEU/NEW & FAST/SCHNELL
    case "fast":
    case "schnell":
      if (router.history.current.name == "lesson_new") router.push({ name: "dashboard" });
      router.push({ name: "lesson_new", params: { redirect: false } });
      return;
    case "neu":
    case "new":
      if (router.history.current.name == "lesson_new") router.push({ name: "dashboard" });
      router.push({ name: "lesson_new" });
      return;
  }
  let shortcut = global_shortcuts.find(shortcut => shortcut.keyword == command);
  if (!shortcut) throw new Error("NOT_FOUND");
  if (router.history.current.name != shortcut.name) router.push({ name: shortcut.name });
};
router.dictionary = (searchText) => {
  searchText = searchText.trim().toLowerCase();
  if (searchText == "") return null;
  return router.options.routes.filter(elem => {
    if (elem.meta && !elem.meta.tags) return false; // only entries with tags are part of the result
    if (elem.meta && elem.meta.title && (elem.meta.title.trim().toLowerCase().includes(searchText))) return true;
    if (elem.meta && elem.meta.tags && elem.meta.tags.map(entry => entry.trim().toLowerCase()).find(entry => entry.includes(searchText))) return true;
    return false;
  });
}
// RIGHT-Management
router.missingRouteRights = (name) => {
  let route = router.options.routes.find(route => route.name == name);
  if (!route) throw new Error("NOT_FOUND(route) - This route was not found in the router");
  if (route.meta && route.meta.rights) {
    let missingrights = [];
    route.meta.rights.forEach(right => {
      right = right.trim().toLowerCase();
      if (!false) missingrights.push(right);
    });
    return missingrights;
  }
  return [];
}
router.checkRouteRights = () => {
  let missingRights = router.missingRouteRights(router.history.current.name);
  if (missingRights.length>0) router.push({ name: "authorization_required", params: {rights: missingRights}}); // rerun router.beforeEach()
}
router.beforeEach((to, from, next) => {
  if (!store.getters.currentUser) return next(); // if not logged in, stay in route until login
  let missingRights = router.missingRouteRights(to.name);
  if (missingRights.length == 0) return next();
  next({ name: "authorization_required", params: {rights: missingRights}});
});
export default router;
// TESTING
if (process.env.NODE_ENV !== "production") {
  let missingcomponents = [];
  router.options.routes.forEach(route => {
    if (route.name != "errors" && route.component == Error404) missingcomponents.push(route.name);
  });
  if (missingcomponents.length>=0) console.warn("Fehlende Routen-Komponenten: ", missingcomponents);
}
