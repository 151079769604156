<template>
  <d-container fluid class="main-content-container px-4">
    <d-row no-gutters class="page-header py-4">
      <a col sm="12" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">System</span>
        <h3 class="page-title">Abrechnung & Vertrag</h3>
      </a>
    </d-row>
    <d-row>

    </d-row>
  </d-container>
</template>

<script>
export default {
  name: "Invoice",
  data() {
    return {};
  }
};
</script>
