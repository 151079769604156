<template>
  <b-container fluid>
    <template v-if="!(loading === false)"> </template>
    <template v-else>
      <!-- Controls -->
      <b-row></b-row>
      <!-- ACTIVE: Data -->
      <b-row>
        <b-col class="p-0">
          <!-- REQUESTS -->
          <template v-if="output_request.length != 0 && !hideRequest">
            <small v-if="!hideLabel" class="ml-1">Anfragen</small>
            <b-list-group flush>
              <b-list-group-item
                style="
                  background: rgb(0, 0, 0, 0);
                  border-color: rgb(0, 0, 0, 0);
                "
                v-for="participator in output_request"
                :key="participator.id"
                class="p-0"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <span v-if="!hideRole" class="font-weight-bold"
                      >{{ getRoleName(participator.role) }}:
                    </span>
                    <template v-if="participator.type == 'user'">
                      <template v-if="participator.user.name"
                        >{{ participator.user.name }}
                      </template>
                      <b-link
                        :class="
                          participator.user.name ? 'text-muted small' : 'text-secondary'
                        "
                        :to="{ name: 'user', params: { id: participator.id } }"
                        v-b-popover.hover.top="'Profil öffnen'"
                        >{{ participator.user.username }}</b-link
                      >
                    </template>
                    <template v-else>
                      <template v-if="participator.name"
                        >{{ participator.name }}
                      </template>
                      <b-link
                        :class="participator.name ? 'text-muted small' : 'text-secondary'"
                        :href="'mailto:' + participator.id"
                        v-b-popover.hover.top="'Email senden'"
                        >{{ participator.id }}</b-link
                      >
                    </template>
                  </div>
                  <div v-if="!noEvents" class="d-flex">
                    <b-button
                      variant="outline-light"
                      size="sm"
                      class="p-1"
                      @click="
                        $emit('acceptParticipation', {
                          type: participator.type,
                          id: participator.id,
                          role: participator.role,
                        })
                      "
                      style="cursor: pointer"
                    >
                      <i class="material-icons">check</i> Akzeptieren
                    </b-button>
                    <b-button
                      variant="outline-light"
                      size="sm"
                      class="p-1 ml-1"
                      @click="
                        $emit('denieParticipation', {
                          type: participator.type,
                          id: participator.id,
                          role: participator.role,
                        })
                      "
                      style="cursor: pointer"
                    >
                      <i class="material-icons">cancel</i> Ablehnen
                    </b-button>
                  </div>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
          <!-- ACTIVE -->
          <template v-if="!hideActive">
            <small v-if="!hideLabel" class="ml-1">Aktiv<br /></small>
            <small v-if="output_active.length == 0" class="text-muted"
              >keine aktiven Teilnehmer</small
            >
            <b-list-group flush>
              <b-list-group-item
                v-for="participator in output_active"
                :key="participator.id"
                style="
                  background: rgb(0, 0, 0, 0);
                  border-color: rgb(0, 0, 0, 0);
                "
                class="p-0"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <span v-if="!hideRole" class="font-weight-bold"
                      >{{ getRoleName(participator.role) }}:
                    </span>
                    <template v-if="participator.type == 'user'">
                      <template v-if="participator.user.name"
                        >{{ participator.user.name }}
                      </template>
                      <b-link
                        :class="
                          participator.user.name ? 'text-muted small' : 'text-secondary'
                        "
                        :to="{ name: 'user', params: { id: participator.id } }"
                        v-b-popover.hover.top="'Profil öffnen'"
                        >{{ participator.user.username }}</b-link
                      >
                    </template>
                    <template v-else>
                      <template v-if="participator.name"
                        >{{ participator.name }}
                      </template>
                      <b-link
                        :class="participator.name ? 'text-muted small' : 'text-secondary'"
                        :href="'mailto:' + participator.id"
                        v-b-popover.hover.top="'Email senden'"
                        >{{ participator.id }}</b-link
                      >
                    </template>
                  </div>
                  <b-button
                    v-if="!noEvents"
                    variant="outline-light"
                    size="sm"
                    class="p-1"
                    @click="
                      $emit('denieParticipation', {
                        type: participator.type,
                        id: participator.id,
                        role: participator.role,
                      })
                    "
                    style="cursor: pointer"
                  >
                    <i class="material-icons">cancel</i> Ausladen
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
          <!-- INACTIVE -->
          <template v-if="output_inactive.length != 0 && !hideInactive">
            <small v-if="!hideLabel" class="ml-1">Inaktiv</small>
            <b-list-group flush class="text-muted">
              <b-list-group-item
                v-for="participator in output_inactive"
                :key="participator.id"
                style="
                  background: rgb(0, 0, 0, 0);
                  border-color: rgb(0, 0, 0, 0);
                "
                class="p-0"
              >
                <div class="d-flex justify-content-between">
                  <div>
                    <span v-if="!hideRole" class="font-weight-bold"
                      >{{ getRoleName(participator.role) }}:
                    </span>
                    <template v-if="participator.type == 'user'">
                      <template v-if="participator.user.name"
                        >{{ participator.user.name }}
                      </template>
                      <b-link
                        :class="
                          participator.user.name ? 'text-muted small' : 'text-secondary'
                        "
                        :to="{ name: 'user', params: { id: participator.id } }"
                        v-b-popover.hover.top="'Profil öffnen'"
                        >{{ participator.user.username }}</b-link
                      >
                    </template>
                    <template v-else>
                      <template v-if="participator.name"
                        >{{ participator.name }}
                      </template>
                      <b-link
                        :class="participator.name ? 'text-muted small' : 'text-secondary'"
                        :href="'mailto:' + participator.id"
                        v-b-popover.hover.top="'Email senden'"
                        >{{ participator.id }}</b-link
                      >
                    </template>
                  </div>
                  <small
                    v-if="
                      participator.participator_status == 0 &&
                      participator.company_status == 1
                    "
                    class="font-italic"
                    >noch keine Antwort</small
                  >
                  <b-button
                    v-else-if="!noEvents"
                    variant="outline-light"
                    size="sm"
                    :disabled="participator.participator_status == 2"
                    class="p-1"
                    @click="
                      $emit('acceptParticipation', {
                        type: participator.type,
                        id: participator.id,
                        role: participator.role,
                      })
                    "
                    style="cursor: pointer"
                  >
                    <i class="material-icons">check</i> Einladen
                  </b-button>
                </div>
              </b-list-group-item>
            </b-list-group>
          </template>
          <div v-if="showRequestCount && output_request.length>0" class="small ml-2">{{ output_request.length }} Anfragen</div>
        </b-col>
      </b-row>
    </template>
  </b-container>
</template>

<script>
/** EVENTS:
 * - newParticipator
 * - acceptParticipator
 * - denieParticipator
 */
export default {
  name: "participation-manager",
  props: {
    participation: {
      type: [Object, Boolean],
      required: true,
    },
    participators: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      default: null,
    },
    noEvents: {
      type: Boolean,
      default: false,
    },
    hideRequest: {
      type: Boolean,
      default: false,
    },
    hideActive: {
      type: Boolean,
      default: false,
    },
    hideInactive: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hideRole: {
      type: Boolean,
      default: false,
    },
    showRequestCount: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      loading: null,
      data: [],
    };
  },
  computed: {
    student_active() {
      return !!this.participation;
    },
    output() {
      return this.data.filter((el) => !this.role || el.role == this.role);
    },
    output_active() {
      return this.output.filter(
        (el) => el.participator_status == 1 && el.company_status == 1
      );
    },
    output_request() {
      return this.data.filter(
        (el) => el.participator_status == 1 && el.company_status == 0
      );
    },
    output_inactive() {
      return this.data.filter(
        (el) => !(el.participator_status == 1) && el.company_status == 2
      );
    },
  },
  methods: {
    async init() {
      this.loading = true;
      await Promise.all(
        this.participators.map((participator, index) =>
          (async () => {
            this.data[index] = {
              ...participator,
              user:
                participator.type == "user"
                  ? await this.$store.dispatch("getUser", {
                      id: participator.id,
                    })
                  : undefined,
            };
          })()
        )
      );
      this.data = [...this.data];
      this.loading = false;
    },
    getRoleName(input) {
      switch (input) {
        case "organisator":
          return "Organisator";
        case "instructor":
          return "Lehrer";
        case "student":
          return "Teilnehmer";
        case "asset":
          return "Objekt";
        case "other":
          return "Anderes";
        default:
          console.error("Unknown Role.");
          return "Unbekannt";
      }
    },
  },
  async created() {
    this.init();
  },
};
</script>

<style>
</style>
