/* eslint-disable */
import Vue from 'vue';
// Sentry Error logger
import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
if (process.env.NODE_ENV == "production")
  Sentry.init({
    dsn: 'https://abef2aba6fbc475ca5f6c124071a0154@o398137.ingest.sentry.io/5253400',
    integrations: [new VueIntegration({ Vue, attachProps: true })],
    release: `${process.env.npm_package_name}@${process.env.npm_package_version}`,
    environment: process.env.NODE_ENV
  });

import ShardsVue from 'shards-vue';

// Moment.js
const moment = require('moment')
require('moment/locale/de')

Vue.use(require('vue-moment'), {
  moment
});

// Plugins
import API from '@/plugins/api.js';
import APIMethod from '@/plugins/apimethod.js';
import GlobalMethod from '@/plugins/globals.js';
import './plugins/bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import swal from 'sweetalert2';
import Clipboard from 'v-clipboard'

Vue.use(require('vue-moment'));
Vue.use(VueSweetalert2);
Vue.use(API);
Vue.use(APIMethod);
Vue.use(GlobalMethod);
Vue.use(Clipboard)

// Store
import store from './store'

// Styles
import 'bootstrap/dist/css/bootstrap.css';
import '@/scss/shards-dashboards.scss';
import '@/assets/scss/date-range.scss';

// Core
import App from './App.vue';
import router from './router';

// Layouts
import Default from '@/layouts/Default.vue';

ShardsVue.install(Vue);

Vue.component('default-layout', Default);

Vue.config.productionTip = false;
Vue.prototype.$eventHub = new Vue();

const ignoreWarnMessage = 'The .native modifier for v-on is only valid on components but it was used on <a>.';
Vue.config.warnHandler = function (msg, vm, trace) {
  // `trace` is the component hierarchy trace
  if (msg === ignoreWarnMessage) {
    msg = null;
    vm = null;
    trace = null;
  } else console.error(("[Vue warn]: " + msg + trace));
}

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app');
