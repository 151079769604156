var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_credit.description),expression:"settings_obj.payment_credit.description",modifiers:{"bottom":true,"hover":true}}],staticClass:"d-flex"},[_vm._v(" Finanzierung / Kredit möglich "),_c('b-form-checkbox',{staticClass:"ml-2",attrs:{"switch":"","checked":_vm.settings_obj.payment_credit.value.current},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', { name: 'payment_credit', value: !!val }); }}})],1),_c('b-collapse',{staticClass:"ml-2 small",model:{value:(_vm.settings_obj.payment_credit.value.current),callback:function ($$v) {_vm.$set(_vm.settings_obj.payment_credit.value, "current", $$v)},expression:"settings_obj.payment_credit.value.current"}},[_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_credit_advertisement_text.description),expression:"settings_obj.payment_credit_advertisement_text.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"Angebotstext"}},[_c('b-form-textarea',{attrs:{"size":"sm","value":_vm.settings_obj.payment_credit_advertisement_text.value.current ||''},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_credit_advertisement_text',
                value: val.trim() == '' ? null : val.trim(),
              }); }}})],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_credit_bank.description),expression:"settings_obj.payment_credit_bank.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"Name der Bank (optional)"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_credit_bank.value.current || ''},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_credit_bank',
                value: val.trim() == '' ? null : val.trim(),
              }); }}})],1),_c('b-form-group',{directives:[{name:"b-popover",rawName:"v-b-popover.bottom.hover",value:(_vm.settings_obj.payment_credit_bank_link.description),expression:"settings_obj.payment_credit_bank_link.description",modifiers:{"bottom":true,"hover":true}}],attrs:{"label":"Link zur Bank / zum Angebot (optional)"}},[_c('b-form-input',{attrs:{"size":"sm","value":_vm.settings_obj.payment_credit_bank_link.value.current || ''},on:{"change":function (val) { return _vm.$store.dispatch('setSetting', {
                name: 'payment_credit_bank_link',
                value: val.trim() == '' ? null : val.trim(),
              }); }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }