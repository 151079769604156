<template>
  <d-card class="card-small">

    <!-- Card Header -->
    <d-card-header class="border-bottom">
      <h6 class="m-0">Neue Artikel</h6>
      <div class="block-handle"></div>
    </d-card-header>

    <d-card-body class="p-0">

      <!-- Top Referrals List Group -->
      <d-list-group flush class="list-group-small">
        <d-list-group-item v-for="(item, idx) in articles" :key="idx" class="d-flex px-3">
          <span class="text-semibold text-fiord-blue">{{ item.title }}</span>
          <span class="ml-auto text-right text-semibold text-reagent-gray">{{ item.value }}</span>
        </d-list-group-item>
      </d-list-group>
      <div v-if="loaded && articles.length==0" class="p-3">
        Noch keine Artikel vorhanden!
      </div>
      <div v-if="!loaded">

      </div>

    </d-card-body>

    <d-card-footer class="border-top">
      <d-row>

        <d-col class="text-center">
          <d-button type="submit" class="btn-white" @click="view_all()">
            Alle Artikel
          </d-button>
        </d-col>

      </d-row>
    </d-card-footer>

  </d-card>
</template>

<script>

export default {
  name: 'DashboardArticles',
  data() {
    return {
      loaded: null,
      articles: [],
    }
  },
  methods: {
    view(global_id) {
      this.$router.push({ name: "articles", params: { global_id: global_id}})
    },
    view_all() {
      this.$router.push({ name: "articles"})
    },
    setup() {
      this.loaded=false;
      // Get Data from Global API
      this.loaded=true;
    },
  },
  created() {
    this.setup();
  }
};
</script>
