<template>
  <d-container fluid class="main-content-container px-4 pb-4">
    <!-- Page Header -->
    <d-row no-gutters class="page-header py-4">
      <!-- Page Title -->
      <d-col col sm="4" class="text-center text-sm-left mb-4 mb-sm-0">
        <span class="text-uppercase page-subtitle">Hilfe</span>
        <h3 class="page-title">Feedback / Fehler melden</h3>
      </d-col>
    </d-row>

    <d-row>
      <!-- Editor -->
      <d-col lg="9" md="12">
        <d-card class="card-small mb-3">
          <d-card-body>
            <d-form class="add-new-post">
              <d-input
                size="lg"
                class="mb-3"
                placeholder="Titel"
                v-model="title"
              />
              <Editor v-model="text" />
            </d-form>
          </d-card-body>
        </d-card>
      </d-col>

      <!-- Sidebar Widgets -->
      <d-col lg="3" md="12">
        <d-card class="card-small mb-3">
          <d-card-body class="p-0 py-2">
            <d-list-group flush>

              <!-- Categories -->
              <d-list-group-item class="px-3 pb-2">
                <template v-for="category in categories">
                  <d-checkbox
                    class="mb-1"
                    v-model="category.value"
                    :key="category.name"
                    >{{ category.name }}</d-checkbox
                  >
                </template>
              </d-list-group-item>

              <!-- Add New Category -->
              <d-list-group-item class="px-3 pb-2">
                <b-input-group class="mb-2">
                  <b-form-input
                    placeholder="Andere Kategorie"
                    v-model="new_category_helper"
                    @keyup.enter.native="handleNewCategory"
                  />
                  <b-input-group-addon append>
                    <d-button class="btn-white px-2" @click="handleNewCategory"
                      ><i class="material-icons">add</i></d-button
                    >
                  </b-input-group-addon>
                </b-input-group>
              </d-list-group-item>

              <d-list-group-item class="d-flex px-3">
                <d-button size="sm" class="btn-outline-accent" @click="publish">
                  <i class="material-icons">save</i> Abschicken
                </d-button>

                <d-button size="sm" class="btn-accent ml-auto" @click="reset">
                  <i class="material-icons">file_copy</i> Zurücksetzen
                </d-button>
              </d-list-group-item>
            </d-list-group>
          </d-card-body>
        </d-card>
      </d-col>
    </d-row>
  </d-container>
</template>

<script>
import Editor from "@/components/editor/Editor.vue";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      title: null,
      text: null,
      new_category_helper: null,
      categories: [
        {
          name: "Programmfehler",
          value: false,
        },
        {
          name: "Design",
          value: false,
        },
        {
          name: "Problem mit einer Schnittstelle",
          value: false,
        },
        {
          name: "Funktionswunsch",
          value: false,
        },
      ],
    };
  },
  computed: {
    reference() {
      return JSON.stringify(this.categories.filter(el => el.value).map(el => el.name))
    }
  },
  methods: {
    reset() {
      this.title = null;
      this.text = null;
      this.reference = null;
      this.categories = this.categories.map(el => {
        el.value = false;
        return el;
      });
    },
    handleNewCategory() {
      if (!this.new_category_helper) return;
      if (this.categories.find((el) => el.name == this.new_category_helper))
        return (this.categories = this.categories.map((el) => {
          if (el.name == this.new_category_helper) el.value = true;
          return el;
        }));
      this.categories.push({ name: this.new_category_helper, value: true });
      this.new_category_helper = null;
    },
    async publish() {
      if (!this.text || this.text.trim() == "" || this.text.trim() == "<br>") return this.$globalmethod.toast_info("Bitte geben Sie einen Text an.");
      await this.$store.dispatch("reportError", {
        title: this.title,
        text: this.text,
        reference: this.reference,
      });
      this.reset();
    },
  },
};
</script>
